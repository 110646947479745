import { all, put, takeLatest, select, call, take } from 'redux-saga/effects';
import { omit } from 'lodash';
import user from 'propera/user';
import { actions as analyticsActions } from 'propera/analytics';
import { getApiCall, postApiCall, deleteApiCall, patchApiCall } from 'propera/HTTP';
import { actions as monitoringActions } from 'propera/monitoring';
import * as slimSearchActions from 'slimSearch/actions';
import { getRfqSelector } from 'slimSearch/selectors';
import { SECTIONS } from 'slimSearch/constants';
import { mapRfqToQuote } from 'slimSearch/utils/mapOpenFreight.utils';
import { endpointConstructor } from 'utils';
import * as actions from './actions';
import { ANALYTIC_EVENTS, ENDPOINTS, NUM_RECENT_SEARCHES_TO_SHOW } from './constants';

const { registerSegmentEvent } = analyticsActions;

function* fetchRecentSearches() {
  const userIsLoggedIn = yield select(user.isLoggedIn);
  if (!userIsLoggedIn) {
    return;
  }

  const userKey = yield select(user.getUserKey);

  yield put({
    type: actions.GET_RECENT_SEARCHES.PENDING,
    payload: true
  });

  try {
    const response = yield call(getApiCall, {
      payload: {
        path: endpointConstructor(ENDPOINTS.getSearchHistory, {
          userKey,
          limit: NUM_RECENT_SEARCHES_TO_SHOW
        })
      }
    });

    yield put({
      type: actions.GET_RECENT_SEARCHES.SUCCESS,
      payload: response
    });
  } catch (err) {
    yield put(monitoringActions.exceptionReporter('Failed in fetching search history' + err));
    yield put({
      type: actions.GET_RECENT_SEARCHES.FAILURE,
      payload: err
    });
  } finally {
    yield put({
      type: actions.GET_RECENT_SEARCHES.PENDING,
      payload: false
    });
  }
}

function* sendSearchItemToServer({ searchItem }) {
  const userKey = yield select(user.getUserKey);

  yield put({
    type: actions.ADD_TO_RECENT_SEARCHES.PENDING,
    payload: true
  });

  try {
    const response = yield call(postApiCall, {
      payload: {
        path: endpointConstructor(ENDPOINTS.postSearchHistoryItem, { userKey }),
        data: searchItem
      }
    });

    yield put({
      type: actions.ADD_TO_RECENT_SEARCHES.SUCCESS,
      payload: response
    });
  } catch (err) {
    yield put(monitoringActions.exceptionReporter('Failed in saving search history' + err));
    yield put({
      type: actions.ADD_TO_RECENT_SEARCHES.FAILURE,
      payload: err
    });
  } finally {
    yield put({
      type: actions.ADD_TO_RECENT_SEARCHES.PENDING,
      payload: false
    });
  }
}

function* saveASearch() {
  const shipment = yield select(getRfqSelector);
  const recentSearchDifferentiatingFields = omit(shipment, [
    'pickupEvent',
    'insuranceValueAmount',
    'fulfilmentInfo',
    'pricePreference',
    'declaredCustoms',
    'load.declaredValue.value'
  ]);

  const { payload: response } = yield take([
    slimSearchActions.GET_RFQ.SUCCESS,
    slimSearchActions.UPDATE_RFQ.SUCCESS
  ]);

  const searchItem = {
    rfqFields: recentSearchDifferentiatingFields,
    rfqKey: response?.messageHeader?.conversationID
  };

  yield put(actions.addToRecentSearches(searchItem));
}

function* handleRecentSearchSelected({ recentSearchItem }) {
  const {
    rfqKey,
    userSelectedAddress: { origin: userAddressOrigin, destination: userAddressDestination }
  } = recentSearchItem;

  yield put(
    slimSearchActions.saveQuoteFromSearchUrl(mapRfqToQuote({ shipment: recentSearchItem }))
  );

  if (userAddressOrigin) {
    yield put(
      slimSearchActions.setUserAddressFromAddressBook({
        type: 'origin',
        address: userAddressOrigin.origin
      })
    );
  }
  if (userAddressDestination) {
    yield put(
      slimSearchActions.setUserAddressFromAddressBook({
        type: 'destination',
        address: userAddressDestination.destination
      })
    );
  }

  yield put(registerSegmentEvent(ANALYTIC_EVENTS.recentSearchItemSelected, { rfq_key: rfqKey }));

  // Once a "recent search" item is selected, the user is left only with "goods" section to fill-in
  yield put(slimSearchActions.updateField('activeSection', SECTIONS.GOODS));
}

function* pinSearchItemSaga(action) {
  const userKey = yield select(user.getUserKey);
  const { searchId, rfqKey, isPinned } = action;

  yield put({
    type: actions.PIN_SEARCH_ITEM.PENDING,
    payload: true
  });

  try {
    yield call(patchApiCall, {
      payload: {
        path: endpointConstructor(ENDPOINTS.patchSearchHistoryItem, { userKey, searchId }),
        data: { isPinned }
      }
    });

    yield put(
      registerSegmentEvent(ANALYTIC_EVENTS.recentSearchItemPinClick, {
        rfq_key: rfqKey,
        is_pinned: isPinned
      })
    );

    yield put({
      type: actions.PIN_SEARCH_ITEM.SUCCESS
    });
    yield put(actions.getRecentSearches({ limit: NUM_RECENT_SEARCHES_TO_SHOW }));
  } catch (err) {
    yield put(monitoringActions.exceptionReporter('Failed in pinning search item' + err));
    yield put({
      type: actions.PIN_SEARCH_ITEM.FAILURE,
      payload: err
    });
  } finally {
    yield put({
      type: actions.PIN_SEARCH_ITEM.PENDING,
      payload: false
    });
  }
}

function* deleteSearchHistorySaga(action) {
  const userKey = yield select(user.getUserKey);
  const { searchId } = action;

  yield put({
    type: actions.DELETE_SEARCH_HISTORY.PENDING,
    payload: true
  });

  try {
    const endpoint = searchId ? ENDPOINTS.deleteSearchItem : ENDPOINTS.deleteSearchHistory;
    yield call(deleteApiCall, {
      payload: {
        path: endpointConstructor(endpoint, { userKey, searchId })
      }
    });

    yield put({
      type: actions.DELETE_SEARCH_HISTORY.SUCCESS
    });
    yield call(fetchRecentSearches);
  } catch (err) {
    yield put(monitoringActions.exceptionReporter('Failed in deleting search history' + err));
    yield put({
      type: actions.DELETE_SEARCH_HISTORY.FAILURE,
      payload: err
    });
  } finally {
    yield put({
      type: actions.DELETE_SEARCH_HISTORY.PENDING,
      payload: false
    });
  }
}

function* reportAnalyticsSaga({ eventData }) {
  yield put(registerSegmentEvent(eventData));
}

export default function* watcher() {
  yield all([
    takeLatest(actions.getRecentSearches({}).type, fetchRecentSearches),
    takeLatest(slimSearchActions.searchServiceCompleted().type, saveASearch),
    takeLatest(actions.setSearchWithNewFields().type, handleRecentSearchSelected),
    takeLatest(actions.addToRecentSearches().type, sendSearchItemToServer),
    takeLatest(actions.pinSearchItem().type, pinSearchItemSaga),
    takeLatest(actions.deleteSearchHistory().type, deleteSearchHistorySaga),
    takeLatest(actions.reportAnalytics().type, reportAnalyticsSaga)
  ]);
}
