import React from 'react';
import ReactDOM from 'react-dom';
import { connect } from 'microfronts-redux';
import userModel from 'propera/user';
import { Dropdown, Avatar, Icon } from '@freightos/design-system';

import styled from 'styled-components/macro';
import { upperFirst, toUpper } from 'lodash/fp';
import { getPorts, getLocations, getAddressBookAddresses } from 'slimSearch/actions';
import { mobileOverLayStyle } from 'slimSearch/sharedStyles';

import { ADDRESS_TYPES, SECTIONS } from 'slimSearch/constants';
import { t } from 'utils/translationProvider';
import Category from 'slimSearch/components/Category';
import OriginDestinationMobile from 'slimSearch/components/sections/locations/mobile/OriginDestinationMobile';
import { getCountryFlag, getLocationLabel } from 'slimSearch/utils';
import { isMobileSelector } from 'slimSearch/selectors';
import OriginDestinationSelect from './OriginDestinationSelect';

const { isLoggedIn } = userModel;

export const OriginDestination = ({
  type,
  stateType,
  activeSection,
  isLoggedIn,
  isMobile,
  touched,
  mode,
  getAddressBookAddresses,
  userSelectedAddress,
  userAddressesLoaded
}) => {
  const locationType = stateType[`${type}Location`];
  const isPort = locationType.locationTypeCode === ADDRESS_TYPES.PORT.value;
  const isFulfilmentCenter =
    locationType.locationTypeCode === ADDRESS_TYPES.FULFILMENT_CENTER.value;
  const isUps = locationType.locationTypeCode === ADDRESS_TYPES.LAST_MILE_DELIVERY.value;
  const country = locationType?.countryID?.value;
  const isStaticCenters = isUps || isFulfilmentCenter;

  React.useEffect(() => {
    if (type === SECTIONS.ORIGIN && !isStaticCenters && !isPort && country) {
      getAddressBookAddresses({ type: 'pickup', country });
    }
    if (type === SECTIONS.DESTINATION && !isStaticCenters && !isPort && country) {
      getAddressBookAddresses({ type: 'delivery', country });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [type, country, isLoggedIn]);

  const title =
    type === SECTIONS.ORIGIN
      ? t('search/ss_origin_header_text', 'Where are you shipping from?')
      : t('search/ss_destination_header_text', 'Where are you shipping to?');

  const LocationCodeTitle = () => {
    let title = locationType.locationCode;

    if (isPort) {
      title = toUpper(locationType.locationCode);
      return <span title={title}>{title}</span>;
    }

    if (isFulfilmentCenter) {
      title = locationType.ecommerceKey ? locationType.ecommerceKey : locationType.label;
      return <span title={title}>{title}</span>;
    }

    if (userAddressesLoaded) {
      return <span title={title}>{userSelectedAddress?.[type]?.company ?? title}</span>;
    } else {
      return (
        <span>
          <Icon type="loading" size="tiny" />
        </span>
      );
    }
  };
  const getSubtitle = () => {
    if (!touched) {
      return title;
    }

    const locationTypeLabel = getLocationLabel(type, locationType);
    if (locationType.locationTypeCode && !locationType.locationCode) {
      return (
        <>
          {locationTypeLabel} |{' '}
          {locationType.countryID.value ? (
            <StyledAvatar
              country={getCountryFlag(locationType.countryID.value)}
              type="flag"
              size="small"
            />
          ) : null}{' '}
        </>
      );
    }

    if (locationType.locationTypeCode && locationType.locationCode) {
      return (
        <>
          {locationTypeLabel} |{' '}
          {locationType.countryID.value ? (
            <StyledAvatar
              country={getCountryFlag(locationType.countryID.value)}
              type="flag"
              size="small"
            />
          ) : null}{' '}
          <LocationCodeTitle />
        </>
      );
    }

    return title;
  };

  if (isMobile && mode !== 'search' && mode !== 'services-results') {
    return <div>{getSubtitle()}</div>;
  }

  if (isMobile && activeSection === type) {
    return ReactDOM.createPortal(
      <OriginDestinationMobile
        isUps={isUps}
        type={type}
        locationType={locationType}
        title={title}
      />,
      document.body
    );
  }

  return (
    <StyledDropdown
      data-test-id={`${SECTIONS[type]}-dropdown`}
      {...(isMobile
        ? {
            transitionName: null
          }
        : { getPopupContainer: (trigger) => trigger.parentElement })}
      visible={activeSection === type}
      disabled={activeSection === type}
      overlayStyle={isMobile ? mobileOverLayStyle : ''}
      overlay={<OriginDestinationSelect type={type} title={title} locationType={locationType} />}
      size="large"
      trigger={['click']}
    >
      <Category
        sectionTitle={t(upperFirst(type), upperFirst(type))}
        subTitle={getSubtitle()}
        section={type}
      />
    </StyledDropdown>
  );
};

const StyledDropdown = styled(Dropdown)`
  position: relative;
`;

const StyledAvatar = styled(Avatar)`
  &.ant-avatar-sm {
    width: 12px;
    height: 12px;
    line-height: 12px;
    top: -1px;
  }
`;

const mapStateToProps = (store, { type }) => ({
  stateType: store.search.quote[type],
  activeSection: store.search.activeSection,
  userAddressesLoaded: store.search.userAddressesLoaded,
  userSelectedAddress: store.search.quote?.userSelectedAddress,
  isMobile: isMobileSelector(store),
  warehouses: store.search.locationsData.destination.brandLocations,
  touched: store.search?.touched[type],
  isLoggedIn: isLoggedIn(store)
});

export default connect(mapStateToProps, {
  getPorts,
  getLocations,
  getAddressBookAddresses
})(OriginDestination);
