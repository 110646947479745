import React, { useState } from 'react';
import { Button, Tooltip, Popconfirm } from '@freightos/design-system';
import { t } from 'utils/translationProvider';
import { deleteSearchHistory as deleteSearchHistoryAction } from '../actions';
import { connect } from 'react-redux';

const ClearIcon = ({ searchId, deleteSearchHistory }) => {
  const [deleteConfirmationOpened, setDeleteConfirmationOpened] = useState(false);

  const showDeleteConfirmation = (e) => {
    e.stopPropagation();
    setDeleteConfirmationOpened(true);
  };

  const hideDeleteConfirmation = (e) => {
    e.stopPropagation();
    setDeleteConfirmationOpened(false);
  };

  const clearItem = (e) => {
    e.stopPropagation();
    setDeleteConfirmationOpened(false);
    deleteSearchHistory(searchId);
  };

  return (
    <Popconfirm
      placement="left"
      title={t(
        'myRecentSearches/deleteConfirmTitle',
        'Are you sure you want to delete this search?'
      )}
      okText={t('myRecentSearches/deleteConfirmYes', 'Yes')}
      okType="danger"
      cancelText={t('myRecentSearches/deleteConfirmNo', 'No')}
      onConfirm={clearItem}
      onCancel={hideDeleteConfirmation}
      visible={deleteConfirmationOpened}
      getPopupContainer={(trigger) => trigger.parentNode}
      className="search-history-delete-confirmation-popconfirm"
    >
      <Tooltip
        placement="topRight"
        title={
          deleteConfirmationOpened ? null : t('myRecentSearches/clearTooltip', 'Clear this search')
        }
      >
        <Button
          data-test-id="search-history-item-clear"
          type="tertiary"
          size="small"
          icon="clear-circled"
          onClick={showDeleteConfirmation}
        />
      </Tooltip>
    </Popconfirm>
  );
};

export default connect(null, { deleteSearchHistory: deleteSearchHistoryAction })(ClearIcon);
