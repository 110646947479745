import { vendorsActionType } from 'results/vendors/vendors.actions';

export default (state = {}, action) => {
  switch (action.type) {
    case 'AUTH_UI/LOG_OUT': {
      return {};
    }
    case vendorsActionType.VENDOR_LOADING:
      return { ...state, [action.payload]: { loading: true } };

    case vendorsActionType.GET_VENDORS_DATA_SUCCESS:
      return action.payload.ID ? { ...state, [action.payload.ID]: action.payload } : state;
    default:
      return state;
  }
};
