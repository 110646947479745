import React from 'react';
import styled, { css } from 'styled-components/macro';
import { connect } from 'microfronts-redux';
import { updateField, searchAnalytics } from 'slimSearch/actions';
import { ANALYTICS_EVENT_NAME, SECTIONS } from 'slimSearch/constants';
import {
  fdsComponentSecondaryColor,
  fdsComponentSpacingLg,
  fdsComponentSpacingSm,
  fdsComponentBoxShadowToken,
  fdsComponentPrimaryColorClick,
  fdsComponentTextColorPlaceholder,
  fdsBodyTextColor,
  fdsErrorColor,
  fdsComponentBackgroundColor
} from '@freightos/design-system/dist/tokens';

import { Icon } from '@freightos/design-system';
import { categoryNotValid } from 'slimSearch/utils';
import { isMobileSelector } from 'slimSearch/selectors';

export const Category = ({
  activeSection,
  updateField,
  searchAnalytics,
  touched,
  pristine,
  sectionTitle,
  subTitle,
  section,
  sectionData,
  isMobile,
  readOnly
}) => {
  const error = !pristine && categoryNotValid(section, sectionData);
  const valid = !pristine && !categoryNotValid(section, sectionData);

  return (
    <CategoryWrapper
      tabIndex={0}
      data-test-id={`CategoryWrapper-${section}`}
      isMobile={isMobile}
      active={activeSection === section}
      onClick={() => {
        if (readOnly) {
          return false;
        }
        if (!touched) {
          const eventName = ANALYTICS_EVENT_NAME[`SS_${section.toUpperCase()}_BOX_INPUT_CLICKED`];
          if (eventName) {
            searchAnalytics({
              name: eventName,
            });
          }
        }
        updateField(`touched.${section}`, true);
        return activeSection === section
          ? updateField('activeSection', null)
          : updateField('activeSection', section);
      }}
    >
      <StyledTitle error={error} data-test-id={`CategoryWrapper-${section}-title`}>
        {sectionTitle}{' '}
        <CategoryStatus isMobile={isMobile}>
          {error && (
            <Icon
              type="alert-circled"
              color="@fds-error-color"
              size={isMobile ? 'small' : 'tiny'}
            />
          )}{' '}
          {valid && (
            <Icon
              type="check"
              color="@fds-component-color-positive"
              size={isMobile ? 'small' : 'tiny'}
            />
          )}
        </CategoryStatus>
      </StyledTitle>
      <SubTitle
        title={section === SECTIONS.ORIGIN || section === SECTIONS.DESTINATION ? null : subTitle}
        touched={touched}
        data-test-id={`CategoryWrapper-${section}-subtitle`}
        isMobile={isMobile}
      >
        {subTitle}
      </SubTitle>
    </CategoryWrapper>
  );
};

export const CategoryWrapper = styled.div`
  width: 100%;
  cursor: pointer;
  box-sizing: border-box;
  margin: 0;
  padding: ${fdsComponentSpacingSm} ${fdsComponentSpacingLg};
  border: 1px solid transparent;
  border-radius: 6px;
  background: ${fdsComponentBackgroundColor};

  ${({ isMobile }) =>
    isMobile
      ? css`
          position: relative;
          border: 1px solid ${fdsBodyTextColor};
          border-radius: 6px;
          margin-bottom: ${fdsComponentSpacingLg};
        `
      : css`
          background: linear-gradient(${fdsComponentSecondaryColor}, ${fdsComponentSecondaryColor})
              right / 1px 40%,
            transparent;
          background-repeat: no-repeat;
        `}

  ${({ active }) =>
    active &&
    css`
      border: 1px solid ${fdsComponentPrimaryColorClick};
      border-radius: 6px;
      box-shadow: ${fdsComponentBoxShadowToken};
    `};

  :last-child {
    background: none;
  }
`;

const StyledTitle = styled.h5`
  transition: all 0.5s ease-in-out;

  > i {
    position: absolute;
    margin: -1px 0 0 5px;
  }

  ${({ error }) =>
    error
      ? css`
          color: ${fdsErrorColor};
        `
      : ''}
`;

export const SubTitle = styled.p`
  overflow: hidden;
  transition: all 0.5s ease-in-out;
  text-overflow: ellipsis;
  white-space: nowrap;
  ${({ isMobile }) =>
    isMobile
      ? ''
      : css`
          max-width: 15vw;
        `}
  ${({ touched }) =>
    touched
      ? ''
      : css`
          color: ${fdsComponentTextColorPlaceholder};
        `}
`;

const CategoryStatus = styled.span`
  ${({ isMobile }) =>
    isMobile
      ? css`
          position: absolute;
          top: 19px;
          right: 10px;
        `
      : ''}
`;

const mapStateToProps = (store, { section }) => ({
  touched: store.search.touched[section],
  pristine: store.search.pristine[section],
  activeSection: store.search.activeSection,
  goodsReadyRange: store.search.goodsReadyRange,
  sectionData: store.search.quote[section],
  readOnly: store.search.readOnly,
  isMobile: isMobileSelector(store)
});

export default connect(mapStateToProps, { updateField, searchAnalytics })(Category);
