import React from 'react';
import { connect } from 'react-redux';
import styled, { css } from 'styled-components/macro';
import { Button, Tooltip, tokens } from '@freightos/design-system';
import { t } from 'utils/translationProvider';
import { isMobileSelector } from 'slimSearch/selectors';
import { pinSearchItem as pinSearchItemAction } from '../actions';

const PinIcon = ({ searchId, isPinned, rfqKey, isMobile, pinSearchItem }) => {
  const onPinClicked = (e) => {
    e.stopPropagation();
    pinSearchItem(searchId, rfqKey, !isPinned);
  };

  return (
    <Tooltip
      placement="topRight"
      title={
        isPinned
          ? null
          : t('myRecentSearches/pinTooltip', 'Pin this search for quicker results next time')
      }
    >
      <StyledButton
        data-test-id="search-history-item-pin"
        type="tertiary"
        size="small"
        icon="pin-solid"
        isPinned={isPinned}
        isMobile={isMobile}
        onClick={onPinClicked}
      />
    </Tooltip>
  );
};

const StyledButton = styled(Button)`
  ${({ isMobile, isPinned }) =>
    isMobile
      ? css`
          color: ${isPinned ? tokens.fdsPrimaryColor : tokens.fdsColorPrimaryGray60};
        `
      : css`
          color: ${tokens.fdsPrimaryColor};
        `}
  ${({ isPinned }) =>
    isPinned &&
    css`
      visibility: visible;
    `}
`;

const mapStateToProps = (state) => ({
  isMobile: isMobileSelector(state)
});

export default connect(mapStateToProps, { pinSearchItem: pinSearchItemAction })(PinIcon);
