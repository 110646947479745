import React from 'react';

const VeemWordLogo = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="80px" viewBox="0 0 256.23 54.82">
    <title>Veem_primary_logo_notag_hex</title>
    <g id="Layer_2" data-name="Layer 2">
      <g id="Layer_1-2" data-name="Layer 1">
        <path
          fill="#ff8200"
          d="M31.76,33.46C30.11,30.64,15.24,4,15.24,4A8.29,8.29,0,0,0,3.59,1.69h0a8.5,8.5,0,0,0-2.06,11.8l20.63,36.9A8.49,8.49,0,0,0,29.37,54a8.31,8.31,0,0,0,7.2-3.58l8.69-15.62a7.89,7.89,0,0,1-10.88,1.66A13,13,0,0,1,31.76,33.46Z"
        />
        <path
          fill="#007fe0"
          d="M105,33.62H76.48c4.71,7.17,13.6,8.24,20.45,4.5a7.22,7.22,0,0,1,10,2.57,7.42,7.42,0,0,1-2.57,10.06A30.31,30.31,0,0,1,89,54.82c-12.32,0-22-7-26.24-15.85a27.79,27.79,0,0,1-.32-22.27A27.3,27.3,0,0,1,114.7,24C115.35,30.41,111.06,33.62,105,33.62ZM98.43,21.41c-1-4.06-5.46-7.92-10.92-7.92s-10,3.86-10.92,7.92Z"
        />
        <path
          fill="#007fe0"
          d="M163.16,33.62H134.68c4.71,7.17,13.6,8.24,20.45,4.5a7.22,7.22,0,0,1,10,2.57,7.42,7.42,0,0,1-2.57,10.06,30.31,30.31,0,0,1-15.31,4.07c-12.32,0-22-7-26.24-15.85a27.79,27.79,0,0,1-.32-22.27A27.3,27.3,0,0,1,172.9,24C173.55,30.41,169.26,33.62,163.16,33.62Zm-6.53-12.21c-1-4.06-5.46-7.92-10.92-7.92s-10,3.86-10.92,7.92Z"
        />
        <path
          fill="#007fe0"
          d="M240.4,2.53l-.62-.28c-.61-.24-1.22-.47-1.85-.67-.07,0-1.55-.44-2.24-.59-.11,0-1.63-.29-2.29-.36C232.9.58,231.32.5,231,.5h-.11a23.83,23.83,0,0,0-14.88,5.36A23.83,23.83,0,0,0,201.17.5h-.1a26.52,26.52,0,0,0-4.65.49,24.35,24.35,0,0,0-4.09,1.26l-.63.28a23.4,23.4,0,0,0-13.93,21.26V45.5a8.14,8.14,0,0,0,.59,3,6.39,6.39,0,0,0,.26.6,7,7,0,0,0,.36.66,8.66,8.66,0,0,0,.68,1,2.09,2.09,0,0,1,.14.17,8.36,8.36,0,0,0,3.74,2.55,4,4,0,0,0,.45.15q.39.1.78.18a9.18,9.18,0,0,0,1.47.14,8.56,8.56,0,0,0,8.47-8.47V23.79a6.42,6.42,0,0,1,6.41-6.45,6.48,6.48,0,0,1,6.41,6.45V45.5A8.56,8.56,0,0,0,216,54h.1a8.57,8.57,0,0,0,8.48-8.47V23.79a6.41,6.41,0,1,1,12.81,0V45.5A8.56,8.56,0,0,0,245.86,54a9.78,9.78,0,0,0,1.47-.14q.4-.07.78-.18a3.53,3.53,0,0,0,.46-.15A8.39,8.39,0,0,0,252.3,51l.14-.17a7.54,7.54,0,0,0,.68-1,7,7,0,0,0,.37-.66c.09-.2.18-.39.26-.6a8.36,8.36,0,0,0,.38-1.23c0-.06,0-.12,0-.18a8.56,8.56,0,0,0,.16-1.62V23.79A23.38,23.38,0,0,0,240.4,2.53Z"
        />
        <path
          fill="#007fe0"
          d="M54.91,1.67h0A8.5,8.5,0,0,0,43.15,4L30.38,26.83c1.27,2.28,2.29,4.05,2.67,4.67a14.09,14.09,0,0,0,2.35,2.9c4.85,3.4,9.7-.19,11.24-2.09L57.21,13.43A8.5,8.5,0,0,0,54.91,1.67Z"
        />
        <path
          fill="#007fe0"
          d="M248.21,4.36a4,4,0,0,1,.31-1.59,4.09,4.09,0,0,1,.87-1.27,4.27,4.27,0,0,1,1.28-.85,4.15,4.15,0,0,1,3.1,0,4.27,4.27,0,0,1,1.28.85,3.91,3.91,0,0,1,.86,1.27,3.83,3.83,0,0,1,.32,1.59,4,4,0,0,1-1.18,2.86,4.27,4.27,0,0,1-1.28.85,4.15,4.15,0,0,1-3.1,0,4.27,4.27,0,0,1-1.28-.85A4,4,0,0,1,248.52,6,4,4,0,0,1,248.21,4.36Zm.71,0a3.4,3.4,0,0,0,.26,1.36,3.35,3.35,0,0,0,1.74,1.81,3.35,3.35,0,0,0,1.3.26,3.27,3.27,0,0,0,1.29-.26,3.2,3.2,0,0,0,1-.73,3.22,3.22,0,0,0,.71-1.08,3.66,3.66,0,0,0,0-2.71,3.17,3.17,0,0,0-.71-1.09,3.2,3.2,0,0,0-1-.73,3.27,3.27,0,0,0-1.29-.26,3.35,3.35,0,0,0-1.3.26,3.2,3.2,0,0,0-1,.73,3.15,3.15,0,0,0-.7,1.09A3.38,3.38,0,0,0,248.92,4.36ZM250.69,2h1.77c1.09,0,1.64.44,1.64,1.34a1.14,1.14,0,0,1-.36.93,1.61,1.61,0,0,1-.88.35L254.2,6.7h-.76l-1.27-2h-.77v2h-.71Zm.71,2.09h.73a3.62,3.62,0,0,0,.47,0A1.24,1.24,0,0,0,253,4a.77.77,0,0,0,.28-.23.83.83,0,0,0,.1-.45.74.74,0,0,0-.09-.38.65.65,0,0,0-.25-.22,1.13,1.13,0,0,0-.34-.1l-.36,0h-.94Z"
        />
      </g>
    </g>
  </svg>
);

const PayPalLogo = () => (
<svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px"
	 width="124px" height="33px" viewBox="0 0 124 33" enable-background="new 0 0 124 33" xmlSpace="preserve">
<path fill="#253B80" d="M46.211,6.749h-6.839c-0.468,0-0.866,0.34-0.939,0.802l-2.766,17.537c-0.055,0.346,0.213,0.658,0.564,0.658
	h3.265c0.468,0,0.866-0.34,0.939-0.803l0.746-4.73c0.072-0.463,0.471-0.803,0.938-0.803h2.165c4.505,0,7.105-2.18,7.784-6.5
	c0.306-1.89,0.013-3.375-0.872-4.415C50.224,7.353,48.5,6.749,46.211,6.749z M47,13.154c-0.374,2.454-2.249,2.454-4.062,2.454
	h-1.032l0.724-4.583c0.043-0.277,0.283-0.481,0.563-0.481h0.473c1.235,0,2.4,0,3.002,0.704C47.027,11.668,47.137,12.292,47,13.154z"
	/>
<path fill="#253B80" d="M66.654,13.075h-3.275c-0.279,0-0.52,0.204-0.563,0.481l-0.145,0.916l-0.229-0.332
	c-0.709-1.029-2.29-1.373-3.868-1.373c-3.619,0-6.71,2.741-7.312,6.586c-0.313,1.918,0.132,3.752,1.22,5.031
	c0.998,1.176,2.426,1.666,4.125,1.666c2.916,0,4.533-1.875,4.533-1.875l-0.146,0.91c-0.055,0.348,0.213,0.66,0.562,0.66h2.95
	c0.469,0,0.865-0.34,0.939-0.803l1.77-11.209C67.271,13.388,67.004,13.075,66.654,13.075z M62.089,19.449
	c-0.316,1.871-1.801,3.127-3.695,3.127c-0.951,0-1.711-0.305-2.199-0.883c-0.484-0.574-0.668-1.391-0.514-2.301
	c0.295-1.855,1.805-3.152,3.67-3.152c0.93,0,1.686,0.309,2.184,0.892C62.034,17.721,62.232,18.543,62.089,19.449z"/>
<path fill="#253B80" d="M84.096,13.075h-3.291c-0.314,0-0.609,0.156-0.787,0.417l-4.539,6.686l-1.924-6.425
	c-0.121-0.402-0.492-0.678-0.912-0.678h-3.234c-0.393,0-0.666,0.384-0.541,0.754l3.625,10.638l-3.408,4.811
	c-0.268,0.379,0.002,0.9,0.465,0.9h3.287c0.312,0,0.604-0.152,0.781-0.408L84.564,13.97C84.826,13.592,84.557,13.075,84.096,13.075z
	"/>
<path fill="#179BD7" d="M94.992,6.749h-6.84c-0.467,0-0.865,0.34-0.938,0.802l-2.766,17.537c-0.055,0.346,0.213,0.658,0.562,0.658
	h3.51c0.326,0,0.605-0.238,0.656-0.562l0.785-4.971c0.072-0.463,0.471-0.803,0.938-0.803h2.164c4.506,0,7.105-2.18,7.785-6.5
	c0.307-1.89,0.012-3.375-0.873-4.415C99.004,7.353,97.281,6.749,94.992,6.749z M95.781,13.154c-0.373,2.454-2.248,2.454-4.062,2.454
	h-1.031l0.725-4.583c0.043-0.277,0.281-0.481,0.562-0.481h0.473c1.234,0,2.4,0,3.002,0.704
	C95.809,11.668,95.918,12.292,95.781,13.154z"/>
<path fill="#179BD7" d="M115.434,13.075h-3.273c-0.281,0-0.52,0.204-0.562,0.481l-0.145,0.916l-0.23-0.332
	c-0.709-1.029-2.289-1.373-3.867-1.373c-3.619,0-6.709,2.741-7.311,6.586c-0.312,1.918,0.131,3.752,1.219,5.031
	c1,1.176,2.426,1.666,4.125,1.666c2.916,0,4.533-1.875,4.533-1.875l-0.146,0.91c-0.055,0.348,0.213,0.66,0.564,0.66h2.949
	c0.467,0,0.865-0.34,0.938-0.803l1.771-11.209C116.053,13.388,115.785,13.075,115.434,13.075z M110.869,19.449
	c-0.314,1.871-1.801,3.127-3.695,3.127c-0.949,0-1.711-0.305-2.199-0.883c-0.484-0.574-0.666-1.391-0.514-2.301
	c0.297-1.855,1.805-3.152,3.67-3.152c0.93,0,1.686,0.309,2.184,0.892C110.816,17.721,111.014,18.543,110.869,19.449z"/>
<path fill="#179BD7" d="M119.295,7.23l-2.807,17.858c-0.055,0.346,0.213,0.658,0.562,0.658h2.822c0.469,0,0.867-0.34,0.939-0.803
	l2.768-17.536c0.055-0.346-0.213-0.659-0.562-0.659h-3.16C119.578,6.749,119.338,6.953,119.295,7.23z"/>
<path fill="#253B80" d="M7.266,29.154l0.523-3.322l-1.165-0.027H1.061L4.927,1.292C4.939,1.218,4.978,1.149,5.035,1.1
	c0.057-0.049,0.13-0.076,0.206-0.076h9.38c3.114,0,5.263,0.648,6.385,1.927c0.526,0.6,0.861,1.227,1.023,1.917
	c0.17,0.724,0.173,1.589,0.007,2.644l-0.012,0.077v0.676l0.526,0.298c0.443,0.235,0.795,0.504,1.065,0.812
	c0.45,0.513,0.741,1.165,0.864,1.938c0.127,0.795,0.085,1.741-0.123,2.812c-0.24,1.232-0.628,2.305-1.152,3.183
	c-0.482,0.809-1.096,1.48-1.825,2c-0.696,0.494-1.523,0.869-2.458,1.109c-0.906,0.236-1.939,0.355-3.072,0.355h-0.73
	c-0.522,0-1.029,0.188-1.427,0.525c-0.399,0.344-0.663,0.814-0.744,1.328l-0.055,0.299l-0.924,5.855l-0.042,0.215
	c-0.011,0.068-0.03,0.102-0.058,0.125c-0.025,0.021-0.061,0.035-0.096,0.035H7.266z"/>
<path fill="#179BD7" d="M23.048,7.667L23.048,7.667L23.048,7.667c-0.028,0.179-0.06,0.362-0.096,0.55
	c-1.237,6.351-5.469,8.545-10.874,8.545H9.326c-0.661,0-1.218,0.48-1.321,1.132l0,0l0,0L6.596,26.83l-0.399,2.533
	c-0.067,0.428,0.263,0.814,0.695,0.814h4.881c0.578,0,1.069-0.42,1.16-0.99l0.048-0.248l0.919-5.832l0.059-0.32
	c0.09-0.572,0.582-0.992,1.16-0.992h0.73c4.729,0,8.431-1.92,9.513-7.476c0.452-2.321,0.218-4.259-0.978-5.622
	C24.022,8.286,23.573,7.945,23.048,7.667z"/>
<path fill="#222D65" d="M21.754,7.151c-0.189-0.055-0.384-0.105-0.584-0.15c-0.201-0.044-0.407-0.083-0.619-0.117
	c-0.742-0.12-1.555-0.177-2.426-0.177h-7.352c-0.181,0-0.353,0.041-0.507,0.115C9.927,6.985,9.675,7.306,9.614,7.699L8.05,17.605
	l-0.045,0.289c0.103-0.652,0.66-1.132,1.321-1.132h2.752c5.405,0,9.637-2.195,10.874-8.545c0.037-0.188,0.068-0.371,0.096-0.55
	c-0.313-0.166-0.652-0.308-1.017-0.429C21.941,7.208,21.848,7.179,21.754,7.151z"/>
<path fill="#253B80" d="M9.614,7.699c0.061-0.393,0.313-0.714,0.652-0.876c0.155-0.074,0.326-0.115,0.507-0.115h7.352
	c0.871,0,1.684,0.057,2.426,0.177c0.212,0.034,0.418,0.073,0.619,0.117c0.2,0.045,0.395,0.095,0.584,0.15
	c0.094,0.028,0.187,0.057,0.278,0.086c0.365,0.121,0.704,0.264,1.017,0.429c0.368-2.347-0.003-3.945-1.272-5.392
	C20.378,0.682,17.853,0,14.622,0h-9.38c-0.66,0-1.223,0.48-1.325,1.133L0.01,25.898c-0.077,0.49,0.301,0.932,0.795,0.932h5.791
	l1.454-9.225L9.614,7.699z"/>
</svg>

);

export { VeemWordLogo, PayPalLogo };
