import moment from 'moment';
import { intersection } from 'lodash/fp';
import {
  getDestinationPorts,
  getMode,
  getOriginPorts,
  getVendorsNames,
  getQuotePrice,
  getQuoteValidToDate,
  getCarriers
} from 'results/results.utils';

export const modeExists = (quote, modes = []) => modes.includes(getMode(quote).toLowerCase());

export const originPortExists = (quote, locations) =>
  locations.includes(getOriginPorts(quote).toLowerCase());

export const destinationPortExists = (quote, locations) =>
  locations.includes(getDestinationPorts(quote).toLowerCase());

export const vendorsExists = (quote, options) =>
  intersection(
    getVendorsNames(quote).map((item = '') => item.toLowerCase()),
    options.map((option = '') => option.toLowerCase())
  ).length;

export const withinPriceRange = (quote, options = [0, Infinity]) => {
  let price = getQuotePrice(quote);
  price = Math.floor(price);
  return price >= options[0] && price <= options[1];
};

export const withinExpiryDateRange = (quote, options = []) => {
  const date = getQuoteValidToDate(quote);
  if (!date || options.length !== 2) {
    return false;
  }
  return moment(date).isBetween(
    moment(options[0]).subtract(1, 'd'),
    moment(options[1]).add(1, 'd')
  );
};

export const isCarrierExist = (quote, options) => {
  const carrier = getCarriers(quote);
  if (!carrier || carrier.length === 0) {
    return false;
  }
  return intersection(
    carrier.map((carrierItem) => carrierItem.toLowerCase()),
    options.map((option) => option.toLowerCase())
  ).length;
};

export const validateQuoteFilter = (quote, type, options) => {
  // eslint-disable-next-line default-case
  switch (type) {
    case 'mode':
      return modeExists(quote, options);
    case 'bookingType':
      return quote.filterValue?.guaranteedSpace;
    case 'origin':
      return originPortExists(quote, options);
    case 'destination':
      return destinationPortExists(quote, options);
    case 'vendors':
      return vendorsExists(quote, options);
    case 'price':
      return withinPriceRange(quote, options);
    case 'validTo':
      return withinExpiryDateRange(quote, options);
    case 'carrier':
      return isCarrierExist(quote, options);
    default:
      return false;
  }
};

export const getToggleListActiveItems = (list = []) =>
  list.reduce((acc, item) => {
    if (item.active) {
      acc.push(item.key.toLowerCase());
    }
    return acc;
  }, []);

/**
 * this is the main function - it will return all relevant data for filtering
 * it will go over the filters object and for each filter item it will return
 * the parameters we want to filter the quote base on.
 * @param {*} filters
 * @returns
 */
export const getActiveFilters = (filters = {}) => {
  return Object.entries(filters).reduce((acc, [key, filter]) => {
    // eslint-disable-next-line default-case
    switch (filter.type) {
      case 'toggle':
        // if all filters are not active it means we want them to show all results
        if (!isFilterGroupActive(filter)) {
          return acc;
        }
        const activeItems = getToggleListActiveItems(filter.list);
        if (activeItems.length > 0) {
          acc.push({
            key,
            filters: activeItems
          });
        }
        break;
      case 'range':
        if (filter.currentRange?.length) {
          if (isRangeVisibleActive(filter)) {
            acc.push({
              key,
              range: filter.currentRange
            });
          }
        }
        break;
      case 'dateRange':
        if (filter.currentRange?.length) {
          if (isDateRangeVisibleActive(filter)) {
            acc.push({
              key,
              range: filter.currentRange
            });
          }
        }
        break;
      case 'onOff':
        const activeOnOff = getToggleListActiveItems(filter.list);
        if (activeOnOff.length > 0) {
          acc.push({
            key,
            filters: activeOnOff
          });
        }
        break;
    }

    return acc;
  }, []);
};

export const filterQuotes = (quotes = {}, activeFilters = []) => {
  //if activeFilters is empty return all;
  if (activeFilters.length === 0) {
    return Object.keys(quotes);
  }
  const quoteIds = Object.entries(quotes).reduce((acc, [, quote]) => {
    const valid = activeFilters.every((filter) =>
      validateQuoteFilter(quote, filter.key, filter.filters || filter.range)
    );
    if (valid && quote.referenceID) {
      acc.push(quote.referenceID);
    }
    return acc;
  }, []);
  return quoteIds;
};

export const initCountItems = (filters) => {
  const result = {};
  for (let key in filters) {
    const list = filters[key].list || [];
    list.forEach((listItem) => (result[listItem.key] = listItem.count));
  }
  return result;
};

/**
 * this is for counting the results live or for toggling- if the user filtered already
 * we don't want to count the category that the user has filtered.
 * @param {*} indexIds
 * @param {*} quotes
 * @param {*} activeMode
 * @returns
 */
export const countOtherItems = (indexIds, quotes, activeMode) => {
  const countItems = indexIds.reduce((acc, key) => {
    const quote = quotes[key];

    if (activeMode !== 'mode') {
      const mode = getMode(quote);
      if (mode) {
        acc[mode] = Boolean(acc[mode]) ? acc[mode] + 1 : 1;
      }
    }
    if (activeMode !== 'origin') {
      const origin = getOriginPorts(quote);
      if (origin) {
        acc[origin] = Boolean(acc[origin]) ? acc[origin] + 1 : 1;
      }
    }
    if (activeMode !== 'destination') {
      const destination = getDestinationPorts(quote);
      if (destination) {
        acc[destination] = Boolean(acc[destination]) ? acc[destination] + 1 : 1;
      }
    }
    if (activeMode !== 'vendors') {
      const vendor = getVendorsNames(quote);
      if (vendor) {
        acc[vendor] = Boolean(acc[vendor]) ? acc[vendor] + 1 : 1;
      }
    }
    if (activeMode !== 'carrier') {
      const carrier = getCarriers(quote);
      if (carrier.length > 0) {
        carrier.forEach((carrierItem) => {
          acc[carrierItem] = Boolean(acc[carrierItem]) ? acc[carrierItem] + 1 : 1;
        });
      }
    }
    if (activeMode !== 'bookingType') {
      if (quote.filterValue?.guaranteedSpace) {
        acc['Guaranteed Capacity'] = Boolean(acc['Guaranteed Capacity'])
          ? acc['Guaranteed Capacity'] + 1
          : 1;
      }
    }
    return acc;
  }, {});
  return countItems;
};

export const countItems = (indexIds, quotes, activeMode) => {
  const countItemsResult = indexIds.reduce((acc, key) => {
    const quote = quotes[key];

    if (activeMode === 'mode') {
      const mode = getMode(quote);
      if (mode) {
        acc[mode] = Boolean(acc[mode]) ? acc[mode] + 1 : 1;
      }
    }
    if (activeMode === 'origin') {
      const origin = getOriginPorts(quote);
      if (origin) {
        acc[origin] = Boolean(acc[origin]) ? acc[origin] + 1 : 1;
      }
    }
    if (activeMode === 'destination') {
      const destination = getDestinationPorts(quote);
      if (destination) {
        acc[destination] = Boolean(acc[destination]) ? acc[destination] + 1 : 1;
      }
    }
    if (activeMode === 'vendors') {
      const vendor = getVendorsNames(quote);
      if (vendor) {
        acc[vendor] = Boolean(acc[vendor]) ? acc[vendor] + 1 : 1;
      }
    }
    if (activeMode === 'carrier') {
      const carrier = getCarriers(quote);
      if (carrier.length > 0) {
        carrier.forEach((carrierItem) => {
          acc[carrierItem] = Boolean(acc[carrierItem]) ? acc[carrierItem] + 1 : 1;
        });
      }
    }
    return acc;
  }, {});
  return countItemsResult;
};

export const getActiveModeCountItems = (activeModeFilterKeys = [], oldCountItems) => {
  const result = {};
  activeModeFilterKeys.forEach((item) => {
    if (Boolean(oldCountItems[item.key])) {
      result[item.key] = oldCountItems[item.key];
    }
  });
  return result;
};

/**
 * this function will count all items based all other filters
 * for example if mode is lcl and fcl and origin is cn and us
 * it will return the count for mode based on the origin criteria
 * and the count for origin based on mode criteria
 * @param {*} quotes : ;
 * @param {*} activeFilters
 * @param {*} mode
 * @returns
 */
export const setActiveModeCountItems = (quotes, activeFilters, mode) => {
  const result = activeFilters.reduce((acc, item) => {
    if (mode === item.key) {
      return acc;
    }
    const ids = filterQuotes(
      quotes,
      activeFilters.filter((filterItem) => filterItem.key !== item.key)
    );
    acc = { ...acc, ...countItems(ids, quotes, item.key) };
    return acc;
  }, {});
  return result;
};

/**
 * mode is active if all filters is true or all filters are false
 * @param {*} filters
 * @param {*} mode
 * @returns
 */
export const isFilterGroupActive = (filter) => {
  if (!filter) {
    return false;
  }
  if (filter.type === 'onOff') {
    if (filter.list.every((item) => item.active === true)) {
      return true;
    }
    return false;
  }
  if (filter.type === 'toggle' && !filter.list.length) {
    return false;
  }
  // all equals false - no filter selected
  if (filter.list.every((item) => item.active === false)) {
    return false;
  }
  // all equals true - there isn't really filtering in this section
  if (filter.list.every((item) => item.active === true)) {
    return false;
  }

  return true;
};

/**
 * this flag is to decide if we want to show the filters at all
 * @param {*} filter : ;
 * @returns
 */
export const isRangeFilterVisible = (filter) => filter.range?.min !== Infinity;

/**
 *  this function will show that the price filter is changed and we don't want to
 * allow to close the collapse
 * @param {*} filter : ;
 * @returns
 */
export const isRangeVisibleActive = (filter) => {
  const { range = { min: Infinity, max: 0 }, currentRange = [] } = filter;
  return range.min !== currentRange[0] || range.max !== currentRange[1];
};

/**
 * this flag is to decide if we want to show the filters at all
 * @param {*} filter : ;
 * @returns
 */
export const isDateRangeFilterVisible = (filter) => {
  return (
    filter.range.min && filter.range.max && moment(filter.range.max).diff(filter.range.min, 'd') > 3
  );
};

export const isOnOffFilterVisible = (filter) => {
  return filter.list.length > 0;
};
/**
 *  this function will show that the price filter is changed and we don't want to
 * allow to close the collapse
 * @param {*} filter : ;
 * @returns
 */
export const isDateRangeVisibleActive = (filter) => {
  const { range = { min: undefined, max: undefined }, currentRange = [] } = filter;
  return range.min !== currentRange[0] || range.max !== currentRange[1];
};
