import React from 'react';
import styled, { css } from 'styled-components';
import { connect } from 'microfronts-redux';
import { Icon, tokens } from '@freightos/design-system';
import { AuthUiActions } from 'propera/authUI';
import { t } from 'utils/translationProvider';
import { isMobileSelector } from 'slimSearch/selectors';
import { spacingContainerVertical, spacingContainerHorizontal } from 'slimSearch/sharedStyles';
import { updateField as updateFieldAction } from 'slimSearch/actions';

const { loginModal: loginModalAction } = AuthUiActions;

const LoggedOutView = ({ isMobile, updateField, loginModal, onClick }) => {
  const onLoginSignupClicked = () => {
    updateField('activeSection', null, { section: '' });
    loginModal({ open: true });
    onClick();
  };

  return (
    <Container spacing={tokens.fdsComponentSpacingSm} isMobile={isMobile}>
      <StyledAnchor
        tabIndex={0}
        spacing={tokens.fdsComponentSpacingSm}
        onClick={onLoginSignupClicked}
      >
        <Icon type="user" size="small" />
        <Header>{t('myRecentSearches/loginOrSignup', 'Log in / Sign up')}</Header>
      </StyledAnchor>
      <span>
        {t('myRecentSearches/loginOrSignupSubtitle', 'Access your searches on any device')}
      </span>
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: column;

  ${spacingContainerVertical}

  ${({ isMobile }) =>
    isMobile &&
    css`
      padding: 0 ${tokens.fdsComponentSpacingXl};
    `}
`;

const StyledAnchor = styled.a`
  color: ${tokens.fdsColorPrimaryBlue};
  display: flex;
  flex-direction: row;

  ${spacingContainerHorizontal}
`;

const Header = styled.div`
  font-size: ${tokens.fdsH5FontSize};
  font-weight: ${tokens.fdsFontWeightRegular};
  line-height: 16px;
`;

const mapStateToProps = (state) => ({
  isMobile: isMobileSelector(state)
});

export default connect(mapStateToProps, {
  updateField: updateFieldAction,
  loginModal: loginModalAction
})(LoggedOutView);
