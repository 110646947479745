import React from 'react';
import styled, { css } from 'styled-components/macro';

import { Avatar, Icon, Card } from '@freightos/design-system';
import { useCurrentWidth } from 'hooks';
const MIN_WIDTH_OF_SMALL_SCREEN = 1020;

export const Tile = ({ loading, title, icon, children }) => {
  const pageWidth = useCurrentWidth();

  return (
    <StyledCard
      loading={loading}
      size="small"
      title={title}
      shadow={false}
      bordered
      pageWidth={pageWidth}
      extra={
        <Avatar type="secondary" size="large">
          <Icon type={icon} />
        </Avatar>
      }
    >
      {children}
    </StyledCard>
  );
};

const StyledCard = styled(Card)`
  height: 100%;
  min-width: 210px;
  display: grid;
  grid-template-rows: auto 1fr;

  ${({ pageWidth }) =>
    pageWidth < MIN_WIDTH_OF_SMALL_SCREEN
      ? css`
          :nth-child(3) {
            // last child fill width if screen smaller than MIN_WIDTH_OF_SMALL_SCREEN
            grid-column: 1 / -1;
          }
        `
      : ''}
  &&& {
    box-shadow: none;

    &:hover {
      box-shadow: none;
    }
  }
`;
