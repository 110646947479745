import { all } from 'redux-saga/effects';
import { attachSharedComponent, store } from 'microfronts-redux';

import { searchReducer } from 'slimSearch/reducer';
import { recentSearchesReducer, recentSearchesSaga } from 'slimSearch/components/RecentSearches';
import { latestBookings } from 'latestBookings/reducer';

import slimSearchSagas from 'slimSearch/saga';
import pollingSagas from 'slimSearch/polling.saga';
import analyticsSagas from 'slimSearch/analytics.saga';

import resultsReducer from 'results/results.reducer';
import resultsSaga from 'results/results.saga';

import CustomQuote from 'customQuote/components/CustomQuoteRequestPage';
import CustomQuoteSaga from 'customQuote/saga';
import customQuoteReducer from 'customQuote/reducer';
import vendorsReducer from 'results/vendors/vendors.reducer';
import vendorsSaga from 'results/vendors/vendors.saga';
import latestBookingsSaga from 'latestBookings/saga';

import { PACKAGE_NAME } from './constants';

import Search from 'slimSearch/Search';
import SearchCategories from 'slimSearch/components/SearchCategories';
import RecommendedServices from 'slimSearch/components/services/RecommendedServices';
import {
  actionTypes as resultsActionTypes,
  quoteSelected,
  quotesPollingCompleted,
  getQuoteAndShipment
} from 'results/results.actions';

function* sagas() {
  yield all([
    slimSearchSagas(),
    analyticsSagas(),
    pollingSagas(),
    resultsSaga(),
    CustomQuoteSaga(),
    vendorsSaga(),
    recentSearchesSaga(),
    latestBookingsSaga()
  ]);
}

attachSharedComponent(
  {
    search: searchReducer,
    recentSearches: recentSearchesReducer,
    results: resultsReducer,
    customQuote: customQuoteReducer,
    vendors: vendorsReducer,
    latestBookings
  },
  sagas
);

store.dispatch({ type: `${PACKAGE_NAME.toUpperCase()}_INIT_SHARED_COMPONENTS` });

const { QUOTES_POLLING, SHOW_ALL_RESULTS, QUOTES_POLLING_COMPLETED, GET_QUOTE_AND_SHIPMENT } =
  resultsActionTypes;

export { default as Filters } from 'results/components/Filters';
export * from 'slimSearch/utils/external.utils.js';
export {
  GET_RFQ_SUMMARY,
  setEditMode,
  showServicesModal,
  getRfqSummary,
  updateField
} from 'slimSearch/actions';
export * from 'results/results.selectors';
export { getRfqKeyFromUrl } from 'results/results.utils';
export { getRfqSelector, isOldRfqFlowSelector } from 'slimSearch/selectors';
export { isPollingSelector, quoteBookedAnalyticsSelector } from 'results/results.selectors';
export { default as SortOptions } from 'results/components/SortOptions';
export { default as ResultsView } from 'results/components/ResultsView';
export { mapShipmentToCustomQuote } from 'results/custom-quote.utils';
export { progressSteps, getEventDatesTexts } from 'slimSearch/utils';
export { getTLD } from 'utils';
export { getShipmentTypeByLoadData, mapGoodsReady } from 'slimSearch/utils/mapOpenFreight.utils';
export { quoteVendors, vendorsSelector } from 'results/vendors/vendors.selectors';
export {
  Search,
  SearchCategories,
  RecommendedServices,
  QUOTES_POLLING,
  SHOW_ALL_RESULTS,
  QUOTES_POLLING_COMPLETED,
  GET_QUOTE_AND_SHIPMENT,
  getQuoteAndShipment,
  quoteSelected,
  quotesPollingCompleted,
  CustomQuote
};
