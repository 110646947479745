import React, { useEffect, useState } from 'react';
import useScript from 'react-script-hook';
import { Button } from '@freightos/design-system';
import { t } from 'utils/translationProvider';
import Loading from 'financialSharedComponents/components/common/Loading';
import PayPalAccountsMgmt from './PayPalAccountsMgmt';
import * as constants from './constants';

const PayPal = ({
  onSubmitToken,
  fetchPaymentMethods,
  setDeviceDataCorrelationId,
  isChangingPaymentMethod,
  currentTransactionCurrency,
  brainTreeApiKey,
  isPaymentMethodsInitialized,
  setIsChangingPaymentMethod,
  selectedPayPalAccount
}) => {
  const [payPalScriptInitiated, setPayPalScriptInitiated] = useState(false);
  const [clientInstance, setClientInstance] = useState(null);
  const [loadingClient, clientError] = useScript({ src: constants.PAYPAL_CLIENT_SCRIPT_URL});
  const [loadingDataCollector, dataCollectorError] = useScript({ src: constants.PAYPAL_DATA_COLLECTOR_SCRIPT_URL });
  const [loadingCheckout, checkoutError] = useScript({ src: constants.PAYPAL_CHECKOUT_SCRIPT_URL });
  const [payPalScriptsLoaded, setPayPalScriptsLoaded] = useState(false);
  const [payPalScriptsError, setPayPalScriptsError] = useState(false);
  useEffect(() => {
    if (!loadingClient && !loadingCheckout && !loadingDataCollector) {
      setPayPalScriptsLoaded(true);
    }
    if (checkoutError || clientError || dataCollectorError) {
      setPayPalScriptsError(true);
    }
  }, [loadingClient, loadingCheckout, checkoutError, clientError, loadingDataCollector, dataCollectorError]);
  useEffect(() => {
    if (isPaymentMethodsInitialized) {
      if (!selectedPayPalAccount)
        setIsChangingPaymentMethod(true);
      else {
        setIsChangingPaymentMethod(false);
      }
    }
  }, [selectedPayPalAccount, isPaymentMethodsInitialized]);
  useEffect(() => {
    fetchPaymentMethods();
  }, []);
  const [payPalButtonInitialized, setPayPalButtonInitialized] = useState(false);
  useEffect(()=> {
    if (payPalScriptsLoaded && !payPalScriptsError && brainTreeApiKey && currentTransactionCurrency) {
      if(!payPalScriptInitiated) {
        setPayPalScriptInitiated(true)
        window.braintree.client.create({
          authorization: brainTreeApiKey
        }, function (clientErr, clientInstance) {
          if (clientErr) {
            console.error('Error creating client:', clientErr);
            return;
          }

          setClientInstance(clientInstance);
  
          window.braintree.dataCollector.create({
            client: clientInstance,
          }, (err, dataCollectorInstance) => {
            if (err) {
              console.log("Error loading data collector")
              return;
            }
            setDeviceDataCorrelationId(dataCollectorInstance.deviceData);
          });
  
        });
      }
        if (clientInstance && payPalScriptInitiated && !payPalButtonInitialized && isChangingPaymentMethod) {
          window.braintree.paypalCheckout.create({
            client: clientInstance
          }, function (paypalCheckoutErr, paypalCheckoutInstance) {
            paypalCheckoutInstance.loadPayPalSDK({
              vault: true,
              currency: currentTransactionCurrency,
              commit: true,
              intent: 'capture'
            }, function () {

              window.paypal.Buttons({
                style: {
                  layout: 'vertical',
                  color: 'blue',
                  label: 'pay',
                  height: 32
                },
                fundingSource: window.paypal.FUNDING.PAYPAL,

                createBillingAgreement: function () {
                  return paypalCheckoutInstance.createPayment({
                    flow: 'vault',
                    billingAgreementDescription: 'Freightos can auto-charge payments to your account,including adjustments, subject to our SOP (https://www.freightos.com/standard-operating-procedure/). customs duties and taxes (which includes a 2.5% disbursement fee).',
                    enableShippingAddress: false,
                    shippingAddressEditable: false
                  });
                },

                onApprove: function (data, actions) {
                  return paypalCheckoutInstance.tokenizePayment(data, function (err, payload) {
                    onSubmitToken({ nonce: payload.nonce });
                  });
                },

                onCancel: function (data) {
                  console.log('PayPal payment canceled', JSON.stringify(data, 0, 2));
                },

                onError: function (err) {
                  console.error('PayPal error', err);
                }
              }).render('#paypal-button').then(function () {
                setPayPalButtonInitialized(true);
                // The PayPal button will be rendered in an html element with the ID
                // `paypal-button`. This function will be called when the PayPal button
                // is set up and ready to be used
              });

            });

          });
        }
    }
  },[payPalScriptsLoaded, payPalScriptInitiated, clientInstance, payPalScriptsError, isChangingPaymentMethod, brainTreeApiKey, currentTransactionCurrency]);
  useEffect(()=> {
    if(!isChangingPaymentMethod) {
      let paypalContainer = document.getElementById("paypal-button");
      if (paypalContainer) {
        document.getElementById("paypal-button").innerHTML = "";
      }
      setPayPalButtonInitialized(false);
    }
  }, [isChangingPaymentMethod])
  if (payPalScriptsError) {
    return <div>Error loading paypal, please refresh and try again</div>;
  }
  if (!payPalScriptsLoaded || !brainTreeApiKey) {
    return <Loading />;
  }
  return (
    <React.Fragment>
      <div>
        {(!isPaymentMethodsInitialized || (isChangingPaymentMethod && !payPalButtonInitialized)) && <Loading />}
        {(isPaymentMethodsInitialized && selectedPayPalAccount && !isChangingPaymentMethod) && (
          <React.Fragment>
            <PayPalAccountsMgmt />
          </React.Fragment>
        )}
      </div>
      <div>
        {selectedPayPalAccount && isChangingPaymentMethod && <span>Click on the button below to update your paypal account info and pay or
          <Button
            data-test-id="use-saved-paypal-account"
            onClick={() => {
              setIsChangingPaymentMethod(false);
            }}
            type={'text'}
            size={'medium'}
          >
            {t('use your current PayPal Account', 'use your current PayPal Account')}
          </Button>
          <br /></span>}

        <div id="paypal-button" />
      </div>


    </React.Fragment>
  )
}

PayPal.propTypes = {};

export default PayPal;
