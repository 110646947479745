const PACKAGE_NAME = 'FinancialSharedComponents';
const PORT = 8104;
const HOST = 'ship.freightos.local';
const DIST_DIR = 'build';
const BUNDLE_NAME = 'main.js';

module.exports = {
  PACKAGE_NAME,
  PORT,
  HOST,
  DIST_DIR,
  BUNDLE_NAME
};
