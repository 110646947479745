import {  template } from 'lodash';
import { put, call, takeLatest, all, select } from 'redux-saga/effects';
import * as monitoring from '@propera/monitoring';
import { store } from 'microfronts-redux';
import { model as userModel } from 'propera/user';
import { flagStateOrDefaultValueSelector } from 'feature-flagging';
import { PAYPAL_INTEGRATION } from 'financialSharedComponents/ldFlags';
import { doPostRequest, doGetRequest } from '@propera/HTTP';
import * as AT from 'financialSharedComponents/constants/actionTypes';
import * as Actions from './actions';
import { fetchLHtoken, fetchMPtoken } from 'financialSharedComponents/api.saga';
import { getLhGatewayBaseUrl } from 'financialSharedComponents/constants/lhEndpoints';
import { SUPPORTED_GATEWAYS_API_PATH, GET_USER_PAYMENT_METHODS_API_PATH, getBillingServiceBaseUrl } from 'financialSharedComponents/constants/billingServiceEndpoints';
import * as selectors from 'financialSharedComponents/selectors';

export const businessKeySelector = (state) => userModel.getBusinessKey(state);
const currentState = (state) => state;

export function* fetchSupportedPaymentTypes({ payload }) {
  try {
    const state = yield select(currentState);
    const isPayPalEnabled = flagStateOrDefaultValueSelector(state, PAYPAL_INTEGRATION , false);
    const { currency } = payload;
    const businessKey = yield select(businessKeySelector);
    const token = yield call(fetchMPtoken);
    const response = yield call(
      doGetRequest,
      template(SUPPORTED_GATEWAYS_API_PATH)({ currency}),
      { Authorization: `Bearer ${token}` },
      {},
      true,
      true,
      getBillingServiceBaseUrl()
    );
    if (response) {
      yield put(
        Actions.fetchSupportedPaymentTypesSuccess({
          availableMethods: response,
          isPayPalEnabled,
          ...payload
        })
      );
    }
  } catch (err) {
    yield put(Actions.fetchSupportedPaymentTypesFail());
    yield call(monitoring.utils.exceptionReporter, err, store.getState);
  }
}

export function* doPayment() {
  try {
    const state = yield select(currentState);

    const shipmentNumber = selectors.currentTransactionShipmentNumberSelector(state);
    const deviceDataCorrelationId = selectors.deviceDataCorrelationIdSelector(state);
    const paymentMethodType = selectors.selectedPaymentMethodSelector(state);
    if (!shipmentNumber) {
      throw new Error('missing_arguments__shipmentNumber');
    }
    const paymentAmount = selectors.currentTransactionAmountSelector(state);
    const currency = selectors.currentTransactionCurrencySelector(state);
    const data = {
      paymentMethodType,
      deviceDataCorrelationId,
      paymentAmount,
      currency,
      paymentGateway: 'Braintree'
    };
    const token = yield call(fetchLHtoken);
    yield call(
      doPostRequest,
      `/billing-page/data/lh-billing/payments/${shipmentNumber}/pay`,
      { ...data },
      { Accept: 'application/json', Authorization: `Application ${token}` },
      undefined,
      undefined,
      getLhGatewayBaseUrl()
    );
    yield put(Actions.doPaymentSuccess({ paymentMethodType }));
  } catch (err) {
    const error = err && err.response;
    yield put(Actions.doPaymentFail(error));

    if (!JSON.stringify(error).toLowerCase().includes('declined') || !error) {
      yield call(monitoring.utils.exceptionReporter, err, store.getState);
    }
  }
}

export function* fetchPaymentMethods({payload}) {
  const {type} = payload;
  try {
    const token = yield call(fetchMPtoken);
    const businessKey = yield select(businessKeySelector);
    const response = yield call(
      doGetRequest,
     template(GET_USER_PAYMENT_METHODS_API_PATH)({ type ,paymentGateway: 'Braintree' }),
     { Accept: 'application/json', Authorization: `Bearer ${token}` },
      {},
      true,
      true,
      getBillingServiceBaseUrl()
    );
    if (response) {
      yield put(Actions.fetchPaymentMethodsSuccess({response, type}));
    }
  } catch (err) {
    yield put(Actions.fetchPaymentMethodsFail());
    yield call(monitoring.utils.exceptionReporter, err, store.getState);
  }
}

export default function* watcher() {
  yield all([
    takeLatest(AT.FETCH_SUPPORTED_PAYMENT_METHOD_TYPES, fetchSupportedPaymentTypes),
    takeLatest(AT.DO_PAYMENT, doPayment),
    takeLatest(AT.FETCH_PAYMENT_METHODS, fetchPaymentMethods),
  ]);
}
