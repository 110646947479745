import React, { useEffect } from 'react';
import { connect } from 'microfronts-redux';
import { get } from 'lodash';
import styled, { css } from 'styled-components/macro';
import { Button, Card } from '@freightos/design-system';
import {
  fdsComponentSpacingXxl,
  fdsComponentSpacingLg,
  fdsH5FontSize,
  fdsFontWeightBold
} from '@freightos/design-system/dist/tokens';
import { model as layoutSelector } from 'layout';
import { t } from 'utils/translationProvider';
import SearchCategories from 'slimSearch/components/SearchCategories';
import SearchStepProgress from 'slimSearch/components/services/SearchStepProgress';
import RecommendedServices from 'slimSearch/components/services/RecommendedServices';
import { ReactComponent as CustomQuoteImg } from 'assets/images/custom_quote.svg';
import { getCustomQuotePageDisplayStatus } from 'customQuote/customQuote.selectors';

import { DISPLAY_STATE } from 'customQuote/constants';
import CustomQuoteForm from './CustomQuoteForm';

import { newSearch, initCustomQuotePageStatus } from 'customQuote/actions';
const { isMobileSelector } = layoutSelector;

const CustomQuoteRequestPage = ({
  newSearch,
  pageStatus,
  isMobile,
  showRecommendedServices,
  initCustomQuotePageStatus
}) => {
  useEffect(() => {
    initCustomQuotePageStatus();
  }, [initCustomQuotePageStatus]);
  return (
    <CustomQuoteWrapper isMobile={isMobile} className="custom-quote-page-wrapper slim-search">
      <SearchStepProgress active="results" />
      {!showRecommendedServices && <SearchCategories mode="results" />}
      {showRecommendedServices && (
        <RecommendedServices mode="results">
          <SearchCategories mode="services-results" />
        </RecommendedServices>
      )}
      <StyledCard className={`slim-search-custom-quote-page${isMobile ? '--mobile' : ''}`}>
        <Card>
          <StyledCustomQuote>
            <StyledFormHeader>
              <CustomQuoteImg />
              {pageStatus === DISPLAY_STATE.FAILURE && (
                <>
                  <h2>{t('customQuote/FailureHeader', 'Out of Scope')}</h2>
                  <p>
                    {t(
                      'customQuote/FailureSentence',
                      "We're unable to offer a Custom Quote for your commodity at this time."
                    )}
                  </p>
                  <Button onClick={() => newSearch()}>
                    {t('common/New Search', 'New search')}
                  </Button>
                </>
              )}
              {pageStatus === DISPLAY_STATE.REQUEST && (
                <>
                  <StyledHeader>
                    {t('customQuote/RequestCustomQuote', 'Custom Quotes')}
                  </StyledHeader>
                  <p>
                    {t(
                      'cq/cq_intake_header_2_top_text',
                      "We couldn't find any instant results for this route."
                    )}
                    {isMobile ? ' ' : <br />}
                    {t(
                      'cq/cq_intake_header_2_bottom_text',
                      'You can request a Custom Quote using this form.'
                    )}
                  </p>
                </>
              )}
            </StyledFormHeader>
            {pageStatus === DISPLAY_STATE.REQUEST && <CustomQuoteForm />}
          </StyledCustomQuote>
        </Card>
      </StyledCard>
    </CustomQuoteWrapper>
  );
};

const mapStateToProps = (store) => ({
  pageStatus: getCustomQuotePageDisplayStatus(store),
  showRecommendedServices: get(store, 'search.servicesModalVisible', false),
  isMobile: isMobileSelector(store)
});
export default connect(mapStateToProps, { newSearch, initCustomQuotePageStatus })(
  CustomQuoteRequestPage
);
const StyledCustomQuote = styled.div``;

const StyledFormHeader = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;

  > svg {
    margin: ${fdsComponentSpacingLg};
    max-width: 145px;
  }
`;

const StyledCard = styled.div`
  margin: auto;
  margin-top: ${fdsComponentSpacingXxl};
  max-width: 800px;
`;

const CustomQuoteWrapper = styled.div`
  overflow: auto;
  padding-bottom: ${fdsComponentSpacingXxl};
  ${({ isMobile }) =>
    isMobile
      ? ''
      : css`
          padding-top: ${fdsComponentSpacingXxl};
        `}
`;

const StyledHeader = styled.div`
  font-size: ${fdsH5FontSize};
  font-weight: ${fdsFontWeightBold};
`;
