import * as AT from './actionTypes';
import flow from 'lodash/fp/flow';
import set from 'lodash/fp/set';

import { BANK_TRANSFER_SUPPORTED_CURRENCIES } from 'constants/app.constants';

const initialState = {
    details: [],
    fetching: true
};

const reducer = (state = initialState, { payload, type }) => {
  switch (type) {
    case AT.FETCH_BANK_DETAILS: {
      return flow([
        set('details', {}),
        set('fetching', true)
      ])(state);
    }

    case AT.FETCH_BANK_DETAILS_SUCCESS: {
      let { bankDetails } = payload;
      bankDetails = bankDetails
      .filter(details => BANK_TRANSFER_SUPPORTED_CURRENCIES.includes(details.currency))
      .sort((a,b) => {
        if (a.currency === b.currency) {
          a.title = a.title || "";
          b.title = b.title || "";
          const SORT_TERM = "local";
          return b.title.toLowerCase().indexOf(SORT_TERM) < a.title.toLowerCase().indexOf(SORT_TERM) ? -1 : 1;
       }
       return BANK_TRANSFER_SUPPORTED_CURRENCIES.indexOf(a.currency) 
       - BANK_TRANSFER_SUPPORTED_CURRENCIES.indexOf(b.currency);
      });
      return flow([
        set('details', bankDetails),
        set('fetching', false)
      ])(state);
    }
    
    case AT.FETCH_BANK_DETAILS_FAIL: {
      return flow([
        set('details', []),
        set('fetching', false)
      ])(state);
    }

    default:
      return state;
  }
};

export default reducer;
