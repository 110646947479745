import React from 'react';
import { connect } from 'react-redux';
import styled, { css } from 'styled-components/macro';
import { Icon, tokens } from '@freightos/design-system';
import { getLocationIcon } from 'slimSearch/utils';
import { formatLoad } from 'slimSearch/components/sections/utils';
import { getMeasurements, isMobileSelector } from 'slimSearch/selectors';
import { LocationCodeTitle, getAddressBookOrigin, getAddressBookDestination } from '../utils';
import PinIcon from './PinIcon';
import ClearIcon from './ClearIcon';

const Location = ({ path, type, userSelectedAddress }) => (
  <div data-test-id={`search-history-item-${type}`}>
    <Icon type={getLocationIcon(type, path)} />
    &nbsp;
    <LocationCodeTitle type={type} locationType={path} userSelectedAddress={userSelectedAddress} />
  </div>
);

const SearchItem = ({
  index,
  isMobile,
  searchId,
  rfqKey,
  rfqFields,
  isPinned,
  displayFields,
  measurements,
  onSelected,
  userAddressOrigin,
  userAddressDestination
}) => {
  const {
    origin: { originLocation },
    destination: { destinationLocation },
    load
  } = displayFields;
  const ResponsiveWrapper = ({ children }) =>
    isMobile ? <MobileWrapper>{children}</MobileWrapper> : <>{children}</>;

  return (
    <SearchItemContainer
      isMobile={isMobile}
      tabIndex={0}
      isPinned={isPinned}
      onClick={() =>
        onSelected({
          ...rfqFields,
          rfqKey,
          userSelectedAddress: { origin: userAddressOrigin, destination: userAddressDestination }
        })
      }
      data-test-index={index}
    >
      <ResponsiveWrapper>
        <Location type="origin" path={originLocation} userSelectedAddress={userAddressOrigin} />
        {!isMobile && <Icon type="arrow-forward" />}
        <Location
          type="destination"
          path={destinationLocation}
          userSelectedAddress={userAddressDestination}
        />
        <Load data-test-id="search-history-item-load">{formatLoad(true, load, measurements)}</Load>
      </ResponsiveWrapper>
      <Actions isMobile={isMobile} className={'action-buttons'}>
        <PinIcon tabIndex={0} searchId={searchId} isPinned={isPinned} rfqKey={rfqKey} />
        {!isMobile && <ClearIcon tabIndex={0} searchId={searchId} />}
      </Actions>
    </SearchItemContainer>
  );
};

const SearchItemContainer = styled.div`
  display: grid;
  grid-template-columns: minmax(auto, 1fr) auto minmax(auto, 1fr) minmax(auto, 1.5fr) auto;
  gap: ${tokens.fdsComponentSpacingXl};
  white-space: nowrap;
  padding: ${tokens.fdsComponentSpacingSm} ${tokens.fdsComponentSpacingXl};
  color: ${tokens.fdsColorPrimaryGray60};
  align-items: center;

  ${({ isPinned }) =>
    isPinned &&
    css`
      color: ${tokens.fdsColorPrimaryGray90};
    `}

  ${({ isMobile }) =>
    isMobile &&
    css`
      display: flex;
      justify-content: space-between;
      align-items: flex-start;
    `}

  &:hover {
    cursor: pointer;
    background-color: ${tokens.fdsComponentTertiaryColorHover};
    color: ${tokens.fdsPrimaryColor};
    font-weight: ${tokens.fdsFontWeightSemibold};

    & > .action-buttons {
      visibility: visible;
    }
  }

  & > * {
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
  }
`;

const MobileWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: ${tokens.fdsComponentSpacingSm};
`;

const Load = styled.div``;

const Actions = styled.div`
  visibility: hidden;
  display: flex;
  align-items: center;
  gap: ${tokens.fdsComponentSpacingSm};

  ${({ isMobile }) =>
    isMobile &&
    css`
      visibility: visible;
    `}

  & > button {
    width: ${tokens.fdsSizeSm};
  }
`;

const mapStateToProps = (state, { displayFields }) => {
  const { additionalInformation } = displayFields;
  const userAddressOrigin = additionalInformation?.length
    ? getAddressBookOrigin(state, additionalInformation)
    : undefined;
  const userAddressDestination = additionalInformation?.length
    ? getAddressBookDestination(state, additionalInformation)
    : undefined;

  return {
    isMobile: isMobileSelector(state),
    measurements: getMeasurements(state),
    userAddressOrigin,
    userAddressDestination
  };
};

export default connect(mapStateToProps)(SearchItem);
