import React from 'react';
import { connect } from 'microfronts-redux';
import styled, { css, keyframes } from 'styled-components/macro';

import { Avatar, Icon } from '@freightos/design-system';

import { updateField, searchAnalytics } from 'slimSearch/actions';
import { getCountryFlag } from 'slimSearch/utils';
import { ADDRESS_TYPES } from 'slimSearch/constants';

import OriginDestination from 'slimSearch/components/sections/OriginDestination';
import Load from 'slimSearch/components/sections/Load';
import Goods from 'slimSearch/components/sections/Goods';

const getLocationTitle = (location) => {
  if (location.locationTypeCode === ADDRESS_TYPES.FULFILMENT_CENTER.value) {
    return location?.ecommerceKey ?? location?.label;
  }

  return location?.locationCompany ?? location.locationCode;
};
const CategoriesList = ({ mode }) => (
  <>
    <OriginDestination type="origin" data-test-id="search-category-origin" mode={mode} />
    <OriginDestination type="destination" data-test-id="search-category-destination" mode={mode} />
    <Load data-test-id="search-category-load" mode={mode} />
    <Goods data-test-id="search-category-goods" mode={mode} />
  </>
);

export const Categories = ({ search, smallHeader, mode, isMobile }) => {
  if (smallHeader) {
    return (
      <CollapsedCategories data-test-id="search-categories-mobile-collapsed">
        {search.quote?.origin?.originLocation?.countryID?.value ? (
          <StyledAvatar
            country={getCountryFlag(search.quote?.origin?.originLocation?.countryID?.value)}
            type="flag"
            size="small"
          />
        ) : null}{' '}
        {search.quote?.origin?.originLocation?.locationCode && (
          <span>{getLocationTitle(search.quote?.origin?.originLocation)}</span>
        )}
        <Arrow>
          <Icon type="arrow-forward" size="small" />
        </Arrow>
        {search.quote?.destination?.destinationLocation?.countryID?.value ? (
          <StyledAvatar
            country={getCountryFlag(
              search.quote?.destination?.destinationLocation?.countryID?.value
            )}
            type="flag"
            size="small"
          />
        ) : null}{' '}
        {search.quote?.destination?.destinationLocation?.locationCode && (
          <span>{getLocationTitle(search.quote?.destination?.destinationLocation)}</span>
        )}
      </CollapsedCategories>
    );
  }

  if (isMobile && mode === 'results') {
    return (
      <ExpandedCategories
        data-test-id="search-categories-mobile-expanded"
        animate={isMobile && mode === 'results'}
      >
        <Categories data-test-id="search-categories-desktop" />
      </ExpandedCategories>
    );
  }

  return <CategoriesList mode={mode} />;
};

const slideFromBottom = keyframes`
  0% {
    transform: translateY(40px);
    opacity: 0;
  }
  100% {
    transform: translateY(0);
    opacity: 1;
  }
`;

const CollapsedCategories = styled.div`
  animation: ${slideFromBottom} 0.2s ease-in-out both;
`;

const StyledAvatar = styled(Avatar)`
  &.ant-avatar-sm {
    width: 12px;
    height: 12px;
    line-height: 12px;
    top: -1px;
  }
`;

const Arrow = styled.span`
  margin: 8px;
  width: 12px;
  height: 12px;
  line-height: 12px;
  top: 2px;
  position: relative;
`;

const slideFromTop = keyframes`
  0% {
    transform: translateY(-100px);
    opacity: 0;
  }
  100% {
    transform: translateY(0);
    opacity: 1;
  }
`;

const ExpandedCategories = styled.span`
  ${({ animate }) =>
    animate
      ? css`
          animation: ${slideFromTop} 0.3s linear both;
        `
      : ''}
`;

const mapStateToProps = (store) => ({
  search: store.search
});

export default connect(mapStateToProps, { updateField, searchAnalytics })(Categories);
