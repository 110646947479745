import React, { useRef, useLayoutEffect } from 'react';
import styled, { css } from 'styled-components/macro';
import { tokens } from '@freightos/design-system';
import { connect } from 'microfronts-redux';
import { isMobileSelector } from 'slimSearch/selectors';

export const TrustPilot = ({ isMobile }) => {
  const trustPilotElement = useRef(null);

  useLayoutEffect(() => {
    let script;
    if (window.Trustpilot) {
      // Script was loaded before, load the TrustBox from the existing ref
      window.Trustpilot.loadFromElement(trustPilotElement.current, true);
    } else {
      // The script has not loaded yet
      script = document.createElement('script');
      script.src = 'https://widget.trustpilot.com/bootstrap/v5/tp.widget.bootstrap.min.js';
      script.async = true;
      document.body.appendChild(script);
    }

    return () => {
      if (script) {
        document.body.removeChild(script);
      }
    };
    // eslint-disable-next-line
  }, []);

  return (
    <SpacingContainer
      ref={trustPilotElement}
      isMobile={isMobile}
      className="trustpilot-widget"
      data-locale="en-US"
      data-template-id="5419b732fbfb950b10de65e5"
      data-businessunit-id="59d3904d0000ff0005acb050"
      data-style-height="24px"
      data-style-width="100%"
      data-theme="light"
    />
  );
};

const SpacingContainer = styled.div`
  ${({ isMobile }) =>
    isMobile
      ? css`
          margin-top: ${tokens.fdsSizeLg};
        `
      : css`
          margin-top: ${tokens.fdsSizeXxl};
        `};
`;

const mapStateToProps = (store) => ({
  isMobile: isMobileSelector(store)
});

export default connect(mapStateToProps)(TrustPilot);
