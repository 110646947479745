import React from 'react';
import { connect } from 'react-redux';
import { model as LayoutModel } from 'layout';
import { Icon } from '@freightos/design-system';
import styles from './badge.module.scss';

const { isMobileSelector } = LayoutModel;

const Badge = ({ iconType, text, isMobile }) => (
  <div className={styles.finBadge}>
    <Icon alt={text} type={iconType} size="small" />
    {!isMobile && <span>{text}</span>}
  </div>
);

const mapStateToProps = (state) => {
  return {
    isMobile: isMobileSelector(state)
  };
};

export default connect(mapStateToProps, {})(Badge);
