import { getRootDomain } from 'propera/utils/url';
import {
TEST_DOMAIN,
CHINA_URL_FOR_STAGING,
STAGING_DOMAIN,
CHINA_URL_FOR_PRODUCTION,
GW_LOCAL_PATH,
PRODUCTION_DOMAIN,
GW_SUB_DOMAIN
} from 'financialSharedComponents/constants/endpoints';

/*
 * lh token should be deprecated and start using mp auth so this file should be deleted once that happens
 * */

export const FREIGHTOS_AUTHENTICATION_ENDPOINT =
  'servlet/apptoken?app=lh-transportplan&includeData=true';



export const getLhGatewayBaseUrl = () => {
  const { href, protocol } = window.location;
  let domain = getRootDomain(href);

  if (domain.includes('local')) {
    // local - go to local GW
    return GW_LOCAL_PATH;
  }
  if (domain.includes('appspot')) {
    // appspot - go to test domain
    domain = TEST_DOMAIN;
  }
  if (href.includes(CHINA_URL_FOR_STAGING)) {
    // lhchina.freightos.cn - go to staging domain, staging-freightos.com
    domain = STAGING_DOMAIN;
  }
  if (href.includes(CHINA_URL_FOR_PRODUCTION)) {
    // ship.freightos.cn - go to production domain, freightos.com
    domain = PRODUCTION_DOMAIN;
  }
  return `${protocol}//${GW_SUB_DOMAIN}.${domain}`;
};

export const LH_GATEWAY_VERIFY_END_POINT = 'verify';
