import { Provider } from 'react-redux';
import React from 'react';
import createSagaMiddleware from 'redux-saga';
import { createStore, applyMiddleware } from 'redux';
import { BrowserRouter, HashRouter } from 'react-router-dom';
import { composeWithDevTools } from 'redux-devtools-extension';

import saga from 'saga';
import reducer from 'reducer';
import { BankDetailsPage } from 'application/BankDetails';


const composeEnhancers = composeWithDevTools({
  name: 'BankDetails',
  trace: true,
  traceLimit: 5,
});

const sagaMiddleware = createSagaMiddleware();
const store = createStore(reducer, composeEnhancers(applyMiddleware(sagaMiddleware)));

sagaMiddleware.run(saga);

const BankDetails = (props) => {

  const {
    routerType,
    routerProps = {},
  } = props;

  const Router = routerType === 'hash' ? HashRouter : BrowserRouter;

  return (
    <Provider store={store}>
      <Router {...routerProps}>
        <BankDetailsPage {...props}/>
      </Router>
    </Provider>
  );
}

export default BankDetails;
