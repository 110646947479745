import { isEmpty, flatMap, uniq, difference, get } from 'lodash';
import { select, put, takeEvery, all, call, takeLatest } from 'redux-saga/effects';
import { doGetRequest } from 'propera/HTTP';

import { actions as monitoringActions } from 'propera/monitoring';
import * as vendorsSelector from 'results/vendors/vendors.selectors';
import { ENDPOINTS } from 'slimSearch/constants';
import {
  getVendorsData,
  vendorsActionType,
  vendorLoading,
  getVendorsDataSuccess,
  getVendorsDataFailure
} from 'results/vendors/vendors.actions';

import { endpointConstructor } from 'utils';

export function* getVendorsDataSaga({ payload }) {
  const vendor = yield select(vendorsSelector.vendorItem(payload));

  if (!isEmpty(vendor) || !payload) {
    return;
  }

  yield put(vendorLoading(payload));
  try {
    const endPoint = endpointConstructor(ENDPOINTS.vendors, { vendorId: payload });

    const response = yield call(doGetRequest, endPoint);
    yield put(getVendorsDataSuccess(response));
  } catch (error) {
    yield put(getVendorsDataFailure());
    yield put(monitoringActions.exceptionReporter(error));
  }
}

export function* getQuotesVendors({ payload }) {
  const quotes = payload;
  const vendorKeys = yield select(vendorsSelector.vendorKeys);
  //console.log(vendorKeys, 'vendorKeys', quotes.map(quote => get(quote, 'businessInfo.parties')));
  const vendorKeysMap = quotes.map((quote) =>
    get(quote, 'businessInfo.parties', []).map((item) => item.ID)
  );

  const quoteVendorKeys = uniq(flatMap(vendorKeysMap));
  //console.log(quoteVendorKeys, vendorKeysMap, 'quoteVendorKeys')
  const vendorIds = difference(quoteVendorKeys, vendorKeys);

  yield all(vendorIds.map((id) => put(getVendorsData(id))));
}

export default function* watcher() {
  yield all([
    takeEvery(vendorsActionType.GET_VENDORS_DATA, getVendorsDataSaga),
    takeLatest(vendorsActionType.GET_QUOTES_VENDORS, getQuotesVendors)
  ]);
}
