import { getTLD } from 'utils';

export const NUM_RECENT_SEARCHES_TO_SHOW = 20;
const SEARCH_HISTORY_BASE_URL = `https://business-configuration.${getTLD()}`;

export const ENDPOINTS = {
  getSearchHistory: `${SEARCH_HISTORY_BASE_URL}/user/<%= userKey %>/search-history/?limit=<%= limit %>`,
  postSearchHistoryItem: `${SEARCH_HISTORY_BASE_URL}/user/<%= userKey %>/search-history`,
  patchSearchHistoryItem: `${SEARCH_HISTORY_BASE_URL}/user/<%= userKey %>/search-history/<%= searchId %>`,
  deleteSearchItem: `${SEARCH_HISTORY_BASE_URL}/user/<%= userKey %>/search-history/<%= searchId %>`,
  deleteSearchHistory: `${SEARCH_HISTORY_BASE_URL}/user/<%= userKey %>/search-history`
};

export const ANALYTIC_EVENTS = {
  recentSearchItemSelected: 'recent_search_item_selected',
  recentSearchItemPinClick: 'recent_search_item_pin_click',
  recentSearchMobileDrawerOpened: 'recent_search_mobile_drawer_open'
};
