import { all, takeLatest, call, put, select } from 'redux-saga/effects';
import {
  SERVICE_HELP_ICON_CLICKED,
  SEARCH_SERVICES_COMPLETED,
  PROGRESS_STEP_CLICK,
  SHOW_SERVICES_MODAL,
  SEARCH_ANALYTICS,
  hideServicesModal,
  searchAnalytics
} from 'slimSearch/actions';
import {
  searchSessionIdSelector,
  getQuoteLocation,
  getQuoteLoad,
  getVisibleServiceList
} from 'slimSearch/selectors';

import { quoteBookedAnalyticsSelector, isPollingSelector } from 'results/results.selectors';

import { ANALYTICS_EVENT_NAME } from 'slimSearch/constants';

import { actions as layoutActions } from 'layout';
import { actions as analyticsActions } from 'propera/analytics';

import { actionTypes as resultActionTypes, quotesPollingCompleted } from 'results/results.actions';

const { registerSegmentEvent } = analyticsActions;

/**
 *
 * @param saga
 * @returns {(function(...[*]=): Generator<SimpleEffect<"SELECT", SelectEffectDescriptor>|*, void, *>)|*}
 * example:
 *
 *  in watcher
 *  `takeLatest('actionName', withSessionId(generatorFn))`
 *
 *  in generator
 *  generatorFn(sessionId, [action])
 */
const withSearchKey = (saga) => {
  return function* (...args) {
    const searchKey = yield select(searchSessionIdSelector);
    // NOTE: args is array
    yield saga(searchKey, args);
  };
};

export function* getServicesAnalyticsData() {
  const sessionId = yield select(searchSessionIdSelector);
  const services = yield select(getVisibleServiceList);
  const load = yield select(getQuoteLoad);
  const location = yield select(getQuoteLocation);
  const servicesResults = {};
  services.forEach((item) => {
    servicesResults[Object.keys(item)[0]] = Object.values(item)[0];
  });

  return {
    ...servicesResults,
    search_key: sessionId,
    insurance: !!servicesResults.insurance,
    load,
    location
  };
}

export function* serviceHelpIconClicked({ payload }) {
  const { openHelpCenter } = layoutActions;

  const sessionId = yield select(searchSessionIdSelector);
  yield put(openHelpCenter(payload));
  yield put(
    registerSegmentEvent('rs_tooltip_help_article_clicked', {
      'article-link': payload,
      search_key: sessionId
    })
  );
}

export function* searchServicesCompleted() {
  const data = yield call(getServicesAnalyticsData);
  const sessionId = yield select(searchSessionIdSelector);

  yield put(registerSegmentEvent('rs_recommended_services_selection_success', data));

  yield put(
    registerSegmentEvent(ANALYTICS_EVENT_NAME.SEARCH_EXECUTED, {
      search_key: sessionId
    })
  );
}

export function* searchServiceModalShown() {
  const data = yield call(getServicesAnalyticsData);
  yield put(registerSegmentEvent('rs_recommended_services_selection_success', data));
}

export function* progressStepClick({ payload }) {
  const { step } = payload;
  const sessionId = yield select(searchSessionIdSelector);
  if (step === 1) {
    yield put(hideServicesModal());
    yield put(
      registerSegmentEvent('rs_back_to_search_clicked', {
        search_key: sessionId
      })
    );
  }
}

export function* searchAnalyticsSaga(searchKey, [action]) {
  if (action.payload.name === ANALYTICS_EVENT_NAME.RS_COMPONENT_OPENED) {
    const data = yield call(getServicesAnalyticsData);

    yield put(
      registerSegmentEvent(action.payload.name, {
        ...action.payload.data,
        search_key: searchKey,
        ...data
      })
    );
  } else {
    yield put(
      registerSegmentEvent(action.payload.name, {
        ...action.payload.data,
        search_key: searchKey
      })
    );
  }
}

export function* quoteSelected({ payload }) {
  const isPooling = yield select(isPollingSelector);
  const data = yield select(quoteBookedAnalyticsSelector(payload.quoteId));
  yield put(
    searchAnalytics({
      name: ANALYTICS_EVENT_NAME.BOOK_BUTTON_CLICKED,
      data: {
        ...data,
        forwarder: payload.vendor?.name,
        rating: payload.vendor?.partyRating?.ratingAverage,
        rfqKey: payload.rfqKey,
        quoteId: payload.quoteId,
        seller_business_key: payload.vendor?.id
      }
    })
  );

  if (isPooling) {
    yield put(
      quotesPollingCompleted({
        status: 'booked',
        rfqKey: payload.rfqKey
      })
    );
  }
}

export default function* watcher() {
  yield all([
    takeLatest(SERVICE_HELP_ICON_CLICKED, serviceHelpIconClicked),
    takeLatest(SEARCH_SERVICES_COMPLETED, searchServicesCompleted),
    takeLatest(PROGRESS_STEP_CLICK, progressStepClick),
    takeLatest(SHOW_SERVICES_MODAL, searchServiceModalShown),
    takeLatest(SEARCH_ANALYTICS, withSearchKey(searchAnalyticsSaga)),
    takeLatest(resultActionTypes.QUOTE_SELECTED, quoteSelected)
  ]);
}
