import get from 'lodash/get';
import siteConfigModel from 'propera/siteConfig';
import { createSelector } from 'reselect';
import { mapQuoteToRfq } from './utils/mapOpenFreight.utils';
import * as servicesOptions from 'slimSearch/components/services/services-criteria.utils';
import { ADDRESS_TYPES } from './constants';
import { model } from 'layout';

const { isMobileSelector: isMobile, getWindowWidth, layoutSelector } = model;
const { servicesOptionsTypesColumn1, servicesOptionsTypesColumn2 } = servicesOptions;

export const rootStateSelector = (state = {}) => state;

export const slimSearchSelector = (state = {}) => get(state, 'search');

export const quoteSelector = createSelector(slimSearchSelector, (state) => state.quote);

export const rfqFromQuoteSelector = createSelector(
  slimSearchSelector,
  (state) => state.rfqFromQuote
);

export const locationDataSelector = createSelector(
  slimSearchSelector,
  (state) => state.locationsData
);

export const goodsReadyRangeSelector = createSelector(
  quoteSelector,
  (quote) => quote.goods.pickupEvent?.goodsReadyRange
);

export const getQuoteLoad = createSelector(quoteSelector, (quote) => quote.load);

export const getQuoteLocation = createSelector(quoteSelector, (quote) => ({
  origin: quote.origin?.originLocation,
  destination: quote.destination?.destinationLocation
}));

export const getMeasurements = createSelector(
  slimSearchSelector,
  (state) => state.quote.load.measurements
);

export const locationNameByCode = (value, type) =>
  createSelector(locationDataSelector, (state) =>
    state[type].addresses.find((location) => location.value === value)
  );

export const fullFillmentLocationsSelector = createSelector(
  locationDataSelector,
  (state) => state.destination?.brandLocations
);

export const getRfqSelector = createSelector(
  quoteSelector,
  getMeasurements,
  (quote, measurements) => mapQuoteToRfq(quote, measurements)
);

export const servicesModalVisible = createSelector(
  slimSearchSelector,
  (state) => state.servicesModalVisible
);

export const knownShipperSelector = createSelector(quoteSelector, (quote) => quote.knownShipper);

export const bondTypeSelector = createSelector(quoteSelector, (quote) => {
  const customs = quote.declaredCustoms;
  if (!customs.entry) {
    return undefined;
  } else if (customs.continuousBond) {
    return 'continuous';
  } else if (Boolean(customs.singleEntryBond)) {
    return 'single';
  } else {
    return undefined;
  }
});

export const searchSessionIdSelector = createSelector(
  slimSearchSelector,
  (state) => state.search_session_id
);

export const getVisibleServiceList = createSelector(quoteSelector, (quote) =>
  [...servicesOptionsTypesColumn1, ...servicesOptionsTypesColumn2].reduce((acc, key) => {
    const serviceOption = servicesOptions[key];
    if (!serviceOption || !serviceOption.criteria(quote)) {
      return acc;
    }

    let options = [];
    if (serviceOption.optionType === 'switch') {
      options = serviceOption.getSwitchOptions(quote, serviceOption.options);
      if (options.find((item) => item.key === 'customs-bond-on')) {
        options.push({
          analytic: 'bond-type',
          value: quote.declaredCustoms?.continuousBond ? 'continuous-bond' : 'single-entry-bond'
        });
      }
    } else if (serviceOption.optionType === 'radio') {
      const value = serviceOption.getDefaultValue(quote);

      options = serviceOption.options?.filter((option) => option.key === value);
    }

    return acc.concat(
      options.map((item) => ({
        [item.analytic.replace(/-/g, '_')]: item.value
      }))
    );
  }, [])
);

export const shouldDisableContainers = createSelector(
  [getQuoteLocation],
  ({ origin, destination }) =>
    (origin.locationTypeCode === ADDRESS_TYPES.PORT.value &&
      origin.locationCode &&
      origin.locationCode.length === 3) ||
    (destination.locationTypeCode === ADDRESS_TYPES.PORT.value &&
      destination.locationCode &&
      destination.locationCode.length === 3) ||
    origin.locationTypeCode === ADDRESS_TYPES.ADDRESS.value ||
    destination.locationTypeCode === ADDRESS_TYPES.ADDRESS.value ||
    destination.locationTypeCode === ADDRESS_TYPES.LAST_MILE_DELIVERY.value
);

export const shouldDisableAmazonSelector = createSelector(
  [rootStateSelector],
  (state) => !siteConfigModel.isEnabledFBA(state)
);

export const isMobileSelector = createSelector(
  [isMobile, getWindowWidth, layoutSelector],
  (mobile, windowWidth, layoutSelector) => {
    // at this point we need to target hi-res iPhones (they have retina with large scale)
    // and mobile devices with min-width
    const isIPhone = layoutSelector?.device?.model === 'iPhone';
    const mobileWidthLimit = mobile && windowWidth <= 949;

    return isIPhone || mobileWidthLimit;
  }
);

export const base64Rfq = createSelector(getRfqSelector, (rfq) =>
  window.btoa(encodeURIComponent(JSON.stringify(rfq)))
);

export const isEditMode = createSelector(slimSearchSelector, (state) => state.editMode);

export const getSearchMode = createSelector(slimSearchSelector, (state) => state.searchMode);

export const isOldRfqFlowSelector = createSelector(slimSearchSelector, (state) => state.oldRfqFlow);

export const isInScopeSelector = createSelector(
  slimSearchSelector,
  (state) => state.shipmentInScope
);

export const defaultsInitialized = createSelector(
  slimSearchSelector,
  (state) => state.defaultsInitialized
);

export const shipmentsSelector = createSelector([slimSearchSelector], (state) =>
  get(state, 'tiles.shipments', {})
);

export const paymentsSelector = createSelector([slimSearchSelector], (state) =>
  get(state, 'tiles.payments', {})
);
export const creditSelector = createSelector([slimSearchSelector], (state) =>
  get(state, 'tiles.credit', {})
);
