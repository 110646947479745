import React, { useEffect } from 'react';
import { connect } from 'microfronts-redux';
import { tokens, Alert, Icon, Text } from '@freightos/design-system';
import { paymentsSelector } from 'slimSearch/selectors';
import { t } from 'utils/translationProvider';
import { getSoaInfo, searchAnalytics } from 'slimSearch/actions';
import { ANALYTICS_EVENT_NAME } from 'slimSearch/constants';
import {
  NotificationArea,
  StyledContainer,
  StyledDivider,
  BadgeWrapper,
  ValuesArea,
  StyledBadgeNoWrap,
  StyledParagraph,
  Anchor
} from 'slimSearch/components/Tiles/sharedStyles';
import { Counter } from 'slimSearch/components/Tiles/components/Counter';
import { Tile } from 'slimSearch/components/Tiles/components/Tile';
import userModel from 'propera/user';
import { openSupportCategory } from 'utils';

const { isLoggedIn } = userModel;

export const Payments = ({ payments, getSoaInfo, isLoggedIn, searchAnalytics }) => {
  useEffect(() => {
    isLoggedIn && getSoaInfo();
  }, [getSoaInfo, isLoggedIn]);

  return (
    <Tile
      title={t('slimSearch/payments/manageYourPayments', 'Manage your payments')}
      loading={payments.loading}
      icon="billing"
    >
      <StyledContainer>
        <NotificationArea>
          {payments.data?.DUE_PAYMENT === 0 && payments?.data?.totals > 0 && (
            <Text type="secondary">
              {t('slimSearch/payments/tileAllGood', 'You’re all up to date')}
            </Text>
          )}
          {payments.data?.DUE_PAYMENT > 0 && (
            <Alert
              icon={<Icon type="notification-bell" />}
              message={
                <>
                  {payments.data?.DUE_PAYMENT} item(s) need your attention{' '}
                  <a
                    href="/billing"
                    onClick={() => {
                      searchAnalytics({
                        name: ANALYTICS_EVENT_NAME.MANAGEMENT_TOOL_BUTTON_CLICKED,
                        data: { category: 'payments', action_name: 'quick_fix_payments' }
                      });
                      window.history.pushState({}, '', '/billing');
                    }}
                  >
                    {t('slimSearch/fixNow', 'Fix now')}
                  </a>
                </>
              }
              type="info"
              closable={false}
            />
          )}

          {(payments.error || payments?.data?.totals === 0 || !isLoggedIn) && (
            <StyledParagraph>
              {t(
                'slimSearch/payments/tileFirstBooking',
                'Once you book a shipment, stay up-to-date on your bills and payments.'
              )}{' '}
              <Anchor
                type="text"
                size="small"
                onClick={() => {
                  searchAnalytics({
                    name: ANALYTICS_EVENT_NAME.MANAGEMENT_TOOL_BUTTON_CLICKED,
                    data: { category: 'payments', action_name: 'learn_more' }
                  });
                  openSupportCategory('billing-and-payment');
                }}
              >
                {t('slimSearch/learnMore', 'Learn more')}
              </Anchor>
            </StyledParagraph>
          )}
        </NotificationArea>

        <div>
          <StyledDivider />

          <ValuesArea>
            <BadgeWrapper active={!payments.error && isLoggedIn}>
              <StyledBadgeNoWrap
                color={tokens.fdsChipBackgroundColorEmphasis}
                text={`Open ${payments.data?.OPEN > 0 ? `(${payments.data?.OPEN})` : ''}`}
              />
              <StyledBadgeNoWrap
                status="warning"
                text={`Payment due ${
                  payments.data?.DUE_PAYMENT > 0 ? `(${payments.data?.DUE_PAYMENT})` : ''
                }`}
              />
              <StyledBadgeNoWrap
                status="success"
                text={`Paid in full ${payments.data?.PAID > 0 ? `(${payments.data?.PAID})` : ''}`}
              />
            </BadgeWrapper>
            <div>
              {payments?.data?.totals && (
                <Counter
                  label={t('slimSearch/payments/totalInvoices', 'Total invoices')}
                  value={payments?.data?.totals}
                />
              )}
            </div>
          </ValuesArea>
        </div>
      </StyledContainer>
    </Tile>
  );
};

const mapStateToProps = (store) => ({
  payments: paymentsSelector(store),
  isLoggedIn: isLoggedIn(store)
});

export default connect(mapStateToProps, {
  getSoaInfo,
  searchAnalytics
})(Payments);
