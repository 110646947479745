import * as AT from 'financialSharedComponents/constants/actionTypes';
import flow from 'lodash/fp/flow';
import set from 'lodash/fp/set';
import isEmpty from 'lodash/isEmpty';
import get from 'lodash/get';
import { BANK_TRANSFER_SUPPORTED_CURRENCIES } from 'financialSharedComponents/constants/app.constants';
import {
  SUPPORTED_MONEY_TRANSFER_PSP_NAMES,
  SUPPORTED_PAYMENT_METHODS_TYPES
} from 'financialSharedComponents/constants/app.constants';
import { DefaultCardConstructor } from './components/Payment/utils';

const initialState = {
  selectedPayPalAccount: null,
  deviceDataCorrelationId: null,
  paymentMethodTypes: {},
  cards: {
    default: null,
    list: [],
    busy: false,
    error: null
  },
  paymentLink: {
    paymentInProgress: false,
    error: null,
    paymentSucceeded: false
  },
  availableCredit: {},
  isChangingPaymentMethod: false,
  userCards: {
    list: [],
    default: null
  },
  PSPKeys: {
    stripe: '',
    braintree: ''
  },
  freightosBankDetails: {
    details: [],
    fetching: true
  },
  selectedPaymentMethod: {
    method: null,
    pspName: null,
    metadata: {}
  },
  currentTransaction: {
    initialPaymentMethodType: null,
    paymentAmount: null,
    inProgress: false,
    metadata: {}
  },
  busy: false,
  paymentMethodsInitialized: false,
  error: false,
  cardErrorMessage: null
};

const reducer = (state = initialState, { payload, type }) => {
  switch (type) {
    case AT.FETCH_SUPPORTED_PAYMENT_METHOD_TYPES_FAIL: {
      return flow([set(['paymentMethodTypes'], []), set(['busy'], false), set(['error'], true)])(
        state
      );
    }
    case AT.DO_PAYMENT_LINK_PAYMENT: {
      return flow([
        set(['paymentLink', 'paymentInProgress'], true),
        set(['paymentLink', 'error'], null),
        set(['paymentLink', 'paymentSucceeded'], false)
      ])(state);
    }
    case AT.DO_PAYMENT_LINK_PAYMENT_SUCCESS: {
      return flow([
        set(['paymentLink', 'paymentInProgress'], false),
        set(['paymentLink', 'error'], null),
        set(['paymentLink', 'paymentSucceeded'], true)
      ])(state);
    }
    case AT.DO_PAYMENT_LINK_PAYMENT_FAIL: {
      return flow([
        set(['paymentLink', 'paymentInProgress'], false),
        set(['paymentLink', 'error'], true),
        set(['paymentLink', 'paymentSucceeded'], false)
      ])(state);
    }
    case AT.FETCH_PAYMENT_METHODS: {
      return flow([
        set(['paymentMethodsInitialized'], false),
        set(['cards', 'list'], []),
        set(['selectedPayPalAccount'], null),
        set(['cards', 'default'], null),
        set(['cards', 'busy'], true),
        set(['cards', 'error'], false)
      ])(state);
    }
    case AT.FETCH_PAYMENT_METHODS_FAIL: {
      return flow([
        set(['cards', 'list'], []),
        set(['cards', 'busy'], false),
        set(['cards', 'error'], true)
      ])(state);
    }
    case AT.FETCH_PAYMENT_METHODS_SUCCESS: {
      const {response, type} = payload;
      const methodsList = response || [];
      let selectedMethod =
      methodsList.find((method) => (get(method, 'defaultPaymentMethod', false) === true && get(method, 'paymentMethodType', '') === type)) || null;
      if (type === SUPPORTED_PAYMENT_METHODS_TYPES.CARD && !isEmpty(selectedMethod)) {
        selectedMethod = {...selectedMethod, ...new DefaultCardConstructor(
        get(selectedMethod, 'metadata.last4'),
        get(selectedMethod, 'metadata.cardType'),
        get(selectedMethod, 'metadata.expirationDate')
      )};
      }
      return flow([
        set(['cards', 'list'], methodsList),
        set(['selectedPayPalAccount'], selectedMethod),
        set(['cards', 'default'], selectedMethod),
        set(['cards', 'busy'], false),
        set(['busy'], false),
        set(['error'], false),
        set(['cards', 'error'], false),
        set(['paymentMethodsInitialized'], true)
      ])(state);
    }
    case AT.FETCH_SUPPORTED_PAYMENT_METHOD_TYPES: {
      return flow([set(['paymentMethodTypes'], []), set(['busy'], true), set(['error'], false)])(
        state
      );
    }
    case AT.SET_IS_CHANGING_PAYMENT_METHOD: {
      return flow([set(['isChangingPaymentMethod'], payload)])(state);
    }
    case AT.SET_DEVICE_DATA_CORRELATION_ID: {
      const deviceDataId = payload;
      return flow([set(['deviceDataCorrelationId'], deviceDataId)])(state);
    }
    case AT.SELECTED_PAYMENT_METHOD_CHANGED: {
      const { method, pspName } = payload;
      return flow([
        set(['selectedPaymentMethod', 'method'], method),
        set(
          ['selectedPaymentMethod', 'pspName'],
          pspName ? pspName : get(state, ['paymentMethodTypes', method, '0', 'pspName'])
        )
      ])(state);
    }
    case AT.INIT_PAYMENT_DATA: {
      const { paymentAmount, shipmentNumber, initialPaymentMethodType } = payload;
      return flow([
        set(['currentTransaction', 'metadata', 'shipmentNumber'], shipmentNumber),
        set(['currentTransaction', 'initialPaymentMethodType'], initialPaymentMethodType),
        set(['currentTransaction', 'paymentAmount'], paymentAmount)
      ])(state);
    }
    case AT.FETCH_USER_CARDS_SUCCESS: {
      const { cardsList, defaultCard } = payload;
      return flow([
        set(['userCards', 'list'], cardsList),
        set(['userCards', 'default'], defaultCard),
        set(['freightosBankDetails', 'fetching'], true)
      ])(state);
    }
    case AT.FETCH_PAYMENT_FEE_CONFIG: {
      return flow([set(['paymentFeeConfig', 'fetching'], true)])(state);
    }
    case AT.FETCH_PAYMENT_FEE_CONFIG_SUCCESS: {
      return flow(
        [set(['paymentFeeConfig', 'config'], payload.config)],
        set(['paymentFeeConfig', 'fetching'], false)
      )(state);
    }
    case AT.FETCH_PAYMENT_FEE_CONFIG_FAIL: {
      return flow(
        set(['paymentFeeConfig', 'fetching'], false)
      )(state);
    }
    case AT.FETCH_BANK_DETAILS: {
      return flow([
        set(['freightosBankDetails', 'details'], {}),
        set(['freightosBankDetails', 'fetching'], true)
      ])(state);
    }
    case AT.FETCH_BANK_DETAILS_SUCCESS: {
      let { bankDetails } = payload;
      bankDetails = bankDetails
      .filter(details => BANK_TRANSFER_SUPPORTED_CURRENCIES.includes(details.currency))
      .sort((a,b) => {
        if (a.currency === b.currency) {
          a.title = a.title || "";
          b.title = b.title || "";
          const SORT_TERM = "local";
          return b.title.toLowerCase().indexOf(SORT_TERM) < a.title.toLowerCase().indexOf(SORT_TERM) ? -1 : 1;
       }
       return BANK_TRANSFER_SUPPORTED_CURRENCIES.indexOf(a.currency)
       - BANK_TRANSFER_SUPPORTED_CURRENCIES.indexOf(b.currency);
      });

      return flow([
        set(['freightosBankDetails', 'details'], bankDetails),
        set(['freightosBankDetails', 'fetching'], false)
      ])(state);
    }
    case AT.FETCH_BANK_DETAILS_FAIL: {
      return flow([
        set(['freightosBankDetails', 'details'], []),
        set(['freightosBankDetails', 'fetching'], false)
      ])(state);
    }
    case AT.SAVE_CARD_TOKEN:
    case AT.DO_PAYMENT: {
      return flow([set(['busy'], true), set(['cardErrorMessage'], null)])(state);
    }
    case AT.SAVE_CARD_TOKEN_FAIL: {
      const isIssueFromCard = get(payload, 'isIssueFromCard', false);
      return flow([
        set(['busy'], false),
        set(['cardErrorMessage'], isIssueFromCard ? 'Your card was declined' : null)
      ])(state);
    }
    case AT.SAVE_CARD_TOKEN_SUCCESS: {
      return flow([set(['busy'], false), set(['cardErrorMessage'], null)])(state);
    }
    case AT.DO_PAYMENT_FAIL: {
      let uiFacingError = undefined;
      if (payload && payload.error && payload && payload.error.startsWith('STDERR:')) {
        uiFacingError = payload && payload.error.replace('STDERR:', '');
      }
      return flow([set(['busy'], false), set(['cardErrorMessage'], uiFacingError)])(state);
    }
    case AT.DO_PAYMENT_SUCCESS: {
      return flow([set(['busy'], false)])(state);
    }
    case AT.FETCH_SUPPORTED_PAYMENT_METHOD_TYPES_SUCCESS: {
      let availableCredit = {};
      let { availableMethods, amount, currency, isPayPalEnabled} = payload;
      let pspKeys = {};
        // map gateways to payment methods data
        availableMethods = availableMethods.reduce((c, gateway) => {
          gateway.paymentMethods.forEach((method) =>
            c.push({
              paymentMethodType: get(method, 'paymentMethodType'),
              pspName: get(gateway, 'gatewayName'),
              pspMetadata: { ...get(method, 'metadata', {}), ...get(gateway, 'metadata', {}) }
            })
          );
          return c;
        }, []);
        if(!isPayPalEnabled) {
          availableMethods = availableMethods.filter(method => method.paymentMethodType !== SUPPORTED_PAYMENT_METHODS_TYPES.PAYPAL);
        }
      const SUPPORTED_PAYMENT_METHODS_KEYS = Object.values(SUPPORTED_PAYMENT_METHODS_TYPES);
      const paymentMethodTypesResult = availableMethods
        .filter((m) => SUPPORTED_PAYMENT_METHODS_KEYS.includes(m['paymentMethodType']))
        .reduce((c, a) => {
          if (a['paymentMethodType'] === SUPPORTED_PAYMENT_METHODS_TYPES.CARD) {
            pspKeys[a.pspName] = get(a, ['pspMetadata', 'tokenizationKey']);
          }
          if (a['paymentMethodType'] === SUPPORTED_PAYMENT_METHODS_TYPES.BALANCE) {
            availableCredit = {
              amount: get(a, ['pspMetadata', 'availableCredit']),
              currency: get(a, ['pspMetadata', 'currency'])
            };
            if (
              Number(get(a, ['pspMetadata', 'availableCredit'], 0)) < amount ||
              get(a, ['pspMetadata', 'currency'], null) !== currency
            ) {
              a['UIMetadata'] = {
                disabled: true
              };
            }
          }
          c[a['paymentMethodType']] = c[a['paymentMethodType']] || [];
          if (
            a['paymentMethodType'] === SUPPORTED_PAYMENT_METHODS_TYPES.BANK_TRANSFER &&
            a['pspName'] === SUPPORTED_MONEY_TRANSFER_PSP_NAMES.VEEM
          ) {
            c[a['paymentMethodType']].unshift(a);
          } else {
            c[a['paymentMethodType']].push(a);
          }
          return c;
        }, {});
      const selectedPaymentMethod =
        state.currentTransaction.initialPaymentMethodType === 'Cash'
          ? SUPPORTED_PAYMENT_METHODS_TYPES.BANK_TRANSFER
          : Object.keys(paymentMethodTypesResult)
              .sort(
                (a, b) =>
                  SUPPORTED_PAYMENT_METHODS_KEYS.indexOf(a) -
                  SUPPORTED_PAYMENT_METHODS_KEYS.indexOf(b)
              )
              .find((m) => {
                return (
                  paymentMethodTypesResult[m].filter((n) => get(n, ['UIMetadata', 'disabled']))
                    .length !== paymentMethodTypesResult[m].length
                );
              });
      const selectedPspName = get(
        paymentMethodTypesResult,
        [selectedPaymentMethod, '0', 'pspName'],
        ''
      );
      // making sure previous method is still available
      let previousSelectedPSPName = get(state, ['selectedPaymentMethod', 'pspName'], null);
      previousSelectedPSPName = availableMethods
        .map((m) => m.pspName)
        .find((m) => m === previousSelectedPSPName);
      let previousSelectedPaymentMethod = get(state, ['selectedPaymentMethod', 'method'], null);
      previousSelectedPaymentMethod = availableMethods
        .map((m) => m.paymentMethodType)
        .find((m) => m === previousSelectedPaymentMethod);
      return flow([
        set(['PSPKeys'], pspKeys),
        set(['paymentMethodTypes'], paymentMethodTypesResult),
        set(['availableCredit'], availableCredit),
        set(
          ['selectedPaymentMethod', 'method'],
          previousSelectedPaymentMethod || selectedPaymentMethod
        ),
        set(['selectedPaymentMethod', 'pspName'], previousSelectedPSPName || selectedPspName),
        set(['busy'], false),
        set(['error'], false)
      ])(state);
    }
    default:
      return state;
  }
};

export default reducer;
