import React, { useState } from 'react';
import { connect } from 'microfronts-redux';
import siteConfig from 'propera/siteConfig';
import styled from 'styled-components/macro';
import { tokens, Text, Route, Icon, Button, Paragraph, Tooltip } from '@freightos/design-system';

import { t } from 'utils/translationProvider';
import { getQuoteLocation } from 'slimSearch/selectors';
import { onGoingHolidays } from 'utils';

const date = new Date();
const monthName = date.toLocaleString('default', { month: 'long' });
const trends = {
  January: 'typical',
  February: 'typical',
  March: 'typical',
  April: 'low',
  May: 'typical',
  June: 'typical',
  July: 'low',
  August: 'typical',
  September: 'typical',
  October: 'low',
  November: 'typical',
  December: 'low'
};

const currentTrend = trends[monthName];
const trendColor =
  currentTrend === 'low' ? tokens.fdsComponentColorPositive : tokens.fdsColorSemanticYellow;

export const Trend = ({ locations, originLocation, holidays }) => {
  const [trendObjectivesOpen, setTrendObjectivesOpen] = useState(false);

  if (!currentTrend) {
    return null;
  }

  return (
    <TrendWrapper>
      <TrendContent>
        <Text data-test-id="trends-title" type="secondary">
          {t('trends/pricingBasedOn', 'Pricing based on your route')}
        </Text>
        <StyledRoute
          legs={[
            {
              label: (
                <Tooltip title={locations?.origin?.locationCode}>
                  <LocationLabel data-test-id="trends-origin-label">
                    {locations?.origin?.locationCode}
                  </LocationLabel>
                </Tooltip>
              ),
              icon: 'arrow-forward'
            },
            {
              label: (
                <Tooltip title={locations?.destination?.locationCode}>
                  <LocationLabel data-test-id="trends-destination-label">
                    {locations?.destination?.locationCode}
                  </LocationLabel>
                </Tooltip>
              )
            }
          ]}
        />
        <div>
          <Icon
            type={currentTrend === 'low' ? 'trending-down' : 'trending-flat'}
            color={trendColor}
          />{' '}
          <TrendText color={trendColor} data-test-id="trends-trend-text">
            {t('trends/pricesAre', 'Prices are')}{' '}
            <strong data-test-id="trends-current-trend-text">
              {t(`trends/${currentTrend}`, currentTrend)}
            </strong>
          </TrendText>
        </div>
      </TrendContent>
      <TrendObjectives>
        <Button
          type="text"
          onClick={() => setTrendObjectivesOpen(!trendObjectivesOpen)}
          block
          data-test-id="trends-more-button"
        >
          {t('trends/goodTimeToBook', "It's a good time to book")}{' '}
          <Icon type={trendObjectivesOpen ? 'caret-up' : 'caret-down'} />
        </Button>
        {trendObjectivesOpen && (
          <>
            <div>
              {onGoingHolidays(holidays, originLocation?.countryID?.value).map((item) => (
                <StyledParagraph
                  data-test-id="trends-holiday-description"
                  key={item.holiday?.holidayName}
                >
                  {item.holiday?.holidayNotification}
                </StyledParagraph>
              ))}
            </div>
            <StyledParagraph data-test-id="trends-description">
              {t(
                'trends/descriptionLegal',
                'The pricing information shown here is based on analyzing current and past prices. Past prices are no indication of future pricing.'
              )}
            </StyledParagraph>
          </>
        )}
      </TrendObjectives>
    </TrendWrapper>
  );
};

const TrendWrapper = styled.div`
  margin: ${tokens.fdsComponentSpacingLg} 0;
`;

const TrendContent = styled.div`
  padding: ${tokens.fdsComponentSpacingLg};
  border: 1px solid ${tokens.fdsColorPrimaryGray20};
  border-bottom: none;
  border-radius: 4px 4px 0 0;
`;

const TrendText = styled(Text)`
  font-size: ${tokens.fdsH6FontSize};
  font-weight: ${tokens.fdsFontWeightRegular};
  color: ${tokens.fdsColorPrimaryGray80};

  strong {
    font-weight: ${tokens.fdsFontWeightSemibold};
    color: ${({ color }) => color};
  }
`;

const TrendObjectives = styled.div`
  padding: ${tokens.fdsComponentSpacingLg};
  border: 1px solid ${tokens.fdsColorPrimaryGray20};
  border-radius: 0 0 4px 4px;
  background: ${tokens.fdsColorPrimaryGray05};
`;

const StyledRoute = styled(Route)`
  margin: ${tokens.fdsComponentSpacingSm} 0 ${tokens.fdsComponentSpacingLg};
`;

const LocationLabel = styled.div`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 95px;
  line-height: 21px;
`;

const StyledParagraph = styled(Paragraph)`
  line-height: 21px;
`;

const mapStateToProps = (state) => ({
  locations: getQuoteLocation(state),
  originLocation: state.search.quote.origin.originLocation,
  holidays: siteConfig.getHolidays(state)
});

export default connect(mapStateToProps)(Trend);
