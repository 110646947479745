import BankDetails from '@freightos/bank-details';
import {getBankDetailsCallback} from 'financialSharedComponents/components/BankDetails/helpers/bankDetailsAPI';

const BankDetailsComponent = ({
  buyerCurrency,
  showAllDetails,
  hideExpandArrows
}) => {
    return <BankDetails getBankDetailsCallback ={getBankDetailsCallback} buyerCurrency = {buyerCurrency} showAllDetails={showAllDetails} hideExpandArrows = {hideExpandArrows} />
  };

  export default BankDetailsComponent;
  