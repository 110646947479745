import get from 'lodash/get';
import { getVendors } from 'results/results.utils';
import { loadSummaryToOldFormat, totalShipmentSummary } from 'results/custom-quote.utils';
import { mapRfqToQuote, isLoadCalculateByTotal } from 'slimSearch/utils/mapOpenFreight.utils';

export const isCarrierServiceQuote = (quote) => {
  const additionalInformation = get(quote, 'additionalInformation', []);
  const providerType = additionalInformation.find((item) => item.key === 'providerType');
  if (get(providerType, 'value') === 'CARRIER') {
    return true;
  }
  return false;
};

export const isCancellationPolicy = (quote) => {
  const additionalInformation = get(quote, 'additionalInformation', []);
  const providerType = additionalInformation.find((item) => item.key === 'cancellationPolicy');
  if (get(providerType, 'value') === 'STRICT') {
    return true;
  }
  return false;
};

export const getPromotion = (quote) =>
  get(quote, 'connection.generalCharges', []).find((item) => item.feeCode === 'FFPROMO');

export const getTransitTimeString = ([timeObject = undefined]) => {
  if (!timeObject) {
    return undefined;
  }
  if (!timeObject.to) {
    return `${timeObject.from.value}`;
  }
  return `${timeObject.from?.value} - ${timeObject.to?.value}`;
};

export const getCustomBondType = (shipment = {}) => {
  if (shipment.declaredCustoms?.entry) {
    if (Boolean(shipment.declaredCustoms?.continuousBond)) {
      return 'continuousBond';
    } else if (Boolean(shipment.declaredCustoms?.singleEntryBond)) {
      return 'singleEntryBond';
    }
  }
  return undefined;
};

export const getShipmentLabel = (shipment = {}) => {
  const quote = mapRfqToQuote({ shipment });
  if (isLoadCalculateByTotal(shipment.load)) {
    return totalShipmentSummary(quote);
  }
  return loadSummaryToOldFormat(quote.load);
};

export const getHighestPrice = (quotes) => {
  return Object.entries(quotes).reduce((acc, [, quote = {}]) => {
    const price = quote.priceIndicator?.totalCharge?.value;
    if (price > acc) {
      return price;
    }
    return acc;
  }, 0);
};

export const mapShipmentData = (shipment = {}) => {
  return {
    pickupInfo: {
      facility: shipment.originLocation?.locationName,
      country: shipment.originLocation?.countryID.value,
      address: get(shipment, 'originLocation.addressLines[0]'),
      portCharges: shipment.pricePreference?.includeOriginPortCharges,
      pickupDate: shipment.pickupEvent?.eventDate?.scheduledDateTime,
      locationTypeCode: get(shipment, 'originLocation.locationTypeCode'),
      locationCode: get(shipment, 'originLocation.locationCode')
    },
    deliveryInfo: {
      facility: shipment.destinationLocation?.locationName,
      country: shipment.destinationLocation?.countryID.value,
      address: get(shipment, 'destinationLocation.addressLines[0]'),
      portCharges: shipment.pricePreference?.includeDestinationPortCharges,
      locationTypeCode: get(shipment, 'destinationLocation.locationTypeCode'),
      locationCode: get(shipment, 'destinationLocation.locationCode')
    },
    customs: {
      type: getCustomBondType(shipment),
      entry: shipment.declaredCustoms?.entry
    },
    origin_country_code: shipment.originLocation?.countryID.value, // we need for Intercom
    destination_country_code: shipment.destinationLocation?.countryID.value, // we need for Intercom
    costoms_bond: shipment.declaredCustoms?.entry, // support old flows ;-(,
    insurance: Boolean(shipment.insuranceValueAmount),
    estimated_goods_value: shipment?.insuranceValueAmount?.value,
    estimated_goods_value_currency: shipment?.insuranceValueAmount?.currencyID,
    load: getShipmentLabel(shipment),
    packages: shipment?.load?.packages // different format
  };
};

export const getQuoteBadges = (quote) => {
  const result = [];
  if (isCarrierServiceQuote(quote)) {
    result.push('direct carrier integration');
  }

  if (isCancellationPolicy(quote)) {
    result.push('strict cancellation policy');
  }

  if (quote.sortValue?.guaranteedSpace) {
    result.push('guaranteed capacity');
  }

  return result;
};

export const quotesAnalyticsData = (quotes, indexIds) => {
  const positions = {};

  indexIds.forEach((key, index) => {
    positions[key] = index + 1;
  });

  const results = [];
  for (let quoteKey in quotes) {
    const quote = quotes[quoteKey];
    const vendors = getVendors(quote) || [{}];
    const sellerKey = vendors[0].ID;
    const promotion = getPromotion(quote) || {};

    const bookingParty = get(quote, 'additionalInformation', []).find(
      ({ key, value }) => key === 'bookingParty' && value === 'external'
    ) || { value: 'internal' };

    const tile = {
      clientId: quoteKey.split('|')[1],
      sellerKey,
      sellerName: vendors[0].name,
      position: positions[quoteKey],
      discount: promotion.rate?.value,
      promotionKey: promotion.feeComment,
      totalAmount: quote.priceIndicator?.totalCharge?.value,
      totalAmountCurrency: quote.priceIndicator?.totalCharge?.currencyID,
      label: quote.accolade,
      mode: quote.transportMode,
      transportService: quote.transportService,
      p2pTT: getTransitTimeString(
        quote.connection?.portToPortTransitTime?.estimatedTransitTimes ?? []
      ),
      e2eTT: getTransitTimeString(quote.connection?.transitTime?.estimatedTransitTimes ?? []),
      bookingParty: bookingParty.value,
      quoteBadges: getQuoteBadges(quote)
    };
    results.push(tile);
  }
  return results;
};

export const resultsCompletedAnalyticsData = (state, shipment) => {
  const modeValuesList = state.filters?.mode?.list;
  const { bestValueId, cheapestId, quickestId } = state.topQuotesIndexes;
  const results = {};

  modeValuesList.forEach((item) => {
    if (item.key) {
      results[item.key] = item.count;
    }
  });

  return {
    resultsCount: {
      bestValuePerSeller: state.uniqueResults,
      moreResultsFromSameSeller: state.resultsCount - state.uniqueResults,
      total: state.resultsCount
    },

    isFba: Boolean(shipment.fulfilmentInfo),
    results_number: state.resultsCount,
    resultsCount_guaranteedCapacity: state.guaranteedCapacityCount,
    results,
    cheapest_price: get(state.quotes[cheapestId], 'priceIndicator.totalCharge.value'),
    highest_price: getHighestPrice(state.quotes),
    fastest_price: get(state.quotes[quickestId], 'priceIndicator.totalCharge.value'),
    best_value_price: get(state.quotes[bestValueId], 'priceIndicator.totalCharge.value'),
    ...mapShipmentData(shipment)
  };
};
