import { getRootDomain } from 'propera/utils/url';
import {
TEST_DOMAIN,
CHINA_URL_FOR_STAGING,
CHINA_URL_FOR_PRODUCTION,
PRODUCTION_DOMAIN,
BILLING_SUB_DOMAIN
} from 'financialSharedComponents/constants/endpoints';

export const FREIGHTOS_AUTHENTICATION_ENDPOINT_MP =
  'servlet/apptoken?app=mp_auth_v2&includeData=true';

export const BANK_DETAILS_API_PATH =
 '/billing-service/api/v1/bank-details';

export const SUPPORTED_GATEWAYS_API_PATH = '/billing-service/web/api/v1/supported-gateways?currency=<%= currency %>';
export const GET_USER_PAYMENT_METHODS_API_PATH = '/billing-service/web/api/v1/payment-methods?type=<%= type %>&paymentGateway=<%= paymentGateway %>&defaultMethod=true';
export const SUBMIT_PAYMENT_TOKEN_API_PATH ='billing-service/web/api/v1/payment-methods';

export const getBillingServiceBaseUrl = () => {
  const { href, protocol } = window.location;
  let domain = getRootDomain(href);
  if (domain.includes('local')) {
    // local - go to test
    domain = TEST_DOMAIN;
  }
  if (domain.includes('appspot')) {
    // appspot - go to test domain
    domain = TEST_DOMAIN;
  }
  if (href.includes(CHINA_URL_FOR_STAGING)) {
    // lhchina.freightos.cn - go to production domain, freightos.com
    domain = PRODUCTION_DOMAIN;
  }
  if (href.includes(CHINA_URL_FOR_PRODUCTION)) {
    // ship.freightos.cn - go to production domain, freightos.com
    domain = PRODUCTION_DOMAIN;
  }
  return `${protocol}//${BILLING_SUB_DOMAIN}.${domain}`;
};
