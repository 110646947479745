import { getMsEndpointUrl, MICROSERVICES_DOMAINS, getRootDomain } from 'propera/utils/url';

const { AUTH_SERVICE } = MICROSERVICES_DOMAINS;

export const AUTH_TOKEN_ENDPOINT = `${getMsEndpointUrl(AUTH_SERVICE.NAME)}/getToken`;

export const BANK_DETAILS_API_PATH = '/billing-service/api/v1/bank-details';

export const TEST_DOMAIN = 'test-freightos.com';
export const CHINA_URL_FOR_STAGING = 'lhchina.freightos.cn';
export const STAGING_DOMAIN = 'staging-freightos.com';
export const CHINA_URL_FOR_PRODUCTION = 'ship.freightos.cn';
export const GW_LOCAL_PATH = 'http://lighthouse.freightos.local:8080/';
export const PRODUCTION_DOMAIN = 'freightos.com';
export const GW_SUB_DOMAIN = 'lighthouse';
export const BILLING_SUB_DOMAIN = 'platform';

export const FREIGHTOS_AUTHENTICATION_ENDPOINT_MP =
  'servlet/apptoken?app=mp_auth_v2&includeData=true';

export const getFreightosServerEndpoint = () => {
  const { search, href } = window.location;
  if (getRootDomain(href).includes('local')) {
    const params = search.slice(1) ? `&${search.slice(1)}` : '';
    return `${FREIGHTOS_AUTHENTICATION_ENDPOINT}${params}`;
  }
  return FREIGHTOS_AUTHENTICATION_ENDPOINT;
};

export const getFreightosServerBaseUrl = () => {
  const { protocol, host, href } = window.location;
  const domain = getRootDomain(href);

  if (domain.includes('local')) {
    // local - go to local GW
    return GW_LOCAL_PATH;
    // in order to use local freightos BE use this
    // return 'http://ship.freightos.local:8888/';
  }
  return `${protocol}//${host}`;
};

export const getFreightosMpServerEndpoint = () => {
  const { search, href } = window.location;
  if (getRootDomain(href).includes('local')) {
    const params = search.slice(1) ? `&${search.slice(1)}` : '';
    return `${FREIGHTOS_AUTHENTICATION_ENDPOINT_MP}${params}`;
  }
  return FREIGHTOS_AUTHENTICATION_ENDPOINT_MP;
};

