import { createSelector } from 'reselect';
import { quoteSelector } from 'slimSearch/selectors';
import { ADDRESS_TYPES } from 'slimSearch/constants';
import { getShipmentTypeByLoadData, isAmazonShipment } from 'slimSearch/utils/mapOpenFreight.utils';
import { getRfqKeyFromUrl } from 'results/results.utils';
export {
  updateField as updateFieldAction,
  showServicesModal as showServicesModalAction,
  hideServicesModal as hideServicesModalAction
} from 'slimSearch/actions';

export const getRfqOptionsSelector = createSelector(quoteSelector, (shipment) => ({
  isOriginChargesRequested: shipment.pricePreference?.includeOriginPortCharges,
  isDestinationChargesRequested: shipment.pricePreference?.includeDestinationPortCharges,
  isCustomsBrokerageRequested: shipment.declaredCustoms?.entry,
  isInsuranceRequested: shipment.insuranceValueAmount,
  isDestinationPortAirport:
    shipment.origin?.originLocation?.locationTypeCode === ADDRESS_TYPES.PORT.value,
  isOriginPortAirport:
    shipment.destination?.destinationLocation?.locationTypeCode === ADDRESS_TYPES.PORT.value,
  pickupDate: new Date(shipment.pickupEvent?.eventDate?.scheduledDateTime).getTime(),
  transportMode: getShipmentTypeByLoadData(shipment.load?.packages ?? []),
  rfqKey: getRfqKeyFromUrl(),
  isAmazonShipment: isAmazonShipment(shipment)
}));
