import { PACKAGE_NAME } from 'slimSearch/constants';
export const vendorsActionType = {
  GET_VENDORS_DATA: `${PACKAGE_NAME}/GET_VENDORS_DATA`,
  GET_VENDORS_DATA_FAILURE: `${PACKAGE_NAME}/GET_VENDORS_DATA_FAILURE`,
  GET_VENDORS_DATA_SUCCESS: `${PACKAGE_NAME}/GET_VENDORS_DATA_SUCCESS`,
  GET_QUOTES_VENDORS: `${PACKAGE_NAME}/GET_QUOTES_VENDORS`,
  VENDOR_LOADING: `${PACKAGE_NAME}/VENDOR_LOADING`
};

export const vendorLoading = (payload) => ({
  type: vendorsActionType.VENDOR_LOADING,
  payload
});

export const getVendorsData = (payload) => ({
  type: vendorsActionType.GET_VENDORS_DATA,
  payload
});

export const getVendorsDataSuccess = (payload) => ({
  type: vendorsActionType.GET_VENDORS_DATA_SUCCESS,
  payload
});
export const getVendorsDataFailure = () => ({
  type: vendorsActionType.GET_VENDORS_DATA_FAILURE
});

export const getQuoteVendors = (payload) => ({
  type: vendorsActionType.GET_QUOTES_VENDORS,
  payload
});
