import { connect } from 'react-redux';
import MoneyTransferOptions from './MoneyTransferOptions';
import * as actions from '../actions';
import * as selectors from 'financialSharedComponents/selectors';
const mapStateToProps = (state) => {
  const moneyTransferOptions = selectors.moneyTransferOptionsSelector(state).map(
    (m) => m.pspName
  );
  return {
    moneyTransferOptions,
    shipmentNumber: selectors.currentTransactionShipmentNumberSelector(state),
    selectedPSPName: selectors.selectedPaymentMethodPSPNameSelector(state)
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    paymentMethodChanged: (payload) => dispatch(actions.paymentMethodChanged(payload))
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(MoneyTransferOptions);
