import { all, put, takeLatest, call, delay } from 'redux-saga/effects';
import { doGetRequest } from 'propera/HTTP';
import { actions as monitoringActions } from 'propera/monitoring';

import { getLatestBookings, getLatestBookingsSuccess } from 'latestBookings/actions';
import {
  ENDPOINTS,
  CORE_TOKEN_ENDPOINT,
  LH_TOKEN_VERIFY_ENDPOINT,
  MAX_CALLS_PER_SESSION,
  LATEST_BOOKINGS_IN_MINUTES,
  LATEST_BOOKINGS_INTERVAL_MS
} from 'latestBookings/constants';
import { endpointConstructor } from 'utils';

export function* fetchLatestBookings() {
  try {
    let counter = 0;

    // we need special authentication against lighthouse
    const { token } = yield call(doGetRequest, CORE_TOKEN_ENDPOINT);
    const { token: LhToken } = yield call(doGetRequest, LH_TOKEN_VERIFY_ENDPOINT, {
      Authorization: `Application ${token}`
    });

    while (counter < MAX_CALLS_PER_SESSION) {
      const { data, status } = yield call(
        doGetRequest,
        endpointConstructor(ENDPOINTS.latestBookings, {
          minutes: LATEST_BOOKINGS_IN_MINUTES
        }),
        { Authorization: `Application ${LhToken}` },
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        true // fullResponse - for status
      );

      if (status === 204) {
        // if no data 1st time - don't poll, reset counter to max, no use
        counter = MAX_CALLS_PER_SESSION;
      }

      if (data) {
        yield put(getLatestBookingsSuccess(data));
      }

      yield delay(LATEST_BOOKINGS_INTERVAL_MS);
      counter = counter + 1;
    }
  } catch (error) {
    yield put(monitoringActions.exceptionReporter(error, false));
  }
}

export default function* () {
  yield all([takeLatest(getLatestBookings().type, fetchLatestBookings)]);
}
