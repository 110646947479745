import React from 'react';
import { head, pullAt } from 'lodash/fp';
import { connect } from 'microfronts-redux';
import styled, { css } from 'styled-components/macro';
import {
  fdsComponentSpacingLg,
  fdsFontSizeSm,
  fdsBoxShadowSm
} from '@freightos/design-system/dist/tokens';
import {
  Alert,
  Col,
  FormItem,
  Input,
  RadioButtonGroup,
  Row,
  Select,
  Option,
  RadioGroup,
  Icon
} from '@freightos/design-system';
import siteConfig from 'propera/siteConfig';
import { t } from 'utils/translationProvider';

import { updateField } from 'slimSearch/actions';
import {
  LOAD_TYPES,
  PALLET_TYPES,
  WEIGHT_UNITS,
  VOLUME_UNITS,
  CUBIC_FEET_IN_METERS,
  LCL_LOAD_DEFAULTS,
  FULFILMENT_CENTER_COMPANIES,
  ADDRESS_TYPES,
  SECTIONS
} from 'slimSearch/constants';
import Quantity from 'slimSearch/components/sections/load/Quantity';
import MeasurementUnitFieldInput from 'slimSearch/components/sections/load/MeasirementUnitFieldInput';

import {
  getMeasurements,
  getQuoteLoad,
  getQuoteLocation,
  isMobileSelector
} from 'slimSearch/selectors';
import { loadHasWidthLengthValue } from 'slimSearch/utils/mapOpenFreight.utils';

import CollapsedLoad from 'slimSearch/components/sections/load/CollapsedLoad';

const { Group: InputGroup } = Input;

const LOAD_TYPE = LOAD_TYPES.LOOSE_CARGO.value;

export const LooseCargo = ({
  load,
  updateField,
  isMobile,
  measurements,
  isAmazon,
  cbmToShowAlsoFCLLimit
}) => {
  const suggestFcl = () => {
    if (load[LOAD_TYPE].calculateByTotals) {
      const { value } = load[LOAD_TYPE].totals.volume;
      const { CBM, CFT } = VOLUME_UNITS;

      if (measurements.volume === CBM && value >= cbmToShowAlsoFCLLimit) {
        return true;
      }

      if (measurements.volume === CFT && value >= CUBIC_FEET_IN_METERS * cbmToShowAlsoFCLLimit) {
        return true;
      }
    }

    return false;
  };

  const keepPrevLoadValues = (load, prevLoad, index) => {
    const quantity = load[LOAD_TYPE].packages[index].quantity || 1;
    const weight = load[LOAD_TYPE].packages[index]?.weight?.value ?? '';
    const width = load[LOAD_TYPE].packages[index]?.packageDimensions?.width?.value ?? '';
    const length = load[LOAD_TYPE].packages[index]?.packageDimensions.length?.value;
    const height = load[LOAD_TYPE].packages[index]?.packageDimensions.height?.value;
    const palletsOrBox = [LOAD_TYPES.PALLETS.value, LOAD_TYPES.BOX.value].includes(
      load[LOAD_TYPE].packages[index].packagingType
    );

    return {
      ...prevLoad,
      quantity,
      weight: { value: weight },
      packageDimensions: {
        width: { value: width },
        ...(palletsOrBox ? { length: { value: length } } : null),
        ...(palletsOrBox ? { height: { value: height } } : null)
      }
    };
  };

  return (
    <LooseCargoWrapper>
      <FormItem colon={false} label={isMobile ? t('Calculate by', 'Calculate by') : ''}>
        <RadioGroup
          data-test-id={`${SECTIONS.LOAD}-loose-cargo-calculate-by`}
          onChange={(event) => {
            updateField(
              `quote.load.${LOAD_TYPES.LOOSE_CARGO.value}.calculateByTotals`,
              event.target.value === 'totals'
            );
          }}
          value={load[LOAD_TYPES.LOOSE_CARGO.value].calculateByTotals ? 'totals' : 'units'}
          options={[
            {
              label: isMobile
                ? t('looseCargo/byUnitsMobile', 'Unit type')
                : t('looseCargo/byUnits', 'Calculate by unit type'),
              value: 'units'
            },
            {
              label: isMobile
                ? t('looseCargo/byTotalsMobile', 'Total shipment')
                : t('looseCargo/byTotals', 'Calculate by total shipment'),
              value: 'totals'
            }
          ]}
        />
      </FormItem>
      {load[LOAD_TYPE].calculateByTotals ? (
        <>
          <Alert
            data-test-id={`${SECTIONS.LOAD}-loose-cargo-calculate-by-totals-alert`}
            type="info"
            message={t(
              'Calculating by total shipment is less accurate and therefore more likely to incur an additional charge for inaccurate or oversized dimensions.',
              'Calculating by total shipment is less accurate and therefore more likely to incur an additional charge for inaccurate or oversized dimensions.'
            )}
            closable={false}
          />
          <br />
          <Row wrap={false} gutter={[8, 8]}>
            <Col span={isMobile ? 24 : 24 / 3}>
              <FormItem colon={false} label={t('# of units', '# of units')}>
                <Quantity
                  data-test-id={`${SECTIONS.LOAD}-loose-cargo-calculate-by-totals-qty`}
                  value={load[LOAD_TYPE].totals.quantity}
                  onChange={(value) =>
                    updateField(
                      `quote.load.${LOAD_TYPE}.totals.quantity`,
                      value === null ? 1 : value
                    )
                  }
                />
              </FormItem>
            </Col>
            <Col span={isMobile ? 24 / 2 : 24 / 3}>
              <FormItem
                isMobile={isMobile}
                colon={false}
                data-test-id={`${SECTIONS.LOAD}-loose-cargo-calculate-by-totals-volume`}
                label={t('Total volume', 'Total volume')}
              >
                <MeasurementUnitFieldInput
                  path="totals.volume"
                  units={[VOLUME_UNITS.CBM, VOLUME_UNITS.CFT]}
                  measurementType="volume"
                />
              </FormItem>
            </Col>
            <Col span={isMobile ? 24 / 2 : 24 / 3}>
              <FormItem
                isMobile={isMobile}
                colon={false}
                data-test-id={`${SECTIONS.LOAD}-loose-cargo-calculate-by-totals-volume`}
                label={t('Total weight', 'Total weight')}
              >
                <MeasurementUnitFieldInput
                  selectWidth={58}
                  path="totals.weight"
                  units={[WEIGHT_UNITS.KG, WEIGHT_UNITS.LB]}
                  measurementType="weight"
                />
              </FormItem>
            </Col>
          </Row>
          {suggestFcl() && (
            <>
              <br />
              <Alert
                data-test-id={`${SECTIONS.LOAD}-loose-cargo-suggest-fcl-alert`}
                type="info"
                closable={false}
                message={t(
                  'A container may be cheaper and quicker for a shipment this size. Look for the FCL (Full Container Load) results. Note: You’ll need a loading dock and will have two hours to load/unload.',
                  'A container may be cheaper and quicker for a shipment this size. Look for the FCL (Full Container Load) results. Note: You’ll need a loading dock and will have two hours to load/unload.'
                )}
              />
            </>
          )}
        </>
      ) : (
        load[LOAD_TYPE].packages &&
        load[LOAD_TYPE].packages.map((pkg, index) => {
          const isWidthLengthValue = loadHasWidthLengthValue(
            load[LOAD_TYPE].packages[index].packagingType
          );
          return load[LOAD_TYPE].packages.length > 1 && load.active !== index ? (
            <CollapsedLoad
              pkg={pkg}
              loadType={LOAD_TYPE}
              index={index}
              data-test-id={`${SECTIONS.LOAD}-loose-cargo-package-collapsed-${index}`}
            />
          ) : (
            <LoadRow
              multipleLoads={load[LOAD_TYPE].packages.length > 1}
              key={index}
              data-test-id={`${SECTIONS.LOAD}-loose-cargo-package-expanded-${index}`}
            >
              {load[LOAD_TYPE].packages.length > 1 && (
                <>
                  <LoadTitle>Load {index + 1}</LoadTitle>
                  <StyledIcon
                    color="@fds-error-color"
                    type="trash"
                    data-test-id={`${SECTIONS.LOAD}-expanded-load-delete-btn-${index}`}
                    onClick={() =>
                      updateField(
                        `quote.load.${LOAD_TYPE}.packages`,
                        pullAt([index], load[LOAD_TYPE].packages)
                      )
                    }
                  />
                </>
              )}
              <Row wrap={false} gutter={[8, 8]}>
                <Col span={24}>
                  {isAmazon &&
                    load[LOAD_TYPE].packages[index].packagingType === LOAD_TYPES.BOX.value && (
                      <Alert
                        data-test-id={`${SECTIONS.LOAD}-loose-cargo-package-palletizing-alert-${index}`}
                        closable={false}
                        type="info"
                        message="If your supplier is palletizing the goods, search by pallets not boxes to avoid adjustments and extra charges."
                      />
                    )}
                </Col>

                <Col span={isMobile ? 24 : 18}>
                  <FormItem colon={false} label="Package type">
                    <StyledRadioButtonGroup
                      data-test-id={`${SECTIONS.LOAD}-loose-cargo-package-type-${index}`}
                      size={isMobile ? 'large' : 'default'}
                      onChange={(event) => {
                        if (isAmazon && event.target.value === 'pallets') {
                          updateField(
                            `quote.load.${LOAD_TYPE}.packages[${index}]`,
                            keepPrevLoadValues(load, LCL_LOAD_DEFAULTS.pallet_48_40, index)
                          );
                        } else {
                          updateField(
                            `quote.load.${LOAD_TYPE}.packages[${index}]`,
                            keepPrevLoadValues(load, LCL_LOAD_DEFAULTS[event.target.value], index)
                          );
                        }
                      }}
                      value={
                        PALLET_TYPES.some(
                          (palletType) =>
                            palletType.value === load[LOAD_TYPE].packages[index].packagingType
                        )
                          ? LOAD_TYPES.PALLETS.value
                          : LOAD_TYPES.BOX.value
                      }
                      options={[LOAD_TYPES.PALLETS, LOAD_TYPES.BOX]}
                    />
                  </FormItem>
                </Col>

                <Col span={isMobile ? 24 : 6}>
                  <FormItem colon={false} label={t('# of units', '# of units')}>
                    <StyledQuantity
                      data-test-id={`${SECTIONS.LOAD}-loose-cargo-package-qty-${index}`}
                      isMobile={isMobile}
                      value={pkg.quantity}
                      onChange={(value) =>
                        updateField(
                          `quote.load.${LOAD_TYPE}.packages[${index}].quantity`,
                          value === null ? 1 : value
                        )
                      }
                    />
                  </FormItem>
                </Col>
              </Row>

              {load[LOAD_TYPE].packages[index].packagingType !== LOAD_TYPES.BOX.value && (
                <Row>
                  <Col span={24}>
                    <FormItem colon={false} label={t('Pallet type', 'Pallet type')}>
                      <StyledPalletsSelect
                        {...(isMobile
                          ? { getPopupContainer: (trigger) => trigger.parentElement }
                          : null)}
                        data-test-id={`${SECTIONS.LOAD}-loose-cargo-pallet-type-${index}`}
                        disabled={isAmazon}
                        size={isMobile ? 'large' : 'default'}
                        value={
                          isAmazon &&
                          load[LOAD_TYPE].packages[index].packagingType ===
                            LOAD_TYPES.PALLETS.value &&
                          load[LOAD_TYPE].packages[index].packagingType !==
                            LCL_LOAD_DEFAULTS['pallet_48_40'].packagingType
                            ? LCL_LOAD_DEFAULTS['pallet_48_40'].packagingType
                            : load[LOAD_TYPE].packages[index].packagingType
                        }
                        onChange={(type) => {
                          updateField(
                            `quote.load.${LOAD_TYPE}.packages[${index}]`,
                            keepPrevLoadValues(load, LCL_LOAD_DEFAULTS[type], index)
                          );
                        }}
                      >
                        {(isAmazon ? [head(PALLET_TYPES)] : PALLET_TYPES).map((option) => (
                          <Option
                            key={option.value}
                            value={option.value}
                            data-test-id={`${SECTIONS.LOAD}-loose-cargo-pallet-type-${option.value}`}
                          >
                            {option.label}
                          </Option>
                        ))}
                      </StyledPalletsSelect>
                    </FormItem>
                  </Col>
                </Row>
              )}

              {isMobile ? (
                <Row wrap={true} gutter={[8, 8]}>
                  {loadHasWidthLengthValue(load[LOAD_TYPE].packages[index].packagingType) && (
                    <>
                      <Col span={isMobile ? 12 : 6}>
                        <FormItem
                          colon={false}
                          label={
                            <LabelWithHelp>
                              {t('Length', 'Length')} <span>{'(Per unit)'}</span>
                            </LabelWithHelp>
                          }
                        >
                          <MeasurementUnitFieldInput
                            selectWidth={58}
                            path={`packages[${index}].packageDimensions.length`}
                            index={index}
                            units={['cm', 'in']}
                            measurementType="dimensions"
                          />
                        </FormItem>
                      </Col>
                      <Col span={isMobile ? 12 : 6}>
                        <FormItem
                          colon={false}
                          label={
                            <LabelWithHelp>
                              {t('Width', 'Width')} <span>{'(Per unit)'}</span>
                            </LabelWithHelp>
                          }
                        >
                          <MeasurementUnitFieldInput
                            selectWidth={58}
                            path={`packages[${index}].packageDimensions.width`}
                            index={index}
                            units={['cm', 'in']}
                            measurementType="dimensions"
                          />
                        </FormItem>
                      </Col>
                    </>
                  )}
                  <Col
                    span={
                      isMobile ||
                      !loadHasWidthLengthValue(load[LOAD_TYPE].packages[index].packagingType)
                        ? 12
                        : 6
                    }
                  >
                    <FormItem
                      colon={false}
                      label={
                        <LabelWithHelp>
                          {t('Height', 'Height')} <span>{'(Per unit)'}</span>
                        </LabelWithHelp>
                      }
                    >
                      <MeasurementUnitFieldInput
                        selectWidth={58}
                        path={`packages[${index}].packageDimensions.height`}
                        index={index}
                        units={['cm', 'in']}
                        measurementType="dimensions"
                      />
                    </FormItem>
                  </Col>
                  <Col
                    span={
                      isMobile ||
                      !loadHasWidthLengthValue(load[LOAD_TYPE].packages[index].packagingType)
                        ? 12
                        : 6
                    }
                  >
                    <FormItem
                      colon={false}
                      label={
                        <LabelWithHelp>
                          {t('Weight', 'Weight')} <span>{'(Per unit)'}</span>
                        </LabelWithHelp>
                      }
                    >
                      <MeasurementUnitFieldInput
                        selectWidth={58}
                        path={`packages[${index}].weight`}
                        index={index}
                        units={['kg', 'lb']}
                        measurementType="weight"
                      />
                    </FormItem>
                  </Col>
                </Row>
              ) : (
                <Row wrap={false} gutter={[8, 8]}>
                  <Col span={isWidthLengthValue ? 16 : 12}>
                    <FormItem
                      colon={false}
                      label={
                        <LabelWithHelp>
                          {isWidthLengthValue
                            ? t('Dimensions', 'Dimensions')
                            : t('Height', 'Height')}{' '}
                          <span>
                            {isWidthLengthValue
                              ? t('(L×W×H per unit)', '(L×W×H per unit)')
                              : t('(per unit)', '(per unit)')}
                          </span>
                        </LabelWithHelp>
                      }
                    >
                      <StyledInputGroup isWidthLengthValue={isWidthLengthValue}>
                        {isWidthLengthValue && (
                          <>
                            <MeasurementUnitFieldInput
                              placeholder={'L'}
                              withUnits={false}
                              selectWidth={58}
                              path={`packages[${index}].packageDimensions.length`}
                              index={index}
                              units={['cm', 'in']}
                              measurementType="dimensions"
                            />
                            <MeasurementUnitFieldInput
                              selectWidth={58}
                              placeholder={'W'}
                              withUnits={false}
                              path={`packages[${index}].packageDimensions.width`}
                              index={index}
                              units={['cm', 'in']}
                              measurementType="dimensions"
                            />
                          </>
                        )}
                        <MeasurementUnitFieldInput
                          placeholder={isWidthLengthValue ? 'H' : ''}
                          selectWidth={58}
                          path={`packages[${index}].packageDimensions.height`}
                          index={index}
                          units={['cm', 'in']}
                          measurementType="dimensions"
                        />
                      </StyledInputGroup>
                    </FormItem>
                  </Col>
                  <Col span={isWidthLengthValue ? 6 : 12} push={isWidthLengthValue ? 2 : 0}>
                    <FormItem
                      colon={false}
                      label={
                        <LabelWithHelp>
                          {t('Weight', 'Weight')} <span>{'(Per unit)'}</span>
                        </LabelWithHelp>
                      }
                    >
                      <MeasurementUnitFieldInput
                        selectWidth={58}
                        path={`packages[${index}].weight`}
                        index={index}
                        units={['kg', 'lb']}
                        measurementType="weight"
                      />
                    </FormItem>
                  </Col>
                </Row>
              )}
            </LoadRow>
          );
        })
      )}
    </LooseCargoWrapper>
  );
};

const StyledPalletsSelect = styled(Select)``;

const LooseCargoWrapper = styled.div`
  margin: ${fdsComponentSpacingLg} 0;
`;

const LabelWithHelp = styled.span`
  span {
    font-size: ${fdsFontSizeSm};
    color: #ccc;
  }
`;

const LoadRow = styled.div`
  margin: 8px 0;
  ${({ multipleLoads }) =>
    multipleLoads
      ? css`
          box-shadow: ${fdsBoxShadowSm};
          border-radius: 4px;
          padding: 6px 10px 16px;
          position: relative;
        `
      : ''}
`;

const LoadTitle = styled.div`
  margin: 0 0 10px;
`;

const StyledInputGroup = styled(InputGroup)`
  display: flex !important;
  ${({ isWidthLengthValue }) =>
    isWidthLengthValue
      ? css`
          > div {
            width: 100px !important;
          }

          > div:nth-child(3) {
            width: 150px !important;
          }
        `
      : ''}

  div:nth-child(1) input {
    border-radius: 4px 0 0 4px !important;
  }

  div:nth-child(2) input {
    border-radius: 0 !important;
  }

  div:nth-child(3) input {
    border-radius: 0 !important;
  }
`;

const StyledRadioButtonGroup = styled(RadioButtonGroup)`
  label {
    &.ant-radio-button-wrapper {
      width: 100%;
      text-align: center;
      justify-content: center;
    }
  }
`;

const StyledQuantity = styled(Quantity)`
  width: 100%;
`;

const StyledIcon = styled(Icon)`
  position: absolute;
  top: 7px;
  right: 6px;
`;

LooseCargo.propTypes = {};

const mapStateToProps = (store) => ({
  isAmazon:
    getQuoteLocation(store).destination?.brand === FULFILMENT_CENTER_COMPANIES.AMZ.key &&
    getQuoteLocation(store).destination?.locationTypeCode === ADDRESS_TYPES.FULFILMENT_CENTER.value,
  load: getQuoteLoad(store),
  isMobile: isMobileSelector(store),
  measurements: getMeasurements(store),
  cbmToShowAlsoFCLLimit: siteConfig.cbmToShowAlsoFCL(store)
});

export default connect(mapStateToProps, {
  updateField
})(LooseCargo);
