import { PACKAGE_NAME } from 'slimSearch/constants';
import { createAsyncAction } from 'utils';

export const GET_RECENT_SEARCHES = createAsyncAction(`${PACKAGE_NAME}/GET_RECENT_SEARCHES`);
export const ADD_TO_RECENT_SEARCHES = createAsyncAction(`${PACKAGE_NAME}/ADD_TO_RECENT_SEARCHES`);
export const RECENT_SEARCH_SELECTED = `${PACKAGE_NAME}/RECENT_SEARCH_SELECTED`;
export const PIN_SEARCH_ITEM = createAsyncAction(`${PACKAGE_NAME}/PIN_SEARCH_ITEM`);
export const DELETE_SEARCH_HISTORY = createAsyncAction(`${PACKAGE_NAME}/DELETE_SEARCH_HISTORY`);
export const RESET_SEARCH_HISTORY = `${PACKAGE_NAME}/DELETE_SEARCH_HISTORY`;
export const REPORT_ANALYTICS = `${PACKAGE_NAME}/REPORT_ANALYTICS`;

export const getRecentSearches = () => ({
  type: GET_RECENT_SEARCHES.type
});

export const addToRecentSearches = (searchItem) => ({
  type: ADD_TO_RECENT_SEARCHES.type,
  searchItem
});

export const setSearchWithNewFields = (recentSearchItem) => ({
  type: RECENT_SEARCH_SELECTED,
  recentSearchItem
});

export const pinSearchItem = (searchId, rfqKey, isPinned) => ({
  type: PIN_SEARCH_ITEM.type,
  searchId,
  rfqKey,
  isPinned
});

export const deleteSearchHistory = (searchId) => ({
  type: DELETE_SEARCH_HISTORY.type,
  searchId
});

export const resetSearchHistory = () => ({
  type: RESET_SEARCH_HISTORY
});

export const reportAnalytics = (eventData) => ({
  type: REPORT_ANALYTICS,
  eventData
});
