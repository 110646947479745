import * as Types from './actionTypes';

export const newSearch = () => ({
  type: Types.NEW_SEARCH
});

export const initCustomQuotePageStatus = () => ({
  type: Types.INIT_CUSTOM_QUOTE_PAGE_STATUS
});

export const setCustomQuotePageStatus = (payload) => ({
  type: Types.SET_CUSTOM_QUOTE_PAGE_STATUS,
  payload
});

export const notAllowedCommodity = ({ commodity }) => ({
  type: Types.NOT_ALLOWED_COMMODITY,
  commodity
});

export const serverError = (serverErrorPayload, payload) => ({
  type: Types.SERVER_ERROR,
  serverError: serverErrorPayload,
  payload
});

export const submitCustomQuoteRequest = (payload) => ({
  type: Types.SUBMIT_CUSTOM_QUOTE_REQUEST,
  payload
});
