import { connect } from 'react-redux';
import * as selectors from 'financialSharedComponents/selectors';
import PayPalAccountsMgmt from './PayPalAccountsMgmt';

const mapStateToProps = (state) => {
  return {
  isChangingPaymentMethod: selectors.isChangingPaymentMethodSelector(state),
  selectedPayPalAccount: selectors.selectedPayPalAccountSelector(state)}
};

const mapDispatchToProps = (dispatch, ownProps) => {
};

export default connect(mapStateToProps, mapDispatchToProps)(PayPalAccountsMgmt);
