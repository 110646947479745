import { t } from 'utils/translationProvider';
import { SUPPORTED_PAYMENT_METHODS_TYPES } from 'financialSharedComponents/constants/app.constants';

export const getPaymentMethodLabel = (key, paymentMethod) => {
  switch (key) {
    case SUPPORTED_PAYMENT_METHODS_TYPES.CARD:
      return t('actionRequired/payment_method_credit_card_label_text', 'Credit Card');
    case SUPPORTED_PAYMENT_METHODS_TYPES.PAYPAL:
      return t('actionRequired/payment_method_pay_pal_label_text', 'PayPal');
    case SUPPORTED_PAYMENT_METHODS_TYPES.BALANCE:
      return t('actionRequired/payment_method_freightos_credit_label_text', 'Freightos Credit');
    case SUPPORTED_PAYMENT_METHODS_TYPES.BANK_TRANSFER:
      if (paymentMethod.length === 1) {
        return t('actionRequired/payment_method_wire_transfer_label_text', 'Wire transfer/ACH');
      } else {
        return t('actionRequired/payment_method_money_transfer_label_text', 'Money Transfer');
      }
    default:
      return '';
  }
};

export const getPaymentMethodIcon = (key) => {
  switch (key) {
    case SUPPORTED_PAYMENT_METHODS_TYPES.CARD:
      return 'credit-card';
    case SUPPORTED_PAYMENT_METHODS_TYPES.PAYPAL:
      return 'paypal';
    case SUPPORTED_PAYMENT_METHODS_TYPES.BALANCE:
      return 'freightos-credit';
    case SUPPORTED_PAYMENT_METHODS_TYPES.BANK_TRANSFER:
      return 'bank';
    default:
      return '';
  }
};
