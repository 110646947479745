import { connect } from 'react-redux';
import { BankDetailsSelectors } from 'applicationStore/BankDetails';
import { fetchBankDetails } from 'applicationStore/BankDetails/actions';
import BankDetails from './BankDetails';

const mapStateToProps = (state) => {
  return {
    bankDetails: BankDetailsSelectors.bankDetailsSelector(state),
    fetching: BankDetailsSelectors.bankDetailsFetchingSelector(state)
  };
};

export default connect(mapStateToProps, { fetchBankDetails })(BankDetails);