import React, { useEffect, useState } from 'react';
import { connect } from 'microfronts-redux';
import { Modal, Alert, tokens } from '@freightos/design-system';

import { t } from 'utils/translationProvider';
import styled, { css, createGlobalStyle, StyleSheetManager } from 'styled-components/macro';
import createExtraSpecifityPlugin from '@freightos/stylis-class-specificity-plugin';

import { ADDRESS_TYPES, SECTIONS, ANALYTICS_EVENT_NAME, PAGE_WIDTH } from 'slimSearch/constants';
import { allSectionsValid, getLocationLabel, getFullfilmentCompanyLabel } from 'slimSearch/utils';

import {
  hideServicesModal,
  searchServiceCompleted,
  setServicePredictions,
  cancelEditSearch,
  searchAnalytics
} from 'slimSearch/actions';
import {
  servicesModalVisible,
  quoteSelector,
  isMobileSelector,
  isOldRfqFlowSelector
} from 'slimSearch/selectors';

import ServiceOption from './ServiceOption';
import SearchStepProgress from './SearchStepProgress';

import * as options from './services-criteria.utils';

const GlobalModalStyle = createGlobalStyle`
  body {
    .search-services-modal-wrapper {
      top: 70px;
      pointer-events: none;
    }
  }
`;

const GlobalModalStyleMobile = createGlobalStyle`
  body {
    .app-container.clean-layout header.ant-layout-header {
      // to win over modal in body
      z-index: 1001;
    }
  }
`;

export const RecommendedServicesComponent = ({
  visible = false,
  isMobile,
  searchServiceCompletedAction,
  setServicePredictionsAction,
  cancelEditSearchAction,
  destinationLocationString = '',
  originLocationString = '',
  children,
  mode,
  allSectionsValid,
  activeSection,
  searchAnalytics,
  isOldRfqFlow
}) => {
  const forceVisible = mode === 'services';

  const [searchActive, setSearchActive] = useState(false);

  useEffect(() => {
    setSearchActive(false);
    if (forceVisible || visible) {
      setServicePredictionsAction(true);
      searchAnalytics({ name: ANALYTICS_EVENT_NAME.RS_COMPONENT_OPENED });
    }
    // eslint-disable-next-line
  }, [visible]);

  return (
    <StyleSheetManager stylisPlugins={[createExtraSpecifityPlugin(2)]}>
      <StyledModal
        wrapClassName="search-services-modal-wrapper"
        closable={false}
        mode={mode}
        centered
        isMobile={isMobile}
        width={isMobile ? '100%' : ''}
        visible={visible}
        onCancel={() => cancelEditSearchAction()}
        searchActive={searchActive}
        activeSection={activeSection}
        destroyOnClose={true}
        onOk={() => {
          setSearchActive(true);
          searchServiceCompletedAction({ mode });
        }}
        mask={!isMobile && !forceVisible}
        maskClosable={false}
        okText={
          mode === 'results'
            ? t('Search again', 'Search again')
            : t('confirm_services_and_get_results', 'Confirm Services & Get Results')
        }
        cancelText={t('cancel', 'Cancel')}
        okButtonProps={{
          disabled: searchActive || !allSectionsValid,
          loading: searchActive
        }}
      >
        <ServicesModalContent>
          <ServicePopGlobal />
          {!isMobile && (
            <>
              <GlobalModalStyle />
              {mode === 'services' && (
                <SearchStepProgressWrapper>
                  <SearchStepProgress />
                </SearchStepProgressWrapper>
              )}
              {isOldRfqFlow && (
                <AlertWrapper>
                  <Alert
                    message={t(
                      'oldRfqServicesPage/Alert',
                      'You followed an old link. Please update your search and choose your services to continue.'
                    )}
                    type="danger"
                  />
                </AlertWrapper>
              )}
              {children}
              <br />
            </>
          )}
          {isMobile && (mode === 'results' || isOldRfqFlow) && (
            <>
              {isOldRfqFlow && (
                <AlertWrapper>
                  <Alert
                    message={t(
                      'oldRfqServicesPage/Alert',
                      'You followed an old link. Please update your search and choose your services to continue.'
                    )}
                    type="danger"
                  />
                </AlertWrapper>
              )}
              <ServiceModalTitle>
                {t('services/recommended_services_edit_search', 'Edit search')}
              </ServiceModalTitle>
              <br />
              {children}
            </>
          )}
          {isMobile && <GlobalModalStyleMobile />}
          <ServicesContainer isMobile={isMobile}>
            <ServicesTitleContainer>
              <ServiceModalTitle>
                {t('services/recommended_services_header_1_text', 'Recommended Services')}
              </ServiceModalTitle>
              <ServiceModalSubTitle
                dangerouslySetInnerHTML={{
                  __html: t(
                    'services/recommended_services_header_2_text',
                    'We’ve selected all the services you need to ship your goods from a <span class="description-location">{originType}</span> to a <span class="description-location">{destinationType}</span>. Please check and confirm before getting your results.',
                    {
                      originType: originLocationString,
                      destinationType: destinationLocationString
                    }
                  )
                }}
              />
            </ServicesTitleContainer>
            <RecommendedServicesListWrapper isMobile={isMobile}>
              <RecommendedServicesList>
                {options.servicesOptionsTypesColumn1.map((optionName) => (
                  <ServiceOption
                    key={optionName}
                    name={optionName}
                    serviceOptionConfig={options[optionName]}
                  />
                ))}
              </RecommendedServicesList>
              <RecommendedServicesList>
                {options.servicesOptionsTypesColumn2.map((optionName) => (
                  <ServiceOption
                    key={optionName}
                    name={optionName}
                    serviceOptionConfig={options[optionName]}
                  />
                ))}
              </RecommendedServicesList>
            </RecommendedServicesListWrapper>
          </ServicesContainer>
        </ServicesModalContent>
      </StyledModal>
    </StyleSheetManager>
  );
};

// #region Styled Components
export const ServicePopGlobal = createGlobalStyle`
.ant-popover.services-popover{
    max-width: 350px;
}
.ant-popover.services-popover-with-image{
    max-width: initial;
}
`;

const ServicesContainer = styled.div`
  padding-top: 15px;
  padding-left: 5px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`;

const ServicesTitleContainer = styled.div`
  display: flex;
  align-items: flex-start;
  flex-direction: column;
`;

const StyledModal = styled(Modal)`
  ${({ activeSection }) =>
    activeSection !== null
      ? css`
          .ant-modal-content {
            background: #f6f6f6;
            transition: background 1s linear;
          }
        `
      : css`
          .ant-modal-content {
            background: #fff;
            transition: background 1s linear;
          }
        `}

  ${({ isMobile }) =>
    isMobile
      ? css`
          top: 70px;
          left: 0;
          background: ${tokens.fdsComponentBackgroundColor};
          margin: 0;
          height: 100vh;
          overflow: hidden;
          max-width: 100vw;
          padding-bottom: 0;

          .ant-modal-content {
            box-shadow: none;
            height: 100vh;
            display: grid;
            grid-template-rows: 1fr auto;
          }

          .ant-modal-body {
            width: 100vw;
            top: 0;
            left: 0;
            position: relative;
            z-index: 100;
            overflow: hidden auto;
            height: calc(100vh - 56px);
          }
          .ant-modal-footer {
            padding: 10px 16px;
            text-align: right;
            background: #fff;
            border-top: 1px solid ${tokens.fdsComponentSecondaryColorDisabled};
            position: fixed;
            bottom: 0;
            z-index: 1000;
            width: 100%;
            height: 56px;
            display: block;
          }
        `
      : css`
          top: 10px;
          display: flex;
          justify-content: center;
          width: 80%;
          max-width: ${PAGE_WIDTH}px;
          &.ant-modal-wrap,
          & .ant-modal-wrap {
            top: 70px !important;
          }

          .ant-modal-content {
            padding-top: 16px;
          }

          .ant-modal-body {
            overflow: auto;
            max-height: 70vh;
            width: 100%;
          }
        `}

  ${({ searchActive }) =>
    searchActive &&
    css`
      .ant-modal-body {
        pointer-events: none;
        opacity: 0.5;
      }
    `}

  .ant-modal-header {
    display: none;
  }

  .ant-modal-footer {
    ${({ mode }) =>
      mode === 'services' &&
      css`
        .ant-btn:first-child {
          display: none;
        }
      `}

    ${({ isMobile }) =>
      isMobile
        ? css`
            .ant-btn-primary {
              width: 100%;
              margin: 0;
            }
          `
        : css`
            padding: 16px 16px;
          `}

    ${({ isMobile, mode }) =>
      mode === 'results' &&
      isMobile &&
      css`
        > div {
          display: flex;

          .ant-btn {
            width: 100%;
          }

          .ant-btn:first-child {
            margin-right: 16px;
          }
        }
      `}
  }
`;

const ServicesModalContent = styled.div``;

const SearchStepProgressWrapper = styled.div`
  //margin-bottom: 48px;
  display: flex;
  justify-content: center;
  & > div {
    width: 815px;
  }
`;

const ServiceModalTitle = styled.div`
  font-weight: bold;
  font-size: 18px;
  color: #232537;
`;

const ServiceModalSubTitle = styled.div`
  font-weight: normal;
  font-size: 14px;
  color: #283148;
  .description-location {
    font-weight: 500;
  }
`;

const RecommendedServicesList = styled.ul`
  display: flex;
  flex-direction: column;
  /*flex-wrap: wrap;*/
  align-content: flex-start;
  list-style: none;
  margin: 0;
  padding: 0;
`;

const RecommendedServicesListWrapper = styled.div`
  display: grid;
  ${({ isMobile }) =>
    isMobile
      ? css`
          grid-template-columns: 100%;
        `
      : css`
          grid-template-columns: 1fr 1fr;
          justify-content: space-between;
        `}
`;

const AlertWrapper = styled.div`
  margin-bottom: 30px;
`;

// #endregion

const destinationLocationString = (shipment) => {
  const destinationLocation = shipment.destination?.destinationLocation || {};
  const destinationLabel = getLocationLabel(SECTIONS.DESTINATION, destinationLocation);
  const destinationLocationBrand = destinationLocation?.brand
    ? `(${destinationLocation?.brand})`
    : '';
  const fulfilmentCompanyLabel = getFullfilmentCompanyLabel(destinationLocation?.brand);
  const fulfillmentCompanyLabelValue = fulfilmentCompanyLabel ? ` (${fulfilmentCompanyLabel})` : '';

  if (destinationLocation.locationTypeCode === ADDRESS_TYPES.FULFILMENT_CENTER.value) {
    return `${destinationLabel}${fulfillmentCompanyLabelValue}`;
  } else if (destinationLocation.locationTypeCode === ADDRESS_TYPES.LAST_MILE_DELIVERY.value) {
    return `${t(
      'Freight forwarder warehouse for last mile delivery.',
      'Freight forwarder warehouse for last mile delivery'
    )} ${destinationLocationBrand}`;
  } else {
    return destinationLabel;
  }
};

const mapStateToProps = (state, { mode }) => {
  const shipment = quoteSelector(state);
  return {
    visible: mode === 'services' || servicesModalVisible(state),
    isMobile: isMobileSelector(state),
    destinationLocationString: destinationLocationString(shipment),
    originLocationString: getLocationLabel(SECTIONS.ORIGIN, shipment.origin?.originLocation),
    allSectionsValid: allSectionsValid(state.search),
    activeSection: state.search?.activeSection,
    isOldRfqFlow: isOldRfqFlowSelector(state)
  };
};

const mapDispatchToProps = {
  hideServicesModalAction: hideServicesModal,
  searchServiceCompletedAction: searchServiceCompleted,
  setServicePredictionsAction: setServicePredictions,
  cancelEditSearchAction: cancelEditSearch,
  searchAnalytics
};

export default connect(mapStateToProps, mapDispatchToProps)(RecommendedServicesComponent);
