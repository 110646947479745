import { connect } from 'react-redux';
import * as actions from '../../CreditCardsMgmt/actions';
import * as paymentsActions from 'financialSharedComponents/components/Payment/actions';
import * as selectors from 'financialSharedComponents/selectors';
import { SUPPORTED_PAYMENT_METHODS_TYPES } from 'financialSharedComponents/constants/app.constants';
import { SUPPORTED_TOKENIZE_FORMS_PSP_NAMES } from '../../CreditCardsMgmt/constants';
import PayPal from './PayPal';

const mapStateToProps = (state) => ({
  isChangingPaymentMethod: selectors.isChangingPaymentMethodSelector(state),
  currentTransactionCurrency: selectors.currentTransactionCurrencySelector(state),
  brainTreeApiKey: selectors.brainTreeApiKeySelector(state),
  selectedPayPalAccount: selectors.selectedPayPalAccountSelector(state),
  isPaymentMethodsInitialized: selectors.paymentMethodsInitializedSelector(state)
});

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    onSubmitToken: ({nonce}) => {
      dispatch(
        actions.saveCardToken({
          nonce,
          type: SUPPORTED_PAYMENT_METHODS_TYPES.PAYPAL,
          pspName: SUPPORTED_TOKENIZE_FORMS_PSP_NAMES.BRAIN_TREE,
          triggerPayment: true
        })
      );
    },
    fetchUserCards: () => dispatch(actions.fetchUserCards()),
    fetchPaymentMethods: () => dispatch(paymentsActions.fetchPaymentMethods({type: SUPPORTED_PAYMENT_METHODS_TYPES.PAYPAL})),
    setIsChangingPaymentMethod: (payload) => dispatch(actions.setIsChangingPaymentMethod(payload)),
    setDeviceDataCorrelationId: (payload) => dispatch(actions.setDeviceDataCorrelationId(payload))
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(PayPal);
