import { createAsyncAction } from '../utils';
import { PACKAGE_NAME } from '../slimSearch/constants';

export const actionTypes = {
  GET_LATEST_BOOKINGS: createAsyncAction(`${PACKAGE_NAME}/GET_LATEST_BOOKINGS`),
  HIDE_LATEST_BOOKING: `${PACKAGE_NAME}/HIDE_LATEST_BOOKING`
};

export const getLatestBookings = (payload) => ({
  type: actionTypes.GET_LATEST_BOOKINGS.type,
  payload
});

export const getLatestBookingsSuccess = (payload) => ({
  type: actionTypes.GET_LATEST_BOOKINGS.SUCCESS,
  payload
});

export const hideLatestBooking = () => ({
  type: actionTypes.HIDE_LATEST_BOOKING
});
