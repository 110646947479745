import { CONTAINER_TYPES_VALUES, LOAD_TYPES, VOLUME_UNITS } from 'slimSearch/constants';
import { head } from 'lodash/fp';
import get from 'lodash/get';
import { applyTestForDomainPath } from 'customQuote/utils';
import {
  convertVolume,
  getUnitsTotals,
  unitTotalsString
} from 'slimSearch/utils/calculateTotals.utils';
import {
  getShipmentTypeByLoadData,
  mapRfqToQuote,
  isHazardousShipment,
  isLoadCalculateByTotal
} from 'slimSearch/utils/mapOpenFreight.utils';
import { getCustomBondType } from 'results/analytics.utils';
import { getTotalsCalculated } from '../slimSearch/utils';

export const isSameOrWildcardMatch = (x, y, wildcard = '*') => x === y || x === wildcard;

export const isInScope = ({ shipment, config, measurements }) => {
  const origin = get(shipment, 'originLocation.countryID.value');
  const destination = get(shipment, 'destinationLocation.countryID.value');
  const loadType = getShipmentTypeByLoadData(shipment.load.packages);

  if (loadType === LOAD_TYPES.LOOSE_CARGO.value) {
    let totalVolume = undefined;
    if (isLoadCalculateByTotal(shipment.load)) {
      totalVolume = shipment.load?.totalGrossVolumeMeasure?.value ?? 0;
    } else {
      ({ totalVolume } = getUnitsTotals(get(shipment, 'load.packages', []), measurements));
    }

    totalVolume =
      measurements.volume === VOLUME_UNITS.CFT
        ? convertVolume(totalVolume, VOLUME_UNITS.CFT, VOLUME_UNITS.CBM)
        : totalVolume;

    if (!(totalVolume > config.lclCustomQuoteMinCbm && totalVolume < config.lclCustomQuoteMaxCbm)) {
      return false;
    }
  }

  const outOfScopeFound = config.outOfScope.find(
    (country) =>
      isSameOrWildcardMatch(country.origin, origin) &&
      isSameOrWildcardMatch(country.destination, destination)
  );

  if (outOfScopeFound) {
    return false;
  }

  const isFound = config.inScope.find(
    (country) =>
      isSameOrWildcardMatch(country.origin, origin) &&
      isSameOrWildcardMatch(country.destination, destination)
  );
  return Boolean(isFound);
};

/**
 * **Only exported for Jest test**
 * Do not use externally.
 * We need this to support old summary format for quick-silver for now
 *and for analytics
 * @deprecated
 */
export const loadSummaryToOldFormat = (load = {}) => {
  const measurements = load.measurements;
  const dimensions = measurements.dimensions === 'in' ? 'inch' : measurements.dimensions;

  if (load?.type === LOAD_TYPES.CONTAINER.value) {
    const summary = load[LOAD_TYPES.CONTAINER.value].packages.reduce((acc, next) => {
      acc.push(
        `${next.quantity} Full ${CONTAINER_TYPES_VALUES[next.packagingType].label} container${
          next.overWeightIndicator ? ' (Overweight)' : ''
        } (FCL), 0 kg`
      );
      return acc;
    }, []);

    return `[${summary.join(',').toString()}]`;
  } else {
    if (load?.looseCargo?.calculateByTotals === true) {
      // if LCL by totals
      return '[]';
    } else {
      const summary = load[LOAD_TYPES.LOOSE_CARGO.value].packages.reduce((acc, next) => {
        // if package is box
        if (next.packagingType === LOAD_TYPES.BOX.value) {
          acc.push(
            `${next.quantity} Box, ${next.packageDimensions.length.value} ${dimensions}×${next.packageDimensions.width.value} ${dimensions}×${next.packageDimensions.height.value} ${dimensions} ${next.weight.value} ${measurements.weight}`
          );
        }

        // if package is not pre-defined pallet
        if (next.packagingType === LOAD_TYPES.PALLETS.value) {
          acc.push(
            `${next.quantity} Pallet, ${next.packageDimensions.length.value} ${dimensions}×${next.packageDimensions.width.value} ${dimensions}×${next.packageDimensions.height.value} ${dimensions} ${next.weight.value} ${measurements.weight}`
          );
        }

        // if package is 48"x40" pallet
        if (next.packagingType === 'pallet_48_40') {
          acc.push(
            `${next.quantity} 48"x40" Pallet, ${next.weight.value} ${measurements.weight} ${next.packageDimensions.height.value} ${dimensions}`
          );
        }

        // if package is EUR1 pallet
        if (next.packagingType === 'pallet_EUR1') {
          acc.push(
            `${next.quantity} EUR1/ISO1 pallet (80×120cm), ${next.weight.value} ${measurements.weight} ${next.packageDimensions.height.value} ${dimensions}`
          );
        }

        // if package is EUR2 pallet
        if (next.packagingType === 'pallet_EUR2') {
          acc.push(
            `${next.quantity} EUR2/ISO2 pallet (120×100cm), ${next.weight.value} ${measurements.weight} ${next.packageDimensions.height.value} ${dimensions}`
          );
        }

        return acc;
      }, []);

      return `[${summary.join(',').toString()}]`;
    }
  }
};

/**
 * Do not use externally.
 * We need this to support old summary label format for quoi table header
 * and for analytics
 *
 * @deprecated
 */
const getFclLoadLabel = (load) => {
  if (load?.type === LOAD_TYPES.CONTAINER.value) {
    const containers = load[LOAD_TYPES.CONTAINER.value].packages.reduce((acc, next) => {
      acc.push(
        `${next.quantity === 1 ? '' : next.quantity + ' '}${
          CONTAINER_TYPES_VALUES[next.packagingType].label
        } container${next.overWeightIndicator ? ' (Overweight)' : ''}`
      );
      return acc;
    }, []);

    return containers.join(', ');
  }
};

/**
 * Do not use externally.
 * We need this to support old summary label format for quoi table header
 *and for analytics
 * @deprecated
 */
export const totalShipmentSummary = (quote = {}) => {
  let totals = undefined;
  if (quote?.load?.type === LOAD_TYPES.CONTAINER.value) {
    return undefined;
  }

  if (quote?.load?.looseCargo?.calculateByTotals) {
    totals = getTotalsCalculated(quote?.load?.looseCargo?.totals, quote?.load?.measurements);
  } else {
    totals = unitTotalsString(
      getUnitsTotals(quote?.load?.looseCargo?.packages, quote?.load?.measurements),
      quote?.load?.measurements
    );
  }
  // 🤮
  return totals ? totals.split('| ')[1].replace(',', '').replace('Total: ', '') : undefined;
};

/**
 *  ugly fix to support if location has accessorials or not
 * we are just returning the value that will be supported at quoi
 * @param {*} shipment
 * @param {*} type
 * @returns
 */
export const mapAccessorials = (shipment = {}, type) => {
  const accessorials = shipment.accessorials.filter(
    (item) => item.scope === type && item.name.toLowerCase() === 'liftgate'
  );
  if (accessorials.length > 0) {
    return ['BusinessWithLiftGate'];
  } else {
    return ['Port'];
  }
};

export const mapShipmentToCustomQuote = (values, shipment) => {
  const {
    createdByEmail,
    rfqKey,
    preferredModes,
    productType,
    fullName,
    buyerEmail,
    buyerKey,
    buyerBusinessKey,
    company,
    sourceSiteDomain = '',
    autoCloseThresholdInDays,
    comments,
    phoneNumber = ''
  } = values;
  const priceToBeatCurrency = get(values, 'priceToBeat.currency');
  const priceToBeat = get(values, 'priceToBeat.amount')
    ? {
        currency: priceToBeatCurrency,
        amount: get(values, 'priceToBeat.amount')
      }
    : undefined;
  const quote = mapRfqToQuote({ shipment });
  const internalAuthorization =
    values.poNumbers || values.internalAuth
      ? `${values.poNumbers || ''}/${values.internalAuth || ''}`
      : undefined;

  const customBond = getCustomBondType(shipment);
  let customBondType;
  if (customBond) {
    customBondType = customBond === 'singleEntryBond' ? 'SEB' : 'ContinuesBond';
  }
  const autoCloseThresholdInDaysToSend = autoCloseThresholdInDays
    ? autoCloseThresholdInDays
    : undefined;

  return {
    rfqKey,
    sourceSiteDomain: applyTestForDomainPath(sourceSiteDomain),
    autoCloseThresholdInDays: autoCloseThresholdInDaysToSend,
    createdByEmail,
    marketplaceRequest: true,
    origin: {
      countryCode: shipment?.originLocation?.countryID?.value,
      locationType: shipment?.originLocation?.locationTypeCode,
      addressOrPort:
        shipment?.originLocation?.locationName === 'port'
          ? shipment?.originLocation?.locationCode
          : head(shipment?.originLocation?.addressLines)
    },
    destination: {
      countryCode: shipment?.destinationLocation?.countryID?.value,
      locationType: shipment?.destinationLocation?.locationTypeCode,
      addressOrPort:
        shipment?.destinationLocation?.locationName === 'port'
          ? shipment?.destinationLocation?.locationCode
          : head(shipment?.destinationLocation?.addressLines)
    },
    productDetails: {
      goodsValue: {
        currency:
          values.options?.insuranceValue?.currency || shipment.load?.declaredValue?.currencyID,
        amount: values.options?.insuranceValue?.amount || shipment.load?.declaredValue?.value
      },
      goodsReady: new Date(get(shipment, 'pickupEvent.eventDate.scheduledDateTime', '')).getTime(),
      load: {
        summary: loadSummaryToOldFormat(quote?.load),
        totalShipmentSummary: totalShipmentSummary(quote),
        type: quote?.load?.type === LOAD_TYPES.CONTAINER.value ? 'FCL' : 'LCL',
        label: getFclLoadLabel(quote?.load)
      },
      commodities: productType,
      preferredModes
    },
    buyerInfo: {
      fullName: fullName,
      buyerEmail: buyerEmail,
      buyerKey: buyerKey,
      buyerBusinessKey: buyerBusinessKey,
      buyerBusinessName: company,

      knownShipper: get(values, 'options.knownShipper'),
      foreignImporter: get(values, 'options.foreignImporter'),

      comments: `${comments ? comments : ''}${
        phoneNumber.length > 0 ? "\n-\nUser's phone number: " + phoneNumber : ''
      }`,
      internalAuthorization,
      priceToBeat
    },
    additionalServices: {
      originAccessorials: mapAccessorials(shipment, 'Pickup'),
      destinationAccessorials: mapAccessorials(shipment, 'Delivery'),
      includeOriginPortCharges: shipment.pricePreference?.includeOriginPortCharges,
      includeDestinationPortCharges: shipment.pricePreference?.includeDestinationPortCharges,
      insurance: get(values, 'options.insurance'),

      customsBrokerage: Boolean(shipment.declaredCustoms?.entry),
      customsBrokerageType: customBondType,
      hazardous: isHazardousShipment(shipment.accessorials), //get(values, 'options.hazardous'),

      amazonAppointment:
        shipment.fulfilmentInfo?.fulfilmentCompanyShipment?.additionalServices
          ?.fulfilmentCompanyAppointment,
      amazonLabeling:
        shipment.fulfilmentInfo?.fulfilmentCompanyShipment?.additionalServices?.labeling, //get(values, 'specialAccessories.labeling'),
      amazonPalletization:
        shipment.fulfilmentInfo?.fulfilmentCompanyShipment?.additionalServices?.palletization,
      amazonFloorLoaded:
        shipment.fulfilmentInfo?.fulfilmentCompanyShipment?.additionalServices?.floorLoaded,
      labelUps:
        shipment.fulfilmentInfo &&
        !shipment.fulfilmentInfo?.fulfilmentCompanyShipment?.additionalServices
          ?.fulfilmentCompanyAppointment,

      numberOfCommodities: get(values, 'options.numberOfCommodities') // should we disable that
    }
  };
};
