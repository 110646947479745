import React from 'react';
import { toUpper } from 'lodash/fp';
import { ADDRESS_TYPES } from 'slimSearch/constants';
import { mapRfqToQuote } from 'slimSearch/utils/mapOpenFreight.utils';

export const LocationCodeTitle = ({
  type,
  locationType,
  userAddressesLoaded,
  userSelectedAddress
}) => {
  let title = locationType.locationCode;
  const isPort = locationType.locationTypeCode === ADDRESS_TYPES.PORT.value;
  const isFulfilmentCenter =
    locationType.locationTypeCode === ADDRESS_TYPES.FULFILMENT_CENTER.value;

  if (isPort) {
    title = toUpper(locationType.locationCode);
    return <span title={title}>{title}</span>;
  }

  if (isFulfilmentCenter) {
    title = locationType.ecommerceKey ? locationType.ecommerceKey : locationType.label;
    return <span title={title}>{title}</span>;
  }

  if (userAddressesLoaded || userSelectedAddress) {
    return <span title={title}>{userSelectedAddress?.[type]?.company ?? title}</span>;
  }

  return title;
};

export const normalizeSearchListAndSortPinnedFirst = (searchList) =>
  (searchList || []).map((item) => ({
    ...item,
    displayFields: mapRfqToQuote({ shipment: item.rfqFields })
  }));

export const getAddressBookOrigin = (state, additionalInformation) => {
  const givenAddressId = additionalInformation?.find(
    (address) => address.key === 'originUserAddressId'
  );
  return {
    origin: state.search.locationsData?.origin?.addressBook?.find(
      (address) => address.id.toString() === givenAddressId?.value
    )
  };
};

export const getAddressBookDestination = (state, additionalInformation) => {
  const givenAddressId = additionalInformation?.find(
    (address) => address.key === 'destinationUserAddressId'
  );
  return {
    destination: state.search.locationsData?.destination?.addressBook?.find(
      (address) => address.id.toString() === givenAddressId?.value
    )
  };
};
