import React from 'react';
import { connect } from 'react-redux';
import * as selectors from 'financialSharedComponents/selectors';
import { actions as analyticsActions } from 'propera/analytics';
import { getVeemURL } from 'financialSharedComponents/components/Payment/utils';

const { registerSegmentEvent } = analyticsActions;

const VeemPaymentDetails = ({ amount, veemLinkOnClick, currency, shipmentNumber }) => {
  return (
    <div className="veem-payment-details">
      <div className={'veem-details-description'}>
        <a
          onClick={() => {
            veemLinkOnClick();
          }}
          target="_blank"
          href={getVeemURL({ amount, currency, shipmentNumber })}
        >
          Log in
        </a>
        <span>into your Veem account or</span>
        <a
          onClick={() => {
            veemLinkOnClick();
          }}
          target="_blank" href={getVeemURL({ amount, currency, shipmentNumber })}>
          sign up
        </a>
        <span>for free if you don't already have one.</span>
      </div>
      <div className={'text-center veem-details-footer'}>
        Shipment processing will only begin once payment has been received and your shipment has
        been verified.
      </div>
    </div>
  );
};

VeemPaymentDetails.propTypes = {};

const mapStateToProps = (state) => ({
  shipmentNumber: selectors.currentTransactionShipmentNumberSelector(state),
  amount: selectors.currentTransactionAmountSelector(state),
  currency: selectors.currentTransactionCurrencySelector(state)
});

const mapDispatchToProps = (dispatch) => ({
  veemLinkOnClick: (itemName) => {
    dispatch(registerSegmentEvent('sc_payment_veem_login_signup_link_clicked'));
  }
});

export default connect(mapStateToProps, mapDispatchToProps)(VeemPaymentDetails);
