import { all } from 'redux-saga/effects';
import paymentMethodsSaga from './components/Payment/saga';
import CreditCardsMgmtSaga from './components/Payment/CreditCardsMgmt/saga';
import PaymentLinkSaga from './components/PaymentLink/saga';
import apiSaga from './api.saga';

export default function* rootSaga() {
  yield all([
    apiSaga(),
    paymentMethodsSaga(),
    CreditCardsMgmtSaga(),
    PaymentLinkSaga()
  ]);
}
