import React from 'react';
import { connect } from 'react-redux';
import { t } from 'utils/translationProvider';
import { Button } from '@freightos/design-system';
import { model as LayoutModel } from 'layout';
import Classnames from 'classnames';
import { doPayment } from 'financialSharedComponents/components/Payment/actions';
import { SUPPORTED_PAYMENT_METHODS_TYPES } from 'financialSharedComponents/constants/app.constants';
import * as selectors from 'financialSharedComponents/selectors';
import { isCardExpiryDatePassed } from 'utils/financial.utils';
const { isMobileSelector } = LayoutModel;

const getButtonLabel = (paymentMethod, isInitialPaymentWireAndWireSelected) => {
  if (paymentMethod === SUPPORTED_PAYMENT_METHODS_TYPES.BANK_TRANSFER) {
    if (isInitialPaymentWireAndWireSelected) {
      return t(
        'actionRequired/payment_payment_method_confirmed_button_text',
        'Payment Method Confirmed'
      );
    }
    return t('actionRequired/payment_confirm_payment_method_button_text', 'Confirm Payment Method');
  } else if(paymentMethod === SUPPORTED_PAYMENT_METHODS_TYPES.PAYPAL) {
    return t('Pay now with PayPal', 'Pay now with PayPal')
  } else {
    return t('Pay now', 'Pay now');
  }
};

const PayButton = ({
  isChangingPaymentMethod,
  pspName,
  selectedPaymentMethod,
  paymentMethodsInitialized,
  defaultCard,
  initialPaymentMethodType,
  paymentInProgress,
  isMobile,
  doPayment
}) => {
  const isCardOrPayPal = selectedPaymentMethod === SUPPORTED_PAYMENT_METHODS_TYPES.CARD ||
  selectedPaymentMethod === SUPPORTED_PAYMENT_METHODS_TYPES.PAYPAL;
  const isInitialPaymentWireAndWireSelected =
    selectedPaymentMethod === SUPPORTED_PAYMENT_METHODS_TYPES.BANK_TRANSFER &&
    initialPaymentMethodType === 'Cash';
  return (
    <React.Fragment>
      {(!isCardOrPayPal || (paymentMethodsInitialized && !(isCardOrPayPal && isChangingPaymentMethod)))
      && (
        <Button
          icon={isInitialPaymentWireAndWireSelected ? 'check-circled' : undefined}
          className={Classnames('financial-pay-now-button', {
            'financial-pay-now-button-success': isInitialPaymentWireAndWireSelected
          })}
          loading={false}
          type="primary"
          size={isMobile ? 'large' : 'default'}
          block
          disabled={
            paymentInProgress ||
            (selectedPaymentMethod === SUPPORTED_PAYMENT_METHODS_TYPES.CARD &&
              isCardExpiryDatePassed(defaultCard))
          }
          onClick={() => {
            doPayment({ selectedPaymentMethod, pspName });
          }}
          htmlType="submit"
        >
          {getButtonLabel(selectedPaymentMethod, isInitialPaymentWireAndWireSelected)}
        </Button>
      )}
    </React.Fragment>
  );
};

const mapStateToProps = (state) => ({
  selectedPaymentMethod: selectors.selectedPaymentMethodSelector(state),
  pspName: selectors.selectedPaymentMethodPSPNameSelector(state),
  paymentMethodsInitialized: selectors.paymentMethodsInitializedSelector(state),
  isChangingPaymentMethod: selectors.isChangingPaymentMethodSelector(state),
  isMobile: isMobileSelector(state),
  paymentInProgress: selectors.paymentInProgressSelector(state)
});
export default connect(mapStateToProps, { doPayment })(PayButton);
