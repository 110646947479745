import { push } from 'microfronts-router';
import set from 'lodash/set';
import { ANALYTICS_EVENT_NAME, PAGE_ROUTE } from 'slimSearch/constants';
import {
  constants as AuthConstants,
  actions as AuthActions,
  actionTypes as AuthActionTypes
} from 'propera/auth';
import jwtDecode from 'jwt-decode';

import userModel from 'propera/user';
import siteConfigModel from 'propera/siteConfig';
import { cleanResults } from 'results/results.actions';
import { getRfqKeyFromUrl } from 'results/results.utils';
import { resetSearchReducerValues, GET_RFQ_SUMMARY } from 'slimSearch/actions';
import { mapShipmentToCustomQuote } from 'results/custom-quote.utils';
import { getRfqSelector, rfqFromQuoteSelector } from 'slimSearch/selectors';
import get from 'lodash/get';
import { actions as analyticsActions } from 'propera/analytics';
import { actions as monitoringActions } from 'propera/monitoring';
import { actions as formsActions, actionTypes as formsActionTypes } from 'propera/forms';
import { all, call, put, takeLatest, select, take } from 'redux-saga/effects';
import { t } from 'utils/translationProvider';
import * as customQuoteActions from './actions';
import { serverError } from './actions';
import {
  NEW_SEARCH,
  NOT_ALLOWED_COMMODITY,
  SUBMIT_CUSTOM_QUOTE_REQUEST,
  INIT_CUSTOM_QUOTE_PAGE_STATUS
} from './actionTypes';
import {
  customQuoteResultTypes,
  DISPLAY_STATE,
  FORM_ID,
  QUICK_SILVER_BASE_TEST_PATH,
  QUICK_SILVER_END_POINT
} from './constants';

const { registerSegmentEvent } = analyticsActions;
const { FORM_SUBMIT_SUCCEEDED, FORM_SUBMIT_FAILED } = formsActionTypes;
const { doSubmitWithJWTCredentials } = formsActions;

export function* requestCustomQuoteQuickSilverEndPoint(values) {
  const isBuyer = yield select(userModel.isBuyerSelector);
  const domain = yield select(siteConfigModel.getDomain);
  const shipment = yield select(getRfqSelector);
  const rfqKey = yield select(rfqFromQuoteSelector);

  const mappedValues = mapShipmentToCustomQuote({ ...values, rfqKey }, shipment);
  try {
    if (isBuyer) {
      yield put(AuthActions.getJwt(AuthConstants.JWT_TOKEN_ENDPOINT));
      const {
        payload: { type, jwt, error }
      } = yield take(AuthActionTypes.JWT_RESULT);
      if (type === 'error') {
        throw error;
      }
      const jwtDecoded = jwtDecode(jwt);
      set(mappedValues, 'buyerInfo.buyerBusinessKey', jwtDecoded.businessKey);
      set(mappedValues, 'buyerInfo.buyerKey', jwtDecoded.userKey);
      if (!mappedValues.sourceSiteDomain) {
        set(mappedValues, 'sourceSiteDomain', domain);
      }
    }

    yield put(
      registerSegmentEvent(ANALYTICS_EVENT_NAME.CUSTOM_QUOTE_REQUESTED, {
        ...mappedValues,
        endPoint: 'quickSilver'
      })
    );

    yield put(
      doSubmitWithJWTCredentials(
        FORM_ID,
        QUICK_SILVER_END_POINT,
        mappedValues,
        AuthConstants.JWT_TOKEN_ENDPOINT,
        true,
        undefined,
        QUICK_SILVER_BASE_TEST_PATH
      )
    );
  } catch (error) {
    //  eslint-disable-next-line no-console
    console.error(error);
    yield put(monitoringActions.exceptionReporter(error, true));
  }
}

export function* submitCustomQuoteRequest({ payload }) {
  yield call(requestCustomQuoteQuickSilverEndPoint, payload);
}

export const hasServerError = (response) => {
  if (!response) {
    return false;
  }
  if (typeof response === 'string' && response.search('Error 500 Request failed') > -1) {
    return true;
  }
  return false;
};

export function* customQuoteSubmitted({ response = {} }) {
  let resultType;
  let failureResponse;
  if (hasServerError(response)) {
    const message = t(
      'customQuote/serverErrorMessage',
      'Your request couldn’t be submitted. Make sure the information you entered is correct.'
    );
    yield put(serverError(message));
    resultType = customQuoteResultTypes.serverFailureResponse;
    failureResponse = response;
  } else {
    resultType = customQuoteResultTypes.customQuoteSubmitted;
  }
  yield put(
    push(
      `/custom-quotes/${get(response, 'data.customQuoteRequest.id', '')}?ntf=${btoa(Date.now())}`
    )
  );

  yield put(
    registerSegmentEvent(ANALYTICS_EVENT_NAME.CUSTOM_QUOTE_RESULT_PAGE_PRESENTED, {
      resultType,
      productType: get(response, 'data.customQuoteRequest.productDetails.commodities'),
      failureResponse
    })
  );
}

export function* notAllowedCommodity(action) {
  yield put(customQuoteActions.setCustomQuotePageStatus(DISPLAY_STATE.FAILURE));
  yield put(
    registerSegmentEvent(ANALYTICS_EVENT_NAME.CUSTOM_QUOTE_RESULT_PAGE_PRESENTED, {
      resultType: customQuoteResultTypes.notAllowedCommodity,
      productType: action.commodity
    })
  );
}

export function* customQuoteSubmittedFailed(action) {
  const message = t(
    'customQuote/serverErrorMessage',
    'Your request couldn’t be submitted. Make sure the information you entered is correct.'
  );
  const serverErrorMessage = get(action, 'response.error.message') || message;

  yield put(serverError(serverErrorMessage));
  yield put(
    registerSegmentEvent(ANALYTICS_EVENT_NAME.CUSTOM_QUOTE_RESULT_PAGE_PRESENTED, {
      resultType: customQuoteResultTypes.serverFailureResponse,
      productType: action.values.productType
    })
  );
  yield put(monitoringActions.exceptionReporter(new Error(serverErrorMessage), true));
}

export function* newSearch() {
  yield put(resetSearchReducerValues());
  yield put(cleanResults());
  yield put(push(`/${PAGE_ROUTE}`));
}

export function* getOpenFreightRfqSummary() {
  const openFreightRfq = getRfqKeyFromUrl();

  if (openFreightRfq) {
    // if we got rfq from url
    yield put({ type: GET_RFQ_SUMMARY.type, payload: openFreightRfq });
  } else {
    yield put(
      monitoringActions.exceptionReporter(
        new Error(
          'Custom quote accessed with no RFQ key in url, Triggered by getOpenFreightRfqSummary'
        ),
        true
      )
    );
  }
}

export default function* watcher() {
  yield all([
    takeLatest(SUBMIT_CUSTOM_QUOTE_REQUEST, submitCustomQuoteRequest),
    takeLatest(`${FORM_SUBMIT_SUCCEEDED}${FORM_ID}`, customQuoteSubmitted),
    takeLatest(`${FORM_SUBMIT_FAILED}${FORM_ID}`, customQuoteSubmittedFailed),
    takeLatest(NOT_ALLOWED_COMMODITY, notAllowedCommodity),
    takeLatest(NEW_SEARCH, newSearch),
    takeLatest(INIT_CUSTOM_QUOTE_PAGE_STATUS, getOpenFreightRfqSummary)
  ]);
}
