import React from 'react';

import { connect } from 'microfronts-redux';
import { InputNumber } from '@freightos/design-system';

import { updateField } from 'slimSearch/actions';
import { isMobileSelector } from 'slimSearch/selectors';

export const Quantity = ({ value, onChange, isMobile, className }) => {
  const [quantityInFocus, setQuantityInFocus] = React.useState(false);

  return (
    <InputNumber
      data-test-id={`number-input-qty`}
      className={className}
      type="number"
      formatter={(value) => (quantityInFocus ? value : value === '' ? 1 : value)}
      onFocus={() => setQuantityInFocus(true)}
      onBlur={() => setQuantityInFocus(false)}
      size={isMobile ? 'large' : 'default'}
      min={1}
      value={value}
      onChange={(value) => onChange(value)}
    />
  );
};

const mapStateToProps = (store) => ({
  load: store.search.quote.load,
  loadType: store.search.quote.load.type,
  isMobile: isMobileSelector(store)
});

export default connect(mapStateToProps, {
  updateField
})(Quantity);
