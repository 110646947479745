import React, { useState, useEffect } from 'react';
import { t } from 'utils/translationProvider';

import { connect } from 'microfronts-redux';
import { quoteSelector } from 'slimSearch/selectors';
import { kebabize } from 'utils/index';
import { model as siteConfigModel } from 'propera/siteConfig';

import styled, { css } from 'styled-components/macro';
import { Switch, Icon, RadioGroup, Popover, Button } from '@freightos/design-system';
import { updateField, serviceHelpIconClicked } from 'slimSearch/actions';
import { isMobileSelector } from 'slimSearch/selectors';

export const OptionTooltip = ({
  helpText = false,
  articleKey = false,
  ImageComponent = undefined,
  helpTextClass = undefined,
  serviceHelpIconClickedAction = () => {}
}) =>
  helpText && (
    <SwitchHelpTooltip
      overlayClassName={`${helpTextClass ? 'service-popover-with-image' : 'services-popover'}`}
      content={
        <>
          <SwitchHelpContainer
            hasImage={Boolean(ImageComponent)}
            className={Boolean(helpTextClass) && helpTextClass}
          >
            {Boolean(ImageComponent) && (
              <SwitchHelpImage>
                <ImageComponent />
              </SwitchHelpImage>
            )}
            <SwitchHelpText>
              <div data-test-id="switch-help-text" dangerouslySetInnerHTML={{ __html: helpText }} />
              {articleKey && (
                <Button type="text" onClick={() => serviceHelpIconClickedAction(articleKey)}>
                  {t('Learn more', 'Learn more')}.
                </Button>
              )}
            </SwitchHelpText>
          </SwitchHelpContainer>
        </>
      }
    >
      <Icon type="help-circled" />
    </SwitchHelpTooltip>
  );

export const ServiceOptionComponent = ({
  name,
  shipment,
  updateFieldAction,
  serviceOptionConfig,
  isMobile,
  siteConfigData,
  serviceHelpIconClickedAction = () => {}
}) => {
  const options =
    serviceOptionConfig.optionType === 'switch'
      ? serviceOptionConfig.getSwitchOptions(
          shipment,
          serviceOptionConfig.options,
          siteConfigData
        ) || []
      : serviceOptionConfig.options;

  const [animations, setAnimations] = useState([]);
  useEffect(() => {
    setTimeout(() => {
      setAnimations(new Array(options.length).fill(true));
    }, 0);
  }, [options.length, shipment]);

  return (
    serviceOptionConfig.criteria(shipment, siteConfigData) && (
      <ServiceBox isMobile={isMobile} className={kebabize(name)}>
        {serviceOptionConfig.title && (
          <ServiceBoxTitle>
            {serviceOptionConfig.title}
            <OptionTooltip
              ImageComponent={serviceOptionConfig.ImageComponent}
              helpTextClass={serviceOptionConfig.helpTextClass}
              helpText={serviceOptionConfig.helpText}
              articleKey={serviceOptionConfig.articleKey}
              serviceHelpIconClickedAction={serviceHelpIconClickedAction}
            />
          </ServiceBoxTitle>
        )}
        {serviceOptionConfig.optionType === 'switch' &&
          options.map((switchOption, index) => (
            <>
              <SwitchWrapper data-test-id={switchOption.key} key={switchOption.key}>
                <StyledSwitch
                  data-test-id={`${kebabize(name)} ${switchOption.key}`}
                  checked={switchOption.value}
                  disabled={switchOption.disabled}
                  onChange={(optionValue) => {
                    if (typeof serviceOptionConfig.onChange === 'function') {
                      const { path, value } = serviceOptionConfig.onChange(
                        shipment,
                        switchOption,
                        optionValue
                      );
                      updateFieldAction(path, value);
                    } else {
                      updateFieldAction(
                        switchOption.objectPath || serviceOptionConfig.objectPath,
                        optionValue
                      );
                    }

                    const tmpAnimations = [...animations];
                    tmpAnimations[index] = false;
                    setAnimations(tmpAnimations);
                  }}
                ></StyledSwitch>

                <OptionTextWrapper>
                  <SwitchText
                    data-test-id={`${switchOption.key}-label`}
                    className={animations[index] ? 'display-text' : 'hide-text'}
                  >
                    {switchOption.label}
                  </SwitchText>
                  <OptionTooltip
                    data-test-id={`${switchOption.key}-tooltip`}
                    ImageComponent={switchOption.ImageComponent}
                    helpTextClass={switchOption.helpTextClass}
                    helpText={switchOption.helpText}
                    articleKey={switchOption.articleKey}
                    serviceHelpIconClickedAction={serviceHelpIconClickedAction}
                  />
                </OptionTextWrapper>
              </SwitchWrapper>
              {Boolean(switchOption.children) && switchOption.children}
            </>
          ))}

        {serviceOptionConfig.optionType === 'radio' && (
          <RadioGroup
            onChange={(event) => {
              if (typeof serviceOptionConfig.onChange === 'function') {
                const { paths = [], values = [] } = serviceOptionConfig.onChange(
                  shipment,
                  event.target?.value
                );
                paths.forEach((path, index) => {
                  updateFieldAction(path, values[index]);
                });
              } else {
                updateFieldAction(serviceOptionConfig.objectPath, event.target?.value);
              }
            }}
            defaultValue={serviceOptionConfig.getDefaultValue(shipment)}
            options={options.map((option) => ({
              ...option,
              label: (
                <OptionTextWrapper>
                  <SwitchText className="display-text">{option.label}</SwitchText>
                  <OptionTooltip
                    helpText={option.helpText}
                    ImageComponent={option.ImageComponent}
                    helpTextClass={option.helpTextClass}
                    articleKey={option.articleKey}
                    serviceHelpIconClickedAction={serviceHelpIconClickedAction}
                  />
                </OptionTextWrapper>
              )
            }))}
            vertical={true}
          ></RadioGroup>
        )}
      </ServiceBox>
    )
  );
};

const mapStateToProps = (state) => {
  return {
    shipment: quoteSelector(state),
    isMobile: isMobileSelector(state),
    siteConfigData: siteConfigModel.getSiteConfig(state)
  };
};

const mapDispatchToProps = {
  updateFieldAction: updateField,
  serviceHelpIconClickedAction: serviceHelpIconClicked
};

const ServiceOption = connect(mapStateToProps, mapDispatchToProps)(ServiceOptionComponent);

export default ServiceOption;

const ServiceBox = styled.li`
  margin-top: 30px;
  display: flex;
  flex-direction: column;
  ${({ isMobile }) =>
    isMobile
      ? ''
      : css`
          width: 98%;
        `}
`;
const ServiceBoxTitle = styled.div`
  font-size: 14px;
  font-weight: bold;
  margin-bottom: 18px;
  display: flex;
  align-items: center;
  .anticon {
    margin-left: 8px;
  }
`;
const SwitchWrapper = styled.div`
  display: flex;
  align-items: flex-start;
  margin-bottom: 16px;
  &:last-child {
    margin-bottom: 0;
  }
`;
const SwitchText = styled.div`
  font-size: 14px;
  margin-left: 8px;
  margin-right: 12px;
  transition: opacity 500ms ease-out;
  margin-top: -3px;
  &.display-text {
    opacity: 1;
  }
  &.hide-text {
    opacity: 0;
  }
`;

const SwitchHelpTooltip = styled(Popover)``;

const StyledSwitch = styled(Switch)`
  &.ant-switch-checked {
    background-color: #0077b6;
  }
`;

const OptionTextWrapper = styled.div`
  display: flex;
  align-items: flex-start;
`;

const SwitchHelpContainer = styled.div`
  display: flex;
  align-items: center;
  &.floor-loaded {
    width: 550px;
  }
  &.supplier {
    width: 350px;
  }
  &.floor-palletized-loaded {
    width: 595px;
  }
  &.labeling-boxes {
    width: 350px;
  }
  &.labeling-pallets {
    width: 470px;
  }
`;

const SwitchHelpImage = styled.div`
  margin-left: 18px;
  margin-right: 18px;
`;

const SwitchHelpText = styled.div``;
