import React, { useEffect } from 'react';
import { connect } from 'microfronts-redux';
import { Button, Text } from '@freightos/design-system';
import { creditSelector } from 'slimSearch/selectors';
import { getCreditInfo, searchAnalytics } from 'slimSearch/actions';
import { ANALYTICS_EVENT_NAME } from 'slimSearch/constants';
import { formatHTMLMessage, t } from 'utils/translationProvider';
import {
  NotificationArea,
  StyledContainer,
  StyledDivider,
  ValuesArea,
  BadgeWrapper,
  StyledParagraph,
  Anchor
} from 'slimSearch/components/Tiles/sharedStyles';
import { moneyFormat } from 'utils';
import { Counter } from 'slimSearch/components/Tiles/components/Counter';
import { Tile } from 'slimSearch/components/Tiles/components/Tile';
import userModel from 'propera/user';
import { AuthUiSelectors, AuthUiActions, AuthUiConstants } from 'propera/authUI';

const { isLoggedIn } = userModel;
const { setView, loginModal } = AuthUiActions;
const { VIEWS } = AuthUiConstants;

export const Credit = ({
  credit,
  getCreditInfo,
  isLoggedIn,
  searchAnalytics,
  setView,
  isFirstTime,
  loginModal
}) => {
  useEffect(() => {
    isLoggedIn && getCreditInfo();
  }, [getCreditInfo, isLoggedIn]);

  return (
    <Tile
      title={t('slimSearch/credit/freightosCredit', 'Freightos credit')}
      loading={credit.loading}
      icon="pay-later"
    >
      <StyledContainer>
        <NotificationArea>
          {credit?.data?.details?.availableCredit > 0 && (
            <Text type="secondary">
              {formatHTMLMessage('creditTile/allGood', 'Here you can see your credit balance')}
            </Text>
          )}

          {(credit.error ||
            !isLoggedIn ||
            [0, null].includes(credit?.data?.details?.availableCredit)) && (
            <StyledParagraph>
              {t(
                'slimSearch/credit/getCreditText',
                'Based in the US, UK, or Canada? Take advantage of our generous credit terms. More info?'
              )}{' '}
              <Button
                type="text"
                size="small"
                onClick={() => {
                  window.Intercom && window.Intercom('showNewMessage');
                  searchAnalytics({
                    name: ANALYTICS_EVENT_NAME.MANAGEMENT_TOOL_BUTTON_CLICKED,
                    data: { category: 'credit', action_name: 'get_help' }
                  });
                }}
              >
                {t('slimSearch/contactSupport', 'Contact support')}
              </Button>
            </StyledParagraph>
          )}
        </NotificationArea>

        <div>
          <StyledDivider hide={!isLoggedIn} />

          <ValuesArea>
            <BadgeWrapper active={isLoggedIn} />
            <div>
              {credit?.data?.details && credit?.data?.details?.availableCredit > 0 && (
                <Counter
                  label={t('slimSearch/credit/creditBalance', 'Credit balance')}
                  value={moneyFormat({
                    amount: credit?.data?.details?.availableCredit,
                    currency: credit?.data?.baseCurrency
                  })}
                />
              )}
            </div>
            {isLoggedIn && !credit?.data?.details?.availableCredit && (
              <Anchor
                type="secondary"
                size="small"
                onClick={() => {
                  searchAnalytics({
                    name: ANALYTICS_EVENT_NAME.MANAGEMENT_TOOL_BUTTON_CLICKED,
                    data: { category: 'credit', action_name: 'apply_for_credit' }
                  });
                  window.open('https://form.jotform.com/230733598134459');
                }}
              >
                {t('slimSearch/credit/applyForCredit', 'Apply for credit')}
              </Anchor>
            )}
            {!isLoggedIn && (
              <Anchor
                type="secondary"
                size="small"
                onClick={() => {
                  searchAnalytics({
                    name: ANALYTICS_EVENT_NAME.MANAGEMENT_TOOL_BUTTON_CLICKED,
                    data: {
                      category: 'credit',
                      action_name: 'get_started',
                      first_time: isFirstTime
                    }
                  });
                  setView({ view: isFirstTime ? VIEWS.SIGN_UP : VIEWS.LOG_IN });
                  loginModal({
                    open: true
                  });
                }}
              >
                {t('slimSearch/credit/getStarted', 'Get started')}
              </Anchor>
            )}
          </ValuesArea>
        </div>
      </StyledContainer>
    </Tile>
  );
};

const mapStateToProps = (store) => ({
  credit: creditSelector(store),
  isLoggedIn: isLoggedIn(store),
  isFirstTime: store.propera?.user?.isFirstTime,
  loginView: AuthUiSelectors.getView(store),
  isLoginModalOpen: AuthUiSelectors.getLoginModalState(store)
});

export default connect(mapStateToProps, {
  getCreditInfo,
  searchAnalytics,
  setView,
  loginModal
})(Credit);
