export const getAccountDetails = accountDetails => [
  {
    label: "Recipient's name",
    value: accountDetails.accountHolder,
    dataTestId: 'accountHolder',
  },
  {
    label: "Recipient's address",
    value: accountDetails.address,
    dataTestId: 'recepientAddress',
  },
  {
    label: 'Account number',
    value: accountDetails.accountNumber,
    dataTestId: 'accountNumber',
  },
  {
    label: 'Bank name',
    value: accountDetails.bankName,
    dataTestId: 'bankName',
  },
  {
    label: 'Bank address',
    value: accountDetails.bankAddress,
    dataTestId: 'bankAddress',
    fullWidth: true,
  },
  {
    label: 'ABA routing',
    value: accountDetails.abaRouteCode,
    dataTestId: 'abaRouting',
  },
  {
    label: 'Swift code',
    value: accountDetails.bicCode,
    dataTestId: 'swiftCode',
  },
  {
    label: 'IBAN',
    value: accountDetails.iban,
    dataTestId: 'iban',
  },
  {
    label: 'Sort Code',
    value: accountDetails.sortCode,
    dataTestId: 'sortCode',
  },
];

