import { doGetRequest } from '@propera/HTTP';
import { AUTH_TOKEN_ENDPOINT } from './endpoints';
import { isTestEnv, isLocalEnv } from 'propera/utils/url';
import { call } from 'redux-saga/effects';

export const PAYMENT_CONFIG_FEE_URL = isLocalEnv()
    ? `/mock-service/financial-gateway/rest/v1/payment-fees/stripe`
    : `/financial-gateway/rest/v1/payment-fees/stripe`;

const DOMAINS = {
  LOCAL: {
    url: 'http://localhost:4444',
    appId: ''
  },
  TEST: {
    url: 'https://integration.test-freightos.com',
    appId: '8fe289bb-de35-4497-ae6e-ebac1c730333'
  },
  PRODUCTION: {
    url: 'https://integration.freightos.com',
    appId: '5db8914b-cbf4-464a-ab2a-70e7c092b6e9'
  }
};

const getIntegrationBaseURLAndAppId = () => {
  let result = DOMAINS.PRODUCTION;
  if (isLocalEnv()) {
    result = DOMAINS.LOCAL;
  } else if (isTestEnv()) {
    result = DOMAINS.TEST;
  }
  return result;
};

export function* doGetIntegration({ payload: { path, headers, options = undefined } }) {
  const urlAndAppId = getIntegrationBaseURLAndAppId();
  return yield call(
    doGetRequest,
    path,
    {
      Accept: 'application/json',
      appId: urlAndAppId.appId,
      ...headers
    },
    undefined,
    undefined,
    undefined,
    urlAndAppId.url,
    options,
    AUTH_TOKEN_ENDPOINT
  );
}
