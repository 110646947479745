import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import Loading from 'financialSharedComponents/components/common/Loading';
import Classnames from 'classnames';
import isEmpty from 'lodash/isEmpty';
import { Card, Alert } from '@freightos/design-system';
import { t } from 'utils/translationProvider';
import styles from 'financialSharedComponents/components/styles.module.scss';
import {
  SUPPORTED_MONEY_TRANSFER_PSP_NAMES,
  SUPPORTED_PAYMENT_METHODS_TYPES
} from 'financialSharedComponents/constants/app.constants';
import BankDetails from 'financialSharedComponents/components/BankDetails';
import PayButton from 'financialSharedComponents/components/common/PayButton';
import { appendCurrencyToAmount } from 'utils/financial.utils';
import PaymentMethods from './PaymentMethods';

const Payment = ({
  paymentMethodsBusy,
  fetchSupportedPaymentTypes,
  selectedPaymentMethod,
  components = {},
  cardErrorMessage,
  shipmentNumber,
  selectedPaymentMethodPspName,
  isLastPaymentFailed,
  initialPaymentMethodType,
  defaultCard,
  showAmountDue,
  paymentMethodsError,
  initPaymentData,
  paymentAmount
}) => {
  useEffect(() => {
    initPaymentData({ paymentAmount, shipmentNumber, initialPaymentMethodType });
    fetchSupportedPaymentTypes();
  }, []);
  const { PrePaymentMethods } = components;
  return (
    <div className={'financial-payment-component'}>
      {paymentMethodsBusy && <Loading />}
      {!paymentMethodsBusy && (
        <React.Fragment>
          {!paymentMethodsBusy && showAmountDue && !isEmpty(paymentAmount) && (
            <Card data-test-id={'financial-amount-due'}>
              <div
                className={Classnames(
                  styles.totalAmountDue,
                  'children-vertical-align-middle',
                  'clearfix'
                )}
              >
                <div>{t('actionRequired/amount_due_header1_text', 'Amount due:')}</div>
                <div className={'total-amount pull-right'}>
                  {appendCurrencyToAmount(paymentAmount.amount, paymentAmount.currency)}
                </div>
              </div>
            </Card>
          )}
          {!paymentMethodsBusy && !paymentMethodsError && (
            <React.Fragment>
              {PrePaymentMethods && <PrePaymentMethods />}
              {isLastPaymentFailed && !cardErrorMessage && (
                <Alert
                  className={styles.distanceFromTop}
                  message={t(
                    'Your payment was unsuccessful.',

                    'Your payment was unsuccessful.'
                  )}
                  closable={false}
                  type="danger"
                />
              )}
              {cardErrorMessage && (
                <Alert
                  className="card-declined-alert"
                  message={t(cardErrorMessage, cardErrorMessage)}
                  closable={false}
                  type="danger"
                />
              )}
              <Card className={`payment-section`}>
                <PaymentMethods
                  defaultCard={defaultCard}
                  shipmentNumber={shipmentNumber}
                  paymentAmount={paymentAmount}
                />
                {selectedPaymentMethod === SUPPORTED_PAYMENT_METHODS_TYPES.BANK_TRANSFER &&
                  selectedPaymentMethodPspName !== SUPPORTED_MONEY_TRANSFER_PSP_NAMES.VEEM && (
                    <BankDetails buyerCurrency = {paymentAmount.currency} showAllDetails hideExpandArrows = {true}/>
                  )}
                <PayButton
                  defaultCard={defaultCard}
                  initialPaymentMethodType={initialPaymentMethodType}
                />
              </Card>
            </React.Fragment>
          )}
        </React.Fragment>
      )}

      {!paymentMethodsBusy && paymentMethodsError && <div>Error!</div>}
    </div>
  );
};

Payment.propTypes = {
  components: PropTypes.shape({
    PrePaymentMethods: PropTypes.node
  })
};

export default Payment;
