import React, { useState } from 'react';
import { injectStripe } from 'react-stripe-elements';
import Classnames from 'classnames';
import { Formik } from 'formik';
import { SUPPORTED_TOKENIZE_FORMS_PSP_NAMES } from 'financialSharedComponents/components/Payment/CreditCardsMgmt/constants';
import { t } from 'utils/translationProvider';
import { model as LayoutModel } from 'layout';
import { connect } from 'react-redux';
import CreditCardStripElements from './CreditCardElements';
import styles from './CreditCardForm.module.scss';
import { Input, Row } from '@freightos/design-system';
import { STRIPE_ERROR_CODES, WRONG_CARD_NUMBER, WRONG_CVC, WRONG_EXPIRY_DATE } from './constants';
import CheckoutFormFooter from '../common/CheckoutFormFooter';

const { isMobileSelector } = LayoutModel;

const StripeTokenizeForm = ({ stripe, onSubmitToken, payButtonTitle, isMobile }) => {
  const [isLoading, setLoading] = useState(false);
  const [saveCardChecked, setSaveCardChecked] = useState(false);
  const [isCCInputsComplete, setCCInputsComplete] = useState(false);
  const [errorCode, setErrorCode] = useState(null);

  const showValidation = (code) => {
    switch (code) {
      case STRIPE_ERROR_CODES.INCOMPLETE_NUMBER:
      case STRIPE_ERROR_CODES.INVALID_NUMBER:
        setErrorCode(WRONG_CARD_NUMBER);
        break;
      case STRIPE_ERROR_CODES.INCOMPLETE_EXPIRY:
        setErrorCode(WRONG_EXPIRY_DATE);
        break;
      case STRIPE_ERROR_CODES.INVALID_EXPIRY_YEAR_PAST:
        setErrorCode(WRONG_EXPIRY_DATE);
        break;
      case STRIPE_ERROR_CODES.INVALID_EXPIRY_MONTH_PAST:
        setErrorCode(WRONG_EXPIRY_DATE);
        break;
      case STRIPE_ERROR_CODES.INCOMPLETE_CVC:
        setErrorCode(WRONG_CVC);
        break;
      case null:
      default:
        setErrorCode(null);
    }
  };

  const submit = (values) => {
    if (isLoading) {
      return;
    }
    setLoading(true);

    stripe
      .createToken({ name: values.name })
      .then(({ token, error }) => {
        if (error) {
          const { code } = error;
          showValidation(code);
        }
        showValidation(null);
        onSubmitToken({ nonce: token.id, pspName: SUPPORTED_TOKENIZE_FORMS_PSP_NAMES.STRIPE });
      })
      .finally(() => setLoading(false));
  };

  const isSubmitDisabled = isLoading || !isCCInputsComplete;

  return (
    <div className={styles.stripeCheckoutForm}>
      <Formik
        initialValues={{ name: '' }}
        validate={(values) => {
          const errors = {};
          if (!values.name) {
            errors.name = 'Required';
          }
          return errors;
        }}
        onSubmit={(values, { setSubmitting }) => submit(values, setSubmitting)}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
          setFieldValue,
          isValid,
          isSubmitting
        }) => (
          <form onSubmit={saveCardChecked && isValid && handleSubmit}>
            <Row>
              <span className={styles.ccInputLabel}>
                {t('payment/shared_comp_cardholder_name_label_text', 'Cardholder Name')}
              </span>
              <Input
                placeholder={t(
                  'payment/shared_comp_cardholder_name_prompt_text',
                  'Enter full name'
                )}
                className={Classnames(styles.ccInput, {
                  [styles.TokenizeElementInvalid]: errors.name && touched.name
                })}
                name="name"
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.name}
              />
              <span className={styles.stripError}>{errors.name && touched.name}</span>
            </Row>
            <CreditCardStripElements
              errorCode={errorCode}
              onCompleteChange={(isComplete) => setCCInputsComplete(isComplete)}
            />
            <CheckoutFormFooter
              saveCardChecked={saveCardChecked}
              setSaveCardChecked={setSaveCardChecked}
              payButtonTitle={payButtonTitle}
              isSubmitDisabled={isSubmitDisabled || isSubmitting || !isValid || !values.name}
            />
          </form>
        )}
      </Formik>
    </div>
  );
};

StripeTokenizeForm.propTypes = {};

const mapStateToProps = (state) => {
  return {
    isMobile: isMobileSelector(state)
  };
};
export default connect(mapStateToProps)(injectStripe(StripeTokenizeForm));
