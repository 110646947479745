import { t } from 'utils/translationProvider';
import { getMsEndpointUrl, MICROSERVICES_DOMAINS } from 'propera/utils/url';
const { QUICKSILVER } = MICROSERVICES_DOMAINS;

export const getQuickSilverEndPoint = () => getMsEndpointUrl(QUICKSILVER.NAME);

export const FORM_ID = 'CUSTOM_QUOTE';
export const QUICK_SILVER_BASE_TEST_PATH = getQuickSilverEndPoint(); // 'https://marvel.test-freightos.com/quicksilver'; // check if test
export const QUICK_SILVER_END_POINT = '/custom-quote-requests';

export const DISPLAY_STATE = {
  REQUEST: 'REQUEST',
  FAILURE: 'FAILURE',
  SUCCESS: 'SUCCESS'
};

export const shipmentModeValues = {
  EXPRESS: 'express',
  AIR: 'air',
  OCEAN: 'ocean',
  TRUCKING: 'LTL'
};

export const commoditiesOptions = [
  {
    key: '7',
    label: t('customQuoteCommodities/Chemicals(with MSDS)', 'Chemicals (with MSDS)'),
    status: 'yes'
  },
  {
    key: '5',
    label: t(
      'customQuoteCommodities/Consumer Products(with Batteries inside)',
      'Consumer Products (with Batteries inside)'
    ),
    status: 'yes'
  },
  {
    key: '12',
    label: t(
      'customQuoteCommodities/Dangerous goods such as: firearms, explosives and hazardous materials',
      'Dangerous goods such as: firearms, \n explosives and hazardous materials'
    ),
    status: 'no'
  },
  { key: '4', label: t('customQuoteCommodities/Dry goods', 'Dry goods'), status: 'yes' },
  {
    key: '6',
    label: t('customQuoteCommodities/FDA Approved Foodstuffs', 'FDA Approved Foodstuffs'),
    status: 'yes'
  },
  {
    key: '2',
    label: t(
      'customQuoteCommodities/General department store goods',
      'General department store goods'
    ),
    status: 'yes'
  },
  {
    key: '11',
    label: t('customQuoteCommodities/Motorized vehicles', 'Motorized vehicles'),
    status: 'no'
  },
  { key: '8', label: t('customQuoteCommodities/New Auto Parts', 'New Auto Parts'), status: 'yes' },
  {
    key: '3',
    label: t('customQuoteCommodities/New manufacturing parts', 'New manufacturing parts'),
    status: 'yes'
  },
  {
    key: '9',
    label: t(
      'customQuoteCommodities/Perishable food products / Alcoholic beverages',
      'Perishable food products / Alcoholic beverages'
    ),
    status: 'no'
  },
  {
    key: '1',
    label: t('customQuoteCommodities/Textiles / Garments', 'Textiles / Garments'),
    status: 'yes'
  },
  {
    key: '10',
    label: t(
      'customQuoteCommodities/Valuable such as: jewelry, precious stones, gold, silver, diamonds, cash, art',
      'Valuable such as: jewelry, precious stones, gold, silver, diamonds, cash, art'
    ),
    status: 'no'
  },
  { key: '13', label: t('customQuoteCommodities/Others', 'Others'), status: 'optional' }
];

export const customQuoteFormName = 'custom-quote-form';

export const shipmentModeOptions = [
  { label: t('common/mode/display/express', 'Express'), value: shipmentModeValues.EXPRESS },
  { label: t('common/mode/display/air', 'Air'), value: shipmentModeValues.AIR },
  { label: t('common/mode/display/ocean', 'Ocean'), value: shipmentModeValues.OCEAN },
  { label: t('common/mode/display/trucking', 'Trucking'), value: shipmentModeValues.TRUCKING }
];

export const customQuoteResultTypes = {
  serverFailureResponse: 'server_failure_response',
  customQuoteSubmitted: 'custom-quote-submitted',
  notAllowedCommodity: 'not-allowed-commodity'
};
