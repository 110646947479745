(function webpackUniversalModuleDefinition(root, factory) {
	if(typeof exports === 'object' && typeof module === 'object')
		module.exports = factory(require("React"), require("ProperaFWpackagesmicrofrontsStore"), require("ProperaFWproperautilsurl"), require("ProperaFWproperaHTTP"), require("ProperaFWlayout"), require("ProperaFWproperamonitoring"), require("ProperaFWproperatranslations"), require("ProperaFWproperauser"), require("ProperaFWpackagesfeatureFlagging"), require("ProperaFWproperasiteConfig"), require("ProperaFWproperaanalytics"), require("ReactDOM"));
	else if(typeof define === 'function' && define.amd)
		define("FinancialSharedComponents", ["React", "ProperaFWpackagesmicrofrontsStore", "ProperaFWproperautilsurl", "ProperaFWproperaHTTP", "ProperaFWlayout", "ProperaFWproperamonitoring", "ProperaFWproperatranslations", "ProperaFWproperauser", "ProperaFWpackagesfeatureFlagging", "ProperaFWproperasiteConfig", "ProperaFWproperaanalytics", "ReactDOM"], factory);
	else if(typeof exports === 'object')
		exports["FinancialSharedComponents"] = factory(require("React"), require("ProperaFWpackagesmicrofrontsStore"), require("ProperaFWproperautilsurl"), require("ProperaFWproperaHTTP"), require("ProperaFWlayout"), require("ProperaFWproperamonitoring"), require("ProperaFWproperatranslations"), require("ProperaFWproperauser"), require("ProperaFWpackagesfeatureFlagging"), require("ProperaFWproperasiteConfig"), require("ProperaFWproperaanalytics"), require("ReactDOM"));
	else
		root["FinancialSharedComponents"] = factory(root["React"], root["ProperaFWpackagesmicrofrontsStore"], root["ProperaFWproperautilsurl"], root["ProperaFWproperaHTTP"], root["ProperaFWlayout"], root["ProperaFWproperamonitoring"], root["ProperaFWproperatranslations"], root["ProperaFWproperauser"], root["ProperaFWpackagesfeatureFlagging"], root["ProperaFWproperasiteConfig"], root["ProperaFWproperaanalytics"], root["ReactDOM"]);
})(this, function(__WEBPACK_EXTERNAL_MODULE__0__, __WEBPACK_EXTERNAL_MODULE__6__, __WEBPACK_EXTERNAL_MODULE__14__, __WEBPACK_EXTERNAL_MODULE__15__, __WEBPACK_EXTERNAL_MODULE__21__, __WEBPACK_EXTERNAL_MODULE__25__, __WEBPACK_EXTERNAL_MODULE__29__, __WEBPACK_EXTERNAL_MODULE__36__, __WEBPACK_EXTERNAL_MODULE__96__, __WEBPACK_EXTERNAL_MODULE__148__, __WEBPACK_EXTERNAL_MODULE__154__, __WEBPACK_EXTERNAL_MODULE__183__) {
return 