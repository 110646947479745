/* eslint-disable global-require */
import {
  t,
  formatHTMLMessage,
  reinitializeI18n,
  formatPlurals,
  formatDateWithTemplate,
  utils
} from 'propera/translations';
import { PACKAGE_NAME } from '../constants';

const { shouldLoadLocaleData, setPackageLocaleData, getLanguageWithoutRegionCode } = utils;

const getLang = () => {
  const locale = new URLSearchParams(document.location.search.substring(1)).get('locale');
  const [lang] = locale.split('_');

  if (lang) {
    return lang;
  }

  return getLanguageWithoutRegionCode();
};

if (shouldLoadLocaleData(PACKAGE_NAME)) {
  try {
    let localeData = {};
    try {
      // @todo find a different approach (either to load it with the config or to load it async)
      localeData = require(`../assets/locales/${getLang()}.json`);
    } catch (error) {
      localeData = require('../assets/locales/en.json');
    }
    setPackageLocaleData(PACKAGE_NAME, localeData);
    reinitializeI18n();
  } catch (error) {
    // eslint-disable-next-line no-console
    console.error(error);
  }
}

export { t, formatHTMLMessage, formatPlurals, formatDateWithTemplate };
