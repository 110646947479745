import React from 'react';
import { connect } from 'microfronts-redux';
import styled, { css } from 'styled-components/macro';
import {
  Row,
  Col,
  FormItem,
  RadioButtonGroup,
  CheckboxGroup,
  Alert,
  Icon
} from '@freightos/design-system';
import { fdsComponentSpacingLg } from '@freightos/design-system/dist/tokens';

import { t } from 'utils/translationProvider';

import {
  LOAD_TYPES,
  CONTAINER_TYPES,
  ADDRESS_TYPES,
  FULFILMENT_CENTER_COMPANIES,
  SECTIONS
} from 'slimSearch/constants';
import { updateField } from 'slimSearch/actions';
import { isMobileSelector } from 'slimSearch/selectors';

import Quantity from 'slimSearch/components/sections/load/Quantity';
import CollapsedLoad from 'slimSearch/components/sections/load/CollapsedLoad';
import { pullAt } from 'lodash/fp';

const LOAD_TYPE = LOAD_TYPES.CONTAINER.value;

export const Container = ({ load, updateField, isMobile, isAmazon, alerts }) => {
  React.useEffect(() => {
    if (!isAmazon) {
      updateField('alerts.amazon45container', false);
    }
  }, [isAmazon, alerts.amazon45container, updateField]);

  return (
    <ContainerWrapper>
      {alerts.amazon45container && (
        <>
          <Alert
            type="danger"
            closable={false}
            message={t(
              "We are not able to ship 45' containers to Amazon fulfillment centers. Please select an alternative load.",
              "We are not able to ship 45' containers to Amazon fulfillment centers. Please select an alternative load."
            )}
          />
          <br />
        </>
      )}
      {load[LOAD_TYPE] && (
        <Packages data-test-id={`${SECTIONS.LOAD}-containers-packages`}>
          {load[LOAD_TYPE].packages.map((pkg, index) =>
            load[LOAD_TYPE].packages.length > 1 && load.active !== index ? (
              <CollapsedLoad
                pkg={pkg}
                loadType={LOAD_TYPE}
                index={index}
                data-test-id={`${SECTIONS.LOAD}-containers-package-collapsed-${index}`}
              />
            ) : (
              <LoadRow
                multipleLoads={load[LOAD_TYPE].packages.length > 1}
                key={index}
                data-test-id={`${SECTIONS.LOAD}-containers-package-expanded-${index}`}
              >
                {load[LOAD_TYPE].packages.length > 1 && (
                  <>
                    <LoadTitle>Load {index + 1}</LoadTitle>
                    <StyledIcon
                      color="@fds-error-color"
                      type="trash"
                      data-test-id={`${SECTIONS.LOAD}-expanded-load-delete-btn-${index}`}
                      onClick={() =>
                        updateField(
                          `quote.load.${LOAD_TYPE}.packages`,
                          pullAt([index], load[LOAD_TYPE].packages)
                        )
                      }
                    />
                  </>
                )}
                <Row wrap={false} gutter={[8, 8]}>
                  <Col span={isMobile ? 24 : 6}>
                    <FormItem colon={false} label={t('# of units', '# of units')}>
                      <Quantity
                        data-test-id={`${SECTIONS.LOAD}-containers-package-qty-${index}`}
                        value={parseFloat(pkg.quantity)}
                        onChange={(value) =>
                          updateField(
                            `quote.load.${LOAD_TYPE}.packages.[${index}].quantity`,
                            value === null ? 1 : parseFloat(value)
                          )
                        }
                      />
                    </FormItem>
                  </Col>
                  <Col span={isMobile ? 24 : 18}>
                    <FormItem colon={false} label={t('Container type', 'Container type')}>
                      <StyledRadioButtonGroup
                        data-test-id={`${SECTIONS.LOAD}-containers-package-type-${index}`}
                        size={isMobile ? 'large' : 'default'}
                        value={pkg.packagingType}
                        onChange={(event) =>
                          updateField(
                            `quote.load.${LOAD_TYPE}.packages.[${index}].packagingType`,
                            event.target.value
                          )
                        }
                        options={
                          isAmazon
                            ? CONTAINER_TYPES.filter(
                                (container) => container.value !== 'container45HC'
                              )
                            : CONTAINER_TYPES
                        }
                      />
                    </FormItem>
                  </Col>
                </Row>

                <Row wrap={false} gutter={[8, 8]}>
                  <Col span={24}>
                    <CheckboxGroup
                      data-test-id={`${SECTIONS.LOAD}-containers-package-overweight-${index}`}
                      size={isMobile ? 'large' : 'default'}
                      options={[t('search/ss_load_overweight_label_text', 'Overweight')]}
                      value={
                        pkg.overWeightIndicator
                          ? t('search/ss_load_overweight_label_text', 'Overweight')
                          : null
                      }
                      onChange={(value) => {
                        updateField(
                          `quote.load.${LOAD_TYPE}.packages.[${index}].overWeightIndicator`,
                          value.includes(t('search/ss_load_overweight_label_text', 'Overweight'))
                        );
                      }}
                    />
                  </Col>
                </Row>
              </LoadRow>
            )
          )}
        </Packages>
      )}
    </ContainerWrapper>
  );
};

const ContainerWrapper = styled.div`
  margin: ${fdsComponentSpacingLg} 0;
`;

const Packages = styled.div`
  margin-bottom: ${fdsComponentSpacingLg};
`;

const LoadRow = styled.div`
  ${({ multipleLoads }) =>
    multipleLoads
      ? css`
          box-shadow: 0 0 10px rgba(35, 37, 55, 0.12);
          border-radius: 4px;
          padding: 6px 10px 16px;
          position: relative;
        `
      : ''}
`;

const LoadTitle = styled.div`
  margin: 10px 0;
`;

const StyledRadioButtonGroup = styled(RadioButtonGroup)`
  label {
    &.ant-radio-button-wrapper {
      width: 100%;
      text-align: center;
      justify-content: center;
    }
  }
`;

const StyledIcon = styled(Icon)`
  position: absolute;
  top: 7px;
  right: 6px;
`;

Container.propTypes = {};

const mapStateToProps = (store) => ({
  load: store.search.quote.load,
  isMobile: isMobileSelector(store),
  alerts: store.search.alerts,
  isAmazon:
    store.search.quote.destination?.destinationLocation?.locationTypeCode ===
      ADDRESS_TYPES.FULFILMENT_CENTER.value &&
    store.search.quote.destination?.destinationLocation?.brand ===
      FULFILMENT_CENTER_COMPANIES.AMZ.key
});

export default connect(mapStateToProps, {
  updateField
})(Container);
