import get from 'lodash/get';
import { model as preferencesModel } from 'propera/preferences';
import { model as userModel } from 'propera/user';
import { createSelector } from 'reselect';
import { getActiveFilters } from 'results/filters.utils';
import {
  appendTopValues,
  getRankedQuotes,
  resultsByVendorByKey,
  getTopWithGuaranteedCapacityQuotes
} from 'results/sort.utils';
import { getRfqSelector } from 'slimSearch/selectors';

import {
  getShipmentLabel,
  resultsCompletedAnalyticsData,
  quotesAnalyticsData
} from 'results/analytics.utils';
import { vendorsSelector } from 'results/vendors/vendors.selectors';

const { getEmail } = userModel;
const { getCurrency } = preferencesModel;

export const selectResultsState = (state) => state.results;

export const selectFilters = createSelector(selectResultsState, (state) => state.filters);

export const currentSortSelector = createSelector(selectResultsState, (state) => state.sortingType);
export const selectQuotesAndTopScoreIds = createSelector(selectResultsState, (state) => {
  return {
    quotes: state.quotes,
    topQuotesIndexes: state.topQuotesIndexes,
    indexIds: state.indexIds
  };
});

export const selectQuotes = createSelector(
  selectQuotesAndTopScoreIds,
  ({ quotes, topQuotesIndexes }) => {
    return {
      ...quotes,
      [topQuotesIndexes.quickestId]: {
        ...quotes[topQuotesIndexes.quickestId],
        accolade: 'Quickest'
      },
      [topQuotesIndexes.cheapestId]: {
        ...quotes[topQuotesIndexes.cheapestId],
        accolade: 'Cheapest'
      },
      [topQuotesIndexes.bestValueId]: {
        ...quotes[topQuotesIndexes.bestValueId],
        accolade: 'Best value'
      }
    };
  }
);

export const isPollingSelector = createSelector(selectResultsState, (state) => state.isPolling);

export const activeFiltersSelector = createSelector(selectFilters, (state) =>
  getActiveFilters(state)
);

export const resultsFiltersCount = createSelector(
  selectResultsState,
  (state) => state.filtersCount
);

export const filteredResultsSelector = createSelector(
  selectResultsState,
  (state) => state?.indexIds
);

export const selectedSortType = createSelector(selectResultsState, (state) => state.sortingType);

export const selectQuotesIdsList = createSelector(
  selectResultsState,
  activeFiltersSelector,
  selectedSortType,
  (state, filters, sortType) => {
    if (filters.length > 0) {
      if (sortType === 'bestValue') {
        // push the guaranteed to top
        return getTopWithGuaranteedCapacityQuotes({ main: state.indexIds }, []);
      }
      // filter is active
      return { main: state.indexIds, others: [] };
    }
    if (sortType !== 'bestValue') {
      // sorting is active
      return { main: state.indexIds, others: [] };
    }

    const indexIds = appendTopValues(state.indexIds, state.topQuotesIndexes);
    const result = resultsByVendorByKey(indexIds, state.quotes);
    const resultWithGuaranteed = getTopWithGuaranteedCapacityQuotes(result, state.quotes);

    return resultWithGuaranteed;
  }
);

export const bestScoreQuotesValues = createSelector(
  selectQuotesAndTopScoreIds,
  activeFiltersSelector,
  ({ quotes, indexIds, topQuotesIndexes }, activeFilterSelector) => {
    const topQuotes =
      activeFilterSelector.length !== 0
        ? getRankedQuotes(quotes, indexIds)
        : { ...topQuotesIndexes };

    return {
      bestValueQuote: quotes[topQuotes.bestValueId]?.sortValue,
      cheapestQuote: quotes[topQuotes.cheapestId]?.sortValue,
      quickestQuote: quotes[topQuotes.quickestId]?.sortValue,
      greenestQuote: quotes[topQuotes.greenestId]?.sortValue
    };
  }
);

export const resultsCountSelector = createSelector(
  selectResultsState,
  ({ uniqueResults, resultsCount }) => ({
    uniqueResults,
    resultsCount
  })
);

export const servicesReferrerSelector = createSelector(
  selectResultsState,
  (state) => state.servicesReferrer
);

export const filtersViewSelector = createSelector(
  selectResultsState,
  (state) => state.displayFiltersView
);

export const quoteBookedAnalyticsSelector = (quoteId) =>
  createSelector(
    selectResultsState,
    selectQuotes,
    getRfqSelector,
    activeFiltersSelector,
    selectQuotesIdsList,
    vendorsSelector,
    (
      state,
      quotes = {},
      shipment = {},
      activeFilters = [],
      idsList = { main: [], others: [] },
      vendors
    ) => {
      const filters = {};

      activeFilters.forEach((filter) => {
        filters[filter.key] = {
          range: filter.range?.toString(),
          values: filter.filters?.toString()
        };
      });

      const [tileData] = quotesAnalyticsData({ [quoteId]: quotes[quoteId] }, [quoteId]) || {};
      const quote = quotes[quoteId] || {};
      tileData.position = idsList.main.concat(idsList.others).indexOf(quoteId) + 1;
      tileData.sellerBadges = get(vendors[tileData.sellerKey], 'partyIdentifiers.badges', []);
      return {
        filters,
        tileData,
        currentSort: state.sortingType,
        amount: {
          amount: quote.priceIndicator?.totalCharge?.value,
          currency: quote.priceIndicator?.totalCharge?.currencyID
        },
        origin: {
          pickupDate: shipment.pickupEvent?.eventDate?.scheduledDateTime,
          addressOrPort: {
            code: shipment.originLocation?.locationCode,
            type: shipment.originLocation?.locationTypeCode,
            label: get(shipment, 'originLocation.addressLines[0]')
          },
          country: shipment.originLocation?.countryID?.value
        },
        destination: {
          addressOrPort: {
            code: shipment.destinationLocation?.locationCode,
            label: get(shipment, 'destinationLocation.addressLines[0]'),
            type: shipment.destinationLocation?.locationTypeCode
          },
          country: shipment.destinationLocation?.countryID?.value
        },
        mode: quote.transportMode,
        load: getShipmentLabel(shipment),
        redirectUrl: quote.URIReferences?.bookingConfirm?.uri
      };
    }
  );

export const quotesAnalyticsDataSelector = createSelector(
  selectQuotes,
  selectQuotesIdsList,
  (quotes, { main = [], others = [] }) => quotesAnalyticsData(quotes, main.concat(others))
);

export const searchResultsAnalyticsData = (rfqKey) =>
  createSelector(
    selectResultsState,
    getRfqSelector,
    getCurrency,
    getEmail,
    quotesAnalyticsDataSelector,
    activeFiltersSelector,
    (state, quote, currency, email, tilesData, activeFilters) => {
      const data = resultsCompletedAnalyticsData(state, quote);
      const filters = {};
      activeFilters.forEach((filter) => {
        filters[filter.key] = {
          range: filter.range?.toString(),
          values: filter.filters?.toString()
        };
      });
      return {
        ...data,
        email,
        currency,
        rfqKey,
        tilesData,
        currentSort: state.sortingType,
        filters
      };
    }
  );

export const singleQuoteSelector = (quoteId) =>
  createSelector(selectResultsState, (state) => state.quotes[quoteId]);

export const loaderSelector = createSelector(selectResultsState, (state) => state.showLoader);
