import React from 'react';
import { connect } from 'react-redux';
import * as selectors from 'financialSharedComponents/selectors';
import StripeTokenizeForm from './StripeTokenizeForm';
import BrainTreeTokenizeForm from './BrainTreeTokenizeForm';
import { SUPPORTED_TOKENIZE_FORMS_PSP_NAMES } from '../constants';
import { Elements, StripeProvider } from 'react-stripe-elements';
import * as Actions from '../actions';

const CreditCardTokenizeFormFactory = ({
  pspName,
  brainTreeApiKey,
  stripeApiKey,
  onSubmitToken,
  isSavingToken,
  registerToAutoPay,
  payButtonTitle,
  fetchPaymentFeeConfig
}) => {

  React.useEffect(() => {
    fetchPaymentFeeConfig();
  }, []);

  return (
    <React.Fragment>
      {pspName === SUPPORTED_TOKENIZE_FORMS_PSP_NAMES.BRAIN_TREE && brainTreeApiKey && (
        <BrainTreeTokenizeForm
          registerToAutoPay={registerToAutoPay}
          payButtonTitle={payButtonTitle}
          isSavingToken={isSavingToken}
          onSubmitToken={onSubmitToken}
          tokenizeKey={brainTreeApiKey}
        />
      )}
      {pspName === SUPPORTED_TOKENIZE_FORMS_PSP_NAMES.STRIPE && stripeApiKey && (
        <StripeProvider apiKey={stripeApiKey}>
          <Elements>
            <StripeTokenizeForm
              payButtonTitle={payButtonTitle}
              isSavingToken={isSavingToken}
              onSubmitToken={onSubmitToken}
            />
          </Elements>
        </StripeProvider>
      )}
    </React.Fragment>
  );
};

CreditCardTokenizeFormFactory.propTypes = {};

const mapStateToProps = (state) => ({
  stripeApiKey: selectors.stripeApiKeySelector(state),
  isSavingToken: selectors.paymentMethodTypesBusySelector(state),
  brainTreeApiKey: selectors.brainTreeApiKeySelector(state),
  pspName: selectors.selectedPaymentMethodPSPNameSelector(state)
});

const mergeProps = (stateProps, dispatchProps, ownProps) => {
  return {
    ...stateProps,
    ...dispatchProps,
    ...ownProps
  };
};

const mapDispatchToProps = (dispatch) => ({
  fetchPaymentFeeConfig: () => dispatch(Actions.fetchPaymentFeeConfigAction())
});

export default connect(mapStateToProps,mapDispatchToProps, mergeProps)(CreditCardTokenizeFormFactory);
