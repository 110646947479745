import { put, call, takeLatest, select, all } from 'redux-saga/effects';
import * as AT from './actionTypes';
import * as Actions from './actions';

export function* fetchBankDetails(action) { 
  try {
    const response = yield call(action.payload)   
    yield put(Actions.fetchBankDetailsSuccess({ bankDetails: response.data }));
  } catch (err) {
    yield put(Actions.fetchBankDetailsFail());
  }
}

export default function* watcher() {
  yield all([takeLatest(AT.FETCH_BANK_DETAILS, fetchBankDetails)]);
}
