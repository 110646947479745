import styled, { css } from 'styled-components/macro';
import { tokens, Card, FormItem } from '@freightos/design-system';
import {
  fdsComponentSpacingLg,
  fdsColorPrimaryGray30,
  fdsColorPrimaryGray70,
  fdsFontWeightBold,
  fdsH5FontSize,
  fdsSizeXl,
  fdsComponentPrimaryColorError,
  fdsFontSizeSm,
  fdsComponentSecondaryColorDisabled
} from '@freightos/design-system/dist/tokens';

export const mobileOverLayStyle = {
  top: '0px !important',
  left: '0px !important',
  width: '100vw',
  // height: 'calc(100vh - 60px)',
  // maxHeight: 'calc(100vh - 60px)',
  height: '100vh',
  maxHeight: '100vh',
  overflow: 'hidden',
  position: 'fixed'
};

export const StyledCategoryCard = styled(Card)`
  &&& {
    box-shadow: ${tokens.fdsBoxShadowSm};
    ${({ isMobile, height }) =>
      !isMobile
        ? css`
            max-height: ${height || 300}px !important;
            overflow-y: auto !important;
          `
        : css`
            //height: calc(var(--search-vh, 1vh) * 100 - 78px) !important;
            //max-height: calc(var(--search-vh, 1vh) * 100 - 78px) !important;
            //height: 100vh !important;
            //max-height: 100vh !important;
            height: calc(var(--search-vh, 1vh) * 100) !important;
            max-height: calc(var(--search-vh, 1vh) * 100) !important;
          `}

    ${({ hasFooter }) =>
      hasFooter &&
      css`
        padding-bottom: 0;
      `}

    h3 {
      font-family: Rajdhani, 'Open sans', sans-serif;
      margin: 0 0 16px 0;
      font-size: ${fdsH5FontSize};
      font-weight: ${fdsFontWeightBold};
    }

    .ant-dropdown-menu-body {
      margin: 0;
      width: ${({ width }) => width || 500}px;
      ${({ isMobile }) =>
        isMobile
          ? css`
              width: 100%;
            `
          : css``};
    }

    .ant-form-item {
      margin-bottom: ${({ isMobile }) => (isMobile ? '10px' : 0)};

      label {
        margin-bottom: 4px;
      }

      .ant-form-item-label {
        line-height: 18px;
      }
    }
  }
`;

export const Divider = styled.hr`
  margin: ${fdsSizeXl} 0;
  border: none;
  border-top: 1px solid ${fdsColorPrimaryGray30};
  height: 1px;
`;

export const CategoryOverlayWrapper = styled.div`
  margin: 0 -6px ${fdsComponentSpacingLg};
  padding: 0 6px;
  overflow: auto;
  overflow-x: hidden;
  ${({ isMobile }) =>
    isMobile
      ? css``
      : css`
          flex: 1;
        `}
  ${({ isMobile, hasFooter }) =>
    isMobile && hasFooter
      ? css`
          padding: 0 6px 61px;
        `
      : css`
          padding: 0 6px;
        `}
}
`;

export const MobileClose = styled.div`
  display: flex;
  justify-content: flex-end;
  position: absolute;
  right: 16px;
  top: 16px;
  z-index: 1001;
`;

export const ViewHolder = styled.div`
  display: flex;
  flex-direction: column;
  ${({ isMobile }) =>
    isMobile
      ? css`
          //height: calc(var(--search-vh, 1vh) * 100 - 154px);
          //height: calc(var(--search-vh, 1vh) * 100);
          height: calc(100vh - 32px) !important;
          min-height: calc(100vh - 32px) !important;
          //height: calc(100vh - 55px);
          //min-height: calc(100vh - 55px);
        `
      : ''}
`;

export const FieldError = styled.span`
  color: ${fdsComponentPrimaryColorError};
  font-size: ${fdsFontSizeSm};
`;

export const StyledFormItem = styled(FormItem)`
  .ant-form-item-control {
    line-height: 21px;
  }
`;

export const MobileSectionTitle = styled.h5`
  font-size: 18px;
`;

export const MobileSectionHeader = styled.div`
  margin: 0 0 20px 0;
  border-bottom: 1px solid ${fdsComponentSecondaryColorDisabled};
`;

export const MobileSectionSubTitle = styled.h6`
  font-size: 15px;
  font-weight: normal;
  color: ${fdsColorPrimaryGray70};
  margin: 0 0 12px 0;
`;

export const MobilePortalContainer = styled.div`
  position: fixed;
  padding: 16px 0 0;
  top: 0;
  left: 0;
  z-index: 1000;
  height: 100vh;
  width: 100vw;
  background: #fff;
`;

export const MobilePortalMain = styled.div`
  overflow: auto;
  height: calc(100vh - 16px);
  padding: 0 16px 60px;
`;

export const MobilePortalFooter = styled.div`
  bottom: 0;
  position: sticky;
  height: 60px;
  width: calc(100vw + 16px);
  z-index: 2;
  padding: 0 0 0 16px;
`;

export const AddressBookItem = styled.div`
  line-height: 21px;

  > div:first-child {
    margin: 0 8px 0 0;
  }
  display: flex;
  align-items: flex-start;

  strong {
    font-weight: 600;
  }
`;

export const spacingContainerHorizontal = ({ spacing }) => `
  & > *:not(:last-child) {
    margin-right: ${spacing};
  }
`;

export const spacingContainerVertical = ({ spacing }) => `
  & > *:not(:last-child) {
    margin-bottom: ${spacing};
  }
`;

export const RowContainerCentered = styled.div`
  display: flex;
  align-items: center;
  ${spacingContainerHorizontal}
`;

export const ColumnContainerCentered = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  ${spacingContainerVertical}
`;
