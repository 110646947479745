import { connect } from 'microfronts-redux';
import React from 'react';
import { Input, Option, Select } from '@freightos/design-system';
import { get, getOr, toString } from 'lodash/fp';

import { getMeasurements, getQuoteLoad, isMobileSelector } from 'slimSearch/selectors';
import { updateField } from 'slimSearch/actions';
import { LOAD_VALIDATION } from 'slimSearch/constants';
import { getLoadType, numberFormat } from 'slimSearch/utils';

import { FieldError } from 'slimSearch/sharedStyles';

const MeasurementUnitFieldInput = ({
  path,
  units,
  load,
  updateField,
  selectWidth = 66,
  measurementType = '',
  measurements = {},
  isMobile,
  index = null,
  pristine,
  withUnits = true,
  placeholder
}) => {
  const fieldError = ({ load, path, measurements, measurementType, index }) => {
    const value = getOr(0, `${path}.value`, load[load.type]);
    const units = measurementType === 'weight' ? measurements.weight : measurements.dimensions;
    const isByTotals = load[load.type].calculateByTotals;

    if (!value || !isFinite(value)) {
      return 'Required';
    }

    if (isByTotals) {
      if (value > LOAD_VALIDATION.totals[measurementType].max[measurements[measurementType]]) {
        return (
          'Max ' +
          numberFormat(LOAD_VALIDATION.totals[measurementType].max[measurements[measurementType]]) +
          measurements[measurementType].toUpperCase()
        );
      }

      if (value < LOAD_VALIDATION.totals[measurementType].min[measurements[measurementType]]) {
        return (
          'Min ' +
          numberFormat(LOAD_VALIDATION.totals[measurementType].min[measurements[measurementType]]) +
          measurements[measurementType].toUpperCase()
        );
      }
    } else {
      const loadType = getLoadType(load[load.type]?.packages[index]?.packagingType);

      if (value > LOAD_VALIDATION[measurementType][loadType].max[units]) {
        return (
          'Max ' +
          numberFormat(LOAD_VALIDATION[measurementType][loadType].max[units]) +
          units.toUpperCase()
        );
      }

      if (value < LOAD_VALIDATION[measurementType][loadType].min[units]) {
        return (
          'Min ' +
          numberFormat(LOAD_VALIDATION[measurementType][loadType].min[units]) +
          units.toUpperCase()
        );
      }
    }

    return '';
  };

  const shouldShowError = () => {
    if (pristine === false) {
      return true;
    } else {
      if (
        fieldError({ path, load, measurements, measurementType, index }).startsWith('Max') ||
        fieldError({ path, load, measurements, measurementType, index }).startsWith('Min')
      ) {
        return true;
      }
    }

    return false;
  };
  return (
    <div>
      <Input
        placeholder={placeholder ? placeholder : ''}
        size={isMobile ? 'large' : 'default'}
        type="number"
        data-test-id={`MeasurementUnitFieldInputComponent-${measurementType}`}
        min={0.1}
        step=".5"
        addonAfter={
          withUnits ? (
            <Select
              size={isMobile ? 'large' : 'default'}
              getPopupContainer={(trigger) => trigger.parentElement}
              value={get(`${measurementType}`, measurements)}
              style={{ width: isMobile ? 'auto' : selectWidth }}
              onChange={(val) => updateField(`quote.load.measurements.${measurementType}`, val)}
            >
              {units.map((option) => (
                <Option
                  key={option}
                  data-test-id={`MeasurementUnitFieldInputComponent-${measurementType}-${option.toUpperCase()}`}
                  value={option}
                >
                  {option.toUpperCase()}
                </Option>
              ))}
            </Select>
          ) : null
        }
        value={toString(getOr(1, `${path}.value`, load[load.type]))}
        onChange={(event) =>
          updateField(`quote.load.${load.type}.${path}.value`, parseFloat(event.target.value))
        }
        onBlur={() =>
          updateField(
            `quote.load.${load.type}.${path}.value`,
            parseFloat(getOr(1, `${path}.value`, load[load.type]).toFixed(2))
          )
        }
      />
      {shouldShowError() && (
        <FieldError>
          {fieldError({ path, load, measurements, measurementType, index })}&nbsp;
        </FieldError>
      )}
    </div>
  );
};

const mapStateToProps = (store) => ({
  load: getQuoteLoad(store),
  isMobile: isMobileSelector(store),
  measurements: getMeasurements(store),
  pristine: store.search.pristine.load
});

export default connect(mapStateToProps, {
  updateField
})(MeasurementUnitFieldInput);
