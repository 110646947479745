import { LOAD_TYPES } from 'slimSearch/constants';
import { getContainersCalculated, getTotalsCalculated } from 'slimSearch/utils';
import { getUnitsTotals, unitTotalsString } from 'slimSearch/utils/calculateTotals.utils';
import { t } from 'utils/translationProvider';

export function formatLoad(touched, load, measurements) {
  // eslint-disable-next-line no-console
  if (touched) {
    if (load.type === LOAD_TYPES.CONTAINER.value) {
      return getContainersCalculated(load.container);
    }

    if (load.type === LOAD_TYPES.LOOSE_CARGO.value) {
      if (load.looseCargo?.calculateByTotals) {
        return getTotalsCalculated(load.looseCargo?.totals, measurements);
      } else {
        return unitTotalsString(
          getUnitsTotals(load.looseCargo?.packages, measurements),
          measurements
        );
      }
    }
  }

  return t('search/what_are_you_shipping', 'What are you shipping?');
}
