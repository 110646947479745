import { Icon } from '@freightos/design-system';
import { fdsColorPrimaryGray90 } from '@freightos/design-system/dist/tokens';
import React, { useState } from 'react';
import { ResultsScreenValues } from 'slimSearch/constants';
import styled, { css } from 'styled-components/macro';

const CollapseComponent = ({
  disabled = false,
  title,
  children,
  open = false,
  key,
  className = ''
}) => {
  const [isOpen, setIsOpen] = useState(open);
  return (
    <CollapseContainer className={className} key={key}>
      <React.Fragment>
        <CollapseHeader onClick={() => setIsOpen(!isOpen)}>
          <CollapseHeaderTitle>{title}</CollapseHeaderTitle>
          <CollapseHeaderIcon isOpen={isOpen} disabled={disabled}>
            <Icon type="caret-up" />
          </CollapseHeaderIcon>
        </CollapseHeader>
        <CollapseContent isOpen={isOpen} disabled={disabled}>
          {children}
        </CollapseContent>
      </React.Fragment>
    </CollapseContainer>
  );
};

export default CollapseComponent;

const CollapseContainer = styled.div`
  width: 100%;
  margin-top: 18px;
  &:first-child {
    margin-top: 0px;
  }
  @media (max-width: ${ResultsScreenValues.tablet - 1}px) {
    padding-right: 32px;
    padding-left: 4px;
  }
`;

const CollapseHeaderIcon = styled.div`
  transition: all 0.15s ease-in-out;
  cursor: pointer;
  ${({ isOpen }) =>
    isOpen
      ? css``
      : css`
          transform: rotate(-180deg);
        `}
  ${({ disabled }) =>
    disabled
      ? css`
          display: none;
        `
      : null}
`;

const CollapseContent = styled.div`
  ${({ disabled, isOpen }) =>
    disabled || isOpen
      ? css`
          max-height: 800px;
        `
      : css`
          max-height: 0px;
          overflow: hidden;
          transition: max-height 0.15s ease-out;
        `}
`;

const CollapseHeaderTitle = styled.div`
  font-size: 14px;
  color: ${fdsColorPrimaryGray90};
  font-weight: bold;
  line-height: 32px;
`;

const CollapseHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;
