import React from 'react';
import { pullAt } from 'lodash/fp';
import styled, { css } from 'styled-components/macro';
import { connect } from 'microfronts-redux';
import { Icon } from '@freightos/design-system';
import { fdsErrorColor } from '@freightos/design-system/dist/tokens';
import { updateField } from 'slimSearch/actions';
import { getLoadTypeLabel, getPackageSizes, validLoadPackage } from 'slimSearch/utils';
import { getMeasurements, isMobileSelector } from 'slimSearch/selectors';
import { LOAD_TYPES, SECTIONS } from 'slimSearch/constants';

const CollapsedLoad = ({ load, loadType, pkg, index, updateField, measurements }) => {
  const hasError =
    loadType !== LOAD_TYPES.CONTAINER.value ? validLoadPackage(pkg, measurements) : false;

  return (
    <CollapsedPackage onClick={() => updateField(`quote.load.active`, index)}>
      <LoadTitle data-test-id={`${SECTIONS.LOAD}-collapsed-load-title-${index}`} error={hasError}>
        Load {index + 1}{' '}
        {hasError && <Icon type="alert-circled" color="@fds-error-color" size="small" />}
      </LoadTitle>
      <div data-test-id={`${SECTIONS.LOAD}-collapsed-load-type-${index}`}>
        {pkg.quantity} {getLoadTypeLabel(pkg.packagingType)}
      </div>
      <div data-test-id={`${SECTIONS.LOAD}-collapsed-load-size-${index}`}>
        {getPackageSizes(pkg, measurements)}
      </div>
      <div>
        <StyledIcon
          color="@fds-error-color"
          type="trash"
          data-test-id={`${SECTIONS.LOAD}-collapsed-load-delete-btn-${index}`}
          onClick={() =>
            updateField(`quote.load.${loadType}.packages`, pullAt([index], load[loadType].packages))
          }
        />
      </div>
    </CollapsedPackage>
  );
};

const CollapsedPackage = styled.div`
  box-shadow: 0 0 10px rgba(35, 37, 55, 0.12);
  border-radius: 4px;
  padding: 6px 10px;
  margin: 8px 0;
  cursor: pointer;
  display: grid;
  //grid-template-columns: 76px 152px 240px 16px;
  grid-template-columns: 16% 33% 48% 3%;
  grid-template-rows: 1fr;
  grid-column-gap: 0;
  grid-row-gap: 0;
`;

const StyledIcon = styled(Icon)`
  cursor: pointer;
`;

const LoadTitle = styled.div`
  ${({ error }) =>
    error
      ? css`
          color: ${fdsErrorColor};
        `
      : ''}
`;

const mapStateToProps = (store) => ({
  load: store.search.quote.load,
  measurements: getMeasurements(store),
  isMobile: isMobileSelector(store)
});

export default connect(mapStateToProps, {
  updateField
})(CollapsedLoad);
