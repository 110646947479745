import { PACKAGE_NAME } from 'constants/app.constants';

// apis saga
export const DO_GET_REQUEST = `${PACKAGE_NAME}/DO_GET_REQUEST`;
export const DO_POST_REQUEST = `${PACKAGE_NAME}/DO_POST_REQUEST`;
export const DO_PUT_REQUEST = `${PACKAGE_NAME}/DO_PUT_REQUEST`;

// Payment Methods types
export const FETCH_SUPPORTED_PAYMENT_METHOD_TYPES = `${PACKAGE_NAME}/FETCH_SUPPORTED_PAYMENT_METHOD_TYPES`;
export const FETCH_SUPPORTED_PAYMENT_METHOD_TYPES_SUCCESS = `${PACKAGE_NAME}/FETCH_SUPPORTED_PAYMENT_METHOD_TYPES_SUCCESS`;
export const FETCH_SUPPORTED_PAYMENT_METHOD_TYPES_FAIL = `${PACKAGE_NAME}/FETCH_SUPPORTED_PAYMENT_METHOD_TYPES_FAIL`;
export const SELECTED_PAYMENT_METHOD_CHANGED = `${PACKAGE_NAME}/SELECTED_PAYMENT_METHOD_CHANGED`;

// Payment Methods
export const FETCH_PAYMENT_METHODS = `${PACKAGE_NAME}/FETCH_PAYMENT_METHODS`;
export const FETCH_PAYMENT_METHODS_SUCCESS = `${PACKAGE_NAME}/FETCH_PAYMENT_METHODS_SUCCESS`;
export const FETCH_PAYMENT_METHODS_FAIL = `${PACKAGE_NAME}/FETCH_PAYMENT_METHODS_FAIL`;

// BANK DETAILS
export const FETCH_BANK_DETAILS = `${PACKAGE_NAME}/FETCH_BANK_DETAILS`;
export const FETCH_BANK_DETAILS_SUCCESS = `${PACKAGE_NAME}/FETCH_BANK_DETAILS_SUCCESS`;
export const FETCH_BANK_DETAILS_FAIL = `${PACKAGE_NAME}/FETCH_BANK_DETAILS_FAIL`;

// CARD MANAGEMENT
export const FETCH_USER_CARDS = `${PACKAGE_NAME}/FETCH_USER_CARDS`;
export const FETCH_USER_CARDS_SUCCESS = `${PACKAGE_NAME}/FETCH_USER_CARDS_SUCCESS`;
export const FETCH_USER_CARDS_FAIL = `${PACKAGE_NAME}/FETCH_USER_CARDS_FAIL`;
export const SAVE_CARD_TOKEN = `${PACKAGE_NAME}/SAVE_CARD_TOKEN`;
export const SAVE_CARD_TOKEN_SUCCESS = `${PACKAGE_NAME}/SAVE_CARD_TOKEN_SUCCESS`;
export const SAVE_CARD_TOKEN_FAIL = `${PACKAGE_NAME}/SAVE_CARD_TOKEN_FAIL`;
export const SET_IS_CHANGING_PAYMENT_METHOD = `${PACKAGE_NAME}/SET_IS_CHANGING_PAYMENT_METHOD`;

// INIT PAYMENT DATA
export const INIT_PAYMENT_DATA = `${PACKAGE_NAME}/INIT_PAYMENT_DATA`;

// PAYMENT
export const DO_PAYMENT = `${PACKAGE_NAME}/DO_PAYMENT`;
export const DO_PAYMENT_SUCCESS = `${PACKAGE_NAME}/DO_PAYMENT_SUCCESS`;
export const DO_PAYMENT_FAIL = `${PACKAGE_NAME}/DO_PAYMENT_FAIL`;

export const SET_DEVICE_DATA_CORRELATION_ID = `${PACKAGE_NAME}/SET_DEVICE_DATA_CORRELATION_ID`;
// PAYMENT LINK
export const DO_PAYMENT_LINK_PAYMENT = `${PACKAGE_NAME}/DO_PAYMENT_LINK_PAYMENT`;
export const DO_PAYMENT_LINK_PAYMENT_SUCCESS = `${PACKAGE_NAME}/DO_PAYMENT_LINK_PAYMENT_SUCCESS`;
export const DO_PAYMENT_LINK_PAYMENT_FAIL = `${PACKAGE_NAME}/DO_PAYMENT_LINK_PAYMENT_FAIL`;

// PAYMENT FEE
export const FETCH_PAYMENT_FEE_CONFIG = `${PACKAGE_NAME}/FETCH_PAYMENT_FEE_CONFIG`;
export const FETCH_PAYMENT_FEE_CONFIG_SUCCESS = `${PACKAGE_NAME}/FETCH_PAYMENT_FEE_CONFIG_SUCCESS`;
export const FETCH_PAYMENT_FEE_CONFIG_FAIL = `${PACKAGE_NAME}/FETCH_PAYMENT_FEE_CONFIG_FAIL`;
