import { all, call, put, takeLatest, takeEvery, select, take, fork } from 'redux-saga/effects';
import { head, toLower } from 'lodash/fp';
import { Message } from '@freightos/design-system';
import { doGetRequest } from 'propera/HTTP';
import { getApiCall } from 'propera/HTTP';
import { push, replace } from 'microfronts-router';
import { flagSelector } from 'feature-flagging';
import {
  constants as AuthConstants,
  actions as AuthActions,
  actionTypes as AuthActionTypes
} from 'propera/auth';
import { actions as monitoringActions } from 'propera/monitoring';
import siteConfig from 'propera/siteConfig';
import { actions as analyticsActions } from 'propera/analytics';
import preferencesModel from 'propera/preferences';
import userModel from 'propera/user';
import { AuthUiActions } from 'propera/authUI';

import {
  GET_LOCATIONS,
  GET_PORTS,
  GET_WAREHOUSES,
  GET_COUNTRIES,
  SEARCH,
  EDIT_SEARCH,
  CANCEL_EDIT_SEARCH,
  SEARCH_SERVICES_COMPLETED,
  GET_RFQ,
  GET_RFQ_SUMMARY,
  getRfq,
  updateField,
  searchAnalytics,
  UPDATE_FIELD,
  showServicesModal,
  hideServicesModal,
  PROGRESS_STEP_CLICK,
  saveQuoteFromSearchUrl,
  UPDATE_RFQ_FROM_SEARCH_PARAMS,
  updateRfqFromSearchParams,
  triggerWidgetSearch,
  TRIGGER_WIDGET_SEARCH,
  setEditMode,
  updateRfq,
  UPDATE_RFQ,
  setOldRfqFlow,
  clearServicePredictions,
  GET_ADDRESS_BOOK_ADDRESSES,
  searchServiceCompleted,
  GET_SHIPMENTS_METADATA,
  GET_SOA_INFO,
  GET_CREDIT_INFO
} from 'slimSearch/actions';
import {
  rootStateSelector,
  quoteSelector,
  slimSearchSelector,
  getQuoteLoad,
  searchSessionIdSelector,
  base64Rfq,
  isEditMode,
  getSearchMode,
  rfqFromQuoteSelector,
  defaultsInitialized
} from 'slimSearch/selectors';
import { loadHas45Container } from 'slimSearch/utils';
import { endpointConstructor, paramObjectToUri, getDefaultValue, getTLD } from 'utils';
import {
  ANALYTICS_EVENT_NAME,
  WIDGET_SEARCH_WITH_URL,
  ENDPOINTS,
  FULFILMENT_CENTER_COMPANIES,
  LCL_LOAD_DEFAULTS,
  LOAD_TYPES,
  CONTAINER_TYPES_VALUES,
  PAGE_ROUTE,
  ldFlags,
  MOST_COMMON_ORIGIN_COUNTRIES,
  MOST_COMMON_DESTINATION_COUNTRIES
} from 'slimSearch/constants';
import { hasPallets } from 'slimSearch/utils/recommendedServices.utils';
import { mapRfqToQuote } from 'slimSearch/utils/mapOpenFreight.utils';

import {
  cleanResults,
  startPolling,
  setServicesReferrer,
  getSingleQuote,
  actionTypes as resultsActionTypes,
  getQuoteAndShipmentSuccess,
  showLoaderOnResults
} from 'results/results.actions';
import { getRfqKeyFromUrl } from 'results/results.utils';
import { isPollingSelector, singleQuoteSelector } from 'results/results.selectors';

import { PACKAGE_NAME } from '../constants';
import { getSoaAppId } from '../utils';
import { AuthUiConstants } from 'propera/authUI';

const { VIEWS } = AuthUiConstants;

const { loginModal } = AuthUiActions;
const { getLengthUnit, getVolumeUnit, getWeightUnit, getCurrency } = preferencesModel;

const { isLoggedIn, getBusinessKey, userTypeSelector } = userModel;
const isFromWidget = new URLSearchParams(document.location.search).get('widget') === 'true';
const isSectionPristine = (action, pristine) =>
  action.payload.path === 'activeSection' &&
  action?.meta?.section &&
  pristine[action?.meta?.section] === true;

// handle amazon 48'x40' pallets pre-select
const isAmazonDestination = (action, destination) => {
  const isAmazonBrand =
    destination?.destinationLocation?.brand === FULFILMENT_CENTER_COMPANIES.AMZ.key &&
    action.payload.value === FULFILMENT_CENTER_COMPANIES.AMZ.key;
  const changeFromTotalsToUnits =
    action.payload.path === 'quote.load.looseCargo.packages[0]' &&
    action.payload.value?.packagingType === LCL_LOAD_DEFAULTS.pallets.packagingType;
  const changeFromBoxesToPallets =
    action.payload.path === 'quote.load.looseCargo.packages' &&
    action.payload.value[0]?.packagingType === LCL_LOAD_DEFAULTS.pallets.packagingType;
  const pathIsBrand = action.payload.path === 'quote.destination.destinationLocation.brand';
  const changeFclToLcl =
    action.payload.path === 'quote.load.type' && action.payload.value === 'looseCargo';

  return (
    isAmazonBrand &&
    (changeFromTotalsToUnits || changeFromBoxesToPallets || pathIsBrand || changeFclToLcl)
  );
};

export function* setDefaults() {
  const q = new URLSearchParams(window.location.search).get('q');
  if (q) {
    yield put(updateRfqFromSearchParams());
    return;
  }

  const getPreselectedOriginCountry = yield select(siteConfig.getPreselectedOriginCountry);
  const getPreselectedDestinationCountry = yield select(
    siteConfig.getPreselectedDestinationCountry
  );

  const originCountry =
    getDefaultValue('originCountry') ??
    getPreselectedOriginCountry ??
    head(MOST_COMMON_ORIGIN_COUNTRIES);
  const destinationCountry =
    getDefaultValue('destinationCountry') ??
    getPreselectedDestinationCountry ??
    head(MOST_COMMON_DESTINATION_COUNTRIES);

  if (originCountry) {
    yield put(updateField('quote.origin.originLocation.countryID.value', originCountry));
  }

  if (destinationCountry) {
    yield put(
      updateField('quote.destination.destinationLocation.countryID.value', destinationCountry)
    );
  }

  updateField('defaultsInitialized', true);
}

export function* initSearchSharedComponent() {
  yield fork(getBrandLocations);
  yield fork(getCountries);

  const lengthUnit = yield select(getLengthUnit);
  const volumeUnit = yield select(getVolumeUnit);
  const weightUnit = yield select(getWeightUnit);

  yield put(
    updateField('quote.load.measurements', {
      volume: toLower(volumeUnit),
      weight: toLower(weightUnit),
      dimensions: toLower(lengthUnit) === 'inch' ? 'in' : toLower(lengthUnit)
    })
  );

  const currency = yield select(getCurrency);
  yield put(updateField('quote.pricePreference.requestCurrency.value', currency));

  //if we have q then atob it and update the summary without the summary success
  yield put(clearServicePredictions());

  const isInitialized = yield select(defaultsInitialized);
  if (!isInitialized) {
    yield call(setDefaults);
  }
}

export function* getLocations(action) {
  const isPorts = action.type === GET_PORTS.type;
  const endpoint = isPorts ? ENDPOINTS.portsAutocomplete : ENDPOINTS.addressesAutocomplete;

  try {
    const data = yield call(
      doGetRequest,
      endpointConstructor(endpoint, {
        country: action.payload.country,
        searchTerm: action.payload.searchTerm
      })
    );

    const successAction = isPorts ? GET_PORTS.SUCCESS : GET_LOCATIONS.SUCCESS;
    const failureAction = isPorts ? GET_PORTS.FAILURE : GET_LOCATIONS.FAILURE;
    const payloadType = isPorts ? 'ports' : 'addresses';

    if (data) {
      yield put({
        type: successAction,
        payload: { [payloadType]: data },
        meta: action.meta
      });
    } else {
      yield put({
        type: failureAction
      });
    }
  } catch (e) {
    yield put(monitoringActions.exceptionReporter(e));
  }
}

export function* getBrandLocations() {
  try {
    const data = yield call(doGetRequest, ENDPOINTS.brandLocations);

    if (data) {
      yield put({
        type: GET_WAREHOUSES.SUCCESS,
        payload: { data }
      });
    } else {
      yield put({
        type: GET_WAREHOUSES.FAILURE
      });
    }
  } catch (e) {
    yield put(monitoringActions.exceptionReporter(e));
  }
}

export function* getAddressBookAddresses({ payload }) {
  const addressBookAddressesInSearch = yield select(
    flagSelector(ldFlags.ADDRESS_BOOK_ADDRESSES_IN_SEARCH)
  );

  if (addressBookAddressesInSearch) {
    const { type, country } = payload;
    const getType = type === 'pickup' ? 'origin' : 'destination';
    const businessKey = yield select(getBusinessKey);
    const loggedIn = yield select(isLoggedIn);
    let addresses;

    try {
      if (!loggedIn) {
        yield put({
          type: GET_ADDRESS_BOOK_ADDRESSES.SUCCESS,
          payload: { addresses: [], type: getType }
        });
      }
      if (businessKey) {
        addresses = yield call(getApiCall, {
          payload: {
            path: endpointConstructor(ENDPOINTS.addressBook, {
              businessKey,
              type,
              country
            })
          }
        });
      }

      if (addresses) {
        yield put({
          type: GET_ADDRESS_BOOK_ADDRESSES.SUCCESS,
          payload: { addresses, type: getType }
        });
      }
    } catch (e) {
      yield put({
        type: GET_ADDRESS_BOOK_ADDRESSES.SUCCESS,
        payload: { addresses: [], type: getType }
      });
    }
  }
}

export function* getCountries() {
  try {
    const data = yield call(doGetRequest, ENDPOINTS.brandCountries);

    if (data) {
      yield put({
        type: GET_COUNTRIES.SUCCESS,
        payload: { data }
      });
    } else {
      yield put({
        type: GET_COUNTRIES.FAILURE
      });
    }
  } catch (e) {
    yield put(monitoringActions.exceptionReporter(e));
  }
}

export function* triggerWidgetSearchSaga() {
  const domain = yield select(siteConfig.getDomain);
  const loggedIn = yield select(isLoggedIn);
  const sessionId = yield select(searchSessionIdSelector);
  const searchDomain = `https://${
    getDefaultValue('redirect_test_url') !== null ? getDefaultValue('redirect_test_url') : domain
  }`;

  const parentUrl =
    window.location !== window.parent.location ? document.referrer : document.location.href;

  const analytics = {
    utm_medium: 'poc_widget',
    utm_referrer: getTLD(parentUrl),
    widget_search_key: window.crypto.randomUUID(),
    host_page_url: getDefaultValue('host_page_url')
  };

  yield put(
    analyticsActions.registerSegmentEvent(ANALYTICS_EVENT_NAME.SS_SEARCH_BUTTON_SUCCESS, {
      widget: true,
      utm_medium: analytics.utm_medium,
      utm_referrer: analytics.utm_referrer,
      widget_search_key: analytics.widget_search_key
    })
  );
  const q = yield select(base64Rfq);
  window.open(
    searchDomain && loggedIn
      ? '/search/services/' +
          sessionId +
          '?' +
          paramObjectToUri(analytics) +
          '&widget=true' +
          (q ? '&q=' + q : '')
      : '/search/?' + paramObjectToUri(analytics) + '&widget=true' + (q ? '&q=' + q : ''),
    '_blank'
  );
}

export function* search() {
  if (WIDGET_SEARCH_WITH_URL) {
    yield put(triggerWidgetSearch());
  } else {
    const loggedIn = yield select(isLoggedIn);
    const justSignedUp = yield select((state) => state.propera.AuthUI.justSignedUp);
    const sessionId = yield select(searchSessionIdSelector);
    if (!loggedIn) {
      const q = yield select(base64Rfq);
      if (Boolean(window.localStorage.getItem('visitedBefore')) && justSignedUp) {
        yield put(loginModal({ open: true, view: VIEWS.EMAIL_ACTIVATION }));
      }
      yield put(loginModal({ open: true, redirectUrl: `/search/services/${q ? '?q=' + q : ''}` }));
      yield take('AUTH_UI/OUTPUT_AUTH_SUCCESS');
    } else {
      yield put(push(`/search/services/${sessionId}${isFromWidget ? '?widget=true' : ''}`));
    }

    yield put(searchAnalytics({ name: ANALYTICS_EVENT_NAME.SS_SEARCH_BUTTON_SUCCESS }));
  }
}

export function* searchServicesCompletedSaga({ payload }) {
  yield put(cleanResults());
  const rfqPollingType = 'with-polling';
  const keepRfq = payload.mode === 'results';
  const editMode = yield select(isEditMode);

  if (editMode.status) {
    yield put(updateRfq({ rfqKey: editMode.rfqKey }));
    yield put(setEditMode(false));
  } else {
    yield put(getRfq(rfqPollingType, keepRfq));
  }

  const { payload: response } = yield take([GET_RFQ.SUCCESS, UPDATE_RFQ.SUCCESS]);

  if (!response) {
    yield put(monitoringActions.exceptionReporter('failed to GET_RFQ'));
    return;
  }

  const id = response?.messageHeader?.conversationID;

  yield put(showLoaderOnResults(true));
  yield put(startPolling(response));

  const basePath = `/results/${id}`;

  const path = `${basePath}${isFromWidget ? '?widget=true' : ''}`;

  if (payload.mode === 'results') {
    /// this is from search we should change it to reSearch Action inside the search button
    yield put(push(path));
  } else if (payload.mode !== 'results') {
    // open-freight-results mode is when the user open the search bar on results page
    yield put(setServicesReferrer(true));
    yield put(setOldRfqFlow(false));
    yield put(push(path));
  }
}

export function* handleExpiredQuote({ payload }) {
  Message.warning(
    payload?.message || 'Your session has expired. Please select a new Quote.',
    10,
    null
  );

  return yield put(searchServiceCompleted({ mode: 'results' }));
}

export function* editSearch() {
  const isPolling = yield select(isPollingSelector);
  yield put(
    searchAnalytics({
      name: ANALYTICS_EVENT_NAME.RESULTS_EDIT_SEARCH_BUTTON_CLICKED,
      data: {
        rfqKey: getRfqKeyFromUrl(),
        isPolling
      }
    })
  );
  yield put(showServicesModal());
}

// @NOTE also reducer has handleFieldsChangeWithResets
export function* updateFieldInterceptor(action) {
  const { pristine } = yield select(slimSearchSelector);
  const { destination } = yield select(quoteSelector);
  const load = yield select(getQuoteLoad);

  if (isSectionPristine(action, pristine)) {
    yield put(updateField(`pristine.${action?.meta?.section}`, false));
  }

  if (isAmazonDestination(action, destination)) {
    if (load.type === LOAD_TYPES.CONTAINER.value && loadHas45Container(load)) {
      const newLoad = load.container.packages.map((pkg) => {
        if (pkg.packagingType === CONTAINER_TYPES_VALUES['container45HC'].value) {
          return { ...pkg, packagingType: CONTAINER_TYPES_VALUES['container40'].value };
        }

        return pkg;
      });

      yield put(updateField('quote.load.container.packages', newLoad));
      yield put(updateField('alerts.amazon45container', true));
    } else if (load.looseCargo?.packages?.length >= 1 && hasPallets(load)) {
      const palletsTo48x40 = load.looseCargo.packages.reduce((acc, pkg) => {
        if (
          [
            LCL_LOAD_DEFAULTS.boxes.packagingType,
            LCL_LOAD_DEFAULTS.pallet_48_40.packagingType
          ].includes(pkg.packagingType)
        ) {
          acc.push(pkg);
        } else {
          if (
            pkg.packagingType.startsWith('pallet') &&
            pkg.packagingType !== LCL_LOAD_DEFAULTS.pallet_48_40.packagingType
          ) {
            acc.push({
              ...pkg,
              packagingType: LCL_LOAD_DEFAULTS.pallet_48_40.packagingType,
              packageDimensions: { height: pkg.packageDimensions.height }
            });
          }
        }

        return acc;
      }, []);

      yield put(updateField('quote.load.looseCargo.packages', palletsTo48x40));
    } else {
      yield put(updateField('quote.load.looseCargo.packages[0]', LCL_LOAD_DEFAULTS.pallet_48_40));
    }
  }
}

export function* progressStepClick({ payload }) {
  const { step } = payload;
  const rfqFromQuote = yield select(rfqFromQuoteSelector);

  if (step === 'search') {
    yield put(push(`/${PAGE_ROUTE}`));
  }
  if (step === 'recommended-services') {
    yield put(push(`/results/${rfqFromQuote}`));
    yield call(editSearch);
  }
  if (step === 'results') {
    yield put(push(`/results/${rfqFromQuote}`));
  }
  if (step === 'booking') {
    return;
  }
  if (step === 'verification') {
    return;
  }
}

export function* updateUrl() {
  const editMode = yield select(isEditMode);
  const searchMode = yield select(getSearchMode);
  if (
    editMode.status ||
    searchMode === 'search' ||
    searchMode === 'results' ||
    searchMode === 'services-results'
  ) {
    return;
  }
  const state = yield select(slimSearchSelector);
  const { touched, pristine } = state;

  let allow = Object.entries(touched).some(([, value]) => value);
  if (!allow) {
    allow = Object.entries(pristine).some(([, value]) => !value);
  }

  if (allow) {
    const encodedRfq = yield select(base64Rfq);
    yield put(replace(window.location.pathname + '?q=' + encodedRfq));
  }
}

export function* cancelEditSearch() {
  const rfqKey = getRfqKeyFromUrl();
  yield put(hideServicesModal());
  yield put({
    type: GET_RFQ_SUMMARY.type,
    payload: rfqKey
  });
}

export function* updateRfqFromSearchParamsSaga() {
  const q = new URLSearchParams(window.location.search).get('q');
  if (q) {
    try {
      const data = JSON.parse(decodeURIComponent(atob(q)));
      yield put(saveQuoteFromSearchUrl(mapRfqToQuote({ shipment: data })));
      return;
    } catch (e) {
      // eslint-disable-next-line no-console
      console.log(e);
      // do nothing just keep the regular flow
    }
  }
}

export function* openServicesModalOnResults({ payload }) {
  const searchMode = yield select(getSearchMode);

  if (payload.path === 'activeSection') {
    if (searchMode.includes('result')) {
      yield put(showServicesModal());
    }
  }
}

export function* getQuoteAndShipmentData(action) {
  const { quoteId } = action.payload;

  if (!quoteId) {
    yield put({ type: resultsActionTypes.GET_QUOTE_AND_SHIPMENT.FAILURE });
  }

  const quoteExists = yield select(singleQuoteSelector(quoteId));
  if (quoteExists) {
    yield put(getQuoteAndShipmentSuccess());
    return;
  }

  try {
    yield put(getSingleQuote(quoteId));
    yield all([take(GET_RFQ_SUMMARY.SUCCESS), take(resultsActionTypes.GET_SINGLE_QUOTE.SUCCESS)]);
    yield put(getQuoteAndShipmentSuccess());
  } catch (error) {
    yield put({ type: resultsActionTypes.GET_QUOTE_AND_SHIPMENT.FAILURE });
    yield put(monitoringActions.exceptionReporter(error, false));
  }
}

export function* getShipmentsMetadata() {
  try {
    const loggedIn = yield select(isLoggedIn);
    const role = yield select(userTypeSelector);

    const getUserType = (role) => {
      switch (role) {
        case 'BUYER': {
          return 'buyer';
        }
        case 'SELLER': {
          return 'seller';
        }
        case 'SYS_ADMIN': {
          return 'admin';
        }
        default: {
          return '';
        }
      }
    };
    yield put({ type: GET_SHIPMENTS_METADATA.PENDING, payload: true });
    yield put(AuthActions.getJwt(AuthConstants.JWT_TOKEN_ENDPOINT));
    const {
      payload: { jwt }
    } = yield take(AuthActionTypes.JWT_RESULT);

    if (!loggedIn && !jwt) {
      return;
    }

    const data = yield call(
      doGetRequest,
      endpointConstructor(ENDPOINTS.getShipmentsMetadata, {
        role: getUserType(role),
        urlEncodedParams: '?page=0&activeShipmentsTab=false&isActionRequiredTab=false&size=50'
      }),
      {
        authorization: `Bearer ${jwt}`
      }
    );

    yield put({ type: GET_SHIPMENTS_METADATA.SUCCESS, payload: data.shipmentStatuses });
  } catch (error) {
    yield put(monitoringActions.exceptionReporter(error, false));
  }
}

export function* getSoaInfo() {
  try {
    yield put({ type: GET_SOA_INFO.PENDING, payload: true });

    const state = yield select(rootStateSelector);
    const customerId = userModel.getBusinessKey(state);
    const endpoint = endpointConstructor(ENDPOINTS.getSoaInfo, {
      customerId: customerId
    });
    const params = {
      Accept: 'application/json',
      appId: getSoaAppId()
    };

    const creditInfo = yield call(
      doGetRequest,
      endpoint,
      params,
      {},
      {},
      undefined,
      undefined,
      undefined,
      AuthConstants.JWT_TOKEN_ENDPOINT
    );

    yield put({ type: GET_SOA_INFO.SUCCESS, payload: creditInfo });
  } catch (error) {
    yield put({ type: GET_SOA_INFO.FAILURE, payload: error });
    yield put(monitoringActions.exceptionReporter(error, false));
  }
}

export function* getCreditInfo() {
  try {
    yield put({ type: GET_CREDIT_INFO.PENDING, payload: true });

    const state = yield select(rootStateSelector);
    const customerId = userModel.getBusinessKey(state);

    const params = {
      Accept: 'application/json',
      appId: getSoaAppId()
    };

    const endpoint = endpointConstructor(ENDPOINTS.getCreditInfo, {
      customerId: customerId
    });

    const creditInfo = yield call(
      doGetRequest,
      endpoint,
      params,
      {},
      {},
      undefined,
      undefined,
      undefined,
      AuthConstants.JWT_TOKEN_ENDPOINT
    );

    yield put({ type: GET_CREDIT_INFO.SUCCESS, payload: creditInfo });
  } catch (error) {
    yield put({ type: GET_CREDIT_INFO.FAILURE, payload: error });
    yield put(monitoringActions.exceptionReporter(error, false));
  }
}

export default function* watcher() {
  yield all([
    takeLatest(GET_LOCATIONS.type, getLocations),
    takeLatest(GET_SHIPMENTS_METADATA.type, getShipmentsMetadata),
    takeLatest(GET_SOA_INFO.type, getSoaInfo),
    takeLatest(GET_CREDIT_INFO.type, getCreditInfo),
    takeLatest(GET_PORTS.type, getLocations),
    takeEvery(GET_ADDRESS_BOOK_ADDRESSES.type, getAddressBookAddresses),
    takeLatest(SEARCH, search),
    takeLatest(EDIT_SEARCH, editSearch),
    takeLatest(CANCEL_EDIT_SEARCH, cancelEditSearch),
    takeLatest(SEARCH_SERVICES_COMPLETED, searchServicesCompletedSaga),
    takeLatest(resultsActionTypes.QUOTE_EXPIRED, handleExpiredQuote),
    takeLatest(PROGRESS_STEP_CLICK, progressStepClick),
    takeEvery(UPDATE_FIELD, updateFieldInterceptor),
    takeLatest(UPDATE_FIELD, updateUrl),
    takeLatest(UPDATE_FIELD, openServicesModalOnResults),
    takeLatest(UPDATE_RFQ_FROM_SEARCH_PARAMS, updateRfqFromSearchParamsSaga),
    takeLatest(`${PACKAGE_NAME.toUpperCase()}_INIT_SHARED_COMPONENTS`, initSearchSharedComponent),
    takeLatest('PROPERA/BOOTSTRAP_SUCCESS', setDefaults),
    takeLatest(TRIGGER_WIDGET_SEARCH, triggerWidgetSearchSaga),
    takeLatest(resultsActionTypes.GET_QUOTE_AND_SHIPMENT.type, getQuoteAndShipmentData),
  ]);
}
