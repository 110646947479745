import React from 'react';
import styled from 'styled-components';
import { t } from 'utils/translationProvider';
import { tokens, Button } from '@freightos/design-system';
import { ReactComponent as EmptyBox } from 'assets/images/EmptyBox.svg';
import { RowContainerCentered, ColumnContainerCentered } from 'slimSearch/sharedStyles';

const DeleteConfirmation = ({ onOk, onCancel }) => (
  <StyledColumnContainer spacing={tokens.fdsComponentSpacingXxl}>
    <ColumnContainerCentered spacing={tokens.fdsComponentSpacingXxl}>
      <ColumnContainerCentered spacing={tokens.fdsComponentSpacingSm}>
        <StyledH5>
          {t('myRecentSearches/deleteConfirmationTitle', 'Clear all recent searches?')}
        </StyledH5>
        <Subtitle>
          {t(
            'myRecentSearches/deleteConfirmationSubTitle',
            'We won’t be able to restore your searches'
          )}
        </Subtitle>
      </ColumnContainerCentered>
      <EmptyBox />
    </ColumnContainerCentered>
    <StyledRowContainer spacing={tokens.fdsComponentSpacingMd}>
      <Button type="secondary" onClick={onCancel}>
        {t('myRecentSearches/cancel', 'Cancel')}
      </Button>
      <Button onClick={onOk}>{t('myRecentSearches/clearAll', 'Clear all')}</Button>
    </StyledRowContainer>
  </StyledColumnContainer>
);

const StyledColumnContainer = styled(ColumnContainerCentered)`
  height: 100%;
  padding: ${tokens.fdsComponentSpacingXxl};
  justify-content: space-between;
`;

const StyledH5 = styled.h5`
  font-weight: ${tokens.fdsFontWeightSemibold};
  font-size: ${tokens.fdsH5FontSize};
  line-height: 22px;
`;

const Subtitle = styled.span`
  color: ${tokens.fdsColorPrimaryGray70};
  font-weight: ${tokens.fdsFontWeightRegular};
  font-size: ${tokens.fdsFontSizeBase};
  line-height: 21px;
`;

const StyledRowContainer = styled(RowContainerCentered)`
  align-self: flex-end;
`;

export default DeleteConfirmation;
