/* eslint-disable no-console */
import { actionTypes } from 'results/results.actions';
import { t } from 'utils/translationProvider';

import {
  toggleFilterItem,
  updateFilters,
  resetAllFilters,
  setRangeItem
} from 'results/results.utils';

import {
  filterQuotes,
  initCountItems,
  countOtherItems,
  getActiveModeCountItems,
  getActiveFilters,
  setActiveModeCountItems,
  isFilterGroupActive
} from 'results/filters.utils';

import {
  addRanksValuesToQuotes,
  getRankedQuotes,
  sortQuotes,
  countUniqResultsByVendorByKey,
  countGuaranteedCapacityResults
} from 'results/sort.utils';

import { GET_RFQ_SUMMARY } from 'slimSearch/actions';

export const initialState = {
  quotes: {},
  filters: {
    mode: {
      key: 'mode',
      type: 'toggle',
      label: t('search/filters/mode', 'Modes'),
      modeActive: false,
      list: []
    },
    bookingType: {
      key: 'bookingType',
      type: 'onOff',
      label: t('search/filters/bookingType', 'Booking Type'),
      modeActive: false,
      list: []
    },
    vendors: {
      key: 'vendors',
      type: 'toggle',
      label: t('search/filters/Seller', 'Seller'),
      modeActive: false,
      list: []
    },
    carrier: {
      key: 'carrier',
      type: 'toggle',
      label: t('search/filters/portToPortCarrier', 'Port to Port Carrier'),
      modeActive: false,
      list: []
    },
    origin: {
      key: 'origin',
      type: 'toggle',
      label: t('search/filters/origin', 'Origin Port'),
      list: []
    },
    destination: {
      key: 'destination',
      type: 'toggle',
      label: t('search/filters/destination', 'Destination Port'),
      modeActive: false,
      list: []
    },
    price: {
      key: 'price',
      type: 'range',
      label: t('search/filters/price', 'Price'),
      modeActive: false,
      range: {
        min: Infinity,
        max: 0
      }
    },
    validTo: {
      key: 'validTo',
      type: 'dateRange',
      label: t('search/filters/expirationDate', 'Expiration date'),
      modeActive: false,
      range: {
        min: undefined,
        max: undefined
      }
    }
  },
  filtersCount: {},
  indexIds: [],
  isPolling: undefined,

  topQuotesIndexes: {},
  sortingType: 'bestValue',
  servicesReferrer: false,
  displayFiltersView: false,
  noCoverage: false,
  uniqueResults: 0,
  resultsCount: 0,
  guaranteedCapacityCount: 0,
  showLoader: false
};

export default (state = initialState, action) => {
  let filters = undefined;
  let indexIds = undefined;
  let activeFilters = [];

  switch (action.type) {
    case 'AUTH_UI/LOG_OUT': {
      return initialState;
    }
    case actionTypes.SAVE_QUOTES:
      filters = updateFilters(state.filters, action.payload?.quotesFilters);
      activeFilters = getActiveFilters(filters);

      const quotes = addRanksValuesToQuotes(
        {
          ...state.quotes,
          ...action.payload?.quotes
        },
        action.payload.resultPageConfig
      );

      indexIds = filterQuotes(quotes, activeFilters);
      indexIds = sortQuotes(indexIds, quotes, state.sortingType);

      let filtersCount = {};
      if (activeFilters.length > 0) {
        filtersCount = {
          ...countOtherItems(indexIds, quotes, undefined),
          ...setActiveModeCountItems(quotes, activeFilters)
        };
      } else {
        filtersCount = initCountItems(filters);
      }
      const uniqueResults = countUniqResultsByVendorByKey(quotes);
      const topQuotesIndexes = { ...getRankedQuotes(quotes, indexIds) };
      const guaranteedCapacityCount = countGuaranteedCapacityResults(quotes);
      return {
        ...state,
        quotes,
        filters,
        indexIds,
        filtersCount,
        topQuotesIndexes,
        uniqueResults,
        resultsCount: Object.keys(quotes).length,
        guaranteedCapacityCount
      };

    case actionTypes.TOGGLE_FILTER_ITEM:
      filters = { ...toggleFilterItem(action.payload.mode, action.payload.itemKey, state.filters) };
      activeFilters = getActiveFilters(filters);

      indexIds = filterQuotes(state.quotes, activeFilters);
      indexIds = sortQuotes(indexIds, state.quotes, state.sortingType);

      const modeActive = isFilterGroupActive(filters[action.payload.mode]);

      const active = modeActive
        ? // get last count of the filter mode from the store
          getActiveModeCountItems(filters[action.payload.mode].list, state.filtersCount)
        : // get all count items based on indexing the all data beside this one
          setActiveModeCountItems(
            state.quotes,
            activeFilters.filter((key) => key !== action.payload.mode)
          );

      // get all others filters
      const otherFilterCount = countOtherItems(
        indexIds,
        state.quotes,
        modeActive ? action.payload.mode : undefined
      );
      return {
        ...state,
        filters,
        indexIds,
        filtersCount: { ...active, ...otherFilterCount }
      };

    case actionTypes.RANGE_FILTER_ITEM:
      filters = setRangeItem(action.payload.mode, action.payload.range, state.filters);
      activeFilters = getActiveFilters(filters);

      indexIds = filterQuotes(state.quotes, activeFilters);
      indexIds = sortQuotes(indexIds, state.quotes, state.sortingType);

      return {
        ...state,
        filters,
        indexIds,
        filtersCount: countOtherItems(indexIds, state.quotes)
      };

    case actionTypes.RESET_ALL_FILTERS:
      filters = resetAllFilters(state.filters);
      activeFilters = getActiveFilters(filters);

      indexIds = filterQuotes(state.quotes, activeFilters);
      indexIds = sortQuotes(indexIds, state.quotes, state.sortingType);

      return {
        ...state,
        filters,
        indexIds,
        filtersCount: initCountItems(filters)
      };

    case actionTypes.SET_SORTING_TYPE:
      if (action.payload !== state.sortingType) {
        indexIds = sortQuotes(state.indexIds, state.quotes, action.payload);
        return {
          ...state,
          sortingType: action.payload,
          indexIds
        };
      }
      return state;

    case actionTypes.START_POLLING:
      return { ...state, isPolling: true };

    case actionTypes.QUOTES_POLLING.FAILURE:
    case actionTypes.QUOTES_POLLING_COMPLETED:
      return { ...state, isPolling: false };

    case actionTypes.SET_SERVICES_REFERRER:
      return { ...state, servicesReferrer: action.payload };
    case actionTypes.DISPLAY_FILTERS_VIEW:
      return { ...state, displayFiltersView: action.payload };
    case actionTypes.CLEAN_RESULTS:
      return { ...initialState };
    case actionTypes.NO_COVERAGE_RESULTS:
      // @to do we need to clear that on new quote
      return { ...state, noCoverage: true };
    case GET_RFQ_SUMMARY.SUCCESS:
      return { ...state, noCoverage: false };
    case actionTypes.SHOW_LOADER_ON_RESULTS:
      return { ...state, showLoader: action.payload };
    default:
      return state;
  }
};
