import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import Classnames from 'classnames';
import { t } from 'utils/translationProvider';

import { CardCVCElement, CardExpiryElement, CardNumberElement } from 'react-stripe-elements';

import { Col, Row } from '@freightos/design-system';
import { STRIPE_ERROR_CODES, WRONG_CARD_NUMBER, WRONG_CVC, WRONG_EXPIRY_DATE } from './constants';

import styles from './CreditCardForm.module.scss';

const CreditCardStripElements = ({ errorCode, onCompleteChange }) => {
  const [ccNumberError, setCCNumberError] = useState(null);
  const [expiryError, setExpiryError] = useState(null);
  const [CVCError, setCVCError] = useState(null);
  const [expiryFocused, setExpiryFocused] = useState(false);
  const [numberFocused, setNumberFocused] = useState(false);
  const [CVCFocused, setCVCFocused] = useState(false);
  const [CVCComplete, setCVCComplete] = useState(false);
  const [numberComplete, setNumberComplete] = useState(false);
  const [expiryComplete, setExpiryComplete] = useState(false);

  useEffect(() => {
    switch (errorCode) {
      case STRIPE_ERROR_CODES.INCOMPLETE_NUMBER:
      case STRIPE_ERROR_CODES.INVALID_NUMBER:
        setCCNumberError(WRONG_CARD_NUMBER);
        break;
      case STRIPE_ERROR_CODES.INCOMPLETE_EXPIRY:
      case STRIPE_ERROR_CODES.INVALID_EXPIRY_YEAR_PAST:
      case STRIPE_ERROR_CODES.INVALID_EXPIRY_MONTH_PAST:
        setExpiryError(WRONG_EXPIRY_DATE);
        break;
      case STRIPE_ERROR_CODES.INCOMPLETE_CVC:
        setCVCError(WRONG_CVC);
        break;
      default:
        break;
    }
  }, [errorCode]);

  return (
    <React.Fragment>
      <Row gutter={16}>
        <Col span={12} className={styles.ccInputContainer}>
          <Row>
            <span className={styles.ccInputLabel}>
              {t('payment/shared_comp_card_number_label_text', 'Card Number')}
            </span>
          </Row>
          <Row>
            <CardNumberElement
              className={Classnames('credit-card-form-card-number', 'form-control', {
                [styles.TokenizeElementInvalid]: !numberFocused && ccNumberError
              })}
              placeholder={t(
                'payment/shared_comp_card_number_label_prompt',
                'Enter credit card number'
              )}
              onChange={(e) => {
                setNumberComplete(e.complete);
                setCCNumberError(e.complete ? null : WRONG_CARD_NUMBER);
                setNumberFocused(true);
                onCompleteChange(CVCComplete && expiryComplete && e.complete);
              }}
              onBlur={() => setNumberFocused(false)}
            />
          </Row>
          <Row>
            <span className={styles.stripError}>{!numberFocused && ccNumberError}</span>
          </Row>
        </Col>
        <Col span={6} className={styles.ccInputContainer}>
          <Row>
            <span className={styles.ccInputLabel}>
              {t('payment/shared_comp_expiration_label_text', 'Expiration')}
            </span>
          </Row>
          <Row>
            <CardExpiryElement
              className={Classnames('credit-card-form-card-number', 'form-control', {
                [styles.TokenizeElementInvalid]: !expiryFocused && expiryError
              })}
              placeholder={t('payment/shared_comp_expiration_prompt_text', 'MM/YY')}
              onChange={(e) => {
                setExpiryComplete(e.complete);
                setExpiryError(e.complete ? null : WRONG_EXPIRY_DATE);
                setExpiryFocused(true);
                onCompleteChange(CVCComplete && numberComplete && e.complete);
              }}
              onBlur={() => setExpiryFocused(false)}
            />
          </Row>
          <Row>
            <span className={styles.stripError}>{!expiryFocused && expiryError}</span>
          </Row>
        </Col>
        <Col span={6} className={styles.ccInputContainer}>
          <Row>
            <span className={styles.ccInputLabel}>
              {t('payment/shared_comp_cvc_label_text', 'CVC')}
            </span>
          </Row>
          <Row>
            <CardCVCElement
              className={Classnames('credit-card-form-card-number', 'form-control', {
                [styles.TokenizeElementInvalid]: !CVCFocused && CVCError
              })}
              placeholder={t('payment/shared_comp_cvc_label_prompt', 'Enter CVC')}
              onChange={(e) => {
                setCVCComplete(e.complete);
                setCVCError(e.complete ? null : WRONG_CVC);
                setCVCFocused(true);
                onCompleteChange(expiryComplete && numberComplete && e.complete);
              }}
              onBlur={() => setCVCFocused(false)}
            />
          </Row>
          <Row>
            <span className={styles.stripError}>{!CVCFocused && CVCError}</span>
          </Row>
        </Col>
      </Row>
    </React.Fragment>
  );
};

CreditCardStripElements.propTypes = {
  errorCode: PropTypes.string,
  onCompleteChange: PropTypes.func
};

export default CreditCardStripElements;
