import axios from 'axios';
import {
  getFreightosServerBaseUrl,
  getFreightosMpServerEndpoint,
} from 'financialSharedComponents/constants/endpoints';
import {
  getBillingServiceBaseUrl,
  BANK_DETAILS_API_PATH,
} from 'financialSharedComponents/constants/billingServiceEndpoints';

export const getBankDetailsCallback = () => {
  return axios.get(`${getFreightosServerBaseUrl()}/${getFreightosMpServerEndpoint()}`).then((response) => {
    return axios
      .get(`${getBillingServiceBaseUrl()}${BANK_DETAILS_API_PATH}`, { headers: { Authorization: `Bearer ${response.data}` } })
      .then((res) => res);
  });
};
