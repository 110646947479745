import { connect } from 'react-redux';
import PaymentMethodsView from './PaymentMethods';
import * as selectors from 'financialSharedComponents/selectors';
import * as actions from './actions';

const mapStateToProps = (state) => {
  return {
    paymentMethods: selectors.paymentMethodTypesSelector(state),
    selectedPaymentMethod: selectors.selectedPaymentMethodSelector(state),
    selectedPaymentMethodPspName: selectors.selectedPaymentMethodPSPNameSelector(state),
    availableCredit: selectors.availableCreditSelector(state)
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    paymentMethodChanged: (payload) => dispatch(actions.paymentMethodChanged(payload))
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(PaymentMethodsView);
