import { cloneDeep } from 'lodash/fp';
import { WEIGHT_UNITS, VOLUME_UNITS, DIMENSION_UNITS, DEFAULT_PALLET_SIZES } from '../constants';

export const formatDecimals = (value, decimals = 2) =>
  Number(value)
    .toFixed(decimals)
    .replace(/(\.|,)00$/g, '');

export const unitTotalsString = ({ totalQuantity, totalWeight, totalVolume }, measurements) => {
  const value = [`${totalQuantity} ${totalQuantity === 1 ? 'Unit' : 'Units'} | `];

  if (totalVolume) {
    value.push(
      `Total: ${isFinite(totalVolume) ? totalVolume : 0} ${
        measurements.dimensions === DIMENSION_UNITS.CM ? VOLUME_UNITS.CBM : VOLUME_UNITS.CFT
      }`
    );
  }

  if (totalWeight) {
    value.push(`, ${isFinite(totalWeight) ? totalWeight : 0} ${measurements.weight}`);
  }

  return value.join('');
};

/**
  This method will loop through all the load units and creates a totals object
 * */
export const getUnitsTotals = (packages = [], measurements = {}) => {
  let totalQuantity = 0;
  let totalWeight = 0;
  let totalVolume = 0;

  packages.forEach((currentLoad) => {
    const quantity = parseInt(currentLoad.quantity || 1, 10);
    totalQuantity += quantity;

    let dimensions = cloneDeep(currentLoad.packageDimensions);

    if (DEFAULT_PALLET_SIZES[currentLoad.packagingType]) {
      const sizes = DEFAULT_PALLET_SIZES[currentLoad.packagingType][measurements.dimensions];
      dimensions.width = { value: sizes.width };
      dimensions.length = { value: sizes.length };
    }

    const volume = getVolume(dimensions, measurements.dimensions, currentLoad.packagingType);
    totalVolume += volume * quantity;

    totalWeight += (currentLoad.weight?.value ?? 0) * quantity;
  });
  return {
    totalVolume: formatDecimals(totalVolume),
    totalQuantity,
    totalWeight: formatDecimals(totalWeight)
  };
};

export const ccmToCbm = (volume) => volume / 1000000;

export const cuiToCft = (volume) => volume / 1728;

const getVolume = (
  dimensions = { width: { value: 0 }, length: { value: 0 }, height: { value: 0 } },
  dimensionsUnit
) => {
  const computedUnitVolume =
    dimensions.length?.value * dimensions.width?.value * dimensions.height?.value;

  return dimensionsUnit === DIMENSION_UNITS.CM
    ? ccmToCbm(computedUnitVolume)
    : cuiToCft(computedUnitVolume);
};

export const convertVolume = (value, fromUnit, toUnit) => {
  if (fromUnit === VOLUME_UNITS.CBM && toUnit === VOLUME_UNITS.CFT) {
    return value * 35.3147;
  }
  if (fromUnit === VOLUME_UNITS.CFT && toUnit === VOLUME_UNITS.CBM) {
    return value * 0.0283168;
  }
};

export const convertWeight = (value, fromUnit, toUnit) => {
  if (fromUnit === WEIGHT_UNITS.KG && toUnit === WEIGHT_UNITS.LB) {
    return value * 2.20462262;
  }
  if (fromUnit === WEIGHT_UNITS.LB && toUnit === WEIGHT_UNITS.KG) {
    return value * 0.45359237;
  }
};
