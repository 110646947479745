/* eslint-disable default-case,no-fallthrough,no-duplicate-case */
import { flow, set } from 'lodash/fp';

import {
  LOAD_TYPES,
  ADDRESS_TYPES,
  ACCESSORIALS_SCOPES,
  ACCESSORIALS_NAMES,
  LCL_LOAD_DEFAULTS,
  SINGLE_ENTRY_BOND
} from 'slimSearch/constants';

export const replacePortCharges = (quote = {}) => {
  if (quote.origin?.originLocation?.locationTypeCode === ADDRESS_TYPES.PORT.value) {
    if (quote.load?.type === LOAD_TYPES.CONTAINER.value) {
      return false;
    }
  }
  return true;
};

export const getPortCharges = (locationTypeCode, loadType) => {
  if (locationTypeCode === ADDRESS_TYPES.PORT.value) {
    if (loadType === LOAD_TYPES.CONTAINER.value) {
      return false;
    }
  }
  return true;
};

export const updatePortChargesDefaultValues = (quote = {}) => {
  return {
    ...quote,
    pricePreference: {
      ...quote.pricePreference,
      includeOriginPortCharges: getPortCharges(
        quote.origin?.originLocation?.locationTypeCode,
        quote.load?.type
      ),
      includeDestinationPortCharges: getPortCharges(
        quote.destination?.destinationLocation?.locationTypeCode,
        quote.load?.type
      )
    }
  };
};

export const replaceOriginAccessorials = (quote = {}) => {
  const scopes = updateOriginAccessorialsDefaultValues(
    quote.origin?.originLocation?.locationTypeCode,
    quote.load?.type
  );
  const quoteScopes =
    quote.accessorials?.filter((item) => item.scope !== ACCESSORIALS_SCOPES.PICKUP) ?? [];
  return [...quoteScopes, ...scopes];
};

export const replaceDestinationAccessorials = (quote = {}) => {
  const scopes = updateDestinatioAccessorialsDefaultValues(
    quote.destination?.destinationLocation?.locationTypeCode,
    quote.load?.type
  );
  const quoteScopes =
    quote.accessorials?.filter((item) => item.scope !== ACCESSORIALS_SCOPES.DELIVERY) ?? [];
  return [...quoteScopes, ...scopes];
};

export const updateHazardousAccessorials = (quote = {}) => {
  const quoteScopes = quote.accessorials.filter(
    (item) => item.name !== ACCESSORIALS_NAMES.HAZARDOUS
  );
  const hazardousAccessorials = quote.load?.hazardous
    ? [
        {
          scope: ACCESSORIALS_SCOPES.GENERAL,
          name: ACCESSORIALS_NAMES.HAZARDOUS
        }
      ]
    : [];
  return [...quoteScopes, ...hazardousAccessorials];
};

export const updateInsuranceValue = (quote) => {
  if (quote.insuranceValueAmount) {
    return {
      value: parseInt(quote.goods?.goodsValue?.value ?? 0, 10),
      currencyID: quote.goods?.goodsValue?.currency
    };
  }
  return undefined;
};

export const updateCustomsValue = (quote) => {
  let bond = {};
  if (updateShowCustomBondOptions(quote)) {
    bond =
      getSingleEntryBondMaximumValue(quote) <= 400 || quote.goods?.goodsValue?.currency !== 'USD'
        ? {
            singleEntryBond: {
              value: getGoodsValue(quote),
              currencyID: quote.goods?.goodsValue?.currency
            },
            continuousBond: false
          }
        : {
            continuousBond: true,
            singleEntryBond: undefined
          };
  }
  return {
    ...quote.declaredCustoms,
    ...bond
  };
};

export const updateOriginAccessorialsDefaultValues = (locationTypeCode, loadType) => {
  const scopes = [];
  if (loadType === LOAD_TYPES.CONTAINER.value) {
    return [];
  }
  switch (locationTypeCode) {
    case ADDRESS_TYPES.ADDRESS.key:
      scopes.push({
        scope: ACCESSORIALS_SCOPES.PICKUP,
        name: ACCESSORIALS_NAMES.RESIDENTIAL_BASIC_SERVICES
      });
    case ADDRESS_TYPES.BUSINESS_ADDRESS.key:
    case ADDRESS_TYPES.ADDRESS.key:
      scopes.push({
        scope: ACCESSORIALS_SCOPES.PICKUP,
        name: ACCESSORIALS_NAMES.LIFT_GATE
      });
      break;
  }
  return scopes;
};

export const updateDestinatioAccessorialsDefaultValues = (locationTypeCode, loadType) => {
  const scopes = [];
  if (loadType === LOAD_TYPES.CONTAINER.value) {
    return [];
  }
  switch (locationTypeCode) {
    case ADDRESS_TYPES.ADDRESS.key:
      scopes.push({
        scope: ACCESSORIALS_SCOPES.DELIVERY,
        name: ACCESSORIALS_NAMES.RESIDENTIAL_BASIC_SERVICES
      });
    case ADDRESS_TYPES.BUSINESS_ADDRESS.key:
    case ADDRESS_TYPES.ADDRESS.key:
      scopes.push({
        scope: ACCESSORIALS_SCOPES.DELIVERY,
        name: ACCESSORIALS_NAMES.LIFT_GATE
      });
      break;
  }
  return scopes;
};

export const updateAccessorialsDefaultValues = (quote = {}) => {
  const originAccessorials = updateOriginAccessorialsDefaultValues(
    quote.origin?.originLocation?.locationTypeCode,
    quote.load?.type
  );
  const destinationAccessorials = updateDestinatioAccessorialsDefaultValues(
    quote.destination?.destinationLocation?.locationTypeCode,
    quote.load?.type
  );
  const hazardousAccessorials = quote.load?.hazardous
    ? [
        {
          scope: ACCESSORIALS_SCOPES.GENERAL,
          name: ACCESSORIALS_NAMES.HAZARDOUS
        }
      ]
    : [];

  return {
    ...quote,
    accessorials: [...originAccessorials, ...destinationAccessorials, ...hazardousAccessorials]
  };
};

export const updateInsuranceDefaultValues = (quote = {}) => {
  return {
    ...quote,
    insuranceValueAmount: {
      value: parseInt(quote.goods?.goodsValue?.value ?? 0, 10),
      currencyID: quote.goods?.goodsValue?.currency
    }
  };
};

export const updateFulFilamentDefaultValues = (quote = {}) => {
  let fulfilmentCompanyName = undefined;
  let palletization = false;
  let labeling = false;
  let fulfilmentCompanyAppointment = true;
  let floorLoaded = false;

  if (
    quote.destination?.destinationLocation?.locationTypeCode ===
      ADDRESS_TYPES.FULFILMENT_CENTER.key ||
    quote?.destinationLocation?.locationName === ADDRESS_TYPES.FULFILMENT_CENTER.key
  ) {
    if (
      quote?.destination?.destinationLocation?.brand === 'AMZ' ||
      (quote?.destinationLocation?.locationCode.startsWith('#') &&
        !quote?.destinationLocation?.locationCode.includes('-')) // shipbob has - in label
    ) {
      fulfilmentCompanyName = 'Amazon';

      if (quote.load?.type === LOAD_TYPES.CONTAINER.value) {
        palletization = true;
      } else if (quote.load?.looseCargo?.calculateByTotals) {
        palletization = true;
        labeling = true;
      } else {
        if (isBoxesLoadType(quote.load)) {
          palletization = true;
          labeling = true;
        } else {
          labeling = true;
        }
      }
    } else {
      return { ...quote, fulfilmentInfo: undefined };
    }
  } else if (
    quote.destination?.destinationLocation?.locationTypeCode ===
      ADDRESS_TYPES.LAST_MILE_DELIVERY.key ||
    quote.destinationLocation?.locationName === ADDRESS_TYPES.LAST_MILE_DELIVERY.key
  ) {
    // this is working because we  returning the vale on the end of the function
    fulfilmentCompanyAppointment = false;
  } else {
    return { ...quote, fulfilmentInfo: undefined };
  }

  return {
    ...quote,
    fulfilmentInfo: {
      fulfilmentCompanyShipment: {
        fulfilmentCompanyName,
        additionalServices: {
          palletization,
          labeling,
          fulfilmentCompanyAppointment,
          floorLoaded
        }
      }
    }
  };
};

export const updateCustomsDefaultValues = (quote = {}) => {
  const originCountry = quote.origin?.originLocation?.countryID?.value;
  const destinationCountry = quote.destination?.destinationLocation?.countryID?.value;
  const goods = quote.goods?.goodsValue ?? {};

  let declaredCustoms = {};

  if (originCountry === destinationCountry) {
    declaredCustoms = {
      ...declaredCustoms,
      entry: false
    };
  } else if (destinationCountry === 'US') {
    if (
      quote.destination?.destinationLocation?.locationTypeCode ===
      ADDRESS_TYPES.FULFILMENT_CENTER.key
    ) {
      if (quote?.destination?.destinationLocation?.brand === 'AMZ') {
        declaredCustoms = {
          ...declaredCustoms,
          foreignImporterOfRecord: false
        };
      }
    } else if (
      quote.destination?.destinationLocation?.locationTypeCode ===
      ADDRESS_TYPES.LAST_MILE_DELIVERY.key
    ) {
      declaredCustoms = {
        ...declaredCustoms,
        foreignImporterOfRecord: false
      };
    }
    if (!goods.value) {
      declaredCustoms = {
        ...declaredCustoms,
        entry: true
      };
    }
    // we need to exchange rate here if the currency is not usd
    else if (goods.currency !== 'USD' || getSingleEntryBondMaximumValue(quote) <= 400) {
      declaredCustoms = {
        ...declaredCustoms,
        entry: true,
        singleEntryBond: {
          value: getGoodsValue(quote),
          currencyID: goods.currency
        }
      };
    } else {
      declaredCustoms = {
        ...declaredCustoms,
        entry: true,
        continuousBond: true
      };
    }
  } else {
    // if destination is not us
    declaredCustoms = {
      ...declaredCustoms,
      entry: true
    };
  }

  return { ...quote, declaredCustoms };
};

export const changeRecommendedServicesAfterUpdate = ({ quote = {} }, action) => {
  let tasks = [];
  switch (action.payload?.path) {
    case 'quote.origin.originLocation.locationTypeCode':
    case 'quote.origin.originLocation.locationCode':
    case 'quote.origin.originLocation.countryID.value':
      tasks.push(set('accessorials', replaceOriginAccessorials(quote)));
      const quoteData = updateFulFilamentDefaultValues(quote);

      tasks.push(set('fulfilmentInfo', quoteData.fulfilmentInfo));
      tasks.push(
        set(
          'pricePreference.includeOriginPortCharges',
          getPortCharges(quote.origin?.originLocation?.locationTypeCode, quote?.load?.type)
        )
      );
      break;
    case 'quote.destination.destinationLocation.locationTypeCode':
    case 'quote.destination.destinationLocation.locationCode':
    case 'quote.destination.destinationLocation.countryID.value':
      const fulFilament1 = updateFulFilamentDefaultValues(quote);
      tasks.push(set('fulfilmentInfo', fulFilament1.fulfilmentInfo));
      // update only the destination accessorials
      tasks.push(set('accessorials', replaceDestinationAccessorials(quote)));

      break;
    case 'quote.destination.destinationLocation.brand':
      const fulFilament = updateFulFilamentDefaultValues(quote);
      tasks.push(set('fulfilmentInfo', fulFilament.fulfilmentInfo));
      break;
    case 'quote.goods.goodsValue.value':
      tasks.push(set('insuranceValueAmount', updateInsuranceValue(quote)));
      tasks.push(set('declaredCustoms', updateCustomsValue(quote)));
      break;
    case 'quote.load.hazardous':
      tasks.push(set('accessorials', updateHazardousAccessorials(quote)));
      break;
    case 'quote.load.type':
    case 'quote.load.looseCargo.calculateByTotals':
      const quoteAccessorials = updateAccessorialsDefaultValues(quote);
      const quoteFullfilmentCenter = updateFulFilamentDefaultValues(quote);
      tasks.push(set('accessorials', quoteAccessorials.accessorials));
      tasks.push(set('fulfilmentInfo', quoteFullfilmentCenter.fulfilmentInfo));
      break;
    case 'quote.fulfilmentInfo.fulfilmentCompanyShipment.additionalServices.labeling':
      if (isBoxesLoadType(quote.load) || quote.load?.looseCargo?.calculateByTotals) {
        tasks.push(
          set(
            'fulfilmentInfo.fulfilmentCompanyShipment.additionalServices.palletization',
            action.payload?.value
          )
        );
      }
      break;
  }

  if (action.payload?.path.includes('load.looseCargo.packages')) {
    const { fulfilmentInfo: fulfilmentInfoPackages } = updateFulFilamentDefaultValues(quote);
    tasks.push(set('fulfilmentInfo', fulfilmentInfoPackages));
  }

  return flow([...tasks])(quote);
};

export const updateQuoteRecommendedServicesDefaults = ({ quote = {} }) =>
  flow([
    updateCustomsDefaultValues,
    updateFulFilamentDefaultValues,
    updateAccessorialsDefaultValues,
    updateInsuranceDefaultValues,
    updatePortChargesDefaultValues
  ])(quote);

export const updateShowCustomBondOptions = (quote = {}) => {
  if (quote?.declaredCustoms?.entry) {
    if (quote.declaredCustoms?.singleEntryBond || quote.declaredCustoms?.continuousBond) {
      return true;
    }
  }
  return false;
};

export const hasLiftgate = (shipment, mode) => {
  if (!shipment.accessorials) {
    return false;
  }
  return shipment.accessorials.some(
    (item) => item.scope === mode && item.name === ACCESSORIALS_NAMES.LIFT_GATE
  );
};

export const isLclShipment = (quote) => quote.load?.type === LOAD_TYPES.LOOSE_CARGO.value;

export const isBoxesLoadType = (load = {}) => {
  const loadItem = load[LOAD_TYPES.LOOSE_CARGO.value] || {};
  const result =
    load.type === LOAD_TYPES.LOOSE_CARGO.value &&
    !loadItem.calculateByTotals &&
    loadItem.packages?.length > 0 &&
    loadItem.packages.some(
      (packageItem) => packageItem.packagingType === LCL_LOAD_DEFAULTS.boxes?.packagingType
    );
  return result;
};

export const hasPallets = (load = {}) => {
  const palletsTypes = [
    LCL_LOAD_DEFAULTS.pallets.packagingType,
    LCL_LOAD_DEFAULTS.pallet_48_40.packagingType,
    LCL_LOAD_DEFAULTS.pallet_EUR1.packagingType,
    LCL_LOAD_DEFAULTS.pallet_EUR2.packagingType
  ];
  const loadItem = load[LOAD_TYPES.LOOSE_CARGO.value] || {};
  return (
    load.type === LOAD_TYPES.LOOSE_CARGO.value &&
    !loadItem.calculateByTotals &&
    loadItem.packages?.length > 0 &&
    loadItem.packages.some((packageItem) => palletsTypes.includes(packageItem.packagingType))
  );
};

export const loadHasOnlyPallets = (load = {}) => {
  const palletsTypes = [
    LCL_LOAD_DEFAULTS.pallets.packagingType,
    LCL_LOAD_DEFAULTS.pallet_48_40.packagingType,
    LCL_LOAD_DEFAULTS.pallet_EUR1.packagingType,
    LCL_LOAD_DEFAULTS.pallet_EUR2.packagingType
  ];
  const loadItem = load[LOAD_TYPES.LOOSE_CARGO.value] || {};
  return (
    load.type === LOAD_TYPES.LOOSE_CARGO.value &&
    !loadItem.calculateByTotals &&
    loadItem.packages?.length > 0 &&
    loadItem.packages.every((packageItem) => palletsTypes.includes(packageItem.packagingType))
  );
};

export const getSingleEntryBondMaximumValue = (shipment) => {
  const goodsValue = getGoodsValue(shipment);

  let singleEntryBondValue = goodsValue * SINGLE_ENTRY_BOND.percentOfShipmentValue;

  if (singleEntryBondValue < SINGLE_ENTRY_BOND.minPrice) {
    return SINGLE_ENTRY_BOND.minPrice;
  }

  return singleEntryBondValue;
};

export const getGoodsValue = (quote) => {
  const goods = quote.goods?.goodsValue ?? {};
  const singleEntryValue = parseInt(goods.value, 10);
  return singleEntryValue;
};

export const isFullFilmentCenter = (quote) =>
  [ADDRESS_TYPES.LAST_MILE_DELIVERY.key, ADDRESS_TYPES.FULFILMENT_CENTER.key].includes(
    quote.destination?.destinationLocation?.locationTypeCode
  );

export const showAmazonServiceSection = (shipment) => {
  const locationFlag = shipment.destination?.destinationLocation?.locationTypeCode;
  if (!isFullFilmentCenter(shipment)) {
    return false;
  }
  if (locationFlag === ADDRESS_TYPES.LAST_MILE_DELIVERY.key) {
    return true;
  } else if (
    (
      shipment.fulfilmentInfo?.fulfilmentCompanyShipment?.fulfilmentCompanyName ?? ''
    ).toLowerCase() === 'amazon'
  ) {
    return true;
  } else {
    return false;
  }
};
