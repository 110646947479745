import { createSelector } from 'reselect';
import get from 'lodash/get';

export const vendorsSelector = (state) => state.vendors || {};

export const vendorKeys = createSelector(vendorsSelector, (vendors) => Object.keys(vendors));

export const vendorItem = (id) => createSelector(vendorsSelector, (vendors) => vendors[id] || {});

export const quoteVendors = (quote, vendors) => {
  return get(quote, 'businessInfo.parties', []).map((item) => vendors[item.ID]);
};
