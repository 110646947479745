import React, { useEffect } from 'react';
import { connect } from 'microfronts-redux';
import { get } from 'lodash/fp';

import styled, {
  css,
  createGlobalStyle,
  StyleSheetManager,
  keyframes
} from 'styled-components/macro';

import { tokens } from '@freightos/design-system';

import { SearchCategories, Tiles } from 'slimSearch';
import Articles from 'slimSearch/components/Articles';
import { TrustPilot } from 'slimSearch/components/TrustPilot';
import { isMobileSelector } from 'slimSearch/selectors';
import { searchAnalytics } from 'slimSearch/actions';
import {
  cdnPath,
  PAGE_WIDTH,
  ResultsScreenValues
} from 'slimSearch/constants';
import { t } from 'utils/translationProvider';
import siteConfigModel from 'propera/siteConfig';
import { model } from 'layout';
import createExtraSpecifityPlugin from '@freightos/stylis-class-specificity-plugin';

import userModel from 'propera/user';
import {
  getRecentSearches,
  resetSearchHistory
} from 'slimSearch/components/RecentSearches/actions';
const { userTypeSelector, isLoggedIn } = userModel;

const { getWindowSize } = model;

const onlySea = `${cdnPath}/slim-search/sea.svg`;

const slideBottom = keyframes`
  0% {
    transform: translateY(1000px) scaleY(2.5) scaleX(0.2);
    transform-origin: 50% 100%;
    filter: blur(100px);
    opacity: 0;
  }
  100% {
    transform: translateY(0) scaleY(1) scaleX(1);
    transform-origin: 50% 50%;
    filter: blur(0);
    opacity: 1;
  }
`;

const GlobalStyle = createGlobalStyle`
  body {
    #app-container {
      > main {
        overflow-x: hidden;

        ${({ isOverviewCardsShown }) =>
          isOverviewCardsShown
            ? css`
                &.ant-layout-content {
                  overflow: auto;
                  animation: ${slideBottom} 0.7s cubic-bezier(0.23, 1, 0.32, 1) both;
                  background: url(${onlySea}) no-repeat left bottom;
                }
              `
            : css`
                &.ant-layout-content {
                  overflow: auto;
                  position: relative;
                  display: flex;
                  justify-content: center;
                }

                .inner-content {
                  overflow: hidden;
                }
              `}
      }
    }
  }
`;

const GlobalStyleMobile = createGlobalStyle`
  body {
    #app-container {
      > main {
        &.ant-layout-content {
          overflow: auto;
          overflow-x: hidden;
          position: relative;
          display: flex;
          justify-content: center;
          animation: ${slideBottom} .7s cubic-bezier(0.230, 1.000, 0.320, 1.000) both;
          background: url(${onlySea}) no-repeat left bottom;
        }
      }
    }

    .inner-content {
      height: 100vh;
      overflow: auto;
    }
  }
`;

const CardsArea = ({ isOverviewCardsShown, isMobile }) => {
  if (isOverviewCardsShown) {
    return <Tiles />;
  } else if (!isMobile) {
    return <Articles />;
  } else {
    return null;
  }
};

export const Search = ({
  isMobile,
  userRole,
  getRecentSearches,
  resetSearchHistory,
  isLoggedIn
}) => {
  const isOverviewCardsShown = userRole === 'BUYER';

  useEffect(() => {
    if (isLoggedIn) {
      getRecentSearches();
    } else {
      resetSearchHistory();
    }
    // eslint-disable-next-line
  }, [isLoggedIn, getRecentSearches, resetSearchHistory]);

  return (
    <StyleSheetManager stylisPlugins={[createExtraSpecifityPlugin(2)]}>
      <Wrapper isMobile={isMobile}>
        {isMobile ? (
          <GlobalStyleMobile />
        ) : (
          <GlobalStyle isOverviewCardsShown={isOverviewCardsShown} />
        )}

        <BlueHeadingWrapper show={isOverviewCardsShown} isMobile={isMobile}>
          <PageTitles isMobile={isMobile}>
            <PageTitle
              isMobile={isMobile}
              isOverviewCardsShown={isOverviewCardsShown}
            >
              {t('search/search_page_header_1_text', 'Where would you like to ship?')}
            </PageTitle>
            <SubTitle isOverviewCardsShown={isOverviewCardsShown}>
              {isMobile
                ? ''
                : t(
                    'search/search_page_header_2_text',
                    'Start searching to compare, book and manage your freight, all on one platform'
                  )}
            </SubTitle>
          </PageTitles>
          <SearchCategories />
        </BlueHeadingWrapper>

        <PageContent isMobile={isMobile}>
          <TrustPilot />

          <SpacingContainer>
            <CardsArea isMobile={isMobile} isOverviewCardsShown={isOverviewCardsShown} />
          </SpacingContainer>
        </PageContent>
      </Wrapper>
    </StyleSheetManager>
  );
};

const Wrapper = styled.div`
  position: relative;
  font-family: ${tokens.fdsBodyFontFamily};
  margin: 0 auto;
  width: 100vw;
  z-index: 1;
  padding: 0;
  color: ${tokens.fdsBodyTextColor};
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-self: center;
  overflow-x: hidden;
`;

const BlueHeadingWrapper = styled.div`
  ${({ show, isMobile }) =>
    show
      ? css`
          background: linear-gradient(
            94.28deg,
            rgba(5, 56, 125, 0.8) 17.83%,
            rgba(5, 56, 125, 0.84) 50.25%,
            rgba(5, 56, 125, 0.71) 88.91%,
            ${tokens.fdsColorPrimaryBlue50} 121.43%
          );
          width: 100vw;
          position: relative;
          padding: 30px 0 ${isMobile ? tokens.fdsComponentSpacingSm : '30px'};
        `
      : css`
          padding: 30px 0;
        `}
`;

const PageTitles = styled.div`
  margin: 0 auto;
  max-width: ${PAGE_WIDTH}px;

  ${({ isMobile }) =>
    isMobile
      ? css`
          width: 100%;
        `
      : css`
          @media (min-width: ${ResultsScreenValues.desktop}px) {
            width: 100%;
          }
          @media (max-width: ${ResultsScreenValues.desktop - 1}px) {
            width: 80vw;
          }
        `}
`;

const PageContent = styled.div`
  ${({ isMobile }) =>
    isMobile
      ? css`
          padding: 0;
        `
      : css`
          @media (min-width: ${ResultsScreenValues.desktop}px) {
          }
          @media (max-width: ${ResultsScreenValues.desktop - 1}px) {
            padding: 0 20px;
          }
        `}
`;

const PageTitle = styled.h2`
  font-family: Rajdhani, 'Open sans', sans-serif;
  font-weight: 600;
  margin: 0 auto;
  color: ${tokens.fdsColorPrimaryWhite};

  ${({ isMobile }) =>
    isMobile
      ? css`
          font-size: 24px;
          width: 100%;
          text-align: center;
        `
      : css`
          text-align: left;
          font-size: 35px;
          max-width: ${PAGE_WIDTH}px;
          width: ${PAGE_WIDTH}px;
        `}

  ${({ isOverviewCardsShown }) =>
    isOverviewCardsShown
      ? css`
          color: ${tokens.fdsColorPrimaryWhite};
        `
      : css`
          color: ${tokens.fdsHeadingsTextColor};
        `}

  ${({ isOverviewCardsShown, isMobile }) =>
    isOverviewCardsShown && !isMobile
      ? css`
          text-align: left;
        `
      : css`
          text-align: center;
        `}
`;

const SubTitle = styled.p`
  margin: 0 auto ${tokens.fdsComponentSpacingXxl};
  font-size: 14px;
  line-height: inherit;
  color: ${tokens.fdsColorPrimaryWhite};
  max-width: ${PAGE_WIDTH}px;
  width: ${PAGE_WIDTH}px;

  ${({ isOverviewCardsShown }) =>
    isOverviewCardsShown
      ? css`
          color: ${tokens.fdsColorPrimaryWhite};
        `
      : css`
          color: ${tokens.fdsHeadingsTextColor};
          text-align: center;
        `}
`;

const SpacingContainer = styled.div`
  ${({ isMobile }) =>
    isMobile
      ? css`
          margin-top: ${tokens.fdsSizeXl};
          margin-bottom: 74px;
        `
      : css`
          margin-top: ${tokens.fdsSizeBig};
          margin-bottom: ${tokens.fdsComponentSpacingXl};
        `};
`;

const mapStateToProps = (store) => ({
  isMobile: isMobileSelector(store),
  windowHeight: get('height', getWindowSize(store)),
  isFirstTime: store.propera?.user?.isFirstTime,
  domain: siteConfigModel.getDomain(store),
  activeSection: store.search?.activeSection,
  userRole: userTypeSelector(store),
  isLoggedIn: isLoggedIn(store)
});

export default connect(mapStateToProps, {
  searchAnalytics,
  getRecentSearches,
  resetSearchHistory
})(Search);
