import { createSelector } from 'reselect';
import { model as authModel } from 'propera/auth';
import { CookiesUtils } from 'propera/cookies';
import preferences from 'propera/preferences';
import siteConfig from 'propera/siteConfig';
import userModel from 'propera/user';

const { getLegalName, getFullName, isSeller, isSystemAdmin } = userModel;

export const allowLongIntakeForm = (state) => {
  if (isSystemAdmin(state) || isSeller(state)) {
    return true;
  }
  return false;
};

export const mapRFQToInitialValues = (state) => {
  const customQuoteSender = isSeller(state) || isSystemAdmin(state);
  const marketplaceRequest = !siteConfig.isSalesPortal(state);
  const jwt = authModel.jwtDecodedSelector(state) || {};
  const emailFromCookie = CookiesUtils.getEmailFromSessionCookie();
  return {
    options: {},
    load: {},
    fullName: !customQuoteSender && getFullName(state),
    country: !customQuoteSender && preferences.getCountry(state),
    company: !customQuoteSender && getLegalName(state),
    buyerKey: !customQuoteSender && jwt.userKey,
    buyerBusinessKey: !customQuoteSender && jwt.businessKey,
    buyerEmail: !customQuoteSender && emailFromCookie,
    sourceSiteDomain: siteConfig.getDomain(state),
    autoCloseThresholdInDays: siteConfig.getAutoCloseCustomQuoteRequestThreshold(state),
    createdByEmail: emailFromCookie,
    marketplaceRequest
  };
};

export const getCustomQuoteState = (state) => state.customQuote;

export const getCustomQuotePageDisplayStatus = createSelector(
  getCustomQuoteState,
  (results) => results.display
);

export const getCustomQuotePageServerError = createSelector(
  getCustomQuoteState,
  (results) => results.serverError
);
