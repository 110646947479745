import get from 'lodash/get';
import { createSelector } from 'reselect';
import * as actions from './actions';
import { normalizeSearchListAndSortPinnedFirst } from './utils';

const initialState = {
  pendingRequest: false,
  items: []
};

const recentSearchesReducer = (state = initialState, action) => {
  switch (action.type) {
    case actions.GET_RECENT_SEARCHES.PENDING:
      return {
        ...state,
        pendingRequest: action.payload
      };
    case actions.GET_RECENT_SEARCHES.SUCCESS:
      return {
        ...state,
        items: normalizeSearchListAndSortPinnedFirst(action.payload)
      };
    case actions.resetSearchHistory().type:
      return {
        ...state,
        items: []
      };
    default:
      return state;
  }
};

export default recentSearchesReducer;

const recentSearchesSelector = (state = {}) => get(state, 'recentSearches');
export const getPendingRequest = createSelector(
  recentSearchesSelector,
  (state) => state.pendingRequest
);
export const getRecentSearchItems = createSelector(recentSearchesSelector, (state) => state.items);
