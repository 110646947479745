/* eslint-disable */
import { all, put, takeLatest, select, take, call, takeEvery } from 'redux-saga/effects';
import { get } from 'lodash';
import { push, replace } from 'microfronts-router';
import { doGetRequest } from 'propera/HTTP';
import { model as preferencesModel } from 'propera/preferences';
import { actions as monitoringActions } from 'propera/monitoring';
import { actions as analyticsActions } from 'propera/analytics';
import { model as siteConfigModel } from 'propera/siteConfig';
import { ENDPOINTS, ANALYTICS_EVENT_NAME } from 'slimSearch/constants';
import { endpointConstructor } from 'utils';

import { noCoverageResults, saveQuotes } from 'results/results.actions';

import {
  GET_RFQ,
  setIsInScope,
  GET_RFQ_SUMMARY,
  setOldRfqFlow,
  updateField,
  searchAnalytics,
  getRfq
} from 'slimSearch/actions';
import {
  getRfqSelector,
  getMeasurements,
  fullFillmentLocationsSelector,
  getQuoteLoad
} from 'slimSearch/selectors';

import {
  actionTypes as resultsActionTypes,
  startPolling,
  getSingleQuoteSuccess
} from 'results/results.actions';
import {
  mapArrayToObject,
  mapQuotesToFilters,
  isOldRfqVersion,
  mapOldRfqLocations,
  getVendors,
  getQuoteCurrency
} from 'results/results.utils';
import { fillQuotesDataForSortingAndFiltering } from 'results/sort.utils.js';
import { isInScope } from 'results/custom-quote.utils';
import { getRfqKeyFromUrl } from 'results/results.utils';
import { getRfqOptionsSelector } from 'slimSearch/utils/external.utils.js';
import { mapRfqToQuote } from 'slimSearch/utils/mapOpenFreight.utils';

import { getQuoteVendors } from 'results/vendors/vendors.actions';

const { getResultsPageConfig, getSiteConfig, getDomain } = siteConfigModel;
const { getCurrency } = preferencesModel;

export function* initResultsPage({ payload }) {
  const domain = yield select(getDomain);
  yield put({ type: GET_RFQ_SUMMARY.type, payload: payload.rfqKey });
  yield take(GET_RFQ_SUMMARY.SUCCESS);
  // this is a work around because I don't want to break the signature of the get_rfq_summary.success resolved value
  const shipment = yield select(getRfqSelector);

  try {
    if (isOldRfqVersion(shipment)) {
      yield put(
        analyticsActions.registerSegmentEvent(ANALYTICS_EVENT_NAME.RESULTS_OLD_RFQ_LINK_REDIRECT, {
          rfkKey: payload.rfqKey
        })
      );
      if (domain.includes('ship.')) {
        yield put(setOldRfqFlow(true));
        const fullFillmentLocations = yield select(fullFillmentLocationsSelector);
        const fields = mapOldRfqLocations(shipment, fullFillmentLocations);
        yield all(fields.map(({ path, value }) => put(updateField(path, value))));
        yield put(push(`/search/services/${payload.rfqKey}`));
      } else {
        const pagingPath = endpointConstructor(ENDPOINTS.fetchQuotes, {
          rfqId: payload.rfqKey,
          cursor: 0
        });
        yield put(startPolling({ paging: { next: pagingPath } }));
        yield put(replace(`/results/${payload.rfqKey}`));
      }
    } else {
      const currency = yield select(getCurrency);
      const quoteCurrency = getQuoteCurrency(shipment);
      let result = { paging: undefined, messageHeader: undefined };
      if (currency !== quoteCurrency) {
        yield put(updateField('quote.pricePreference.requestCurrency.value', currency));
        yield put(getRfq('with-polling', false));
        const { payload } = yield take(GET_RFQ.SUCCESS);
        result = payload;
      } else {
        // because of some server restrictions - we can not re search for existing rfq after 2 hours - so we are re triggering the search
        const endpoint = endpointConstructor(ENDPOINTS.updateRfq, { rfqKey: payload.rfqKey });

        result = yield call(doGetRequest, endpoint);
      }
      const {
        paging,
        messageHeader: { conversationID }
      } = result;

      if (conversationID !== payload.rfqKey) {
        yield put(replace(`/results/${conversationID}`));
      }

      yield put(startPolling({ paging }));
    }
  } catch (error) {
    yield put(monitoringActions.exceptionReporter(error, false));
  }
}

/**
 * this is to load quote on book placed event (this is needed if the user refreshed the checkout page)
 * @param {*}
 * @returns
 */
export function* saveQuotesSaga({ payload }) {
  if (!payload.quotes?.length) {
    return null;
  }
  yield put(getQuoteVendors(payload.quotes));

  const resultPageConfig = yield select(siteConfigModel.getResultsPageConfig);
  const rfqRequiredServices = yield select(getRfqOptionsSelector);

  const quoteWithSortingData = fillQuotesDataForSortingAndFiltering(
    payload.quotes,
    rfqRequiredServices
  );

  const quotes = mapArrayToObject(quoteWithSortingData, 'referenceID');

  const quotesFilters = mapQuotesToFilters(payload.quotes);
  yield put(
    saveQuotes({
      quotes,
      quotesFilters,
      resultPageConfig
    })
  );
}

/**
 * every time we receive quote summary we will add it the isInScope flag
 * this will mostly used to decide either to show custom quote or no coverage view
 * it is not required for all processes - but it is for all app usage
 */
export function* isInScopeSaga() {
  const shipment = yield select(getRfqSelector);
  const measurements = yield select(getMeasurements);

  const {
    inScope = [
      {
        origin: '*',
        destination: '*'
      }
    ],
    outOfScope = [],
    lclCustomQuoteMinCbm = 0,
    lclCustomQuoteMaxCbm = Infinity
  } = yield select(siteConfigModel.getSiteConfig);
  const config = {
    inScope,
    outOfScope,
    lclCustomQuoteMinCbm,
    lclCustomQuoteMaxCbm
  };
  const shipmentInScope = isInScope({ shipment, config, measurements });
  const { hazardous } = yield select(getQuoteLoad);
  if (!shipmentInScope && hazardous) {
    yield put(noCoverageResults());
  }
  yield put(setIsInScope(shipmentInScope));
}

export function* toggleFilterItem({ payload }) {
  const { mode, itemKey, active } = payload;
  const rfqKey = getRfqKeyFromUrl();
  if (mode === 'mode') {
    yield put(
      searchAnalytics({
        name: ANALYTICS_EVENT_NAME.RESULTS_FILTER_MODES_SELECTED,
        data: {
          modeSelected: itemKey,
          status: active,
          rfqKey
        }
      })
    );
  }
  if (mode === 'vendors') {
    yield put(
      searchAnalytics({
        name: ANALYTICS_EVENT_NAME.RESULTS_FILTER_SELLER_SELECTED,
        data: {
          modeSelected: itemKey,
          status: active,
          rfqKey
        }
      })
    );
  }
}

export function* setSortingType({ payload }) {
  yield put(
    searchAnalytics({
      name: ANALYTICS_EVENT_NAME.RESULTS_SORTED,
      data: {
        sortBy: payload,
        rfqKey: getRfqKeyFromUrl()
      }
    })
  );
}

export function* getSingleQuote({ payload }) {
  const endpoint = endpointConstructor(ENDPOINTS.singleQuoteSummary, { quoteId: payload });
  try {
    const result = yield call(doGetRequest, endpoint);
    yield put(getSingleQuoteSuccess({ quotes: [result.quote] }));

    const vendors = getVendors(result.quote);
    // @todo take it from current repo - the quote view call id deprecated
    if (vendors) {
      yield put({
        type: 'QuoteView/GET_BUSINESS_DATA',
        payload: vendors[0].ID
      });
    }

    const rfqObject =
      get(result, 'request.additionalInformation', []).find((item) => item.key === 'rfqKey') || {};

    yield put({
      type: GET_RFQ_SUMMARY.SUCCESS,
      payload: {
        quote: mapRfqToQuote({ shipment: result.request }),
        rfq: rfqObject.value
      }
    });
  } catch (error) {
    yield put(monitoringActions.exceptionReporter(error, false));
  }
}

export default function* () {
  yield all([
    takeLatest([GET_RFQ.SUCCESS, resultsActionTypes.QUOTES_POLLING.SUCCESS], saveQuotesSaga),
    takeLatest(resultsActionTypes.GET_SINGLE_QUOTE.SUCCESS, saveQuotesSaga),
    takeLatest(GET_RFQ_SUMMARY.SUCCESS, isInScopeSaga),
    takeLatest(resultsActionTypes.INIT_RESULTS_PAGE, initResultsPage),
    takeLatest(resultsActionTypes.TOGGLE_FILTER_ITEM, toggleFilterItem),
    takeLatest(resultsActionTypes.SET_SORTING_TYPE, setSortingType),
    takeLatest(resultsActionTypes.GET_SINGLE_QUOTE.type, getSingleQuote)
  ]);
}
