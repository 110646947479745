(function webpackUniversalModuleDefinition(root, factory) {
	if(typeof exports === 'object' && typeof module === 'object')
		module.exports = factory(require("React"), require("ProperaFWpackagesmicrofrontsStore"), require("ProperaFWproperasiteConfig"), require("ProperaFWproperauser"), require("ProperaFWproperamonitoring"), require("ProperaFWproperaHTTP"), require("ProperaFWproperaauthUI"), require("ProperaFWlayout"), require("ProperaFWproperaauth"), require("ProperaFWproperapreferences"), require("ProperaFWproperaanalytics"), require("ProperaFWproperatranslations"), require("ReactDOM"), require("ProperaFWpackagesfeatureFlagging"), require("ProperaFWproperautilsurl"), require("ProperaFWproperaforms"), require("ProperaFWproperaauthUIcomponents"), require("ProperaFWproperacookies"), require("ProperaFWproperacomponentsloaders"));
	else if(typeof define === 'function' && define.amd)
		define("SlimSearch", ["React", "ProperaFWpackagesmicrofrontsStore", "ProperaFWproperasiteConfig", "ProperaFWproperauser", "ProperaFWproperamonitoring", "ProperaFWproperaHTTP", "ProperaFWproperaauthUI", "ProperaFWlayout", "ProperaFWproperaauth", "ProperaFWproperapreferences", "ProperaFWproperaanalytics", "ProperaFWproperatranslations", "ReactDOM", "ProperaFWpackagesfeatureFlagging", "ProperaFWproperautilsurl", "ProperaFWproperaforms", "ProperaFWproperaauthUIcomponents", "ProperaFWproperacookies", "ProperaFWproperacomponentsloaders"], factory);
	else if(typeof exports === 'object')
		exports["SlimSearch"] = factory(require("React"), require("ProperaFWpackagesmicrofrontsStore"), require("ProperaFWproperasiteConfig"), require("ProperaFWproperauser"), require("ProperaFWproperamonitoring"), require("ProperaFWproperaHTTP"), require("ProperaFWproperaauthUI"), require("ProperaFWlayout"), require("ProperaFWproperaauth"), require("ProperaFWproperapreferences"), require("ProperaFWproperaanalytics"), require("ProperaFWproperatranslations"), require("ReactDOM"), require("ProperaFWpackagesfeatureFlagging"), require("ProperaFWproperautilsurl"), require("ProperaFWproperaforms"), require("ProperaFWproperaauthUIcomponents"), require("ProperaFWproperacookies"), require("ProperaFWproperacomponentsloaders"));
	else
		root["SlimSearch"] = factory(root["React"], root["ProperaFWpackagesmicrofrontsStore"], root["ProperaFWproperasiteConfig"], root["ProperaFWproperauser"], root["ProperaFWproperamonitoring"], root["ProperaFWproperaHTTP"], root["ProperaFWproperaauthUI"], root["ProperaFWlayout"], root["ProperaFWproperaauth"], root["ProperaFWproperapreferences"], root["ProperaFWproperaanalytics"], root["ProperaFWproperatranslations"], root["ReactDOM"], root["ProperaFWpackagesfeatureFlagging"], root["ProperaFWproperautilsurl"], root["ProperaFWproperaforms"], root["ProperaFWproperaauthUIcomponents"], root["ProperaFWproperacookies"], root["ProperaFWproperacomponentsloaders"]);
})(this, function(__WEBPACK_EXTERNAL_MODULE__0__, __WEBPACK_EXTERNAL_MODULE__5__, __WEBPACK_EXTERNAL_MODULE__8__, __WEBPACK_EXTERNAL_MODULE__9__, __WEBPACK_EXTERNAL_MODULE__11__, __WEBPACK_EXTERNAL_MODULE__12__, __WEBPACK_EXTERNAL_MODULE__13__, __WEBPACK_EXTERNAL_MODULE__14__, __WEBPACK_EXTERNAL_MODULE__16__, __WEBPACK_EXTERNAL_MODULE__18__, __WEBPACK_EXTERNAL_MODULE__23__, __WEBPACK_EXTERNAL_MODULE__25__, __WEBPACK_EXTERNAL_MODULE__30__, __WEBPACK_EXTERNAL_MODULE__35__, __WEBPACK_EXTERNAL_MODULE__37__, __WEBPACK_EXTERNAL_MODULE__57__, __WEBPACK_EXTERNAL_MODULE__132__, __WEBPACK_EXTERNAL_MODULE__133__, __WEBPACK_EXTERNAL_MODULE__135__) {
return 