import React, { useEffect } from 'react';
import { connect } from 'microfronts-redux';
import { Icon, Notification, tokens, Text } from '@freightos/design-system';
import styled from 'styled-components/macro';

import { t } from 'utils/translationProvider';
import { MODES } from 'slimSearch/constants';
import { latestBookings, latestBookingsVisible } from 'latestBookings/selectors';
import { getLatestBookings, hideLatestBooking } from 'latestBookings/actions';
import {
  LOAD_TYPES,
  LOCAL_STORAGE_LATEST_BOOKINGS_COUNT_KEY,
  MAX_COUNTS_TO_STOP_CALLING_PERMANENTLY,
  SESSION_STORAGE_LATEST_BOOKINGS_HIDE_KEY
} from 'latestBookings/constants';
import { isMobileSelector } from 'slimSearch/selectors';

/**
 * LatestBookings
 *
 * This component renders 5 (MAX_CALLS_PER_SESSION) times, latest bookings by time interval of 5000 ms. (LATEST_BOOKINGS_INTERVAL_MS).
 * After 5 (MAX_CALLS_PER_SESSION) times it stops.
 * If user closes the notification from X button - it will not show per this session (this open tab)
 * User will be able to close it 5 (MAX_COUNTS_TO_STOP_CALLING_PERMANENTLY) times,
 *  after this it will never show, meaning user will see the notifications for 5 (MAX_COUNTS_TO_STOP_CALLING_PERMANENTLY) sessions.
 */
export const LatestBookings = ({ visible, booking, getLatestBookings, hideLatestBooking }) => {
  const key = 'only-one-instance';
  const localStorageCount = window.localStorage.getItem(LOCAL_STORAGE_LATEST_BOOKINGS_COUNT_KEY);
  const showBookings =
    window.sessionStorage.getItem(SESSION_STORAGE_LATEST_BOOKINGS_HIDE_KEY) !== 'true';

  const calculateLastBookedTitle = (bookTime) => {
    const hours = Math.floor(bookTime / 60); // bookTime is minutes since now
    if (isNaN(hours) || bookTime === 0) {
      return `${t('latestBookings/title', 'Last booked')}`;
    }

    if (hours === 0) {
      return `${t('latestBookings/titleMinutes', 'Last booked {bookTime} minutes ago', {
        bookTime
      })}`;
    } else {
      return `${t('latestBookings/titleHours', 'Last booked {hours} hour(s) ago', { hours })}`;
    }
  };

  useEffect(() => {
    if (
      (!localStorageCount || localStorageCount < MAX_COUNTS_TO_STOP_CALLING_PERMANENTLY) &&
      showBookings
    ) {
      getLatestBookings();
    }
  }, [getLatestBookings, localStorageCount, showBookings]);

  useEffect(() => {
    if (booking && showBookings) {
      Notification.info({
        message: (
          <MessageTitle level={4}>{calculateLastBookedTitle(booking?.bookTime)}</MessageTitle>
        ),
        description: (
          <Description>
            <NoWrap>{booking.origin}</NoWrap>{' '}
            <StyledIcon type={MODES[booking.mode]?.icon ?? 'arrow-forward'} />{' '}
            <NoWrap>{booking.destination}</NoWrap>{' '}
            <Load ellipsis>
              {booking.load.units} <Icon type={LOAD_TYPES[booking.load.type]?.icon ?? 'box'} />{' '}
              {LOAD_TYPES[booking.load.type]?.label ?? ''}
            </Load>
          </Description>
        ),
        icon: <Icon type="history" size="medium" color={tokens.fdsColorPrimaryBlue} />,
        style: {
          backgroundColor: '#F7FAFF',
          width: 'max-content',
          paddingRight: 40
        },
        placement: 'bottomLeft',
        key,
        onClose: () => {
          hideLatestBooking();
          Notification.destroy(key);
          window.sessionStorage.setItem(SESSION_STORAGE_LATEST_BOOKINGS_HIDE_KEY, 'true');
          window.localStorage.setItem(
            LOCAL_STORAGE_LATEST_BOOKINGS_COUNT_KEY,
            +localStorageCount + 1
          );
        },
        duration: null // never auto-closing,
      });
    }
  }, [visible, booking, getLatestBookings, localStorageCount, hideLatestBooking, showBookings]);

  // components return nothing, the Notification added to body by design via useEffect
  return null;
};

const NoWrap = styled(Text)`
  white-space: nowrap;
`;

const Load = styled.div`
  margin-left: ${tokens.fdsComponentSpacingLg};
  color: ${tokens.fdsColorPrimaryGray80};
  font-size: ${tokens.fdsH6FontSize};
`;

const StyledIcon = styled(Icon)`
  margin: 0 ${tokens.fdsComponentSpacingSm};
`;

const Description = styled.div`
  display: flex;
  align-items: center;
  color: ${tokens.fdsColorPrimaryGray80};
  font-size: ${tokens.fdsH6FontSize};
`;

const MessageTitle = styled.h5`
  font-size: ${tokens.fdsH6FontSize};
  font-weight: ${tokens.fdsFontWeightBold};
`;

const mapStateToProps = (state) => ({
  booking: latestBookings(state),
  visible: latestBookingsVisible(state),
  isMobile: isMobileSelector(state)
});

export default connect(mapStateToProps, {
  getLatestBookings,
  hideLatestBooking
})(LatestBookings);
