import { Button, Icon, Tooltip } from '@freightos/design-system';
import { fdsColorPrimaryGray30 } from '@freightos/design-system/dist/tokens';
import { useDispatch, useSelector } from 'microfronts-redux';
import React from 'react';
import { displayFiltersView, resetAllFilters } from 'results/results.actions';
import {
  activeFiltersSelector,
  resultsCountSelector,
  selectQuotesIdsList,
  currentSortSelector
} from 'results/results.selectors';
import { ResultsScreenValues } from 'slimSearch/constants';
import styled from 'styled-components/macro';
import { t } from 'utils/translationProvider';

const pluralQuotes = (number = 0) => {
  if (number < 1) {
    return '';
  }
  return number === 1 ? t('resultsTitle/quote', 'Quote') : t('resultsTitle/quotes', 'Quotes');
};

const getTopQuotesString = (uniqueResults) => {
  if (uniqueResults === 0) {
    return '';
  }
  if (uniqueResults === 1) {
    return t('topQuotesByProvider/Single', '1 Top Quote');
  }
  return t('topQuotesByProvider', `Top {uniqCount} Quotes`, {
    uniqCount: uniqueResults
  });
};
const ResultCountTitleComponent = () => {
  const dispatch = useDispatch();
  const { resultsCount = 0, uniqueResults = 0 } = useSelector(resultsCountSelector);
  const activeFilters = useSelector(activeFiltersSelector);
  const sortingType = useSelector(currentSortSelector);
  const { main } = useSelector(selectQuotesIdsList);
  return (
    <ResultsMessageSlot>
      {resultsCount > 0 && (
        <ResultCountMessage>
          {sortingType !== 'bestValue' ? (
            <>
              <UniqCountLabel>
                {t('filteredQuotesCount', `{quotesLength} ${pluralQuotes(main.length)}`, {
                  quotesLength: main.length || 0
                })}
              </UniqCountLabel>
              <TotalCountLabel>
                {activeFilters.length > 0 &&
                  t('totalQuotes', '({total} in total)', { total: resultsCount })}
              </TotalCountLabel>
            </>
          ) : (
            <>
              <UniqCountLabel>
                {activeFilters.length === 0 && uniqueResults > 0
                  ? getTopQuotesString(uniqueResults)
                  : t(
                      'filteredQuotesCount',
                      `{ quotesLength } ${pluralQuotes(
                        activeFilters.length ? main.length || 0 : resultsCount
                      )}`,
                      {
                        quotesLength: activeFilters.length > 0 ? main.length || 0 : resultsCount
                      }
                    )}
              </UniqCountLabel>

              <TotalCountLabel>
                {(activeFilters.length > 0 || uniqueResults > 0) &&
                  t('totalQuotes', '({total} in total)', { total: resultsCount })}
                {activeFilters.length === 0 && (
                  <Tooltip
                    title={t(
                      'common/TopQoutesByProviderTooltip',
                      'These results reflect a balance between cost and transit time from each provider on your lane.'
                    )}
                  >
                    <Icon type="info-circled" />
                  </Tooltip>
                )}
              </TotalCountLabel>
            </>
          )}
        </ResultCountMessage>
      )}

      <OpenFilterDrawerWrapper>
        {activeFilters.length > 0 && (
          <Button type="text" onClick={() => dispatch(resetAllFilters())}>
            {t('filters/clearAll', 'Clear All')}
          </Button>
        )}
        <OpenFilterDrawer
          onClick={() => dispatch(displayFiltersView(true))}
          icon="filter"
          size="small"
          type={activeFilters.length > 0 ? 'primary' : 'secondary'}
          /*activeFilters={activeFilters.length > 0}*/
        />
      </OpenFilterDrawerWrapper>
    </ResultsMessageSlot>
  );
};

const ResultsMessageSlot = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  i.anticon {
    margin-left: 4px;
    transform: translateY(2px);
  }
  @media (min-width: ${ResultsScreenValues.desktop}px) {
    margin-right: 12px;
    width: 240px;
  }
  @media (max-width: ${ResultsScreenValues.desktop - 1}px) {
    margin-bottom: 8px;
  }
`;

const ResultCountMessage = styled.div`
  display: flex;
  align-items: center;
`;

const UniqCountLabel = styled.div`
  font-weight: bold;
  font-size: 14px;
  margin-right: 4px;
`;

const TotalCountLabel = styled.div`
  font-size: 12px;
  margin-left: 2px;
`;

const OpenFilterDrawerWrapper = styled.div`
  display: flex;
  align-items: center;
  @media (min-width: ${ResultsScreenValues.desktop}px) {
    display: none;
  }
`;

const OpenFilterDrawer = styled(Button)`
  &.ant-btn.ant-btn-icon-only {
    border: 1px solid ${fdsColorPrimaryGray30};
    //color: ${fdsColorPrimaryGray30};
    //padding: 1px 1px 0 1px;
    margin-left: 12px;
    i.anticon {
      transform: translate(-2px, 1px);
    }
    //border-radius: 3px;

    /*@media (min-width: ${ResultsScreenValues.midDesktop}px) {
      top:initial;
      bottom: 2px;
    }*/
  }
`;

export default ResultCountTitleComponent;
