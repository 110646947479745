import React, { useState, useEffect } from 'react';
import { Slider } from '@freightos/design-system';
import { useDispatch } from 'microfronts-redux';
import { rangeFilterItem } from 'results/results.actions';
import { DATE_FORMAT } from 'slimSearch/constants';

import styled from 'styled-components/macro';
import moment from 'moment';

const formatter = (max) => (value) => moment(max).subtract(value, 'days').format('MMM D, YYYY');

const rangeValueToDate = (max, value) => moment(max).subtract(value, 'days').format();

const dateToRangeValues = (max, range) => moment(max).diff(range, 'd');

const rangeValuesView = (filter) => {
  const start = filter.currentRange?.length
    ? moment(filter.currentRange[0]).format(DATE_FORMAT)
    : moment(filter.range.min).format(DATE_FORMAT);
  return `${start} - ${moment(filter.range.max).format(DATE_FORMAT)}`;
};

const DateRangeFilter = ({ filter }) => {
  const dispatch = useDispatch();

  const dateDiff = moment(filter.range.max).diff(filter.range.min, 'd');
  const tipFormatter = formatter(filter.range.max);
  const [value, setValue] = useState(0);

  useEffect(() => {
    const valueItem = filter.currentRange?.length
      ? dateToRangeValues(filter.range.max, filter.currentRange[0])
      : dateDiff;

    setValue(valueItem);
  }, [filter, dateDiff]);

  return (
    <>
      <SelectedValues>{rangeValuesView(filter)}</SelectedValues>
      <StyledSlider
        value={value}
        min={0}
        max={dateDiff}
        onChange={(num) => setValue(num)}
        onAfterChange={(range) => {
          const rangeValues = [rangeValueToDate(filter.range.max, range), filter.range.max];

          dispatch(
            rangeFilterItem({
              mode: filter.key,
              range: rangeValues
            })
          );
        }}
        tipFormatter={tipFormatter}
        reverse={true}
      ></StyledSlider>
    </>
  );
};

export default DateRangeFilter;

const StyledSlider = styled(Slider)`
  padding: 0 5px;
  width: 85%;
`;

const SelectedValues = styled.div`
  font-size: 10px;
  transform: translateX(-6px);
`;
