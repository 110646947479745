import React, { useState } from 'react';
import { connect } from 'react-redux';
import { Card, Input, FormItem, Row, Icon, Alert, Result, Col } from '@freightos/design-system';
import * as selectors from 'financialSharedComponents/selectors';
import Loading from 'financialSharedComponents/components/common/Loading';
import { isTestEnv, isLocalEnv } from 'propera/utils/url';
import { doPaymentLinkPayment } from './actions';
import CreditCardTokenizeFormFactory from '../Payment/CreditCardsMgmt/CreditCardTokenizeForm';

const mapStateToProps = (state) => ({
  clientToken:
    isTestEnv() || isLocalEnv()
      ? 'sandbox_x65m8bjc_8p6h6fghqg4mnjws'
      : 'production_ndjksbgw_3tn8wxzbx2797sxx',
  paymentInProgress: selectors.paymentLinkPaymentInProgress(state),
  error: selectors.paymentLinkError(state),
  paymentSucceeded: selectors.paymentLinkSucceeded(state)
});

function paramsToObj(entries) {
  const result = {};
  for (const [key, value] of entries) {
    result[key] = value;
  }
  return result;
}

const getParamLabel = (param) => {
  switch (param) {
    case 'order_id': return 'Order ID'; break;
    default: return param.replace('_', ' ').replace(/(^\w|\s\w)/g, m => m.toUpperCase());
  }
}

const PaymentLink = ({
  clientToken,
  paymentInProgress,
  error,
  paymentSucceeded,
  doPaymentLinkPayment
}) => {
  const paramsObj = paramsToObj(new URLSearchParams(window.location.search).entries());
  const paymentAmount = parseFloat(paramsObj['amount']);
  const currency = paramsObj['currency'];
  const description = paramsObj['description'];
  const orderId = paramsObj['order_id'];
  const paramsArr = Object.entries(paramsObj);
  return (
    <Col style={{ marginTop: 20 }} span={12} offset={6}>
      <Card title="Direct Payment">
        <Alert message="Please make sure you correctly copied the URL our team sent. Double check that all the information is correct before paying." type="warning" closable={false} />
        <br />
        <Alert message="Freightos does not keep or track any credit card details entered on this page." type="info" closable={false} />
        <br />
        <Row gutter={[16, 16]}>
          {paramsArr.map((param) => (
            <Col span={6}>
              <FormItem label={getParamLabel(param[0])}>
                {param[1]}
              </FormItem>
            </Col>
          ))}
        </Row>
        {!paymentSucceeded && !error && !paymentInProgress && (
          <React.Fragment>
            <CreditCardTokenizeFormFactory brainTreeApiKey={clientToken} pspName="braintree" onSubmitToken={(paymentObj) => {
              doPaymentLinkPayment({
                ...paymentObj,
                paymentAmount,
                currency,
                description,
                orderId
              })
            }} />
          </React.Fragment>
        )}
        {paymentInProgress && <Loading />}
        {!paymentInProgress && paymentSucceeded && (
          <Row gutter={[16, 16]}>
            <Result
              icon={<Icon type="verified" color="green" size="large" />}
              title="Payment successful!"
              subTitle="It may take up to 1 business day to see this payment on your billing page"
            />
          </Row>
        )}
        {!paymentInProgress && error && (
          <Row gutter={[16, 16]}>
            <Result
              icon={<Icon type="negative-circled" color="red" size="large" />}
              title="Payment was unsuccessful!"
              subTitle="Your direct payment wasn't created. Please make sure you didn't change the URL or contact our support team for more details."
            />
          </Row>
        )}
      </Card>
    </Col>
  );
};
export default connect(mapStateToProps, { doPaymentLinkPayment })(PaymentLink);
