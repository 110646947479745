/* eslint-disable no-console */
import React from 'react';
import { Checkbox } from '@freightos/design-system';
import { useDispatch, useSelector } from 'microfronts-redux';
import { toggleFilterItem } from 'results/results.actions';
import { resultsFiltersCount } from 'results/results.selectors';
import styled from 'styled-components/macro';

import {
  fdsColorPrimaryGray80,
  fdsColorPrimaryGray40,
  fdsColorPrimaryGray30
} from '@freightos/design-system/dist/tokens';

const ToggleFilter = ({ filter }) => {
  const dispatch = useDispatch();
  const filtersCount = useSelector(resultsFiltersCount);
  return (
    <>
      {filter.list
        .slice()
        .sort((a, b) => (a.key.toLowerCase() > b.key.toLowerCase() ? 1 : -1))
        .map((listItem) => (
          <OptionWrapper key={listItem.key}>
            <Checkbox
              onChange={() => {
                dispatch(
                  toggleFilterItem({
                    mode: filter.key,
                    itemKey: listItem.key,
                    active: !listItem.active
                  })
                );
              }}
              checked={listItem.active}
              disabled={!Boolean(filtersCount[listItem.key])}
            >
              <span className="filter-name">
                <span>{listItem.key}</span>
              </span>
              {Boolean(filtersCount[listItem.key]) && (
                <span className="filter-count">{`(${filtersCount[listItem.key]})`}</span>
              )}
            </Checkbox>
          </OptionWrapper>
        ))}
    </>
  );
};

const OptionWrapper = styled.div`
  display: flex;
  justify-content: flex-start;
  margin: 10px 0;
  .ant-checkbox-wrapper {
    display: flex;
    align-items: center;
  }
  .ant-checkbox-wrapper-disabled .filter-name {
    color: ${fdsColorPrimaryGray30};
  }
  .filter-name {
    max-width: 180px;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    display: inline-block;
    vertical-align: middle;
    color: ${fdsColorPrimaryGray80};
  }
  & .filter-count {
    margin-left: 4px;
    display: inline-block;
    color: ${fdsColorPrimaryGray40};
  }
`;

export default ToggleFilter;
