import * as AT from 'financialSharedComponents/constants/actionTypes';

export const doPaymentLinkPayment = (payload) => ({
  type: AT.DO_PAYMENT_LINK_PAYMENT,
  payload
});
export const doPaymentLinkPaymentSuccess = (payload) => ({
  type: AT.DO_PAYMENT_LINK_PAYMENT_SUCCESS,
  payload
});
export const doPaymentLinkPaymentFail = (payload) => ({
  type: AT.DO_PAYMENT_LINK_PAYMENT_FAIL,
  payload
});
