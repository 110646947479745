import { t } from 'utils/translationProvider';

export const STRIPE_ERROR_CODES = {
  INVALID_NUMBER: 'invalid_number',
  INVALID_EXPIRY_YEAR_PAST: 'invalid_expiry_year_past',
  INVALID_EXPIRY_MONTH_PAST: 'invalid_expiry_month_past',
  INCOMPLETE_NUMBER: 'incomplete_number',
  INCOMPLETE_EXPIRY: 'incomplete_expiry',
  INCOMPLETE_CVC: 'incomplete_cvc'
};

export const WRONG_CARD_NUMBER = t('Invalid card number', 'Invalid card number');
export const WRONG_EXPIRY_DATE = t('Invalid expiry date', 'Invalid expiry date');
export const WRONG_CVC = t('Invalid CVC', 'Invalid CVC');

export const CC_FORM_STYLE = {
  base: {
    color: '#373848',
    fontWeight: 200,
    fontSize: '16px',
    marginTop: '4px',
    lineHeight: 1.6
  }
};
