import React from 'react';
import { Icon } from '@freightos/design-system';
import styles from 'financialSharedComponents/components/styles.module.scss';

const Loading = () => (
  <div className={styles.loading}>
    <Icon type="loading" size="large" color="@fds-component-primary-color-click" />
  </div>
);

export default Loading;
