/* eslint-disable react/jsx-indent, jsx-a11y/label-has-for */
import React, { useEffect, useState } from 'react';
import { connect } from 'microfronts-redux';
import styled from 'styled-components/macro';
import {
  Alert,
  Button,
  CheckboxGroup,
  Col,
  Form,
  FormItem,
  Input,
  InputPhoneNumber,
  Option,
  Row,
  Select,
  TextArea
} from '@freightos/design-system';
import {
  fdsBorderWidthSm,
  fdsColorPrimaryGray20,
  fdsComponentSpacingLg,
  fdsComponentSpacingSm
} from '@freightos/design-system/dist/tokens';
import get from 'lodash/get';
import isNaN from 'lodash/isNaN';
import { doGet } from 'propera/HTTP';
import { constants as userConstants } from 'propera/user';
import { quotesPollingCompleted } from 'results/results.actions';
import { t } from 'utils/translationProvider';
import {
  model as siteConfigModel,
  utils as siteConfigUtils,
  constants as siteConfigConstants
} from 'propera/siteConfig';
import userModel from 'propera/user';
import { isMobileSelector, getRfqSelector } from 'slimSearch/selectors';
import { isPollingSelector } from 'results/results.selectors';
import { getShipmentTypeByLoadData } from 'slimSearch/utils/mapOpenFreight.utils';
import {
  commoditiesOptions,
  customQuoteFormName,
  shipmentModeOptions,
  shipmentModeValues
} from 'customQuote/constants';
import * as actions from 'customQuote/actions';
import * as selectors from 'customQuote/customQuote.selectors';
import { getCustomQuotePageServerError } from 'customQuote/customQuote.selectors';

const { userTypeSelector } = userModel;
const { MP_DOMAIN } = siteConfigConstants;
const { notAllowedCommodity, submitCustomQuoteRequest } = actions;

const strongEmailValidationPattern =
  /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

const PhoneField = ({ form, isMobile }) => (
  <Col span={isMobile ? 24 : 12}>
    <FormItem
      colon={false}
      optional
      label={<label>{t('common/phoneNumber', 'Phone Number')} </label>}
    >
      {form.getFieldDecorator('phoneNumber', { initialValue: '' })(
        <InputPhoneNumber
          getPopupContainer={(trigger) => trigger.parentNode}
          errorsEnabled={false}
          onChange={(value) => {
            form.setFieldsValue({ phoneNumber: value });
          }}
          size={isMobile ? 'large' : 'default'}
        />
      )}
    </FormItem>
  </Col>
);

const CustomQuote = ({
  form,
  userRole,
  allowLongIntakeForm,
  shipmentType,
  initialValues,
  serverError,
  isLabeledMarketplace,
  supportedCurrencies,
  insuranceValueAmount,
  insuranceValueCurrency,
  hasInsurance,
  isPolling,
  rfqKey,
  fullName,
  isMobile,
  requestCustomQuote,
  notAllowedCommodity,
  quotesPollingCompleted,
  sites
}) => {
  useEffect(() => {
    form.setFieldsValue({ sourceSiteDomain: MP_DOMAIN });
    // eslint-disable-next-line
  }, [sites]);
  useEffect(() => {
    if (userRole === 'BUYER') {
      form.setFieldsValue({ fullName: fullName });
    }
    // eslint-disable-next-line
  }, [userRole]);
  const [displayPoNumber, setDisplayPoNumber] = useState(false);
  const [showOtherCommodityInput, setShowOtherCommodityInput] = useState(false);
  const [emailValidateResult, setEmailValidateResult] = useState('none');
  const [emailHasFeedback, setEmailHasFeedback] = useState(false);
  const [buyerData, setBuyerData] = useState({});
  const [previousEmailValue, setPreviousEmailValue] = useState('');
  const [previousEmailValidation, setPreviousEmailValidation] = useState('');
  const [isSubmitting, setSubmitting] = useState(false);
  const [priceToBeatCurrency, setPriceToBeatCurrency] = useState('USD');
  const [shipmentValueCurrency, setShipmentValueCurrency] = useState(insuranceValueCurrency);
  if (isPolling) {
    quotesPollingCompleted({ status: 'custom-quote', rfqKey: rfqKey });
  }
  const onCommoditySelectChange = () => {
    const commodities = form.getFieldValue('commodities');

    if (commodities === '13') {
      // key equals Others
      setShowOtherCommodityInput(true);
    } else {
      setShowOtherCommodityInput(false);
    }
  };

  const getBuyerInfo = async (rule, value, callback) => {
    const valueIsValid =
      typeof value === 'string' &&
      !!value.match(strongEmailValidationPattern) &&
      value.length < 255;

    if (allowLongIntakeForm) {
      if (value && !value.replace(/\s+/, '').length) {
        setEmailValidateResult('error');
        setEmailHasFeedback(false);
        callback(t('common/emailRequiredMessage', 'Login email is required'));
        return;
      }
    }
    if (!valueIsValid) {
      setEmailValidateResult('error');
      setEmailHasFeedback(false);
      callback(t('common/emailValidMessage', 'Value must be a valid email'));
      return;
    }

    if (value === previousEmailValue) {
      if (previousEmailValidation) {
        setEmailValidateResult('error');
        setEmailHasFeedback(true);
      }
      callback(previousEmailValidation);
      return;
    }
    const endpoint = `${userConstants.END_POINT}/${value}/details-for-custom-quote`;
    setEmailValidateResult('validating');
    setEmailHasFeedback(true);

    try {
      const { data } = await doGet(endpoint);

      setBuyerData({
        buyerKey: get(data, 'key'),
        buyerBusinessKey: get(data, 'business.key'),
        company: get(data, 'business.legalName'),
        country: get(data, 'business.businessCountry'),
        buyerEmail: get(data, 'email')
      });
      setEmailValidateResult('success');
      setEmailHasFeedback(true);
      setPreviousEmailValue(value);
      setPreviousEmailValidation(undefined);
      callback();
    } catch (error) {
      const validationMessage = t(
        'customQuote/EmailNotExists',
        'No Freightos user with that email exists. Please ask the user to signup.'
      );
      setEmailValidateResult('error');
      setEmailHasFeedback(true);
      setPreviousEmailValue(value);
      setPreviousEmailValidation(validationMessage);
      callback(validationMessage);
    }
  };

  /* eslint-disable class-methods-use-this */
  const mapValues = (values) => {
    return {
      fullName: values.fullName,
      productType: values.commodity,
      preferredModes: values.shipmentMode,
      comments: values.comments,
      buyersCommunication: values.buyersCommunication,
      priceToBeat: { amount: values.priceToBeat, currency: priceToBeatCurrency },
      phoneNumber: values.phoneNumber,
      poNumbers: values.poNumbers && values.poNumbers.replace(/\//g, ','),
      internalAuth: values.internalAuth && values.internalAuth.replace(/\//g, ','),
      sourceSiteDomain: values.sourceSiteDomain
    };
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setSubmitting(true);
    form.validateFields((err, values) => {
      if (!err) {
        const commodity = commoditiesOptions.find((item) => item.key === values.commodities);

        if (commodity.status === 'no') {
          notAllowedCommodity({ commodity: commodity.label });
        } else {
          const commodityOthers = Boolean(commodity.status === 'optional');
          const commodityValue = commodityOthers
            ? (commodityOthers ? 'others-' : '') + values.commodityDescription
            : commodity.label;
          if (shipmentType === 'fcl') {
            /* eslint-disable-next-line no-param-reassign */
            values.shipmentMode = [shipmentModeValues.OCEAN];
          }
          const result = {
            ...initialValues,
            ...mapValues({ ...values, commodity: commodityValue }),
            ...buyerData,
            userRole
          };
          if (values.insurance && values.insurance >= 0) {
            result.options = {
              ...result.options,
              insurance: true,
              insuranceValue: {
                currency: shipmentValueCurrency,
                amount: values.insurance
              }
            };
          } else if (hasInsurance) {
            // this is if the shipment already has insurance
            result.options = {
              ...result.options,
              insurance: true,
              insuranceValue: {
                currency: insuranceValueCurrency,
                amount: insuranceValueAmount
              }
            };
          }
          requestCustomQuote(result);
        }
      } else {
        setSubmitting(false);
      }
    });
  };

  const { getFieldDecorator, getFieldError } = form;

  const insurance = hasInsurance;
  let shipmentValue = insuranceValueAmount;

  if (isNaN(shipmentValue)) {
    shipmentValue = undefined;
  }
  return (
    <div className={`custom-quote-form-container ${allowLongIntakeForm ? 'long-form' : ''}`}>
      {serverError && (
        <Alert message={serverError} type="danger" className="custom-form-alert-message" />
      )}
      <Form
        className="custom-quote-form"
        onSubmit={(e) => handleSubmit(e)}
        colon={false}
        hideRequiredMark={true}
        noValidate
      >
        {shipmentType === 'lcl' && (
          <Row>
            <Col>
              <FormItem label={<label>{t('common/preferredModes', 'Preferred mode(s)')}</label>}>
                {getFieldDecorator('shipmentMode', {
                  rules: [
                    {
                      required: true,
                      message: t(
                        'common/preferredModesRequiredMessage',
                        'Preferred Modes is required'
                      )
                    }
                  ]
                })(<CheckboxGroup options={shipmentModeOptions} />)}
              </FormItem>
            </Col>
          </Row>
        )}
        <Row gutter={16}>
          {allowLongIntakeForm && (
            <Col span={isMobile ? 24 : 12} className="custom-quote-buyer-email">
              <FormItem
                colon={false}
                label={<label>{t('customQuote/buyerEmail', "Buyer's Login email")}</label>}
                required={false}
                hasFeedback={emailHasFeedback}
                validateStatus={emailValidateResult}
              >
                {getFieldDecorator('buyersLoginEmail', {
                  validate: [
                    {
                      trigger: 'onFocus',
                      rules: [
                        {
                          validator: (rule, value, callback) => {
                            setEmailHasFeedback(false);
                            setEmailValidateResult('none');
                            callback();
                          }
                        }
                      ]
                    },
                    {
                      trigger: 'onBlur',
                      rules: [
                        {
                          validator: (rule, value, callback) => getBuyerInfo(rule, value, callback)
                        }
                      ]
                    }
                  ]
                })(
                  <Input
                    size={isMobile ? 'large' : 'default'}
                    placeholder={t(
                      'cq/cq_intake_buyer_email_prompt_text',
                      "Enter the Buyer's email on freightos"
                    )}
                  />
                )}
              </FormItem>
            </Col>
          )}
          <Col span={isMobile ? 24 : 12}>
            <FormItem
              label={<label>{t('customQuote/FullName', 'Full name')}</label>}
              required={false}
            >
              {getFieldDecorator('fullName', {
                rules: [
                  {
                    required: true,
                    message: t('common/FullNameRequiredMessage', 'Full name is required')
                  }
                ]
              })(
                <Input
                  size={isMobile ? 'large' : 'default'}
                  placeholder={t('customQuote/fullNamePlaceHolder', 'Enter first and last name')}
                />
              )}
            </FormItem>
          </Col>
          {!allowLongIntakeForm && <PhoneField isMobile={isMobile} form={form} />}
        </Row>
        <Row>
          <Col span={24}>
            <FormItem
              colon={false}
              label={<label>{t('commodities/Commodities', 'Commodities')}</label>}
              required={false}
            >
              {getFieldDecorator('commodities', {
                rules: [
                  {
                    required: true,
                    message: t('common/commoditiesRequiredMessage', 'Commodities is required')
                  }
                ]
              })(
                <Select
                  size={isMobile ? 'large' : 'default'}
                  id="commodities-selection"
                  showSearch={true}
                  placeholder={t('common/select', 'Select')}
                  showArrow={true}
                  filterOption={(value, options) =>
                    options.props.children.toLowerCase().includes(value.toLowerCase())
                  }
                  onSelect={(event) => onCommoditySelectChange(event)}
                  getPopupContainer={() => document.getElementById('custom-quote-form_commodities')}
                  notFoundContent={null}
                >
                  {commoditiesOptions.map((item) => {
                    const label = t(`customQuote/${item.key}`, item.label);
                    return (
                      <Option key={item.key} title={label}>
                        {label}
                      </Option>
                    );
                  })}
                </Select>
              )}
            </FormItem>
          </Col>
        </Row>
        {showOtherCommodityInput && (
          <Row>
            <Col>
              <FormItem required={false}>
                {getFieldDecorator('commodityDescription', {
                  rules: [
                    {
                      required: true,
                      message: t('common/commoditiesRequiredMessage', 'Commodities is required')
                    }
                  ]
                })(
                  <Input
                    size={isMobile ? 'large' : 'default'}
                    placeholder={t(
                      'common/commoditiesInput/placeholder',
                      'Type in your commodities'
                    )}
                  />
                )}
              </FormItem>
            </Col>
          </Row>
        )}
        {allowLongIntakeForm && (
          <Row>
            <Col span={24}>
              <FormItem colon={false} label={<label>{t('common/site', 'Site')} </label>}>
                {getFieldDecorator('sourceSiteDomain')(
                  <Select
                    size={isMobile ? 'large' : 'default'}
                    getPopupContainer={(trigger) => trigger.parentNode}
                  >
                    {sites.map((site) => (
                      <Option key={site} value={site}>
                        {site}
                      </Option>
                    ))}
                  </Select>
                )}
              </FormItem>
            </Col>
          </Row>
        )}
        <Row className="form-item-comments">
          <Col span={24}>
            <FormItem
              colon={false}
              optional
              label={<label>{t('common/comments', 'Comments')} </label>}
            >
              {getFieldDecorator('comments')(
                <TextArea
                  size={isMobile ? 'large' : 'default'}
                  rows={2}
                  autoSize={{ maxRows: 3, minRows: 2 }}
                  maxLength="1000"
                  placeholder={t(
                    'customQuote/typeInComments',
                    'Is there anything else we should know?'
                  )}
                />
              )}
            </FormItem>
          </Col>
        </Row>
        {userRole === 'SYS_ADMIN' && (
          <React.Fragment>
            <Row gutter={16}>
              <PhoneField isMobile={isMobile} form={form} />
              <Col span={isMobile ? 24 : 12}>
                <FormItem
                  optional
                  label={<label>{t('customQuote/priceToBeat', 'Price To Beat')} </label>}
                >
                  {getFieldDecorator('priceToBeat', {
                    initialValue: undefined
                  })(
                    <Input
                      type="number"
                      size={isMobile ? 'large' : 'default'}
                      formatter={(value) =>
                        value && `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
                      }
                      parser={(value) => value && value.replace(/\$\s?|([^0-9]+)/g, '')}
                      step={100}
                      min={0}
                      addonAfter={
                        <Select
                          style={{ width: 64 }}
                          value={priceToBeatCurrency}
                          onChange={(value) => setPriceToBeatCurrency(value)}
                        >
                          {Object.keys(supportedCurrencies).map((currency) => (
                            <Option key={currency} value={currency}>
                              {currency}
                            </Option>
                          ))}
                        </Select>
                      }
                      placeholder={t(
                        'cq/cq_intake_price_to_beat_prompt_text',
                        'Goods value in USD'
                      )}
                    />
                  )}
                </FormItem>
              </Col>
            </Row>
          </React.Fragment>
        )}
        {!insurance && (
          <Row align="middle" className="insurance-form-part">
            <Col span={isMobile ? 24 : 12}>
              <FormItem
                label={
                  <label>
                    {t('customQuoteForm/AddInsurance', 'Add insurance')}

                    {!isLabeledMarketplace && (
                      <span className="">
                        {' '}
                        (
                        {t(
                          'customQuoteForm/InsuranceRequiredNotification',
                          'required for all Custom Quotes'
                        )}
                        )
                      </span>
                    )}
                    <span className="small-gray-text" />
                  </label>
                }
                required={false}
                help={
                  (getFieldError('insurance') && getFieldError('insurance')[0]) ||
                  `(${t(
                    'customQuoteForm/InsuranceComment',
                    'Insurance covers goods value only, up to $500k'
                  )})`
                }
              >
                {getFieldDecorator('insurance', {
                  initialValue: shipmentValue,
                  rules: [
                    {
                      required: !isLabeledMarketplace,
                      message: t('common/InsuranceRequiredMessage', 'Insurance is required')
                    }
                  ]
                })(
                  <Input
                    size={isMobile ? 'large' : 'default'}
                    type="number"
                    formatter={(value) =>
                      value && `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
                    }
                    parser={(value) => value && value.replace(/\$\s?|([^0-9]+)/g, '')}
                    step={100}
                    min={1}
                    placeholder={t('cq/cq_intake_insurance_prompt_text', 'Enter goods value')}
                    addonAfter={
                      <Select
                        style={{ width: 64 }}
                        value={shipmentValueCurrency}
                        onChange={(value) => setShipmentValueCurrency(value)}
                      >
                        {Object.keys(supportedCurrencies).map((currency) => (
                          <Option key={currency} value={currency}>
                            {currency}
                          </Option>
                        ))}
                      </Select>
                    }
                  />
                )}
              </FormItem>
            </Col>
          </Row>
        )}
        {!displayPoNumber ? (
          <Row>
            <Col span={24}>
              <Button
                type="tertiary"
                icon="plus-circled"
                className="po-number-link"
                onClick={() => setDisplayPoNumber(true)}
              >
                {t('customQuote/addPoNumber', 'Add internal reference number')}
              </Button>
            </Col>
          </Row>
        ) : (
          <Row gutter={16}>
            <Col span={isMobile ? 24 : 12}>
              <FormItem
                optional
                label={
                  <label>{t('customQuote/internalAuthNumber', 'Internal Auth numbers')} </label>
                }
              >
                {getFieldDecorator(
                  'internalAuth',
                  {}
                )(
                  <Input
                    size={isMobile ? 'large' : 'default'}
                    placeholder={t(
                      'cq/cq_intake_internal_auth_prompt_text',
                      'Enter internal auth numbers'
                    )}
                  />
                )}
              </FormItem>
            </Col>
            <Col span={isMobile ? 24 : 12}>
              <FormItem
                colon={false}
                optional
                label={<label>{t('booking/poNumbers', 'PO Numbers')} </label>}
              >
                {getFieldDecorator('poNumbers')(
                  <Input
                    size={isMobile ? 'large' : 'default'}
                    placeholder={t(
                      'cq/cq_intake_po_numbers_prompt_text',
                      'Enter PO numbers and separate them with / '
                    )}
                  />
                )}
              </FormItem>
            </Col>
          </Row>
        )}

        <Row className="submit-button-container">
          <StyledSubmit>
            <Button
              type={'primary'}
              htmlType="submit"
              block
              size="large"
              disabled={emailValidateResult === 'validating' || (isSubmitting && !serverError)}
              loading={isSubmitting}
            >
              {t('cq/cq_intake_cta_button_text', 'Request a Custom Quote')}
            </Button>
          </StyledSubmit>
        </Row>
      </Form>
    </div>
  );
};

const mapStateToProps = (store) => {
  const quote = getRfqSelector(store);
  return {
    userRole: userTypeSelector(store),
    allowLongIntakeForm: selectors.allowLongIntakeForm(store),
    shipmentType: getShipmentTypeByLoadData(quote.load.packages) === 'container' ? 'fcl' : 'lcl',
    initialValues: selectors.mapRFQToInitialValues(store),
    serverError: getCustomQuotePageServerError(store),
    isLabeledMarketplace: siteConfigModel.isLabeledMarketplaceCustomQuotesPage(store),
    supportedCurrencies: siteConfigModel.getSupportedCurrencies(store),
    insuranceValueAmount: get(quote, 'load.declaredValue.value', null),
    insuranceValueCurrency: get(quote, 'load.declaredValue.currencyID', null),
    hasInsurance: get(quote, 'insuranceValueAmount', undefined),
    isPolling: isPollingSelector(store),
    fullName: get(store, 'propera.user.fullName'),
    isMobile: isMobileSelector(store),
    sites: siteConfigUtils.getMPDomains()
  };
};

export default connect(mapStateToProps, {
  requestCustomQuote: submitCustomQuoteRequest,
  notAllowedCommodity,
  quotesPollingCompleted
})(Form.create({ name: customQuoteFormName })(CustomQuote));

const StyledSubmit = styled.div`
  padding-top: ${fdsComponentSpacingLg};
  border-top: ${fdsBorderWidthSm} solid ${fdsColorPrimaryGray20};
  margin-top: ${fdsComponentSpacingSm};
`;
