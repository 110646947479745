import React, { useEffect } from 'react';
import { connect } from 'microfronts-redux';
import { Alert, Button, Icon, Text, tokens } from '@freightos/design-system';
import { formatHTMLMessage, t } from 'utils/translationProvider';
import { getShipmentsMetadata, updateField, searchAnalytics } from 'slimSearch/actions';
import { ANALYTICS_EVENT_NAME } from 'slimSearch/constants';
import { shipmentsSelector } from 'slimSearch/selectors';
import { Counter } from 'slimSearch/components/Tiles/components/Counter';
import { Tile } from 'slimSearch/components/Tiles/components/Tile';
import {
  NotificationArea,
  StyledContainer,
  StyledDivider,
  ValuesArea,
  BadgeWrapper,
  StyledBadgeNoWrap,
  StyledParagraph,
  Anchor
} from 'slimSearch/components/Tiles/sharedStyles';
import userModel from 'propera/user';
import { openArticle } from 'utils';
import styled from 'styled-components';

const { isLoggedIn } = userModel;

export const Shipments = ({
  shipments,
  getShipmentsMetadata,
  updateField,
  isLoggedIn,
  searchAnalytics
}) => {
  useEffect(() => {
    isLoggedIn && getShipmentsMetadata();
  }, [getShipmentsMetadata, isLoggedIn]);

  return (
    <Tile
      loading={shipments.loading}
      title={t('slimSearch/shipments/manageYourShipments', 'Manage your shipments')}
      icon="mode-ship"
    >
      <StyledContainer>
        <NotificationArea>
          {shipments.error && (
            <StyledParagraph>
              {t('slimSearch/tile/errorMessage', "Something's gone wrong.")}{' '}
              <Button
                type="text"
                size="small"
                onClick={() => {
                  getShipmentsMetadata();
                  searchAnalytics({
                    name: ANALYTICS_EVENT_NAME.MANAGEMENT_TOOL_BUTTON_CLICKED,
                    data: { category: 'shipments', action_name: 'try_again' }
                  });
                }}
              >
                {t('slimSearch/tryAgain', 'Try again')}
              </Button>
            </StyledParagraph>
          )}
          {shipments.data?.PLACED === 0 && shipments.data?.totals > 0 && (
            <Text type="secondary">
              {formatHTMLMessage('shipmentTile/allGood', 'You’re all up to date')}
            </Text>
          )}
          {shipments.data?.PLACED > 0 && (
            <Alert
              icon={<Icon type="notification-bell" />}
              message={
                <>
                  {shipments.data?.PLACED} item(s) need your attention{' '}
                  <a
                    href="/shipment"
                    onClick={() => {
                      searchAnalytics({
                        name: ANALYTICS_EVENT_NAME.MANAGEMENT_TOOL_BUTTON_CLICKED,
                        data: { category: 'shipments', action_name: 'quick_fix_shipments' }
                      });
                      window.history.pushState({}, '', '/shipment');
                    }}
                  >
                    {t('slimSearch/fixNow', 'Fix now')}
                  </a>
                </>
              }
              type="info"
              closable={false}
            />
          )}
          {(shipments.data?.totals === 0 || !isLoggedIn) && (
            <StyledParagraph>
              {t(
                'slimSearch/shipment/shipments/firstBooking',
                'Once you make your first booking, we make it easy to manage your shipment.'
              )}{' '}
              <Anchor
                type="text"
                size="small"
                onClick={() => {
                  searchAnalytics({
                    name: ANALYTICS_EVENT_NAME.MANAGEMENT_TOOL_BUTTON_CLICKED,
                    data: {
                      category: 'shipments',
                      action_name: 'learn_more'
                    }
                  });
                  openArticle('49588-why-ship-with-freightos-com');
                }}
              >
                {t('slimSearch/learnMore', 'Learn more')}
              </Anchor>
            </StyledParagraph>
          )}
        </NotificationArea>

        {!shipments.error && (
          <div>
            <StyledDivider />

            <ValuesArea>
              <BadgeWrapper active={shipments.data?.totals > 0 && isLoggedIn}>
                <StyledBadgeNoWrap
                  color={tokens.fdsChipBackgroundColorEmphasis}
                  text={`Booked ${shipments.data?.PLACED > 0 ? `(${shipments.data?.PLACED})` : ''}`}
                />
                <StyledBadgeNoWrap
                  status="warning"
                  text={`In transit ${
                    shipments.data?.IN_TRANSIT > 0 ? `(${shipments.data?.IN_TRANSIT})` : ''
                  }`}
                />
                <StyledBadgeNoWrap
                  status="success"
                  text={`Delivered ${
                    shipments.data?.DELIVERED > 0 ? `(${shipments.data?.DELIVERED})` : ''
                  }`}
                />
              </BadgeWrapper>

              {shipments.data?.totals === 0 || !isLoggedIn ? (
                <StyledButtonWrap
                  type="secondary"
                  size="small"
                  onClick={() => {
                    updateField('activeSection', 'origin');
                    searchAnalytics({
                      name: ANALYTICS_EVENT_NAME.MANAGEMENT_TOOL_BUTTON_CLICKED,
                      data: {
                        category: 'shipments',
                        action_name: 'lets_get_started'
                      }
                    });
                  }}
                >
                  {t('slimSearch//shipments/bookYourFirstShipment', 'Book your first shipment')}
                </StyledButtonWrap>
              ) : (
                shipments.data?.totals && (
                  <Counter
                    label={t('slimSearch/shipments/totalShipments', 'Total shipments')}
                    value={shipments.data?.totals}
                  />
                )
              )}
            </ValuesArea>
          </div>
        )}
      </StyledContainer>
    </Tile>
  );
};

const StyledButtonWrap = styled(Button)`
  white-space: pre-wrap;
  height: 100%;
  min-height: ${tokens.fdsSizeLg};
`;

const mapStateToProps = (store) => ({
  shipments: shipmentsSelector(store),
  isLoggedIn: isLoggedIn(store)
});

export default connect(mapStateToProps, {
  getShipmentsMetadata,
  updateField,
  searchAnalytics
})(Shipments);
