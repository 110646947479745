import React from 'react';
import { t } from 'utils/translationProvider';
import {
  ADDRESS_TYPES,
  LOAD_TYPES,
  ACCESSORIALS_SCOPES,
  ACCESSORIALS_NAMES
} from 'slimSearch/constants';
import {
  hasLiftgate,
  isBoxesLoadType,
  loadHasOnlyPallets,
  isLclShipment,
  showAmazonServiceSection,
  getSingleEntryBondMaximumValue
} from 'slimSearch/utils/recommendedServices.utils';

import { ReactComponent as FloorLoaded } from 'assets/images/floor_loaded.svg';
import { ReactComponent as PalletizedBySupplier } from 'assets/images/palletized.svg';
import { ReactComponent as FloorPalletizedLoaded } from 'assets/images/floor_palletized_loaded.svg';
import { ReactComponent as LabelingBoxes } from 'assets/images/labeling_box.svg';
import { ReactComponent as LabelingPallet } from 'assets/images/labeling_pallet.svg';

import BondOptionsSelection from './BondOptionsSelection';

export const servicesOptionsTypesColumn1 = [
  'amazonFcl',
  'amazonBoxes',
  'amazonPallets',
  'portCharges',
  'liftGate',
  'transportInsurance'
];

export const servicesOptionsTypesColumn2 = [
  'customsBrokerageInternational',
  'customsBrokerageUS',
  'knownShipper'
];

export const portCharges = {
  helpText: t(
    'services/port_charges_tooltip_text',
    'Goods arriving to or departing from a port incur extra handling/or consolidation fees.'
  ),
  articleKey: '50579-what-are-port-charges',
  optionType: 'switch',
  criteria: (shipment = {}) =>
    shipment.origin?.originLocation?.locationTypeCode === ADDRESS_TYPES.PORT.value ||
    shipment.destination?.destinationLocation?.locationTypeCode === ADDRESS_TYPES.PORT.value,
  getSwitchOptions: (shipment = {}, options = []) => {
    const results = [];
    if (shipment.origin?.originLocation?.locationTypeCode === ADDRESS_TYPES.PORT.value) {
      shipment.pricePreference?.includeOriginPortCharges
        ? results.push(options[0])
        : results.push(options[1]);
    }

    if (shipment.destination?.destinationLocation?.locationTypeCode === ADDRESS_TYPES.PORT.value) {
      shipment.pricePreference?.includeDestinationPortCharges
        ? results.push(options[2])
        : results.push(options[3]);
    }
    return results;
  },
  options: [
    {
      analytic: 'origin-port-charges',
      key: 'origin-add',
      label: t('services/origin_charges_on_toggle_text', 'Yes - Add origin charges'),
      value: true,
      objectPath: 'quote.pricePreference.includeOriginPortCharges'
    },
    {
      analytic: 'origin-port-charges',
      key: 'origin-supplier',
      label: t(
        'services/origin_charges_off_toggle_text',
        'No - My delivery agent will cover any supplier charges'
      ),
      value: false,
      objectPath: 'quote.pricePreference.includeOriginPortCharges'
    },
    {
      analytic: 'destination-port-charges',
      key: 'destination-add',
      label: t('services/destination_charges_on_toggle_text', 'Yes - Add destination charges'),
      value: true,
      objectPath: 'quote.pricePreference.includeDestinationPortCharges'
    },
    {
      analytic: 'destination-port-charges',
      key: 'destination-supplier',
      label: t(
        'services/destination_charges_off_toggle_text',
        'No - My supplier will cover delivery agent charges'
      ),
      value: false,
      objectPath: 'quote.pricePreference.includeDestinationPortCharges'
    }
  ],
  title: t('services/port_charges_both_header_text', 'Port Charges')
};

export const liftGate = {
  optionType: 'switch',
  criteria: (shipment = {}) => {
    const originLocationType = shipment.origin?.originLocation?.locationTypeCode;
    const destinationLocationType = shipment.destination?.destinationLocation?.locationTypeCode;
    return (
      ([ADDRESS_TYPES.BUSINESS_ADDRESS.key, ADDRESS_TYPES.FACTORY_WAREHOUSE.key].includes(
        originLocationType
      ) ||
        [ADDRESS_TYPES.BUSINESS_ADDRESS.key, ADDRESS_TYPES.FACTORY_WAREHOUSE.key].includes(
          destinationLocationType
        )) &&
      shipment.load?.type !== LOAD_TYPES.CONTAINER.value
    );
  },

  title: t('services/business_address_both_header_text', 'Liftgate'),
  getSwitchOptions: (shipment = {}, options = []) =>
    liftgateBothGetSwitchOptions(shipment, options),
  options: [
    {
      analytic: 'origin-liftgate',
      key: 'origin-business-add',
      label: t('services/liftgate_pickup_on_toggle_text', 'Yes - Add a liftgate at pickup'),
      value: true
    },
    {
      analytic: 'origin-liftgate',
      key: 'origin-business-supplier',
      label: t(
        'services/liftgate_pickup_business_address_off_toggle_text',
        'No - There’s a loading dock at pickup'
      ),
      value: false
    },
    {
      analytic: 'destination-liftgate',
      key: 'destination-business-add',
      label: t('services/liftgate_delivery_on_toggle_text', 'Yes - Add a liftgate at delivery'),
      value: true
    },
    {
      analytic: 'destination-liftgate',
      key: 'destination-business-supplier',
      label: t(
        'services/liftgate_delivery_business_address_off_toggle_text',
        'No - There’s a loading dock at delivery'
      ),
      value: false
    }
  ],
  onChange: (shipment, option, value) => liftgateBothOnChange(shipment, option, value)
};

export const transportInsurance = {
  optionType: 'switch',
  criteria: () => true,
  title: t('services/insurance_header_text', 'Insurance'),
  options: [
    {
      analytic: 'insurance',
      key: 'insurance-on',
      label: t(
        'services/insurance_on_toggle_text',
        'Yes - (covers the combined value of goods and initial freight costs up to $500k)'
      ),
      value: true
    },
    {
      analytic: 'insurance',
      key: 'insurance-off',
      label: t(
        'services/insurance_off_toggle_text',
        'No - I’ve either got insurance or take full responsibility for any lost or damaged cargo'
      )
    }
  ],
  getSwitchOptions: (shipment = {}, options = []) =>
    Boolean(shipment.insuranceValueAmount) ? [options[0]] : [options[1]],
  onChange: (shipment, option, value) => {
    const path = 'quote.insuranceValueAmount';
    if (!value) {
      return { path, value: undefined };
    } else {
      return {
        path,
        value: {
          value: parseInt(shipment.goods?.goodsValue?.value ?? 0, 10),
          currencyID: shipment.goods?.goodsValue?.currency
        }
      };
    }
  },
  helpText: t(
    'services/insurance_tooltip_text',
    `Shipping without insurance is risky. If you decide not to purchase insurance, you'll be responsible for any potential claims for lost or damaged items. Cover your goods through our trusted third-party provider {insuranceGroup}
    or arrange your own coverage. Learn more about Insurance policy {here}`,
    {
      insuranceGroup: `<a href='https://www.xcover.com/en/pds/total_cargo_protection' target="_blank" >xcover.com</a>`,
      here: `<a href='https://www.xcover.com/en/pds/total_cargo_protection' target="_blank" >here.</a>`
    }
  )
};

export const amazonFcl = {
  optionType: 'radio',
  criteria: (shipment = {}) =>
    showAmazonServiceSection(shipment) && shipment.load?.type === LOAD_TYPES.CONTAINER.value,
  title: t('services/fulfilment_center_amazon_fcl_header_text', 'Amazon container stuffing'),
  getDefaultValue: (shipment) => {
    if (shipment.fulfilmentInfo?.fulfilmentCompanyShipment?.additionalServices?.palletization) {
      return 'freight-forwarder';
    } else if (
      shipment.fulfilmentInfo?.fulfilmentCompanyShipment?.additionalServices?.floorLoaded
    ) {
      return 'floor-loaded';
    } else {
      return 'supplier';
    }
  },
  options: [
    {
      key: 'freight-forwarder',
      analytic: 'amazon-fcl-stuffing',
      label: t(
        'services/amazon_fcl_container_stuffing_by_ff_radio_label_text',
        'Palletized by freight forwarder'
      ),
      value: 'freight-forwarder',
      helpText: t(
        'services/amazon_fcl_container_stuffing_by_ff_tooltip_text',
        'Your supplier must label each box according to Amazon specifications. Your supplier will fill or floor load the container with your loose boxes and the freight forwarder will palletize them before sending to Amazon.'
      ),
      articleKey: '56977-how-do-i-label-my-shipment-for-amazon-fba-delivery',
      ImageComponent: FloorPalletizedLoaded,
      helpTextClass: 'floor-palletized-loaded'
    },
    {
      key: 'supplier',
      analytic: 'amazon-fcl-stuffing',
      label: t(
        'services/amazon_fcl_container_stuffing_by_supplier_radio_label_text',
        'Palletized by supplier '
      ),
      value: 'supplier',
      helpText: t(
        'services/amazon_fcl_container_stuffing_by_supplier_tooltip_text',
        `Your supplier must label all pallets, size, and pack them within the container according to Amazon specifications.`
      ),
      articleKey: '56976-how-do-i-palletize-my-shipment-for-amazon-fba',
      ImageComponent: PalletizedBySupplier,
      helpTextClass: 'supplier'
    },
    {
      key: 'floor-loaded',
      analytic: 'amazon-fcl-stuffing',
      label: t(
        'services/amazon_fcl_container_stuffing_floor_loaded_radio_label_text',
        'Floor loaded'
      ),
      value: 'floor-loaded',
      helpText: t(
        'services/amazon_fcl_container_stuffing_floor_loaded_tooltip_text',
        "Your supplier must label each box according to Amazon specifications. It may take longer to schedule a delivery appointment if you choose this option. Note: Amazon will charge an additional processing fee for floor loaded shipments which won't be included in the quote. Learn more."
      ),
      articleKey: '118768-what-does-floor-loaded-mean',
      ImageComponent: FloorLoaded,
      helpTextClass: 'floor-loaded'
    }
  ],
  objectPath: 'quote.fulfilmentInfo.fulfilmentCompanyShipment.additionalServices.palletization',
  onChange: (shipment, value) => {
    const paths = [
      'quote.fulfilmentInfo.fulfilmentCompanyShipment.additionalServices.palletization',
      'quote.fulfilmentInfo.fulfilmentCompanyShipment.additionalServices.floorLoaded'
    ];
    switch (value) {
      case 'freight-forwarder':
        return {
          paths,
          values: [true, false]
        };
      case 'supplier':
        return {
          paths,
          values: [false, false]
        };
      case 'floor-loaded':
        return {
          paths,
          values: [false, true]
        };
      default:
        // eslint-disable-next-line no-console
        console.error('must specify values');
    }
  }
};

export const amazonBoxes = {
  optionType: 'switch',
  criteria: (shipment = {}) => {
    return (
      showAmazonServiceSection(shipment) &&
      shipment.load.type !== LOAD_TYPES.CONTAINER.value &&
      (isBoxesLoadType(shipment.load) || shipment.load?.looseCargo?.calculateByTotals)
    );
  },
  title: t(
    'services/fulfilment_center_amazon_boxes_crates_header_text',
    'Amazon labeling and palletization'
  ),
  options: [
    {
      analytic: 'amazon-box-labeling',
      key: 'add-labeling',
      label: t('services/labeling_boxes_on_toggle_text', 'Yes - I need labeling'),
      value: true,
      objectPath: 'quote.fulfilmentInfo.fulfilmentCompanyShipment.additionalServices.labeling',
      helpText: t(
        'services/labeling_boxes_tooltip_text',
        'Your supplier must label each box according to Amazon specifications.'
      ),
      articleKey: '56977-how-do-i-label-my-shipment-for-amazon-fba-delivery',
      ImageComponent: LabelingBoxes,
      helpTextClass: 'labeling-boxes'
    },
    {
      analytic: 'amazon-box-labeling',
      key: 'no-labeling',
      label: t('services/labeling_boxes_off_toggle_text', 'No - My supplier will label the goods'),
      value: false,
      objectPath: 'quote.fulfilmentInfo.fulfilmentCompanyShipment.additionalServices.labeling',
      helpText: t(
        'services/labeling_boxes_tooltip_text',
        'Your supplier must label each box according to Amazon specifications.'
      ),
      articleKey: '56977-how-do-i-label-my-shipment-for-amazon-fba-delivery',
      ImageComponent: LabelingBoxes,
      helpTextClass: 'labeling-boxes'
    },
    {
      analytic: 'amazon-box-palletization',
      key: 'add-palletization',
      label: t('services/palletization_on_toggle_text', 'Yes - I need palletization'),
      value: true,
      objectPath: 'quote.fulfilmentInfo.fulfilmentCompanyShipment.additionalServices.palletization',
      helpText: t(
        'services/palletization_tooltip_text',
        'All shipments must arrive on labeled pallets'
      ),
      articleKey: '56976-how-do-i-palletize-my-shipment-for-amazon-fba',
      ImageComponent: LabelingPallet
    },
    {
      analytic: 'amazon-box-palletization',
      key: 'no-palletization',
      label: t(
        'services/palletization_off_toggle_text',
        'No - My supplier will palletize the goods'
      ),
      value: false,
      objectPath: 'quote.fulfilmentInfo.fulfilmentCompanyShipment.additionalServices.palletization',
      helpText: t(
        'services/palletization_tooltip_text',
        'All shipments must arrive on labeled pallets.'
      ),
      articleKey: '56976-how-do-i-palletize-my-shipment-for-amazon-fba',
      ImageComponent: LabelingPallet
    }
  ],
  getSwitchOptions: (shipment = {}, options = []) => {
    const results = [];
    if (shipment.fulfilmentInfo?.fulfilmentCompanyShipment?.additionalServices?.labeling) {
      results.push(options[0]);
      results.push({ ...options[2], disabled: true });
    } else {
      results.push(options[1]);
      if (shipment.fulfilmentInfo?.fulfilmentCompanyShipment?.additionalServices?.palletization) {
        results.push(options[2]);
      } else {
        results.push(options[3]);
      }
    }

    return results;
  }
};

export const amazonPallets = {
  optionType: 'switch',
  criteria: (shipment = {}) =>
    showAmazonServiceSection(shipment) && loadHasOnlyPallets(shipment.load),
  title: t('services/fulfilment_center_amazon_pallets_header_text', 'Amazon labeling'),
  options: [
    {
      analytic: 'amazon-pallets-labeling',
      key: 'labeling-on',
      label: t('services/labeling_pallets_on_toggle_text', 'Yes - Add labeling to pallets'),
      value: true,
      objectPath: 'quote.fulfilmentInfo.fulfilmentCompanyShipment.additionalServices.labeling',
      helpText: t(
        'services/labeling_pallets_tooltip_text',
        'Amazon requires you to attach four labels to each pallet and label each box according to their specifications.You can print these labels from your Amazon shipping queue.'
      ),
      articleKey: '56977-how-do-i-label-my-shipment-for-amazon-fba-delivery',
      ImageComponent: LabelingPallet,
      helpTextClass: 'labeling-pallets'
    },
    {
      analytic: 'amazon-pallets-labeling',
      key: 'labeling-off',
      label: t(
        'services/labeling_pallets_off_toggle_text',
        'No - My supplier will label the pallets'
      ),
      value: false,
      objectPath: 'quote.fulfilmentInfo.fulfilmentCompanyShipment.additionalServices.labeling',
      helpText: t(
        'services/labeling_pallets_tooltip_text',
        'Amazon requires you to attach four labels to each pallet and label each box according to their specifications.You can print these labels from your Amazon shipping queue.'
      ),
      articleKey: '56977-how-do-i-label-my-shipment-for-amazon-fba-delivery',
      ImageComponent: LabelingPallet,
      helpTextClass: 'labeling-pallets'
    }
  ],
  getSwitchOptions: (shipment = {}, options = []) =>
    shipment.fulfilmentInfo?.fulfilmentCompanyShipment?.additionalServices?.labeling
      ? [options[0]]
      : [options[1]]
};

export const customsBrokerageInternational = {
  optionType: 'switch',
  criteria: (shipment = {}) => {
    const originCountry = shipment.origin?.originLocation?.countryID?.value;
    const destinationCountry = shipment.destination?.destinationLocation?.countryID?.value;
    return originCountry !== destinationCountry && destinationCountry !== 'US';
  },
  title: t('services/customs_brokerage_row_header_text', 'Customs brokerage'),
  getSwitchOptions: (shipment = {}, options = []) => {
    const results = [];
    // const originCountry = shipment.origin?.originLocation?.countryID?.value;
    // const destinationCountry = shipment.destination?.destinationLocation?.countryID?.value;

    shipment.declaredCustoms?.entry ? results.push(options[0]) : results.push(options[1]);

    return results;
  },
  options: [
    {
      analytic: 'international-customs-brokerage-entry',
      key: 'entry-on',
      label: t('services/customs_brokerage_on_toggle_text', 'Yes - I need customs brokerage'),
      value: true,
      helpText: t('services/customs_brokerage_row_tooltip_text', 'Why you need customs brokerage.'),
      articleKey: '49919-what-is-customs-brokerage',
      objectPath: 'quote.declaredCustoms.entry'
    },
    {
      analytic: 'international-customs-brokerage-entry',
      key: 'entry-off',
      label: t('services/customs_brokerage_off_toggle_text', 'No - I have a customs broker'),
      value: false,
      helpText: t('services/customs_brokerage_row_tooltip_text', 'Why you need customs brokerage.'),
      articleKey: '49919-what-is-customs-brokerage',
      objectPath: 'quote.declaredCustoms.entry'
    }
  ]
};

//, siteConfigData = {}) => siteConfigData.showForeignImporter &&

export const customsBrokerageUS = {
  optionType: 'switch',
  criteria: (shipment = {}) =>
    shipment.destination?.destinationLocation?.countryID?.value !==
      shipment.origin?.originLocation?.countryID?.value &&
    shipment.destination?.destinationLocation?.countryID?.value === 'US',
  title: t('services/customs_brokerage_and_bond_usa_header_text', 'Customs brokerage and bond'),
  getSwitchOptions: (shipment = {}, options = [], siteConfigData = {}) => {
    const results = [];
    const declaredCustoms = shipment.declaredCustoms || {};
    if (declaredCustoms.entry) {
      results.push(options[0]);
      if (declaredCustoms.singleEntryBond || declaredCustoms.continuousBond) {
        results.push(options[2]);
      } else {
        results.push(options[3]);
      }
    } else {
      results.push(options[1]);
    }

    if (siteConfigData.showForeignImporter) {
      if (shipment.declaredCustoms?.foreignImporterOfRecord) {
        results.push(options[5]);
      } else {
        results.push(options[4]);
      }
    }

    return results;
  },
  onChange: (shipment, switchOption, value) => {
    if (!switchOption.key.includes('customs-bond')) {
      if (switchOption.key.includes('import-of-record')) {
        return { path: switchOption.objectPath, value: !value };
      }
      return { path: switchOption.objectPath, value };
    } else if (switchOption.key === 'customs-bond-off') {
      // this mean we want to change that
      const sebValue = getSingleEntryBondMaximumValue(shipment);
      const bond =
        sebValue <= 400
          ? {
              singleEntryBond: {
                value: parseInt(shipment.goods?.goodsValue?.value, 10),
                currencyID: shipment.goods?.goodsValue?.currency
              }
            }
          : { continuousBond: true };
      return {
        path: 'quote.declaredCustoms',
        value: { ...shipment.declaredCustoms, ...bond }
      };
    } else if (switchOption.key === 'customs-bond-on') {
      return {
        path: 'quote.declaredCustoms',
        value: {
          ...shipment.declaredCustoms,
          singleEntryBond: false,
          continuousBond: false
        }
      };
    }
  },
  options: [
    {
      analytic: 'us-customs-brokerage-entry',
      key: 'entry-on',
      label: t('services/customs_brokerage_on_toggle_text', 'Yes - I need a customs brokerage'),
      value: true,
      helpText: t('services/customs_brokerage_row_tooltip_text', 'Why you need customs brokerage.'),
      articleKey: '49919-what-is-customs-brokerage',
      objectPath: 'quote.declaredCustoms.entry'
    },
    {
      analytic: 'us-customs-brokerage-entry',
      key: 'entry-off',
      label: t('services/customs_brokerage_off_toggle_text', 'No - I have a customs broker'),
      value: false,
      helpText: t('services/customs_brokerage_row_tooltip_text', 'Why you need customs brokerage.'),
      articleKey: '49919-what-is-customs-brokerage',
      objectPath: 'quote.declaredCustoms.entry'
    },
    {
      analytic: 'us-customs-bond',
      key: 'customs-bond-on',
      label: t('services/customs_bond_on_toggle_text', 'Yes - I need a customs bond'),
      value: true,
      children: <BondOptionsSelection />
    },
    {
      analytic: 'us-customs-bond',
      key: 'customs-bond-off',
      label: t('services/customs_bond_off_toggle_text', 'No - I have a customs bond'),
      value: false
    },
    {
      analytic: 'us-customs-import-of-record',
      key: 'import-of-record-on',
      label: t(
        'services/importer_of_record_on_toggle_text',
        "I'm importing into my country of residence"
      ),
      value: true,
      helpText: t(
        'services/importer_of_record_tooltip_text',
        'You need an importer of record when shipping to the US'
      ),
      articleKey: '52378-how-can-i-get-an-importer-of-record-if-i-m-not-based-in-the-us',
      objectPath: 'quote.declaredCustoms.foreignImporterOfRecord'
    },
    {
      analytic: 'us-customs-import-of-record',
      key: 'import-of-record-off',
      label: t('services/importer_of_record_off_toggle_text', "I'm a foreign importer"),
      value: false,
      helpText: t(
        'services/importer_of_record_tooltip_text',
        'You need an importer of record when shipping to the US'
      ),
      articleKey: '52378-how-can-i-get-an-importer-of-record-if-i-m-not-based-in-the-us',
      objectPath: 'quote.declaredCustoms.foreignImporterOfRecord'
    }
  ]
};

export const knownShipper = {
  optionType: 'switch',
  criteria: (shipment = {}) => isLclShipment(shipment),
  getSwitchOptions: (shipment = {}, options = []) => {
    const results = [];
    if (isLclShipment(shipment)) {
      shipment.knownShipper ? results.push(options[0]) : results.push(options[1]);
    }
    return results;
  },
  options: [
    {
      analytic: 'known-shipper',
      key: 'known-shipper-on',
      label: t(
        'services/know_shipper_on_toggle_text',
        "I'm verified with the TSA as a known shipper"
      ),
      value: true,
      helpText: t(
        'services/know_shipper_tooltip_text',
        'Known Shippers have been vetted by the TSA, and can book air rates for both passenger and cargo planes. If you book a passenger rate and your status as a Known Shipper is not validated, your shipment will be canceled.'
      ),
      articleKey: '49576-what-is-a-known-shipper',
      objectPath: 'quote.knownShipper'
    },
    {
      analytic: 'known-shipper',
      key: 'known-shipper-off',
      label: t('services/know_shipper_off_toggle_text', "I'm not a known shipper"),
      value: false,
      helpText: t(
        'services/know_shipper_tooltip_text',
        'Known Shippers have been vetted by the TSA, and can book air rates for both passenger and cargo planes. If you book a passenger rate and your status as a Known Shipper is not validated, your shipment will be canceled.'
      ),
      articleKey: '49576-what-is-a-known-shipper',
      objectPath: 'quote.knownShipper'
    }
  ]
};

const liftgateBothOnChange = (shipment, option, value) => {
  const path = 'quote.accessorials';
  let result = shipment.accessorials || [];

  if (option.key.includes('origin')) {
    if (value) {
      result.push({ name: ACCESSORIALS_NAMES.LIFT_GATE, scope: ACCESSORIALS_SCOPES.PICKUP });
    } else {
      result = result.filter(
        (item) =>
          !(item.name === ACCESSORIALS_NAMES.LIFT_GATE && item.scope === ACCESSORIALS_SCOPES.PICKUP)
      );
    }
  }

  if (option.key.includes('destination')) {
    if (value) {
      result.push({ name: ACCESSORIALS_NAMES.LIFT_GATE, scope: ACCESSORIALS_SCOPES.DELIVERY });
    } else {
      result = result.filter(
        (item) =>
          !(
            item.name === ACCESSORIALS_NAMES.LIFT_GATE &&
            item.scope === ACCESSORIALS_SCOPES.DELIVERY
          )
      );
    }
  }

  return { path, value: result };
};

const liftgateBothGetSwitchOptions = (shipment, options) => {
  const originLocationType = shipment.origin?.originLocation?.locationTypeCode;
  const destinationLocationType = shipment.destination?.destinationLocation?.locationTypeCode;

  const result = [];
  if (
    [ADDRESS_TYPES.BUSINESS_ADDRESS.key, ADDRESS_TYPES.FACTORY_WAREHOUSE.key].includes(
      originLocationType
    )
  ) {
    hasLiftgate(shipment, ACCESSORIALS_SCOPES.PICKUP)
      ? result.push(options[0])
      : result.push(options[1]);
  }
  if (
    [ADDRESS_TYPES.BUSINESS_ADDRESS.key, ADDRESS_TYPES.FACTORY_WAREHOUSE.key].includes(
      destinationLocationType
    )
  ) {
    hasLiftgate(shipment, ACCESSORIALS_SCOPES.DELIVERY)
      ? result.push(options[2])
      : result.push(options[3]);
  }
  return result;
};
