import React, { useState, useEffect } from 'react';
import { Slider } from '@freightos/design-system';
import { useDispatch } from 'microfronts-redux';
import styled, { css } from 'styled-components/macro';
import { rangeFilterItem } from 'results/results.actions';
import { moneyFormat } from 'utils/index';

const rangeValueView = (filter) => {
  const start = filter.currentRange?.length ? filter.currentRange[0] : filter.range.min;
  const end = filter.currentRange?.length ? filter.currentRange[1] : filter.range.max;
  return `${moneyFormat({
    amount: start,
    currency: filter.currency
  })} -  ${moneyFormat({ amount: end, currency: filter.currency })}`;
};

const formatter =
  ({ currency }) =>
  (value) =>
    moneyFormat({ amount: value, currency });

const RangeFilterComponent = ({ filter, inModal }) => {
  const dispatch = useDispatch();

  const [values, setValues] = useState([]);
  const tipFormatter = formatter(filter);

  useEffect(() => {
    const defaultValues = filter.currentRange?.length
      ? filter.currentRange
      : [filter.range.min, filter.range.max];
    setValues(defaultValues);
  }, [filter]);
  return (
    <>
      <SelectedValues>{rangeValueView(filter)}</SelectedValues>
      <StyledSlider
        value={values}
        min={filter.range.min}
        max={filter.range.max}
        onChange={(values) => setValues(values)}
        onAfterChange={(range) => {
          dispatch(rangeFilterItem({ mode: filter.key, range }));
        }}
        tipFormatter={tipFormatter}
        range={true}
        inModal={inModal}
      ></StyledSlider>
    </>
  );
};

export default RangeFilterComponent;

const StyledSlider = styled(Slider)`
  padding: 0 5px;

  ${({ inModal }) =>
    inModal
      ? css`
          width: 85%;
        `
      : css`
          width: 85%;
        `}
`;

const SelectedValues = styled.div`
  font-size: 10px;
  transform: translateX(2px);
`;
