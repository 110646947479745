import { combineReducers } from 'redux';

import { BankDetailsReducer, BANK_DETAILS_FIELDS } from 'applicationStore/BankDetails';

export const REDUCERS_NAMES = {
  BANK_DETAILS: BANK_DETAILS_FIELDS.name
};

const rootReducer = combineReducers({
  [REDUCERS_NAMES.BANK_DETAILS]: BankDetailsReducer
});

export default rootReducer;
