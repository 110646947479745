(function webpackUniversalModuleDefinition(root, factory) {
	if(typeof exports === 'object' && typeof module === 'object')
		module.exports = factory(require("react"), require("@freightos/design-system"), require("redux"), require("react-redux"), require("redux-saga"), require("lodash"));
	else if(typeof define === 'function' && define.amd)
		define("FreightosBankDetails", ["react", "@freightos/design-system", "redux", "react-redux", "redux-saga", "lodash"], factory);
	else if(typeof exports === 'object')
		exports["FreightosBankDetails"] = factory(require("react"), require("@freightos/design-system"), require("redux"), require("react-redux"), require("redux-saga"), require("lodash"));
	else
		root["FreightosBankDetails"] = factory(root["React"], root["@freightos/design-system"], root["redux"], root["react-redux"], root["redux-saga"], root["lodash"]);
})(this, function(__WEBPACK_EXTERNAL_MODULE__0__, __WEBPACK_EXTERNAL_MODULE__1__, __WEBPACK_EXTERNAL_MODULE__14__, __WEBPACK_EXTERNAL_MODULE__28__, __WEBPACK_EXTERNAL_MODULE__106__, __WEBPACK_EXTERNAL_MODULE__108__) {
return 