import styled, { css } from 'styled-components/macro';
import { Divider, Paragraph, tokens, Badge, Button } from '@freightos/design-system';

export const NotificationArea = styled.div``;

export const StyledContainer = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

export const ValuesArea = styled.div`
  min-width: 160px;
  min-height: 83px;
  display: flex;
  justify-content: space-between;
  align-items: end;
  gap: 8px;
`;

export const BadgeWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${tokens.fdsComponentSpacingSm};
  ${({ active }) =>
    active
      ? ''
      : css`
          opacity: 0.5;
        `}
`;

export const StyledBadgeNoWrap = styled(Badge)`
  white-space: nowrap;
`;

export const StyledDivider = styled(Divider)`
  margin: 10px 0 10px;
  ${({ hide }) =>
    hide
      ? css`
          visibility: hidden;
        `
      : ''}
`;

export const ShipmentsCounter = styled.div`
  text-align: right;
  color: ${tokens.fdsColorPrimaryGray60};
`;

export const Number = styled.div`
  font-weight: ${tokens.fdsFontWeightBold};
  font-size: ${tokens.fdsH1FontSize};
  line-height: 1.25;
  color: ${tokens.fdsColorPrimaryGray80};
`;

export const StyledParagraph = styled(Paragraph)`
  margin: 0;
  font-size: ${tokens.fdsFontSizeSm};
  color: ${tokens.fdsColorPrimaryGray60};
`;

export const Anchor = styled(Button)`
  &&& {
    display: inline-flex;
  }
`;
