import { connect } from 'react-redux';
import CreditCardsMgmt from './CreditCardsMgmt';
import * as actions from './actions';
import * as paymentsActions from 'financialSharedComponents/components/Payment/actions';
import * as selectors from 'financialSharedComponents/selectors';
import { SUPPORTED_PAYMENT_METHODS_TYPES } from 'financialSharedComponents/constants/app.constants';
const mapStateToProps = (state) => ({
  isChangingPaymentMethod: selectors.isChangingPaymentMethodSelector(state),
  isPaymentMethodsInitialized: selectors.paymentMethodsInitializedSelector(state)
});

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    onSubmitToken: ({ nonce, pspName }) => {
      dispatch(
        actions.saveCardToken({
          nonce,
          type: SUPPORTED_PAYMENT_METHODS_TYPES.CARD,
          pspName,
          triggerPayment: true
        })
      );
    },
    fetchUserCards: () => dispatch(actions.fetchUserCards()),
    fetchPaymentMethods: () => dispatch(paymentsActions.fetchPaymentMethods({type: SUPPORTED_PAYMENT_METHODS_TYPES.CARD})),
    setIsChangingPaymentMethod: (payload) => dispatch(actions.setIsChangingPaymentMethod(payload))
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(CreditCardsMgmt);
