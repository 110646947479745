import * as AT from 'financialSharedComponents/constants/actionTypes';

export const fetchUserCards = (payload) => ({
  type: AT.FETCH_USER_CARDS,
  payload
});

export const saveCardToken = (payload) => ({
  type: AT.SAVE_CARD_TOKEN,
  payload
});

export const saveCardTokenSuccess = (payload) => ({
  type: AT.SAVE_CARD_TOKEN_SUCCESS,
  payload
});

export const saveCardTokenFail = (payload) => ({
  type: AT.SAVE_CARD_TOKEN_FAIL,
  payload
});

export const setIsChangingPaymentMethod = (payload) => ({
  type: AT.SET_IS_CHANGING_PAYMENT_METHOD,
  payload
});

export const setDeviceDataCorrelationId = (payload) => ({
  type: AT.SET_DEVICE_DATA_CORRELATION_ID,
  payload
});

export const fetchUserCardsSuccess = (payload) => ({
  type: AT.FETCH_USER_CARDS_SUCCESS,
  payload
});

export const fetchPaymentFeeConfigSuccess = (payload) => ({
  type: AT.FETCH_PAYMENT_FEE_CONFIG_SUCCESS,
  payload
});

export const fetchPaymentFeeConfigFail = (payload) => ({
  type: AT.FETCH_PAYMENT_FEE_CONFIG_FAIL,
  payload
});

export const fetchPaymentFeeConfigAction = (payload) => ({
  type: AT.FETCH_PAYMENT_FEE_CONFIG,
  payload
});
