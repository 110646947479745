import moment from 'moment';
import get from 'lodash/get';
import partition from 'lodash/partition';
import { TRANSPORT_SERVICE } from 'slimSearch/constants';
const BEST_VALUE = 10;

export const appendTopValues = (indexIds = [], topQuotesIndexes) => {
  const topValues = topQuotesIndexes.bestValueId ? [topQuotesIndexes.bestValueId] : [];
  // order **is** important
  if (topQuotesIndexes.cheapestId && !topValues.includes(topQuotesIndexes.cheapestId)) {
    topValues.push(topQuotesIndexes.cheapestId);
  }

  if (topQuotesIndexes.quickestId && !topValues.includes(topQuotesIndexes.quickestId)) {
    topValues.push(topQuotesIndexes.quickestId);
  }

  const filteredIds = indexIds.filter((id) => !topValues.includes(id));
  return [...topValues, ...filteredIds];
};

export const getTopWithGuaranteedCapacityQuotes = ({ main = [], others = [] }, quotes = {}) => {
  // Only one quote per vender per mode are allowed to be at the top.
  const uniqueVendorModes = new Set();
  const isTopGuaranteedSpace = (id) => {
    const quote = quotes[id];
    const vendors = getVendors(quote) || [{}];
    const sellerKey = vendors[0].ID;
    const key = `${sellerKey}-${getMode(quote)}`;
    if (get(quote, 'sortValue.guaranteedSpace', false) && !uniqueVendorModes.has(key)) {
      uniqueVendorModes.add(key);
      return true;
    }
    return false;
  };
  const [guaranteed, nonGuaranteedMain] = partition(main, isTopGuaranteedSpace);
  const [guaranteedOthers, nonGuaranteedOthers] = partition(others, isTopGuaranteedSpace);
  const sortedGuaranteeds = [...guaranteed, ...guaranteedOthers]
    .slice()
    .sort((a, b) => compareQuotesDepartureDates(quotes[a], quotes[b]));
  return { main: [...sortedGuaranteeds, ...nonGuaranteedMain], others: nonGuaranteedOthers };
};
export const getVendors = (quote = {}) =>
  quote.businessInfo?.parties?.filter((item) => item.partyTypeCode === 'FW');

export const getMode = (quote = {}) => quote.transportMode;

// #region QuoteView functions
export function getQuoteBySellerByModeId(quote) {
  const parties = get(quote, 'businessInfo.parties');
  const vendor = parties.find((party) => party.partyTypeCode === 'FW');
  return get(vendor, 'ID') + '_' + quote.transportMode;
}

export const isQuoteGuaranteedSpace = (quote) =>
  get(quote, 'connection.connectionSegments', []).some((segment) =>
    get(segment, 'availableSpace.guaranteedSpace', false)
  );

export const originChargesIncludes = (quote) => {
  if (
    quote.transportService === TRANSPORT_SERVICE.DOOR_TO_DOOR ||
    quote.transportService === TRANSPORT_SERVICE.DOOR_TO_PORT
  ) {
    return true;
  }
  return get(quote, 'connection.connectionSegments', []).some((segment) =>
    segment.charges.find((charge) => charge.feeType === 'origin')
  );
};

export const destinationChargesIncludes = (quote) => {
  if (
    quote.transportService === TRANSPORT_SERVICE.DOOR_TO_DOOR ||
    quote.transportService === TRANSPORT_SERVICE.PORT_TO_DOOR
  ) {
    return true;
  }
  return get(quote, 'connection.connectionSegments', []).some((segment) =>
    segment.charges.find((charge) => charge.feeType === 'destination')
  );
};

export const insuranceIncluded = (quote) => {
  const charges = get(quote, 'connection.generalCharges', []);
  return Boolean(charges.find((service) => service.feeType === 'insurance'));
};

export const customsBrokerageIncludes = (quote) => {
  const charges = get(quote, 'connection.generalCharges', []);
  return Boolean(charges.find((service) => service.feeType === 'customsBrokerage'));
};

export const missingServices = (rfqServices, quote) => {
  const missingServicesList = [];

  if (rfqServices.isInsuranceRequested && !insuranceIncluded(quote)) {
    missingServicesList.push('insurance');
  }

  if (get(quote, 'insurance.partialCoverage')) {
    missingServicesList.push('partialCoverage');
  }
  if (quote.transportMode.toLowerCase() === 'express') {
    return missingServicesList;
  }

  if (
    rfqServices.isCustomsBrokerageRequested &&
    !customsBrokerageIncludes(quote) &&
    quote?.destinationLocation?.countryID?.value !== quote?.originLocation?.countryID?.value
  ) {
    missingServicesList.push('customsBrokerage');
  }

  if (rfqServices.isOriginChargesRequested && !originChargesIncludes(quote)) {
    missingServicesList.push('originCharges');
  }

  if (rfqServices.isDestinationChargesRequested && !destinationChargesIncludes(quote)) {
    missingServicesList.push('destinationCharges');
  }

  return missingServicesList;
};

// #endregion

export const compareTransitTime = (transitTimeA = [], transitTimeB = []) => {
  if (transitTimeA.length !== 2) {
    return transitTimeB;
  }
  if (transitTimeB.length !== 2) {
    return transitTimeA;
  }
  if (transitTimeA[0] < transitTimeB[0]) {
    return transitTimeA;
  } else if (transitTimeA[0] === transitTimeB[0]) {
    if (transitTimeA[1] <= transitTimeB[1]) {
      return transitTimeA;
    } else {
      return transitTimeB;
    }
  } else {
    return transitTimeB;
  }
};

export const countTransitTimeDiff = (transitTimeA = [], transitTimeB = []) => {
  if (transitTimeA.length !== 2) {
    return 1;
  }
  if (transitTimeB.length !== 2) {
    return -1;
  }

  if (transitTimeA[0] === transitTimeB[0]) {
    return transitTimeA[1] - transitTimeB[1];
  } else {
    return transitTimeA[0] - transitTimeB[0];
  }
};

export const getBestValueRank = (cost, transitTime, gsRankValue, resultsPageConfig) => {
  const bestCostPercentageValue = get(resultsPageConfig, 'bestCostPercentage', 0.65);
  const bestTransitTimePercentageValue = get(resultsPageConfig, 'bestTransitTimePercentage', 0.35);
  const bestGuaranteedSpacePercentageValue = get(
    resultsPageConfig,
    'bestGuaranteedSpacePercentage',
    0
  );

  return (
    (bestCostPercentageValue * cost +
      bestTransitTimePercentageValue * transitTime +
      bestGuaranteedSpacePercentageValue * gsRankValue) *
    -1
  );
};

export const getRecommendedValueRank = (cost, transitTime, gsRankValue, resultsPageConfig) => {
  const bestCostPercentageValue = get(resultsPageConfig, 'recommendedCostPercentage', 0.65);
  const bestTransitTimePercentageValue = get(
    resultsPageConfig,
    'recommendedTransitTimePercentage',
    0.35
  );
  const bestGuaranteedSpacePercentageValue = get(
    resultsPageConfig,
    'recommendedGuaranteedSpacePercentage',
    0
  );

  return (
    (bestCostPercentageValue * cost +
      bestTransitTimePercentageValue * transitTime +
      bestGuaranteedSpacePercentageValue * gsRankValue) *
    -1
  );
};

export const getCostRankValue = (quoteCost, minQuoteCost) =>
  BEST_VALUE - BEST_VALUE * ((quoteCost - minQuoteCost) / quoteCost);

export const getCalculatedTransitTimeValue = (quoteTransitTime, minQuoteTransitTime) => {
  let minimumQuoteDivision = 0;
  if (quoteTransitTime.length !== 2) {
    minimumQuoteDivision = 1;
  } else {
    minimumQuoteDivision = (quoteTransitTime[0] - minQuoteTransitTime[0]) / quoteTransitTime[0];
    if (isNaN(minimumQuoteDivision)) {
      minimumQuoteDivision = 1;
    }
  }
  return BEST_VALUE - BEST_VALUE * minimumQuoteDivision;
};

export const getGuaranteedSpaceRankValue = (quoteIsGuaranteedSpace) =>
  quoteIsGuaranteedSpace ? BEST_VALUE : 0;

export const compareBestValueQuote = (a, b) => {
  const bestValueScore = a.rankValues.bestValueRankForQuote - b.rankValues.bestValueRankForQuote;
  if (bestValueScore > 0) {
    return b;
  } else if (bestValueScore === 0) {
    return a.sortValue.vendorKey > b.sortValue.vendorKey ? a : b;
  } else {
    return a;
  }
};

export const compareQuickestQuote = (a, b) => {
  const { transitTimeDiff, costDiff, vendorKey } = compareQuotesValues(a.sortValue, b.sortValue);

  if (transitTimeDiff === 0) {
    if (costDiff !== 0) {
      return costDiff > 0 ? b : a;
    } else {
      return vendorKey ? a : b;
    }
  }
  return transitTimeDiff > 0 ? b : a;
};

export const compareGreenestQuote = (a, b) => {
  const { estimatedCO2Diff, transitTimeDiff, costDiff, vendorKey } = compareQuotesValues(
    a.sortValue,
    b.sortValue
  );

  if (estimatedCO2Diff === 0) {
    if (costDiff === 0) {
      if (transitTimeDiff !== 0) {
        return transitTimeDiff > 0 ? b : a;
      } else {
        return vendorKey ? a : b;
      }
    }
    return costDiff > 0 ? b : a;
  }
  return estimatedCO2Diff > 0 ? b : a;
};

export const compareCheapestQuote = (a, b) => {
  const { transitTimeDiff, costDiff, vendorKey } = compareQuotesValues(a.sortValue, b.sortValue);
  if (costDiff === 0) {
    if (transitTimeDiff !== 0) {
      return transitTimeDiff > 0 ? b : a;
    } else {
      return vendorKey ? a : b;
    }
  }
  return costDiff > 0 ? b : a;
};

export const comapreDepartureDate = (a, b) => {
  const aDeparture = a && moment(a);
  const bDeparture = b && moment(b);
  if (!aDeparture) {
    if (!bDeparture) {
      return 0;
    }
    return 1;
  }
  if (!bDeparture) {
    return -1;
  }
  if (aDeparture.isSame(bDeparture)) {
    return 0;
  }
  return aDeparture.isBefore(bDeparture) ? -1 : 1;
};

export const compareQuotesValues = (a, b) => {
  const estimatedCO2Diff = get(a, 'co2.value', 1000000) - get(b, 'co2.value', 1000000);
  const transitTimeDiff = countTransitTimeDiff(a.transitTime, b.transitTime);
  const costDiff = a.costWithDiscount - b.costWithDiscount;
  const vendorKey = a.vendorKey > b.vendorKey;
  const departureDiff = comapreDepartureDate(
    get(a, 'departureDate', ''),
    get(b, 'departureDate', '')
  );
  return {
    estimatedCO2Diff,
    transitTimeDiff,
    costDiff,
    vendorKey,
    departureDiff
  };
};

export const compareQuotesDepartureDates = (a, b) => {
  const { transitTimeDiff, costDiff, vendorKey, departureDiff } = compareQuotesValues(
    a.sortValue,
    b.sortValue
  );
  if (departureDiff === 0) {
    if (costDiff === 0) {
      if (transitTimeDiff !== 0) {
        return transitTimeDiff > 0 ? 1 : -1;
      } else {
        return vendorKey ? -1 : 1;
      }
    }
    return costDiff > 0 ? 1 : -1;
  }
  return departureDiff > 0 ? 1 : -1;
};

export const getQuoteTransitTimeInDays = (quote) => {
  const { to, from } = get(quote, 'connection.transitTime.estimatedTransitTimes[0]', {});

  if (!to && !from) {
    return [];
  }
  if (!to && from) {
    return [from.value, from.value];
  }

  if (to && !from) {
    return [to.value, to.value];
  }

  return [from.value, to.value];
};

export const fillQuotesDataForSortingAndFiltering = (quotes, rfqServices) => {
  const results = quotes.map((quote) => {
    const guaranteedSpace = isQuoteGuaranteedSpace(quote);
    return {
      ...quote,
      sortValue: {
        cost: get(quote, 'priceIndicator.totalCharge.value'),
        costWithDiscount: get(quote, 'priceIndicator.totalChargeDiscounted.value'),
        currency: get(quote, 'priceIndicator.totalCharge.currencyID'),
        transitTime: getQuoteTransitTimeInDays(quote),
        guaranteedSpace,
        co2: get(quote, 'co2Emissions'),
        vendorKey: getQuoteBySellerByModeId(quote),
        departureDate: get(quote, 'connection.connectionSegments[0].scheduledTimeOfDeparture')
      },
      filterValue: {
        guaranteedSpace
      },
      missingServices: missingServices(rfqServices, quote)
    };
  });
  return results;
};

export const getCheapestAndQuickestQuoteValues = (quotes = {}) =>
  Object.entries(quotes).reduce(
    (acc, [, quote]) => {
      if (quote.sortValue.costWithDiscount < acc.minCostQuote) {
        acc.minCostQuote = quote.sortValue.costWithDiscount;
      }

      acc.minTTQuote = compareTransitTime(quote.sortValue.transitTime, acc.minTTQuote);

      return acc;
    },
    {
      minCostQuote: Infinity,
      minTTQuote: []
    }
  );

export const addRanksValuesToQuotes = (quotes = {}, config) => {
  const minValues = getCheapestAndQuickestQuoteValues(quotes);
  for (let key in quotes) {
    const quote = quotes[key];

    const costRankValue = getCostRankValue(
      quote.sortValue.costWithDiscount,
      minValues.minCostQuote
    );
    const transitTimeRankValue = getCalculatedTransitTimeValue(
      quote.sortValue.transitTime,
      minValues.minTTQuote
    );
    const guaranteedSpace = getGuaranteedSpaceRankValue(quote.sortValue.guaranteedSpace);

    const recommendedValueForQuote = getRecommendedValueRank(
      costRankValue,
      transitTimeRankValue,
      guaranteedSpace,
      config
    );

    const bestValueRankForQuote = getBestValueRank(
      costRankValue,
      transitTimeRankValue,
      guaranteedSpace,
      config
    );

    quotes[key] = {
      ...quote,
      rankValues: {
        recommendedValueForQuote,
        bestValueRankForQuote
      }
    };
  }
  return quotes;
};

export const getRankedQuotes = (quotes = {}, indexIds = [], excludeMissingService = true) => {
  let bestValueQuote;
  let quickestQuote;
  let cheapestQuote;
  let greenestQuote;
  indexIds.forEach((id) => {
    const quote = quotes[id];
    if (excludeMissingService && quote.missingServices.length > 0) {
      return;
    }
    if (!bestValueQuote) {
      bestValueQuote = quote;
    } else {
      bestValueQuote = compareBestValueQuote(bestValueQuote, quote);
    }

    if (!quickestQuote) {
      quickestQuote = quote;
    } else {
      quickestQuote = compareQuickestQuote(quickestQuote, quote);
    }

    if (!cheapestQuote) {
      cheapestQuote = quote;
    } else {
      cheapestQuote = compareCheapestQuote(cheapestQuote, quote);
    }

    if (!greenestQuote) {
      greenestQuote = quote;
    } else {
      greenestQuote = compareGreenestQuote(greenestQuote, quote);
    }
  });

  return {
    bestValueId: bestValueQuote?.referenceID,
    cheapestId: cheapestQuote?.referenceID,
    quickestId: quickestQuote?.referenceID,
    greenestId: greenestQuote?.referenceID
  };
};

export const sortQuotes = (indexes, quotes, type) => {
  switch (type) {
    case 'cheapest':
      return sortByCheapest(indexes, quotes);
    case 'quickest':
      return sortByQuickest(indexes, quotes);
    case 'greenest':
      return sortByQuoteGreenest(indexes, quotes);
    case 'bestValue':
    default:
      return sortQuoteByBestValue(indexes, quotes);
  }
};

export const sortQuoteByBestValue = (indexes, quotes) =>
  indexes.slice().sort((a, b) => {
    const result = compareBestValueQuote(quotes[a], quotes[b]);
    ///If compareFunction(a, b) returns a value > than 0, sort b before a.
    return result === quotes[b] ? 1 : -1;
  });

export const sortByQuickest = (indexes, quotes) =>
  indexes.slice().sort((a, b) => {
    const result = compareQuickestQuote(quotes[a], quotes[b]);
    ///If compareFunction(a, b) returns a value > than 0, sort b before a.
    return result === quotes[b] ? 1 : -1;
  });

export const sortByCheapest = (indexes, quotes) =>
  indexes.slice().sort((a, b) => {
    const result = compareCheapestQuote(quotes[a], quotes[b]);
    ///If compareFunction(a, b) returns a value > than 0, sort b before a.
    return result === quotes[b] ? 1 : -1;
  });

export const sortByQuoteGreenest = (indexes, quotes) =>
  indexes.slice().sort((a, b) => {
    const result = compareGreenestQuote(quotes[a], quotes[b]);
    ///If compareFunction(a, b) returns a value > than 0, sort b before a.
    return result === quotes[b] ? 1 : -1;
  });

export const resultsByVendorByKey = (indexes = [], quotes = {}) => {
  const tmpObject = {};
  const lists = indexes.reduce(
    (acc, id) => {
      const quote = quotes[id];
      const mode = quote.transportMode;
      if (tmpObject[quote.sortValue.vendorKey + '_' + mode] || quote.missingServices.length > 0) {
        acc.others.push(id);
      } else {
        acc.main.push(id);
        tmpObject[quote.sortValue.vendorKey + '_' + mode] = true;
      }
      return acc;
    },
    {
      main: [],
      others: []
    }
  );

  return lists;
};

export const countUniqResultsByVendorByKey = (quotes = {}) => {
  const tmpObject = {};
  return Object.entries(quotes).reduce((acc, [, quote]) => {
    if (
      (!tmpObject[quote.sortValue.vendorKey] && quote.missingServices.length === 0) ||
      quote.sortValue.guaranteedSpace
    ) {
      acc += 1;
      tmpObject[quote.sortValue.vendorKey] = true;
    }
    return acc;
  }, 0);
};

export const countGuaranteedCapacityResults = (quotes = {}) => {
  const guauranteedSpaceQuotes = Object.values(quotes).filter((quote) =>
    get(quote, 'sortValue.guaranteedSpace', false)
  );
  return guauranteedSpaceQuotes.length;
};
