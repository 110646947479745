import React, { useState } from 'react';
import Loading from 'financialSharedComponents/components/common/Loading';
import { paymentFeeConfigSelector } from 'financialSharedComponents/selectors';
import { connect } from 'react-redux';
import { Button, Checkbox, Col, Modal, Row } from '@freightos/design-system';
import { model as LayoutModel } from 'layout';
import { t } from 'utils/translationProvider';
import styles from './TokenizeFormFooter.module.scss';
import { URL_FOR_PRICING_POLICY } from '../constants';
import FreightosAutopayModal from '../FreightosAutopayModal';

const { isMobileSelector } = LayoutModel;

const CheckoutFormFooter = ({
  isSubmitDisabled,
  isTokenizing,
  registerToAutoPay,
  isSavingToken,
  saveCardChecked,
  setSaveCardChecked,
  payButtonTitle,
  isMobile,
  feesConfig,
  onPayNowButtonClick
}) => {
  const [isLearnMoreVisible, setLearnMoreVisible] = useState(false);

  const showLearnMore = () => {
    setLearnMoreVisible(true);
  };

  if (!feesConfig || feesConfig?.fetching) {
    return <Loading />;
  }

  return (
    <div className={styles.TokenizeFormFooter}>
       <Row>{registerToAutoPay &&
        <Col>
          <Checkbox
            className={styles.consentText}
            checked={saveCardChecked}
            onChange={() => setSaveCardChecked(!saveCardChecked)}
          >
            {t(
              'payment/shared_comp_autopay_opt_in_1_text',
              'I consent to join Freightos Autopay. Freightos can auto-charge payments to my card, including adjustments, subject to the Freightos.com '
            )}
            <a href={`${URL_FOR_PRICING_POLICY}`} target="_blank" rel="noopener noreferrer">
              {t('payment/shared_comp_autopay_opt_in_2_text', 'Standard Operating Procedure')}
            </a>
            {t(
              'payment/shared_comp_autopay_opt_in_3_text',
              `, customs duties and taxes (which includes a ${feesConfig?.config?.disbursement}% disbursement fee)`
            , feesConfig)}
            <a
              href="#"
              role="button"
              rel="noopener noreferrer"
              onClick={(event) => {
                event.preventDefault();
                showLearnMore();
              }}
            >
              {t('payment/shared_comp_autopay_opt_in_4_text', '. Learn more.')}
            </a>
          </Checkbox>
        </Col>}
      </Row>
      <Button
        loading={false}
        type="primary"
        size={isMobile ? 'large' : 'default'}
        block
        onClick={onPayNowButtonClick}
        disabled={isTokenizing || isSavingToken || isSubmitDisabled || (registerToAutoPay && !saveCardChecked)}
        htmlType="submit"
      >
        {t(payButtonTitle || 'Pay now', payButtonTitle || 'Pay now')}
      </Button>
      {isLearnMoreVisible && (
        <Modal
          visible={isLearnMoreVisible}
          closable
          centered
          title={undefined}
          cancelButtonProps={{ style: { display: 'none' } }}
          className={styles.autopayModal}
          okText={t('payment/shared_comp_autopay_popup_button_text', 'I agree')}
          onOk={() => {
            setSaveCardChecked(true);
            setLearnMoreVisible(false);
          }}
          onCancel={() => {
            setLearnMoreVisible(false);
          }}
        >
          <FreightosAutopayModal
            isMobile={isMobile}
            onAgree={() => {
              setSaveCardChecked(true);
              setLearnMoreVisible(false);
            }}
          />
        </Modal>
      )}
    </div>
  );
};

const mapStateToProps = (state) => ({
  isMobile: isMobileSelector(state) ,
  feesConfig: paymentFeeConfigSelector(state),
});

export default connect(mapStateToProps)(CheckoutFormFooter);
