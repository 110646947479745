import React from 'react';
import { t } from 'utils/translationProvider';
import Classnames from 'classnames';
import { Icon, Col, Row } from '@freightos/design-system';

import styles from './CreditCardTokenizeForm.module.scss';

// eslint-disable-next-line react/prop-types
const Seperator = () => (
  <Col span={1} className={Classnames(styles.splitter, styles.splitterMoblie)}>
    <span />
  </Col>
);

// eslint-disable-next-line react/prop-types
const FreightosAutopayModal = ({ isMobile }) => (
  <div className={styles.autopayContent}>
    <h1>{t('payment/shared_comp_autopay_popup_header1_text', 'Freightos Autopay')}</h1>
    <div className={styles.autopaySubTitle}>
      {t('payment/shared_comp_autopay__popup_header2_text', 'Hassle-free shipment processing')}
    </div>
    <Row className={Classnames(styles.iconsContainer, styles.iconsContainerMobile)}>
      <Col type="flex" span={5} className={styles.iconContainer}>
        <div className={styles.autopayIcon}>
          <Icon size="large" type="credit-card" />
        </div>
        <div className={styles.autopayText}>
          {t(
            'payment/shared_comp_autopay_popup_add_cc_description_text',
            'Add a credit card for faster, easier booking on future shipments. '
          )}
        </div>
      </Col>
      <Seperator />
      <Col span={5} className={styles.iconContainer}>
        <div className={styles.autopayIcon}>
          <Icon size="large" type="verified" />
        </div>
        <div className={styles.autopayText}>
          {t(
            'payment/shared_comp_autopay_popup_auto_charge_description_text',
            'We’ll auto-charge your card, subject to our Transparent Pricing Policy. '
          )}
        </div>
      </Col>
      <Seperator />
      <Col span={5} className={styles.iconContainer}>
        <div className={styles.autopayIcon}>
          <Icon size="large" type="refund" />
        </div>
        <div className={styles.autopayText}>
          {t(
            'payment/shared_comp_autopay_popup_notify_dispute_description_text',
            'We’ll notify you when any cost adjustments are added to your shipment. '
          )}
        </div>
      </Col>
      <Seperator />
      <Col span={5} className={styles.iconContainer}>
        <div className={styles.autopayIcon}>
          <Icon size="large" type="check-circled" />
        </div>
        <div className={styles.autopayText}>
          {t(
            'payment/shared_comp_autopay_popup_dispute_description_text',
            'If any cost adjustments were made in error, you can submit a dispute. '
          )}
        </div>
      </Col>
    </Row>
  </div>
);

export default FreightosAutopayModal;
