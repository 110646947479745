import React from 'react';
import { connect } from 'microfronts-redux';

import styled, { css } from 'styled-components/macro';
import { Button, tokens } from '@freightos/design-system';

import { isMobileSelector } from 'slimSearch/selectors';
import { searchAnalytics } from 'slimSearch/actions';
import { ANALYTICS_EVENT_NAME, PAGE_WIDTH } from 'slimSearch/constants';

import { t } from 'utils/translationProvider';
import { openArticle } from 'utils';
import siteConfigModel from 'propera/siteConfig';

const {
  fdsHeadingsTextColor,
  fdsH3FontSize,
  fdsBodyLineHeight,
  fdsComponentSpacingLg,
  fdsFontWeightSemibold,
  fdsFontWeightRegular,
  fdsComponentTextColorPlaceholder
} = tokens;

const Articles = ({ isMobile, searchAnalytics, isFirstTime, domain, activeSection }) => {
  const whiteLabelPrefix = `${domain !== 'ship.freightos.com' ? `${domain}/` : ''}`;
  const articlesDataNewUser = {
    articles: [
      {
        img: t(
          `${whiteLabelPrefix}search/search_page_content_new_user_1_image`,
          `https://www.freightos.com/wp-content/uploads/2021/03/11-03.png`
        ),
        title: t('search/search_page_content_new_user_1_header_1_text', 'Freight Resources'),
        content: t(
          'search/search_page_content_new_user_1_header_2_text',
          'Everything you need to know about freight, from HS codes to Incoterms, all in one place.'
        ),
        url: t(
          'search/search_page_content_new_user_1_header_1_link',
          'https://freightos.supporthero.io/article/show/49581-freight-resources-roundup'
        )
      },
      {
        img: t(
          `${whiteLabelPrefix}search/search_page_content_new_user_2_image`,
          'https://www.freightos.com/wp-content/uploads/2021/03/11-02.png'
        ),
        title: t('search/search_page_content_new_user_2_header_1_text', 'Freight Updates'),
        content: t(
          'search/search_page_content_new_user_2_header_2_text',
          'Stay up to date with the latest breaking freight news and book with the power of knowledge.'
        ),
        url: t(
          'search/search_page_content_new_user_2_header_1_link',
          'https://www.freightos.com/freight-resources/coronavirus-updates/'
        )
      },
      {
        img: t(
          `${whiteLabelPrefix}search/search_page_content_new_user_3_image`,
          'https://www.freightos.com/wp-content/uploads/2021/03/11-01.png'
        ),
        title: t('search/search_page_content_new_user_3_header_1_text', 'Using Freightos.com'),
        content: t(
          'search/search_page_content_new_user_3_header_2_text',
          "Here's how to use Freightos.com to find real time freight quotes from vetted logistic providers."
        ),
        url: t(
          'search/search_page_content_new_user_3_header_1_link',
          'https://freightos.supporthero.io/article/show/71933-how-do-i-use-freightos-com'
        )
      }
    ]
  };

  const articlesDataRepeatUser = {
    articles: [
      {
        img: t(
          `${whiteLabelPrefix}search/search_page_content_repeat_user_1_image`,
          'https://www.freightos.com/wp-content/uploads/2021/03/11-03.png'
        ),
        title: t('search/search_page_content_repeat_user_1_header_1_text', 'Freight Resources'),
        content: t(
          'search/search_page_content_repeat_user_1_header_2_text',
          'Everything you need to know about freight, from HS codes to Incoterms, all in one place.'
        ),
        url: t(
          'search/search_page_content_repeat_user_1_header_1_link',
          'https://freightos.supporthero.io/article/show/49581-freight-resources-roundup'
        )
      },
      {
        img: t(
          `${whiteLabelPrefix}search/search_page_content_repeat_user_2_image`,
          'https://www.freightos.com/wp-content/uploads/2021/03/11-02.png'
        ),
        title: t('search/search_page_content_repeat_user_2_header_1_text', 'Shipment Management'),
        content: t(
          'search/search_page_content_repeat_user_2_header_2_text',
          'Get real-time updates about your shipment from source to destination.'
        ),
        url: t(
          'search/search_page_content_repeat_user_2_header_1_link',
          'https://freightos.supporthero.io/article/show/71934-shipment-management-featuresg-your-shipment'
        )
      },
      {
        img: t(
          `${whiteLabelPrefix}search/search_page_content_repeat_user_3_image`,
          'https://www.freightos.com/wp-content/uploads/2021/03/11-01.png'
        ),
        title: t('search/search_page_content_repeat_user_3_header_1_text', 'Freight Updates'),
        content: t(
          'search/search_page_content_repeat_user_3_header_2_text',
          'Stay up to date with the latest breaking freight news and book with the power of knowledge. '
        ),
        url: t(
          'search/search_page_content_repeat_user_3_header_1_link',
          'https://www.freightos.com/freight-resources/coronavirus-updates/'
        )
      }
    ]
  };

  const { articles } = isFirstTime ? articlesDataNewUser : articlesDataRepeatUser;

  return (
    !isMobile &&
    articles.length > 0 && (
      <ArticlesWrapper>
        {(articles || []).map((article, index) => {
          return (
            <Article
              disabled={activeSection}
              key={article.title}
              onClick={() => {
                searchAnalytics({
                  name: `${ANALYTICS_EVENT_NAME[`SEARCH_PAGE_CONTENT_BOX_${index + 1}_CLICKED`]}`,
                  data: { content_link: article.url }
                });
                if (article.url.includes('supporthero.io')) {
                  openArticle(article.url.split('/').reverse()[0]);
                } else {
                  window.open(article.url);
                }
              }}
            >
              <img alt={article.title} src={article.img} />
              <h3>{article.title}</h3>
              <p>{article.content}</p>
              <Button type="text">{t('Read more', 'Read more')}</Button>
            </Article>
          );
        })}
      </ArticlesWrapper>
    )
  );
};

const ArticlesWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  width: 80vw;
  margin: 0 auto;
  max-width: ${PAGE_WIDTH}px;
`;

const Article = styled.div`
  ${({ disabled }) =>
    disabled
      ? css`
          cursor: default;
          pointer-events: none;
        `
      : ''}
  display: flex;
  flex-direction: column;
  width: 20vw;
  max-width: 300px;
  align-items: center;

  > img {
    display: flex;
    height: 150px;
    margin-bottom: ${fdsComponentSpacingLg};
  }

  > p {
    line-height: ${fdsBodyLineHeight};
    color: ${fdsComponentTextColorPlaceholder};
    font-weight: ${fdsFontWeightRegular};
  }

  > h3 {
    font-family: Rajdhani, 'Open sans', sans-serif;
    font-size: ${fdsH3FontSize};
    text-transform: uppercase;
    color: ${fdsHeadingsTextColor};
    align-self: start;
    font-weight: ${fdsFontWeightSemibold};
    line-height: 29px;
  }

  > button {
    width: fit-content;
    align-self: start;
  }

  &:hover {
    cursor: pointer;
  }
`;

const mapStateToProps = (store) => ({
  isMobile: isMobileSelector(store),
  isFirstTime: store.propera?.user?.isFirstTime,
  domain: siteConfigModel.getDomain(store),
  activeSection: store.search?.activeSection
});

export default connect(mapStateToProps, {
  searchAnalytics
})(Articles);
