import React from 'react';
import { t } from 'utils/translationProvider';
import FreightosAutoPayBadge from 'financialSharedComponents/components/common/FreightosAutoPayBadge';
import PayPalAccountsList from './PayPalAccountsList';


const PayPalAccountsMgmt = ({
    selectedPayPalAccount,
    isChangingPaymentMethod
}) => {
    return (
        <React.Fragment>
            <div className="margin-bottom font-bolded children-vertical-align-middle">
                <span>
                    <b>{t('Use my saved PayPal account', 'Use my saved PayPal account')}</b>
                </span>
                <FreightosAutoPayBadge />
            </div>
            <PayPalAccountsList defaultAccount={selectedPayPalAccount} />
        </React.Fragment>
    )
}

PayPalAccountsMgmt.propTypes = {};

export default PayPalAccountsMgmt;
