import get from 'lodash/get';
import { isEmpty, set, flow } from 'lodash/fp';
import moment from 'moment';
import stringSimilarity from 'string-similarity';
import { isFilterGroupActive, isRangeVisibleActive } from 'results/filters.utils';
import { isQuoteGuaranteedSpace } from 'results/sort.utils';
import {
  ADDRESS_TYPES,
  ACCESSORIALS_SCOPES,
  ACCESSORIALS_NAMES,
  LOAD_TYPES,
  OPEN_FREIGHT_LOCATION_TYPES,
  FULFILMENT_CENTER_BRAND_TO_KEY
} from 'slimSearch/constants';
import { getShipmentTypeByLoadData } from 'slimSearch/utils/mapOpenFreight.utils';

const portTypes = [
  OPEN_FREIGHT_LOCATION_TYPES.SEAPORT.toLowerCase(),
  OPEN_FREIGHT_LOCATION_TYPES.AIRPORT.toLowerCase()
];

export const getQuoteCurrency = (shipment) =>
  get(shipment, 'pricePreference.requestCurrency.value', '');

export const getRfqKeyFromUrl = () => {
  if (window.location.pathname.includes('search')) {
    // services and custom quote
    return window.location.pathname.split('/')[3];
  } else if (window.location.pathname.includes('/results')) {
    return window.location.pathname.split('/')[2];
  }
  return undefined;
};

export const isResidential = (shipment = {}) => {
  if (!shipment.accessorials) {
    return false;
  }
  return shipment.accessorials.find(
    (item) =>
      item.scope === ACCESSORIALS_SCOPES.DELIVERY &&
      item.name === ACCESSORIALS_NAMES.RESIDENTIAL_BASIC_SERVICES
  );
};

export const isFullFillmentCenter = (shipment) =>
  shipment.fulfilmentInfo?.fulfilmentCompanyShipment?.additionalServices
    ?.fulfilmentCompanyAppointment;

export const isLastMileDelivery = (shipment) => {
  if (
    shipment.fulfilmentInfo &&
    !shipment.fulfilmentInfo?.fulfilmentCompanyShipment?.additionalServices
      ?.fulfilmentCompanyAppointment
  ) {
    // for last mile we need that fulfilmentInfo will be true and fulfilmentCompanyAppointment will be false
    return true;
  }
  return false;
};

export const mapOldRfqLocations = (shipment, fullFillmentLocations) => {
  const updateFieldsContent = [];
  const originType = get(shipment, 'originLocation.locationTypeCode', '');
  const destinationType = get(shipment, 'destinationLocation.locationTypeCode', '');

  let originLocation = get(shipment, 'originLocation', {});
  let destinationLocation = get(shipment, 'destinationLocation', {});

  if (!portTypes.includes(originType.toLowerCase())) {
    updateFieldsContent.push({
      path: 'quote.origin.originLocation',
      value: {
        ...originLocation,
        locationTypeCode: ADDRESS_TYPES.FACTORY_WAREHOUSE.value,
        locationCode: get(shipment, 'originLocation.addressLines[0]', '')
      }
    });
  }

  if (!portTypes.includes(destinationType.toLowerCase())) {
    const loadType = getShipmentTypeByLoadData(shipment.load.packages, 'load.packages', []);
    const destinationLocationCode = get(shipment, 'destinationLocation.addressLines[0]', '');
    destinationLocation.locationCode = destinationLocationCode;
    if (isResidential(shipment)) {
      destinationLocation.locationTypeCode = ADDRESS_TYPES.ADDRESS.value;
    } else if (isFullFillmentCenter(shipment)) {
      const brand = shipment.fulfilmentInfo?.fulfilmentCompanyShipment?.fulfilmentCompanyName;
      const brandKey = FULFILMENT_CENTER_BRAND_TO_KEY[brand];

      const location = (fullFillmentLocations[brandKey] || []).find(
        (item) => stringSimilarity.compareTwoStrings(destinationLocationCode, item.value) > 0.75
      );

      if (location) {
        destinationLocation = {
          ...destinationLocation,
          label: location.label,
          ecommerceKey: location.ecommerceKey,
          brand: location.brandKey,
          locationCode: location.ecommerceKey,
          locationTypeCode: ADDRESS_TYPES.FULFILMENT_CENTER.value
        };
      }
    } else if (isLastMileDelivery(shipment)) {
      destinationLocation.locationTypeCode = ADDRESS_TYPES.LAST_MILE_DELIVERY.value;
    } else if (loadType === LOAD_TYPES.CONTAINER.value) {
      destinationLocation.locationTypeCode = ADDRESS_TYPES.FACTORY_WAREHOUSE.value;
    } else if (loadType === LOAD_TYPES.LOOSE_CARGO.value) {
      destinationLocation.locationTypeCode = ADDRESS_TYPES.BUSINESS_ADDRESS.value;
    }

    updateFieldsContent.push({
      path: 'quote.destination.destinationLocation',
      value: destinationLocation
    });
  }
  return updateFieldsContent;
};

export const isOldRfqVersion = (shipment) => {
  if (isEmpty(shipment)) {
    return true;
  }
  // const goodsValue = get(shipment, 'load.declaredValue.value');
  // if (!goodsValue) {
  //   return true;
  // }

  // if both destination and origin is port and there is goods value{}
  const originType = get(shipment, 'originLocation.locationTypeCode', '');
  const originName = get(shipment, 'originLocation.locationName', '');
  const destinationType = get(shipment, 'destinationLocation.locationTypeCode', '');
  const destinationName = get(shipment, 'destinationLocation.locationName', '');

  if (!portTypes.includes(originType.toLowerCase())) {
    if (!originName) {
      return true;
    }
  }

  if (!portTypes.includes(destinationType.toLowerCase())) {
    if (!destinationName) {
      return true;
    }
  }
  return false;
  // if one of them is not port or there is no goods values

  // if FCL - always set origin to factory and destination to warehouse

  // if lcl - always set origin to factory and destination to business.

  //
};

export const mapArrayToObject = (arr = [], key) =>
  arr.reduce((acc, value) => {
    acc[value[key]] = value;
    return acc;
  }, {});

const isPort = (leg) => ['Seaport', 'Airport'].includes(leg.locationTypeCode);

export const isPortToPortSegment = (segment) =>
  isPort(get(segment, 'legs[0].origin')) && isPort(get(segment, 'legs[0].destination'));

export const getOriginPorts = (quote = {}) =>
  (quote.connection?.connectionSegments ?? []).reduce((acc, segment) => {
    const item = segment.legs?.find((leg) =>
      segment.transportMode !== 'LTL' ? isPort(leg.origin) : isPort(leg.destination)
    );
    if (item && !acc) {
      acc = item.origin.locationCode;
    }
    return acc;
  }, '');

export const getDestinationPorts = (quote) => {
  return (quote.connection?.connectionSegments ?? [])
    .slice()
    .reverse()
    .reduce((acc, segment) => {
      const item = segment.legs?.find((leg) =>
        segment.transportMode !== 'LTL' ? isPort(leg.destination) : isPort(leg.origin)
      );
      if (item && !acc) {
        acc = item.destination.locationCode;
      }
      return acc;
    }, '');
};

// eslint-disable-next-line no-unused-vars
export const getVendors = (quote = {}) =>
  quote.businessInfo?.parties?.filter((item) => item.partyTypeCode === 'FW');

export const getVendorsNames = (quote = {}) => getVendors(quote).map((item) => item.name);

export const getCarriers = (quote) => {
  return quote.connection?.connectionSegments?.reduce((acc, segment) => {
    if (isPortToPortSegment(segment) && segment.operatingCarrierCode) {
      acc.push(segment.operatingCarrierCode);
    }
    return acc;
  }, []);
};

export const getMode = (quote = {}) => quote.transportMode;

export const getQuotePrice = (quote) => quote.priceIndicator?.totalCharge?.value ?? 0;

export const getQuotePriceCurrency = (quote) => quote.priceIndicator?.totalCharge?.currencyID ?? '';

export const getQuoteValidToDate = (quote) => quote.validTo;

export const getIsGuaranteedSpace = (quote) =>
  isQuoteGuaranteedSpace(quote) ? 'Guaranteed Capacity' : [];

export const addFilterItem = (items = [], list = []) => {
  if (isEmpty(items)) {
    return list;
  }
  if (typeof items === 'string') {
    items = [items];
  }
  items.forEach((item) => {
    const index = list.findIndex((listItem) => listItem.key === item);
    if (index > -1) {
      list[index] = { ...list[index], count: ++list[index].count };
    } else {
      list.push({
        key: item,
        count: 1,
        active: false
      });
    }
  });
  return [...list];
};

export const addRangeFilterItem = (item, range = { min: Infinity, max: 0 }) => {
  if (!item) {
    return range;
  }

  if (item < range.min) {
    range.min = item;
  }
  if (item > range.max) {
    range.max = item;
  }

  return range;
};

export const addDateRangeFilterItem = (date, range = { min: '', max: '' }) => {
  if (!date) {
    return range;
  }
  if (!moment.isDate(new Date(date))) {
    return range;
  }

  if (!range.min) {
    range.min = date;
  } else if (moment(date).isBefore(range.min)) {
    range.min = date;
  }

  if (!range.max) {
    range.max = date;
  } else if (moment(date).isAfter(range.max)) {
    range.max = date;
  }

  return range;
};

export const mapQuotesToFilters = (quotes) => {
  return quotes.reduce((acc, quote) => {
    acc.mode = { list: addFilterItem(getMode(quote), acc.mode?.list) };
    acc.origin = { list: addFilterItem(getOriginPorts(quote), acc.origin?.list) };
    acc.destination = { list: addFilterItem(getDestinationPorts(quote), acc.destination?.list) };
    acc.vendors = { list: addFilterItem(getVendorsNames(quote), acc.vendors?.list) };
    acc.carrier = { list: addFilterItem(getCarriers(quote), acc.carrier?.list) };
    acc.price = {
      range: addRangeFilterItem(getQuotePrice(quote), acc.price?.range),
      currency: getQuotePriceCurrency(quote) // this will take the last quote might cause some bugs
    };
    acc.validTo = { range: addDateRangeFilterItem(getQuoteValidToDate(quote), acc.validTo?.range) };
    acc.bookingType = { list: addFilterItem(getIsGuaranteedSpace(quote), acc.bookingType?.list) };

    return acc;
  }, {});
};

export const combineFilterValues = (filters = [], newValues = []) => {
  if (!filters.length) {
    return newValues;
  }

  return newValues.reduce((acc, value) => {
    const index = acc.findIndex((listItem) => listItem.key === value.key);
    if (index > -1) {
      acc[index].count = acc[index].count + value.count;
    } else {
      acc.push(value);
    }
    return acc;
  }, filters.slice());
};

export const combineDateRangeFilterValues = (dateRange, newDateRange) => {
  if (!dateRange.min || moment(dateRange.min).isAfter(newDateRange.min)) {
    dateRange.min = newDateRange.min;
  }

  if (!dateRange.max || moment(dateRange.max).isBefore(newDateRange.max)) {
    dateRange.max = newDateRange.max;
  }

  return dateRange;
};

export const combineRangeFilterValues = (filter, newFilter) => {
  if (filter.min > newFilter.min) {
    filter.min = newFilter.min;
  }

  if (filter.max < newFilter.max) {
    filter.max = newFilter.max;
  }
  return filter;
};

export const updateFilters = (filters, quotesFilters = {}) => {
  Object.entries(quotesFilters).forEach(([key, filter]) => {
    // eslint-disable-next-line default-case
    switch (filters[key]?.type) {
      case 'toggle':
      case 'onOff':
        filters = set(`${key}.list`, combineFilterValues(filters[key].list, filter.list), filters);
        break;
      case 'range':
        filters = flow([
          set(`${key}.range`, combineRangeFilterValues(filters[key].range, filter.range)),
          set(`${key}.currency`, filter.currency)
        ])(filters);
        break;
      case 'dateRange':
        filters = set(
          `${key}.range`,
          { ...combineDateRangeFilterValues(filters[key].range, filter.range) },
          filters
        );
        break;
    }
  });
  return filters;
};

export const resetAllFilters = (filters) => {
  for (let key in filters) {
    if (filters[key].type === 'toggle') {
      filters = {
        ...filters,
        [key]: {
          ...filters[key],
          modeActive: false,
          list: filters[key].list.map((listItem) => ({
            ...listItem,
            active: false,
            only: false,
            disabled: false
          }))
        }
      };
    }
    if (filters[key].type === 'range' || filters[key].type === 'dateRange') {
      filters = {
        ...filters,
        [key]: {
          ...filters[key],
          currentRange: [],
          modeActive: false
        }
      };
    }
    if (filters[key].type === 'onOff') {
      filters = {
        ...filters,
        [key]: {
          ...filters[key],
          modeActive: false,
          list: filters[key].list.map((listItem) => ({
            ...listItem,
            active: false,
            disabled: false
          }))
        }
      };
    }
  }
  return filters;
};

export const toggleFilterItem = (mode, itemKey, filters) => {
  const list = filters[mode].list.map((listItem) => {
    if (listItem.key === itemKey) {
      return {
        ...listItem,
        active: !listItem.active
      };
    } else {
      return listItem;
    }
  });

  return {
    ...filters,
    [mode]: {
      ...filters[mode],
      modeActive: isFilterGroupActive({ ...filters[mode], list }),
      list
    }
  };
};

export const setRangeItem = (mode, range, filters) => {
  return {
    ...filters,
    [mode]: {
      ...filters[mode],
      currentRange: range,
      modeActive: isRangeVisibleActive({
        ...filters[mode],
        currentRange: range
      })
    }
  };
};
