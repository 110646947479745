import { connect } from 'react-redux';
import 'styles/main.scss';
import * as selectors from 'financialSharedComponents/selectors';
import PaymentView from './Payment';
import * as actions from './actions';

const mapStateToProps = (state) => ({
  paymentMethodsBusy: selectors.paymentMethodTypesBusySelector(state),
  defaultCardFromPaymentService: selectors.defaultCardSelector(state),
  selectedPaymentMethod: selectors.selectedPaymentMethodSelector(state),
  cardErrorMessage: selectors.cardErrorMessageSelector(state),
  selectedPaymentMethodPspName: selectors.selectedPaymentMethodPSPNameSelector(state),
  paymentMethodsError: selectors.paymentMethodTypesErrorSelector(state)
});
const mapDispatchToProps = (dispatch, ownProps) => {
  const { paymentAmount } = ownProps;
  return {
    fetchSupportedPaymentTypes: () => dispatch(actions.fetchSupportedPaymentTypes(paymentAmount)),
    initPaymentData: (payload) => dispatch(actions.initPaymentData(payload))
  };
};

const mergeProps = (stateProps, dispatchProps, ownProps) => {
  let { defaultCard } = ownProps;
  let { defaultCardFromPaymentService } = stateProps;
    defaultCard = defaultCardFromPaymentService;
  return {
    ...stateProps,
    ...dispatchProps,
    ...ownProps,
    defaultCard
  };
};

export default connect(mapStateToProps, mapDispatchToProps, mergeProps)(PaymentView);
