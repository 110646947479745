import { FORM_ID } from './constants';

export const NEW_SEARCH = `${FORM_ID}/NEW_SEARCH`;

export const INIT_CUSTOM_QUOTE_PAGE_STATUS = `${FORM_ID}/INIT_CUSTOM_QUOTE_PAGE_STATUS`;

export const SET_CUSTOM_QUOTE_PAGE_STATUS = `${FORM_ID}/SET_CUSTOM_QUOTE_PAGE_STATUS`;

export const NOT_ALLOWED_COMMODITY = `${FORM_ID}/NOT_ALLOWED_COMMODITY`;

export const SERVER_ERROR = `${FORM_ID}/SERVER_ERROR`;

export const SUBMIT_CUSTOM_QUOTE_REQUEST = `${FORM_ID}/SUBMIT_CUSTOM_QUOTE_REQUEST`;
