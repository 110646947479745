import { getTLD } from 'utils';
export const SESSION_STORAGE_LATEST_BOOKINGS_HIDE_KEY = 'latestBookingsHide';
export const LOCAL_STORAGE_LATEST_BOOKINGS_COUNT_KEY = 'latestBookingsCount';

export const MAX_CALLS_PER_SESSION = 5;
export const MAX_COUNTS_TO_STOP_CALLING_PERMANENTLY = 5;
export const LATEST_BOOKINGS_IN_MINUTES = 1000; // past 8 1/4 hours
export const LATEST_BOOKINGS_INTERVAL_MS = 5000;

export const ENDPOINTS = {
  latestBookings: `https://lighthouse.${getTLD()}/console/shipments/last?minutes=<%= minutes %>`
};

export const CORE_TOKEN_ENDPOINT = '/servlet/apptoken?app=lh-transportplan&includeData=true';
export const LH_TOKEN_VERIFY_ENDPOINT = `https://lighthouse.${getTLD()}/transport-plan/verify`;

export const LOAD_TYPES = {
  BOX: {
    value: 'BOX',
    icon: 'box',
    label: 'Box'
  },
  CONTAINER_40: {
    value: 'CONTAINER_40',
    icon: 'container',
    label: "40'"
  },
  CONTAINER_20: {
    value: 'CONTAINER_20',
    icon: 'container',
    label: "20'"
  },
  PALLET_48_40: {
    value: 'PALLET_48_40',
    icon: 'pallet',
    label: '48" × 40"'
  },
  CONTAINER_40_HC: {
    value: 'CONTAINER_40_HC',
    icon: 'container',
    label: "40'HC"
  },
  PALLET_EUR1: {
    value: 'PALLET_EUR1',
    icon: 'pallet',
    label: '120 × 80CM (EUR1)'
  },
  PALLET: {
    value: 'PALLET',
    icon: 'pallet',
    label: ''
  },
  CRATE: {
    value: 'CRATE',
    icon: 'crate',
    label: 'Crate'
  },
  ENVELOPE: {
    value: 'ENVELOPE',
    icon: 'envelope',
    label: 'Envelope'
  },
  CONTAINER_45_HC: {
    value: 'CONTAINER_45_HC',
    icon: 'container',
    label: "45'HC"
  },
  CONTAINER_53: {
    value: 'CONTAINER_53',
    icon: 'container',
    label: "35'"
  },
  CONTAINER_20_OPEN_TOP: {
    value: 'CONTAINER_20_OPEN_TOP',
    icon: 'container',
    label: "20' Open top"
  },
  PALLET_EUR2: {
    value: 'PALLET_EUR2',
    icon: 'pallet',
    label: '120 × 100CM (EUR2)'
  },
  CONTAINER_20_FR: {
    value: 'CONTAINER_20_FR',
    icon: 'container',
    label: "20'FR"
  }
};
