import React, { useState, useEffect } from 'react';
import styled, { css } from 'styled-components/macro';
import { connect } from 'microfronts-redux';
import { Button, Drawer, tokens } from '@freightos/design-system';
import userModel from 'propera/user';
import siteConfigModel from 'propera/siteConfig';

import { t } from 'utils/translationProvider';
import { isMobileSelector } from 'slimSearch/selectors';
import {
  getRecentSearches as getRecentSearchesAction,
  resetSearchHistory as resetSearchHistoryAction,
  reportAnalytics as reportAnalyticsAction
} from './actions';
import { ANALYTIC_EVENTS, NUM_RECENT_SEARCHES_TO_SHOW } from './constants';
import RecentSearches from './components/RecentSearches';

const { getEmail: userModelGetEmail } = userModel;

const RecentSearchesContainer = ({
  isMobile,
  isLoggedIn,
  getRecentSearches,
  resetSearchHistory,
  reportAnalytics,
  isCoBrandedMPSite
}) => {
  const [openRecentSearchDrawer, setOpenRecentSearchDrawer] = useState(false);

  useEffect(() => {
    if (isLoggedIn) {
      getRecentSearches({ limit: NUM_RECENT_SEARCHES_TO_SHOW });
    } else {
      resetSearchHistory();
    }
  }, [isLoggedIn, getRecentSearches, resetSearchHistory]);

  const openDrawer = () => {
    setOpenRecentSearchDrawer(true);
    reportAnalytics(ANALYTIC_EVENTS.recentSearchMobileDrawerOpened);
  };
  const closeDrawer = () => setOpenRecentSearchDrawer(false);

  if (isMobile) {
    return (
      <>
        <StyledButton
          type="text"
          icon="clock"
          onClick={openDrawer}
          isCoBrandedMPSite={isCoBrandedMPSite}
        >
          {t('myRecentSearches/openMyRecentSearches', 'My recent searches')}
        </StyledButton>
        <StyledDrawer
          placement="bottom"
          height={481}
          visible={openRecentSearchDrawer}
          mask
          destroyOnClose
          closable={false}
          maskClosable
          bodyStyle={{ height: '100%', overflow: 'hidden', padding: 0 }}
          onClose={closeDrawer}
        >
          <RecentSearches onItemSelected={closeDrawer} onSignupClicked={closeDrawer} />
        </StyledDrawer>
      </>
    );
  }

  return <RecentSearches />;
};

const StyledDrawer = styled(Drawer)`
  & .ant-drawer-content-wrapper {
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
    overflow: hidden;
  }
`;

const StyledButton = styled(Button)`
  margin: 0 ${tokens.fdsComponentSpacingLg} 0;
  ${({ isCoBrandedMPSite }) =>
    isCoBrandedMPSite
      ? css``
      : css`
          color: ${tokens.fdsComponentBackgroundColor};
        `}
`;

const mapStateToProps = (state) => ({
  isMobile: isMobileSelector(state),
  // Email field is the indication of logged-in user because before logging-in the email is empty (while the userKey is set also before log-in)
  isLoggedIn: userModelGetEmail(state),
  isCoBrandedMPSite: siteConfigModel.isCoBrandedMPSite(state)
});

export default connect(mapStateToProps, {
  getRecentSearches: getRecentSearchesAction,
  resetSearchHistory: resetSearchHistoryAction,
  reportAnalytics: reportAnalyticsAction
})(RecentSearchesContainer);
