import { createAsyncAction, createToggleAction } from 'utils';
import { PACKAGE_NAME } from 'slimSearch/constants';
import { mapRfqToQuote } from 'slimSearch/utils/mapOpenFreight.utils';

export const UPDATE_FIELD = `${PACKAGE_NAME}/UPDATE_FIELD`;
export const SEARCH = `${PACKAGE_NAME}/SEARCH`;
export const EDIT_SEARCH = `${PACKAGE_NAME}/EDIT_SEARCH`;
export const SEARCH_AFTER_EDIT = `${PACKAGE_NAME}/SEARCH_AFTER_EDIT`;
export const CANCEL_EDIT_SEARCH = `${PACKAGE_NAME}/CANCEL_EDIT_SEARCH`;
export const SHOW_SERVICES_MODAL = `${PACKAGE_NAME}/SHOW_SERVICES_MODAL`;
export const HIDE_SERVICES_MODAL = `${PACKAGE_NAME}/HIDE_SERVICES_MODAL`;
export const SEARCH_SERVICES_COMPLETED = `${PACKAGE_NAME}/SEARCH_SERVICES_COMPLETED`;
export const SET_EDIT_MODE = `${PACKAGE_NAME}/SET_EDIT_MODE`;
export const PROGRESS_STEP_CLICK = `${PACKAGE_NAME}/PROGRESS_STEP_CLICK`;
export const SERVICE_HELP_ICON_CLICKED = `${PACKAGE_NAME}/SERVICE_HELP_ICON_CLICKED`;
export const CLEAR_SERVICE_PREDICTIONS = `${PACKAGE_NAME}/CLEAR_SERVICE_PREDICTIONS`;
export const SET_SERVICE_PREDICTIONS = `${PACKAGE_NAME}/SET_SERVICE_PREDICTIONS`;
export const SEARCH_ANALYTICS = `${PACKAGE_NAME}/SEARCH_ANALYTICS`;

export const GET_LOCATIONS = createAsyncAction(`${PACKAGE_NAME}/GET_LOCATIONS`);
export const GET_ADDRESS_BOOK_ADDRESSES = createAsyncAction(
  `${PACKAGE_NAME}/GET_ADDRESS_BOOK_ADDRESSES`
);
export const GET_ADDRESS_BOOK_SINGLE_ADDRESS = createAsyncAction(
  `${PACKAGE_NAME}/GET_ADDRESS_BOOK_SINGLE_ADDRESS`
);
export const SET_USER_ADDRESS_FROM_ADDRESS_BOOK = `${PACKAGE_NAME}/SET_USER_ADDRESS_FROM_ADDRESS_BOOK`;

export const GET_PORTS = createAsyncAction(`${PACKAGE_NAME}/GET_PORTS`);
export const GET_WAREHOUSES = createAsyncAction(`${PACKAGE_NAME}/GET_WAREHOUSES`);
export const GET_COUNTRIES = createAsyncAction(`${PACKAGE_NAME}/GET_COUNTRIES`);
export const GET_RFQ = createAsyncAction(`${PACKAGE_NAME}/GET_RFQ`);
export const GET_RFQ_SUMMARY = createAsyncAction(`${PACKAGE_NAME}/GET_RFQ_SUMMARY`);
export const UPDATE_RFQ = createAsyncAction(`${PACKAGE_NAME}/UPDATE_RFQ`);
export const TRIGGER_RFQ_SEARCH = createAsyncAction(`${PACKAGE_NAME}/TRIGGER_RFQ_SEARCH`);

export const SET_SEARCH_SESSION_ID = createToggleAction(`${PACKAGE_NAME}/SET_SEARCH_SESSION_ID`);
export const SAVE_QUOTE_FROM_SEARCH_URL = `${PACKAGE_NAME}/SAVE_QUOTE_FROM_SEARCH_URL`;
export const UPDATE_RFQ_FROM_SEARCH_PARAMS = `${PACKAGE_NAME}/UPDATE_RFQ_FROM_SEARCH_PARAMS`;
export const SET_DROPDOWN_HEIGHT = `${PACKAGE_NAME}/SET_DROPDOWN_HEIGHT`;
export const SET_SEARCH_MODE = `${PACKAGE_NAME}/SET_SEARCH_MODE`;
export const RESET_SEARCH_REDUCER_VALUES = `${PACKAGE_NAME}/RESET_SEARCH_REDUCER_VALUES`;
export const SET_IS_IN_SCOPE = `${PACKAGE_NAME}/SET_IS_SCOPE`;
export const TRIGGER_WIDGET_SEARCH = `${PACKAGE_NAME}/TRIGGER_WIDGET_SEARCH`;
export const SET_OLD_RFQ_FLOW = `${PACKAGE_NAME}/SET_OLD_RFQ_FLOW`;
export const SHOW_LOGIN_MODAL = `${PACKAGE_NAME}/SHOW_LOGIN_MODAL`;
export const GET_SHIPMENTS_METADATA = createAsyncAction(`${PACKAGE_NAME}/GET_SHIPMENTS_METADATA`);
export const GET_SOA_INFO = createAsyncAction(`${PACKAGE_NAME}/GET_SOA_INFO`);
export const GET_CREDIT_INFO = createAsyncAction(`${PACKAGE_NAME}/GET_CREDIT_INFO`);
export const NAVIGATE_TO_RESULTS = `${PACKAGE_NAME}/NAVIGATE_TO_RESULTS`;

export const updateField = (path, value, meta = null) => ({
  type: UPDATE_FIELD,
  payload: {
    path,
    value
  },
  meta
});

export const getRfq = (payload, keepRfq = false) => ({
  type: GET_RFQ.type,
  payload,
  meta: { keepRfq }
});

export const search = (payload) => ({
  type: SEARCH,
  payload
});

export const editSearch = (payload) => ({
  type: EDIT_SEARCH,
  payload
});

export const searchServiceCompleted = (payload) => ({
  type: SEARCH_SERVICES_COMPLETED,
  payload
});

export const getLocations = ({ country, searchTerm, type }) => ({
  type: GET_LOCATIONS.type,
  payload: { country, searchTerm },
  meta: { type }
});

export const getAddressBookAddresses = ({ country, type }) => ({
  type: GET_ADDRESS_BOOK_ADDRESSES.type,
  payload: { country, type }
});

export const getPorts = ({ country, searchTerm, type }) => ({
  type: GET_PORTS.type,
  payload: { country, searchTerm },
  meta: { type }
});

export const getWarehouses = ({ country, searchTerm }) => ({
  type: GET_WAREHOUSES.type,
  payload: { country, searchTerm }
});

export const showServicesModal = () => ({
  type: SHOW_SERVICES_MODAL
});

export const hideServicesModal = () => ({
  type: HIDE_SERVICES_MODAL
});

export const setEditMode = (payload = true) => ({
  type: SET_EDIT_MODE,
  payload
});

export const progressStepClick = (payload) => ({
  type: PROGRESS_STEP_CLICK,
  payload
});

export const setSearchSessionIdOn = (payload) => ({
  type: SET_SEARCH_SESSION_ID.ON,
  payload
});

export const setSearchSessionIdOff = () => ({
  type: SET_SEARCH_SESSION_ID.OFF
});

export const serviceHelpIconClicked = (payload) => ({
  type: SERVICE_HELP_ICON_CLICKED,
  payload
});

export const clearServicePredictions = () => ({
  type: CLEAR_SERVICE_PREDICTIONS
});

export const setServicePredictions = (payload) => ({
  type: SET_SERVICE_PREDICTIONS,
  payload
});

export const searchAnalytics = (payload) => ({
  type: SEARCH_ANALYTICS,
  payload
});

/**
 * @deprecated
 */
export const populateRfqFromQuote = (data) => ({
  type: GET_RFQ_SUMMARY.SUCCESS,
  payload: {
    quote: mapRfqToQuote(data)
  }
});

export const getRfqSummary = (rfq) => ({
  type: GET_RFQ_SUMMARY.type,
  payload: rfq
});

export const saveQuoteFromSearchUrl = (payload) => ({
  type: SAVE_QUOTE_FROM_SEARCH_URL,
  payload
});

export const setDropDownHeight = (payload) => ({
  type: SET_DROPDOWN_HEIGHT,
  payload
});

export const cancelEditSearch = () => ({
  type: CANCEL_EDIT_SEARCH
});

export const searchAfterEdit = () => ({
  type: SEARCH_AFTER_EDIT
});

export const updateRfqFromSearchParams = () => ({
  type: UPDATE_RFQ_FROM_SEARCH_PARAMS
});

export const setSearchMode = (payload) => ({
  type: SET_SEARCH_MODE,
  payload
});

export const resetSearchReducerValues = () => ({
  type: RESET_SEARCH_REDUCER_VALUES
});

export const setIsInScope = (payload) => ({
  type: SET_IS_IN_SCOPE,
  payload
});

export const triggerWidgetSearch = () => ({
  type: TRIGGER_WIDGET_SEARCH
});

export const updateRfq = (payload) => ({
  type: UPDATE_RFQ.type,
  payload
});

export const triggerRfqSearch = (payload) => ({
  type: TRIGGER_RFQ_SEARCH,
  payload
});

export const setOldRfqFlow = (payload) => ({
  type: SET_OLD_RFQ_FLOW,
  payload
});

export const setUserAddressFromAddressBook = (payload) => ({
  type: SET_USER_ADDRESS_FROM_ADDRESS_BOOK,
  payload
});

export const getSingleUserAddressFromAddressBook = (payload) => ({
  type: GET_ADDRESS_BOOK_SINGLE_ADDRESS.type,
  payload
});

export const getShipmentsMetadata = () => ({
  type: GET_SHIPMENTS_METADATA.type
});

export const getSoaInfo = () => ({
  type: GET_SOA_INFO.type
});

export const getCreditInfo = () => ({
  type: GET_CREDIT_INFO.type
});

export const navigateToResults = (payload) => ({
  type: NAVIGATE_TO_RESULTS,
  payload
});
