import React, { useState } from 'react';
import { RadioButtonGroup, Icon, Alert } from '@freightos/design-system';
import { VeemWordLogo } from 'financialSharedComponents/images';
import { t } from 'utils/translationProvider';
import {
  SUPPORTED_PAYMENT_METHODS_TYPES,
  SUPPORTED_MONEY_TRANSFER_PSP_NAMES
} from 'financialSharedComponents/constants/app.constants';
import VeemPaymentDetails from 'financialSharedComponents/components/Payment/PaymentMethods/VeemPaymentDetails';

const getLabel = (option) => {
  switch (option) {
    case SUPPORTED_MONEY_TRANSFER_PSP_NAMES.VEEM: {
      return (
        <React.Fragment>
          <span>Bank transfer by </span>
          <VeemWordLogo />
        </React.Fragment>
      );
    }
    default:
      return (
        <React.Fragment>
          <Icon fill="#6A6F73" type="bank" size="medium" />
          <span>
            {t(
              'actionRequired/payment_method_money_transfer_wire_label_text',
              'Pay with Wire Transfer/ACH'
            )}
          </span>
        </React.Fragment>
      );
  }
};

const MoneyTransferOptions = ({
  moneyTransferOptions,
  shipmentNumber,
  paymentMethodChanged,
  selectedPSPName
}) => {
  const [selectedOption, setSelectedOption] = useState(selectedPSPName);
  const options = moneyTransferOptions
    .reduce((transferOptions, option) => {
      if (option === SUPPORTED_MONEY_TRANSFER_PSP_NAMES.VEEM) {
        transferOptions.unshift(option);
      } else {
        transferOptions.push(option);
      }
      return transferOptions;
    }, [])
    .map((m) => ({
      value: m,
      label: <div className={`money-transfer-method ${m}`}>{getLabel(m)}</div>
    }));
  const alertMessage =
    selectedOption === SUPPORTED_MONEY_TRANSFER_PSP_NAMES.VEEM
      ? t(
          'actionRequired/veem_alert_text',
          'Please reference #{shipmentNumber} in the Invoice Number field',
          {
            shipmentNumber
          }
        )
      : t(
          'actionRequired/wire_transfer_alert_text',
          'Please reference #{shipmentNumber} when remitting payment.',
          { shipmentNumber }
        );
  return (
    <div className="money-transfer-options">
      <Alert className={'wirePaymentAlert'} message={alertMessage} type="info" closable={false} />
      {options && options.length > 1 && (
        <React.Fragment>
          <div className="font-bolded">
            <b>
              {t(
                'actionRequired/money_trasfer_type_select_label_text',
                'Select money transfer option'
              )}
            </b>
          </div>
          <div>
            <RadioButtonGroup
              className="financial-separated-radio-button-group horizontal-direction bank-transfer-methods"
              defaultValue={selectedOption}
              onChange={(event) => {
                const val = event.target.value;
                setSelectedOption(val);
                paymentMethodChanged({
                  method: SUPPORTED_PAYMENT_METHODS_TYPES.BANK_TRANSFER,
                  pspName: val
                });
              }}
              options={options}
              size="large"
            />
          </div>
          {selectedOption === SUPPORTED_MONEY_TRANSFER_PSP_NAMES.VEEM && (
            <div className={`selected-bank-transfer-method-details ${selectedOption}`}>
              <VeemPaymentDetails />
            </div>
          )}
        </React.Fragment>
      )}
    </div>
  );
};

MoneyTransferOptions.propTypes = {};

export default MoneyTransferOptions;
