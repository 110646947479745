import currenciesConstants from './currencies.json';
import get from 'lodash/get';

export const roundNumberStr = (num, grouping = false) => {
  // eslint-disable-next-line no-restricted-globals
  if (isNaN(num)) return '';
  return Number(num).toLocaleString('en', {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
    useGrouping: grouping
  });
};

export const roundNumber = (num) => Number(roundNumberStr(num, false));

export const appendCurrencyToAmount = (amount, currency) => {
  const symbol = get(currenciesConstants, [currency, 'symbol'], '');
  return `${symbol}${roundNumberStr(amount, true)}`;
};

export const isCardExpiryDatePassed = (card) => {
  const date = get(card, 'expiry');
  if (!date) {
    return true;
  }
  const dateArr = date.split('/');
  if (dateArr.length !== 2) {
    return true;
  }
  const month = Number(dateArr[0]);
  const year = Number(dateArr[1]);
  const currentDate = new Date();
  const currentMonth = currentDate.getMonth();
  const currnetYear = currentDate.getFullYear();
  if (year < currnetYear) {
    return true;
  } else {
    if (currnetYear === year && month <= currentMonth) {
      return true;
    }
  }
  return false;
};
