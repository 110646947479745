import React, { useEffect, useState } from 'react';

import { Avatar, Collapse, Panel } from '@freightos/design-system';
import WireAccountDetails from 'application/BankDetails/WireAccountDetails';
import { isEmpty } from 'lodash';

import styles from './bankDetails.module.scss';

const BankDetails = ({
                       fetchBankDetails,
                       buyerCurrency,
                       bankDetails,
                       hideExpandArrows = false,
                       showAllDetails = false,
                       fetching,
                       getBankDetailsCallback,
                       numberOfLoadingPanels = 3,
                       ...rest
                     }) => {
  const [buyerBankDetails, setBuyerBankDetails] = useState([]);
  const [activePanels, setActivePanels] = useState([]);

  useEffect(() => {
    if(getBankDetailsCallback){
      fetchBankDetails(getBankDetailsCallback);
    }
  }, [getBankDetailsCallback]);


  useEffect(() => {
    if (!fetching) {
      const buyerBankDetails = bankDetails
        .filter(details => {
          if (buyerCurrency) {
            return details.currency === buyerCurrency;
          }
          return true;
        })

      setBuyerBankDetails(buyerBankDetails);
      if (bankDetails.length) {
        if (showAllDetails) {
          setActivePanels(buyerBankDetails.map((BD) => `${BD.currency}_${BD.title}`));
        } else {
          const activeBankDetails = buyerBankDetails[0];
          setActivePanels([`${activeBankDetails.currency}_${activeBankDetails.title}`]);
        }
      }
    }
  }, [fetching]);

  if (fetching || isEmpty(buyerBankDetails)) {
    return (
      <div className={styles.bankDetailsPageLoading}>
        <Collapse accordion size="small">
          {[...Array(numberOfLoadingPanels)].map(i => (
            <Panel header={<div className={styles.loadingPanel} />} key={i} />
          ))}
        </Collapse>
      </div>
    );
  }

  return (
    <div
      {...rest}
      id={styles.wirePaymentDetailsCollapse}
      data-test-id="financial-bank-details"
      className="financial-bank-details">
      <div className={styles.title}>Copy bank account details</div>
      {buyerBankDetails.length === 1 &&
        <>
          <div data-test-id="bank-details-header-desc" className={styles.bankDescription}>
            {buyerBankDetails[0].description}
          </div>
          <WireAccountDetails accountDetails={buyerBankDetails[0]}/>
        </>
      }
      {buyerBankDetails.length > 1 &&
      <Collapse defaultActiveKey={activePanels} expandIconPosition="left">
        {buyerBankDetails.map(BD => (
          <Panel
            className={`${styles.mobilePanel} 'bank-transfer-panel-${BD.currency}'`}
            key={`${BD.currency}_${BD.title}`}
            showArrow={!hideExpandArrows}
            header={
              <div data-test-id="bank-details-header" style={{ position: 'relative' }}>
                <div>
                  <Avatar type="flag" country={BD.currency.substring(0, 2)} size="small"/>
                  <span data-test-id="bank-details-header-title" className={styles.wireDetailsHeader}>
                        {BD.title}
                      </span>
                </div>
                <div data-test-id="bank-details-header-desc" className={styles.panelBankDescription}>
                  {BD.description}
                </div>
              </div>
            }>
            <WireAccountDetails accountDetails={BD}/>
          </Panel>
        ))}
      </Collapse>
      }
    </div>
  );
};

export default BankDetails;
