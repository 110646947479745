import get from 'lodash/get';
import { isLocalEnv, isTestEnv } from 'propera/utils/url';
import { shipmentModeValues } from './constants';

export const getCustomBrokerageType = (summary) => {
  const customBrokerageIncluded = get(summary, 'options.customsBrokerage.included');
  if (customBrokerageIncluded) {
    if (get(summary, 'options.customBond.included')) {
      return undefined;
    }
    return get(summary, 'options.customBond.customsBondTypes[0].name') === 'Single Entry Bond'
      ? 'SEB'
      : 'ContinuesBond';
  }
  return undefined;
};

export const applyTestForDomainPath = (domain) => {
  if (isTestEnv() || (isLocalEnv() && !domain.includes('test'))) {
    return domain.replace(/(\.)+/, '.test-');
  }
  return domain;
};

export const mapToQuickSilverValues = (values) => {
  const goodsReadyTimeStamp = new Date(values.pickUpDate).getTime();
  const internalAuthorization =
    values.poNumbers || values.internalAuth
      ? `${values.poNumbers || ''}/${values.internalAuth || ''}`
      : undefined;

  const goodsValue = get(values, 'options.insuranceValue')
    ? {
        currency: get(values, 'options.insuranceValue.currency'),
        amount: get(values, 'options.insuranceValue.amount')
      } // insurance value
    : undefined;

  const priceToBeat = get(values, 'priceToBeat.amount')
    ? { currency: 'USD', amount: get(values, 'priceToBeat.amount') }
    : undefined;

  return {
    sourceSiteDomain: applyTestForDomainPath(values.sourceSiteDomain),
    createdByEmail: values.createdByEmail,
    marketplaceRequest: values.marketplaceRequest,
    origin: {
      countryCode: get(values, 'origin.addressOrPort.country', values.origin.country) || undefined,
      locationType:
        get(values, 'origin.addressOrPort.type', values.origin.locationType) || undefined,
      addressOrPort: get(values, 'origin.addressOrPort.value', values.origin.value) || undefined,
      placeId: get(values, 'origin.addressOrPort.placeId', values.origin.placeId) || undefined
    },
    destination: {
      countryCode: get(values, 'destination.addressOrPort.country', values.destination.country),
      locationType:
        get(values, 'destination.addressOrPort.type', values.destination.locationType) || undefined,
      addressOrPort:
        get(values, 'destination.addressOrPort.value', values.destination.value) || undefined,
      placeId:
        get(values, 'destination.addressOrPort.placeId', values.destination.placeId) || undefined
    },
    productDetails: {
      goodsValue,
      goodsReady: goodsReadyTimeStamp,
      load: values.load,
      commodities: values.productType,
      preferredModes:
        values.load.type === 'FCL' ? [shipmentModeValues.OCEAN] : values.preferredModes
    },
    buyerInfo: {
      fullName: values.fullName,
      buyerEmail: values.buyerEmail,
      buyerKey: values.buyerKey,
      buyerBusinessKey: values.buyerBusinessKey,
      buyerBusinessName: values.company,
      knownShipper: get(values, 'options.knownShipper'),
      foreignImporter: get(values, 'options.foreignImporter'),
      comments: values.comments,
      internalAuthorization,
      priceToBeat,
      adminCommunicationToBuyer: values.buyersCommunication
    },
    additionalServices: {
      originAccessorials: [get(values, 'origin.locationType')],
      destinationAccessorials: [get(values, 'destination.locationType')],
      includeOriginPortCharges: get(values, 'origin.portCharges'),
      includeDestinationPortCharges: get(values, 'destination.portCharges'),
      insurance: get(values, 'options.insurance'),
      customsBrokerage: get(values, 'options.customsBrokrage'),
      customsBrokerageType: get(values, 'options.customsBrokerageType'),
      hazardous: get(values, 'options.hazardous'),
      amazonAppointment: get(values, 'specialAccessories.appointment'),
      amazonLabeling: get(values, 'specialAccessories.labeling'),
      amazonPalletization: get(values, 'specialAccessories.pallets'),
      amazonFloorLoaded: get(values, 'specialAccessories.floorLoaded'),
      labelUps: get(values, 'specialAccessories.labelUps'),
      numberOfCommodities: get(values, 'options.numberOfCommodities')
    }
  };
};
