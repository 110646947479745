import { put, call, takeLatest, select, all } from 'redux-saga/effects';
import * as monitoring from '@propera/monitoring';
import { store } from 'microfronts-redux';
import { doPostRequest } from '@propera/HTTP';
import { fetchMPtoken } from 'financialSharedComponents/api.saga';
import * as AT from 'financialSharedComponents/constants/actionTypes';
import { model as userModel } from 'propera/user';
import * as selectors from 'financialSharedComponents/selectors';
import * as PaymentActions from 'financialSharedComponents/components/Payment/actions';
import { SUPPORTED_TOKENIZE_FORMS_PSP_NAMES } from 'financialSharedComponents/components/Payment/CreditCardsMgmt/constants';
import {SUBMIT_PAYMENT_TOKEN_API_PATH , getBillingServiceBaseUrl} from 'financialSharedComponents/constants/billingServiceEndpoints'
import { PAYMENT_CONFIG_FEE_URL, doGetIntegration } from 'financialSharedComponents/constants/integrationEndpoints';
import * as Actions from './actions';

export const businessKeySelector = (state) => userModel.getBusinessKey(state);
const currentState = (state) => state;

export function* submitPaymentToken(action) {
  try {
    const { nonce, triggerPayment, pspName, type } = action.payload;
    const businessKey = yield select(businessKeySelector);
    const isChangingPaymentMethod = yield select(selectors.isChangingPaymentMethodSelector);
    let res;
    if (pspName === SUPPORTED_TOKENIZE_FORMS_PSP_NAMES.BRAIN_TREE) {
       const token = yield call(fetchMPtoken);
      res = yield call(
        doPostRequest,
        SUBMIT_PAYMENT_TOKEN_API_PATH,
        {
          nonce,
           paymentMethodType: type,
           paymentGateway: 'Braintree'
        },
        { Accept: 'application/json', Authorization: `Bearer ${token}` },
        undefined,
        undefined,
        getBillingServiceBaseUrl()
      );
    }
    yield put(Actions.saveCardTokenSuccess());
    yield put(Actions.setIsChangingPaymentMethod(false));
    yield put(PaymentActions.fetchPaymentMethods({type}));
    if (triggerPayment !== false && (triggerPayment || isChangingPaymentMethod === false)) {
      const state = yield select(currentState);
      const pspName = selectors.selectedPaymentMethodSelector(state);
      const selectedPaymentMethod = selectors.selectedPaymentMethodSelector(state);
      yield put(
        PaymentActions.doPayment({
          selectedPaymentMethod,
          pspName
        })
      );
    }
  } catch (err) {
    const error = err && err.response;
    let isIssueFromCard = false;
    if (error && JSON.stringify(error).toLowerCase().includes('declined')) {
      isIssueFromCard = true;
    } else {
      yield call(monitoring.utils.exceptionReporter, err, store.getState);
    }
    yield put(Actions.saveCardTokenFail({ isIssueFromCard }));
  }
}

export function* fetchPaymentFeeConfig() {
  try {
    const data = yield call(doGetIntegration, {
      payload: { path: PAYMENT_CONFIG_FEE_URL }
    });
    yield put(
      Actions.fetchPaymentFeeConfigSuccess({
        config: data
      })
    );
  } catch (error) {
    yield put(Actions.fetchPaymentFeeConfigFail());
    yield call(monitoring.utils.exceptionReporter, error, store.getState);
  }
}

export default function* watcher() {
  yield all([
    takeLatest(AT.FETCH_PAYMENT_FEE_CONFIG, fetchPaymentFeeConfig),
    takeLatest(AT.SAVE_CARD_TOKEN, submitPaymentToken)
  ]);
}
