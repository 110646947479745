import { getLatestBookingsSuccess, hideLatestBooking } from 'latestBookings/actions';

export const initialState = {
  visible: false,
  bookings: null
};

export const latestBookings = (state = initialState, action) => {
  switch (action.type) {
    case getLatestBookingsSuccess().type: {
      return {
        ...state,
        visible: true,
        bookings: action.payload
      };
    }
    case hideLatestBooking().type: {
      return {
        ...state,
        ...initialState
      };
    }
    default:
      return state;
  }
};
