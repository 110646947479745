import { PACKAGE_NAME } from 'slimSearch/constants';
import { createAsyncAction } from 'utils';

export const actionTypes = {
  SHOW_LOADER_ON_RESULTS: `${PACKAGE_NAME}/SHOW_LOADER_ON_RESULTS`,
  INIT_RESULTS_PAGE: `${PACKAGE_NAME}/INIT_RESULTS_PAGE`,
  SET_FILTERS: `${PACKAGE_NAME}/SET_FILTERS`,
  SAVE_QUOTES: `${PACKAGE_NAME}/SAVE_QUOTES`,
  SET_ONLY_FILTER_PER_MODE: `${PACKAGE_NAME}/SET_ONLY_FILTER_PER_MODE`,
  SET_SHOW_ALL_MODE_FILTERS: `${PACKAGE_NAME}/SET_SHOW_ALL_MODE_FILTERS`,
  TOGGLE_FILTER_ITEM: `${PACKAGE_NAME}/TOGGLE_FILTER_ITEM`,
  RANGE_FILTER_ITEM: `${PACKAGE_NAME}/RANGE_FILTER_ITEM`,
  DATE_RANGE_FILTER_ITEM: `${PACKAGE_NAME}/DATE_RANGE_FILTER_ITEM`,
  RESET_ALL_FILTERS: `${PACKAGE_NAME}/RESET_ALL_FILTERS`,
  CLEAN_RESULTS: `${PACKAGE_NAME}/CLEAN_RESULTS`,
  START_POLLING: `${PACKAGE_NAME}/START_POLLING`,
  QUOTES_POLLING: createAsyncAction(`${PACKAGE_NAME}/QUOTES_POLLING`),
  GET_SINGLE_QUOTE: createAsyncAction(`${PACKAGE_NAME}/GET_SINGLE_QUOTE`),
  GET_QUOTE_AND_SHIPMENT: createAsyncAction(`${PACKAGE_NAME}/GET_QUOTE_AND_SHIPMENT`),
  QUOTES_POLLING_COMPLETED: `${PACKAGE_NAME}/QUOTES_POLLING_COMPLETED`,
  SET_SORTING_TYPE: `${PACKAGE_NAME}/SET_SORTING_TYPE`,
  SHOW_ALL_RESULTS: `${PACKAGE_NAME}/SHOW_ALL_RESULTS`,
  NO_COVERAGE_RESULTS: `${PACKAGE_NAME}/NO_COVERAGE_RESULTS`,
  SET_SERVICES_REFERRER: `${PACKAGE_NAME}/SET_SERVICES_REFERRER`,
  DISPLAY_FILTERS_VIEW: `${PACKAGE_NAME}/DISPLAY_FILTERS_VIEW`,
  QUOTE_SELECTED: `${PACKAGE_NAME}/QUOTE_SELECTED`,
  QUOTE_EXPIRED: `${PACKAGE_NAME}/QUOTE_EXPIRED`
};

export const startPolling = (payload) => ({
  type: actionTypes.START_POLLING,
  payload
});

export const showLoaderOnResults = (payload) => ({
  type: actionTypes.SHOW_LOADER_ON_RESULTS,
  payload
});

export const quoteSelected = (payload) => ({
  type: actionTypes.QUOTE_SELECTED,
  payload
});

export const quotesPolling = (payload) => ({
  type: actionTypes.QUOTES_POLLING.type,
  payload
});

export const cleanResults = () => ({
  type: actionTypes.CLEAN_RESULTS
});

export const setFilters = (payload) => ({
  type: actionTypes.SET_FILTERS,
  payload
});

export const saveQuotes = (payload) => ({
  type: actionTypes.SAVE_QUOTES,
  payload
});

export const toggleFilterItem = (payload) => ({
  type: actionTypes.TOGGLE_FILTER_ITEM,
  payload
});

export const resetAllFilters = () => ({
  type: actionTypes.RESET_ALL_FILTERS
});

export const rangeFilterItem = (payload) => ({
  type: actionTypes.RANGE_FILTER_ITEM,
  payload
});

export const dateRangeFilterItem = (payload) => ({
  type: actionTypes.DATE_RANGE_FILTER_ITEM,
  payload
});

export const setSortingType = (payload) => ({
  type: actionTypes.SET_SORTING_TYPE,
  payload
});

export const noCoverageResults = () => ({
  type: actionTypes.NO_COVERAGE_RESULTS
});

export const setServicesReferrer = (payload) => ({
  type: actionTypes.SET_SERVICES_REFERRER,
  payload
});

export const initResultsPage = (payload) => ({
  type: actionTypes.INIT_RESULTS_PAGE,
  payload
});

export const displayFiltersView = (payload) => ({
  type: actionTypes.DISPLAY_FILTERS_VIEW,
  payload
});

export const quotesPollingCompleted = (payload) => ({
  type: actionTypes.QUOTES_POLLING_COMPLETED,
  payload
});

export const getSingleQuote = (payload) => ({
  type: actionTypes.GET_SINGLE_QUOTE.type,
  payload
});

export const getSingleQuoteSuccess = (payload) => ({
  type: actionTypes.GET_SINGLE_QUOTE.SUCCESS,
  payload
});

export const getQuoteAndShipment = (payload) => ({
  type: actionTypes.GET_QUOTE_AND_SHIPMENT.type,
  payload
});

export const getQuoteAndShipmentSuccess = () => ({
  type: actionTypes.GET_QUOTE_AND_SHIPMENT.SUCCESS
});
