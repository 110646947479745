import { Button } from '@freightos/design-system';
import {
  fdsColorPrimaryGray10,
  fdsColorPrimaryGray90,
  primaryColorToken
} from '@freightos/design-system/dist/tokens';
import { useDispatch, useSelector } from 'microfronts-redux';
import React from 'react';
import { ResultsScreenValues, ldFlags } from 'slimSearch/constants';
import CollapseComponent from 'results/components/Collapse';
import {
  isDateRangeFilterVisible,
  isRangeFilterVisible,
  isOnOffFilterVisible
} from 'results/filters.utils';
import { resetAllFilters } from 'results/results.actions';
import { activeFiltersSelector, selectFilters } from 'results/results.selectors';
import styled, { css } from 'styled-components/macro';
import { t } from 'utils/translationProvider';
import DateRangeFilter from './DateRangeFilter';
import PriceRangeFilter from './RangeFilter';
import ToggleFilterPanel from './ToggleFilter';
import Trend from 'results/components/Trend';
import ResultCountTitle from 'results/components/ResultsCountTitle';
import { flagStateSelector } from 'feature-flagging';

// eslint-disable-next-line react/display-name
export default ({ inModal = false }) => {
  const filters = useSelector(selectFilters);
  const activeFilters = useSelector(activeFiltersSelector);
  const isLatestBookingsShown = useSelector((store) =>
    flagStateSelector(store, ldFlags.MP_A_B_TEST_LATEST_BOOKINGS_SHOWN)
  );
  const dispatch = useDispatch();

  return (
    <FiltersContainer>
      {
        // using the same flag as "isLatestBookingsShown" as Data team decided to measure these 2 features together
        isLatestBookingsShown && <Trend />
      }
      <ResultCountTitle />
      <FiltersMainLabel inModal={inModal}>
        {!inModal && <div className="results-filters-title">{t('Filters', 'Filters')}</div>}
        {activeFilters.length ? (
          <Button type="text" onClick={() => dispatch(resetAllFilters())}>
            {t('filters/ClearAll', 'Clear all')}
          </Button>
        ) : null}
      </FiltersMainLabel>
      {isRangeFilterVisible(filters['price']) && (
        <CollapseComponent
          title={filters['price'].label}
          key={filters['price'].key}
          className="range-panel"
          disabled={filters['price'].modeActive}
        >
          <PriceRangeFilter filter={filters['price']} inModal={inModal} />
        </CollapseComponent>
      )}
      {isDateRangeFilterVisible(filters['validTo']) && (
        <CollapseComponent
          title={filters['validTo'].label}
          key={filters['validTo'].key}
          className="range-panel"
          disabled={filters['validTo'].modeActive}
        >
          <DateRangeFilter filter={filters['validTo']} inModal={inModal} />
        </CollapseComponent>
      )}
      {isOnOffFilterVisible(filters['bookingType']) && (
        <CollapseComponent
          title={filters['bookingType'].label}
          key={filters['bookingType'].key}
          disabled={filters['bookingType'].modeActive}
        >
          <ToggleFilterPanel filter={filters['bookingType']} inModal={inModal} />
        </CollapseComponent>
      )}
      {Object.entries(filters).map(
        ([, filter]) =>
          filter.type === 'toggle' &&
          filter.list.length > 0 && (
            <CollapseComponent title={filter.label} key={filter.key} disabled={filter.modeActive}>
              <ToggleFilterPanel filter={filter} />
            </CollapseComponent>
          )
      )}
      {/* </FiltersCollapse> */}
    </FiltersContainer>
  );
};

const FiltersMainLabel = styled.div`
  ${({ inModal }) =>
    !inModal
      ? css`
          border-bottom: 1px solid ${fdsColorPrimaryGray10};
        `
      : css`
          position: absolute;
          top: -3px;

          transform: translate(-65px, 1px);
          @media (min-width: ${ResultsScreenValues.tablet}px) {
            right: -30px;
          }
          @media (max-width: ${ResultsScreenValues.tablet - 1}px) {
            right: -12px;
          }
        `}
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-bottom: 8px;
  padding-top: 8px;
  .results-filters-title {
    font-size: 14px;
    color: ${fdsColorPrimaryGray90};
    font-weight: bold;
    line-height: 32px; // because of the button has line height from antd
  }
  button {
    font-size: 10px;
    color: ${primaryColorToken};
  }
`;

const FiltersContainer = styled.div`
  margin: 0;
  ${({ inModal }) =>
    inModal &&
    css`
      height: calc(100vh - 1600px);
    `}
`;
