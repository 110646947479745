export const SUPPORTED_PAYMENT_METHODS_TYPES = {
  BALANCE: 'BALANCE',
  CARD: 'CARD',
  PAYPAL: 'PAYPAL',
  BANK_TRANSFER: 'BANK_TRANSFER'
};

export const SUPPORTED_MONEY_TRANSFER_PSP_NAMES = {
  LAUMI: 'laumi',
  VEEM: 'veem'
};

export const BANK_TRANSFER_SUPPORTED_CURRENCIES = ['USD', 'EUR', 'GBP'];
