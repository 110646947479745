import React from 'react';
import { createPortal } from 'react-dom';
import { connect } from 'microfronts-redux';
import { openArticle } from 'utils';
import styled from 'styled-components/macro';
import OutsideClickHandler from 'react-outside-click-handler';
import { formatLoad } from 'slimSearch/components/sections/utils';
import { Tooltip, Dropdown, Icon, Alert, Tabs, TabPane, Popover } from '@freightos/design-system';

import { fdsComponentSpacingLg } from '@freightos/design-system/dist/tokens';
import {
  LOAD_TYPES,
  SECTIONS,
  ADDRESS_TYPES,
  LCL_LOAD_DEFAULTS,
  FCL_LOAD_DEFAULTS,
  FULFILMENT_CENTER_COMPANIES
} from 'slimSearch/constants';

import { updateField } from 'slimSearch/actions';
import { formatHTMLMessage, t } from 'utils/translationProvider';
import {
  StyledCategoryCard,
  MobileClose,
  ViewHolder,
  CategoryOverlayWrapper,
  mobileOverLayStyle,
  MobileSectionTitle,
  MobileSectionSubTitle,
  MobilePortalContainer,
  MobilePortalMain,
  MobilePortalFooter
} from 'slimSearch/sharedStyles';

import { getMeasurements, shouldDisableContainers, isMobileSelector } from 'slimSearch/selectors';
import Category from 'slimSearch/components/Category';
import Container from 'slimSearch/components/sections/load/Container';
import SectionFooter from 'slimSearch/components/sections/SectionFooter';

import LooseCargo from 'slimSearch/components/sections/load/LooseCargo';
import { upperFirst } from 'lodash/fp';

export const Load = ({
  measurements,
  activeSection,
  load,
  updateField,
  touched,
  disableContainers,
  isMobile,
  isIPhone,
  dropdownHeight,
  isBusinessAddress,
  isAmazon,
  mode
}) => {
  const getSubtitle = () => formatLoad(touched, load, measurements);

  if (isMobile && mode !== 'search' && mode !== 'services-results') {
    return <div>{getSubtitle()}</div>;
  }

  const Header = (
    <>
      {isMobile && (
        <MobileClose data-test-id={`${SECTIONS.LOAD}-mobile-close`}>
          <Icon
            type="close"
            onClick={() => updateField('activeSection', null, { section: activeSection })}
          />
        </MobileClose>
      )}
      {isMobile ? (
        <>
          <MobileSectionTitle>{upperFirst(SECTIONS.LOAD)}</MobileSectionTitle>
          <MobileSectionSubTitle>
            {t('search/what_are_you_shipping', 'What are you shipping?')}{' '}
            <Icon
              type="help-circled"
              size="tiny"
              onClick={() => openArticle('116995-should-i-ship-fcl-or-lcl')}
            />
          </MobileSectionSubTitle>
        </>
      ) : (
        <Heading>
          <span>
            {t('search/what_are_you_shipping', 'What are you shipping?')}{' '}
            <Popover
              getPopupContainer={(trigger) => trigger.parentElement}
              content={
                <span data-test-id={`${SECTIONS.LOAD}-title-help`}>
                  {formatHTMLMessage(
                    'search/ss_load_tooltip_text',
                    'Deciding between FCL (Full Container Load) and LCL (Less than Container Load)? <a target="_blank" href="https://resources.freightos.com/article/show/116995-should-i-ship-fcl-or-lcl"">Here</a> are some pro tips to help.'
                  )}
                </span>
              }
            >
              <Icon type="help-circled" size="tiny" />
            </Popover>
          </span>
        </Heading>
      )}
    </>
  );
  const Content = (
    <CategoryOverlayWrapper isMobile={isMobile} hasFooter>
      <StyledTabs
        activeKey={load.type}
        onChange={(value) => {
          updateField('quote.load.type', value);
        }}
      >
        <TabPane
          data-test-id={`${SECTIONS.LOAD}-loose-cargo-tab`}
          tab={
            <span>
              <Icon type="box" />
              {t('Loose Cargo', 'Loose Cargo')}
            </span>
          }
          key={LOAD_TYPES.LOOSE_CARGO.value}
        />
        <TabPane
          data-test-id={`${SECTIONS.LOAD}-loose-fcl-tab`}
          disabled={disableContainers}
          tab={
            <Tooltip
              title={
                disableContainers ? (
                  <span
                    data-test-id={`${SECTIONS.LOAD}-loose-fcl-tab-containers-not-supported-alert`}
                  >
                    {t(
                      'Containers are not supported for your selected origin/destination type',
                      'Containers are not supported for your selected origin/destination type'
                    )}
                  </span>
                ) : null
              }
            >
              <span>
                <Icon type="container" />
                {t('Containers', 'Containers')}
              </span>
            </Tooltip>
          }
          key={LOAD_TYPES.CONTAINER.value}
        />
      </StyledTabs>

      {isBusinessAddress && load.type === LOAD_TYPES.CONTAINER.value && (
        <>
          <br />
          <Alert
            message={t(
              'search/containers_can_be_shipped_only_if_loading-dock',
              'Containers can be shipped to or from a business address only if there is a loading dock.'
            )}
            type="info"
            closable={false}
          />
        </>
      )}

      {load.type === LOAD_TYPES.CONTAINER.value ? (
        <Container data-test-id={`${SECTIONS.LOAD}-containers`} />
      ) : (
        <LooseCargo data-test-id={`${SECTIONS.LOAD}-loose-cargo`} />
      )}
    </CategoryOverlayWrapper>
  );
  const Footer = (
    <SectionFooter
      {...(!load.looseCargo?.calculateByTotals || load.type === LOAD_TYPES.CONTAINER.value
        ? {
            onAdd: () => {
              updateField('pristine.load', false);
              if (load.type === LOAD_TYPES.LOOSE_CARGO.value) {
                const type = isAmazon ? LCL_LOAD_DEFAULTS.pallet_48_40 : LCL_LOAD_DEFAULTS.pallets;
                updateField(`quote.load.${load.type}.packages`, [
                  ...load[load.type].packages,
                  type
                ]);
              }
              if (load.type === LOAD_TYPES.CONTAINER.value) {
                updateField(`quote.load.${load.type}.packages`, [
                  ...load[load.type].packages,
                  FCL_LOAD_DEFAULTS
                ]);
              }
              updateField(`quote.load.active`, load[load.type].packages.length);
            }
          }
        : null)}
      onDone={() => updateField('activeSection', SECTIONS.GOODS, { section: activeSection })}
    />
  );

  if (isMobile && activeSection === SECTIONS.LOAD) {
    return createPortal(
      <MobilePortalContainer>
        <MobilePortalMain>
          {Header}
          {Content}
        </MobilePortalMain>
        <MobilePortalFooter isIPhone={isIPhone}>{Footer}</MobilePortalFooter>
      </MobilePortalContainer>,
      document.body
    );
  }

  return (
    <StyledDropdown
      {...(isMobile
        ? {
            transitionName: null
          }
        : { getPopupContainer: (trigger) => trigger.parentElement })}
      placement="bottomRight"
      data-test-id={`${SECTIONS.LOAD}-dropdown`}
      isMobile={isMobile}
      noMaxHeight
      style={{ position: 'relative' }}
      overlayStyle={isMobile ? mobileOverLayStyle : ''}
      visible={activeSection === SECTIONS.LOAD}
      overlay={
        <StyledCategoryCard isMobile={isMobile} width={500} hasFooter height={dropdownHeight}>
          <OutsideClickHandler
            disabled={activeSection !== SECTIONS.LOAD}
            onOutsideClick={(event) => {
              if (
                event.target.matches(
                  '.ant-dropdown-menu, .ant-select-dropdown *, .ant-dropdown-menu *'
                )
                // event?.target?.className.includes('ant-select-dropdown-menu-item') ||
                // event?.target?.className.includes('ant-dropdown-menu')
              ) {
                event.stopPropagation();
                event.stopImmediatePropagation();
              } else {
                updateField('activeSection', null, { section: activeSection });
              }
            }}
          >
            <ViewHolder isMobile={isMobile} dropdownHeight={dropdownHeight}>
              {Header}
              {Content}
              {Footer}
            </ViewHolder>
          </OutsideClickHandler>
        </StyledCategoryCard>
      }
      size="large"
      trigger={['click']}
    >
      <Category sectionTitle={t('Load', 'Load')} subTitle={getSubtitle()} section={SECTIONS.LOAD} />
    </StyledDropdown>
  );
};

const StyledDropdown = styled(Dropdown)`
  margin: ${fdsComponentSpacingLg} 0;

  .ant-dropdown-menu {
    max-height: 300px !important;
  }
`;

const StyledTabs = styled(Tabs)`
  .ant-tabs-top-bar {
    margin: 0;
  }
`;

const Heading = styled.h3`
  margin: 0;
`;

const mapStateToProps = (store) => {
  const origin = store.search.quote.origin.originLocation;
  const destination = store.search.quote.destination.destinationLocation;

  return {
    isAmazon:
      destination.locationTypeCode === ADDRESS_TYPES.FULFILMENT_CENTER.value &&
      destination?.brand === FULFILMENT_CENTER_COMPANIES.AMZ.key,
    isBusinessAddress:
      origin.locationTypeCode === ADDRESS_TYPES.BUSINESS_ADDRESS.value ||
      destination.locationTypeCode === ADDRESS_TYPES.BUSINESS_ADDRESS.value,
    load: store.search.quote.load,
    dropdownHeight: store.search.dropdownHeight,
    activeSection: store.search?.activeSection,
    touched: store.search?.touched?.load,
    measurements: getMeasurements(store),
    disableContainers: shouldDisableContainers(store),
    isMobile: isMobileSelector(store),
    isIPhone: store.layout.device.model === 'iPhone' && store.layout.device.os === 'iOS'
  };
};

export default connect(mapStateToProps, {
  updateField
})(Load);
