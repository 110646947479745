import { Button, Drawer } from '@freightos/design-system';
import { fdsColorPrimaryGray10, fdsColorPrimaryGray90 } from '@freightos/design-system/dist/tokens';
import { useDispatch, useSelector } from 'microfronts-redux';
import React from 'react';
import Filters from 'results/components/Filters';
import { displayFiltersView } from 'results/results.actions';
import {
  activeFiltersSelector,
  filtersViewSelector,
  resultsCountSelector,
  selectQuotesIdsList
} from 'results/results.selectors';
import { ResultsScreenValues } from 'slimSearch/constants';
import styled from 'styled-components/macro';
import { t } from 'utils/translationProvider';

const pluralQuotes = (number = 0) => {
  if (number < 1) {
    return '';
  }
  return number === 1 ? t('resultsTitle/quote', 'Quote') : t('resultsTitle/quotes', 'Quotes');
};

export const FilterDrawerWrapperComponent = () => {
  const drawerVisibility = useSelector(filtersViewSelector);
  const dispatch = useDispatch();
  const { main } = useSelector(selectQuotesIdsList);
  const activeFilters = useSelector(activeFiltersSelector);
  const { resultsCount = 0 } = useSelector(resultsCountSelector);
  return (
    <StyledModal
      visible={drawerVisibility}
      getContainer={false}
      className="filter-modal"
      mask={false}
      title={t('Filters', 'Filters')}
      placement="left"
      onClose={() => dispatch(displayFiltersView(false))}
      footer={
        <FooterElement>
          <Button type="primary" onClick={() => dispatch(displayFiltersView(false))}>
            {t('filtersBox/Done', 'Done')}
          </Button>
        </FooterElement>
      }
    >
      <>
        <FilterSummary>
          {activeFilters.length === 0 ? (
            <QuotesSummary>
              {t('filteredQuotesCount', `{quotesLength} ${pluralQuotes(resultsCount)}`, {
                quotesLength: resultsCount
              })}
            </QuotesSummary>
          ) : (
            <>
              <QuotesSummary>
                {t('filteredQuotesCount', `{quotesLength} ${pluralQuotes(main.length)}`, {
                  quotesLength: main.length || 0
                })}
              </QuotesSummary>
              <InTotalSummary>
                {t('totalQuotes', '({total} in total)', { total: resultsCount })}
              </InTotalSummary>
            </>
          )}
        </FilterSummary>
        <Filters inModal={true} />
      </>
    </StyledModal>
  );
};

export default FilterDrawerWrapperComponent;

const FilterSummary = styled.div`
  font-size: 14px;
  display: flex;
  align-items: center;
  margin-top: 16px;
  margin-bottom: 16px;
`;

const QuotesSummary = styled.div`
  font-weight: bold;
  margin-right: 4px;
`;

const InTotalSummary = styled.div`
  font-size: 11px;
`;

const StyledModal = styled(Drawer)`
  .ant-drawer-open {
    .ant-drawer-content-wrapper {
      box-shadow: none;
    }
  }
  .ant-drawer-content-wrapper {
    position: fixed;
    top: 61px;
    overflow: hidden;

    @media (min-width: ${ResultsScreenValues.tablet}px) {
      width: initial;
    }
    @media (max-width: ${ResultsScreenValues.tablet - 1}px) {
      width: 100vw !important; // to override ant default that is on the style;
    }

    .ant-drawer-content {
      @media (max-width: ${ResultsScreenValues.tablet - 1}px) {
        position: fixed;
      }
    }
  }

  .ant-drawer-body {
    padding-right: 12px;
    @media (max-width: ${ResultsScreenValues.tablet - 1}px) {
      padding-right: 0;
      height: 86% !important; //this is because antd calculate the height and put it in element.style
    }
    .fds-drawer-footer {
      border-top: 1px solid ${fdsColorPrimaryGray10};
      bottom: 18px;
      height: 100px;
      padding-top: 11px;
    }
    .ant-collapse-item:first-child {
      margin-top: 0px;
    }
  }
  .ant-drawer-title {
    font-size: 14px;
    color: ${fdsColorPrimaryGray90};
  }
  .ant-drawer-header {
    min-height: initial;
    height: 42px;

    padding-right: 12px;
    border-bottom: 1px solid ${fdsColorPrimaryGray10};
    @media (max-width: ${ResultsScreenValues.tablet - 1}px) {
      padding-right: 32px;
    }
    .ant-drawer-close {
      &:focus {
        border: none;
        outline: none;
      }
    }
  }
`;

const FooterElement = styled.div``;
