import * as types from './actionTypes';
import { DISPLAY_STATE } from './constants';

const initialState = {
  display: DISPLAY_STATE.REQUEST,
  serverError: undefined
};

const customQuoteReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.INIT_CUSTOM_QUOTE_PAGE_STATUS:
      return { ...state, display: DISPLAY_STATE.REQUEST };
    case types.SET_CUSTOM_QUOTE_PAGE_STATUS:
      return { ...state, display: action.payload };
    case types.SERVER_ERROR:
      return { ...state, serverError: action.serverError };
    default:
      return state;
  }
};

export default customQuoteReducer;
