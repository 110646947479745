import React from 'react';
import { connect } from 'microfronts-redux';
import { StepProgress } from '@freightos/design-system';
import { ResultsScreenValues } from 'slimSearch/constants';
import styled from 'styled-components/macro';

import { isMobileSelector } from 'slimSearch/selectors';
import { progressSteps } from 'slimSearch/utils';
import siteConfigModel from 'propera/siteConfig';

const SearchStepProgressComponent = ({
  active = 'recommended-services',
  isMobile,
  isOrdersEnabled
}) => (
  <StyledStepProgress
    className="search-step-progress"
    active={active}
    steps={progressSteps(isMobile, isOrdersEnabled)}
  />
);

// TODO: get rid of override when LH styling fixed
const StyledStepProgress = styled(StepProgress)`
  border: none !important;

  [class*='StepProgress__fdsStepProgressStep']:first-child,
  [class*='StepProgress__fdsStepProgressStep']:last-child {
    text-align: center !important;
  }
  margin: 0 auto 60px;
  //width: 60%;
  @media (max-width: ${ResultsScreenValues.tablet - 1}px) {
    display: none;
  }
`;

const mapStateToProps = (state) => ({
  isMobile: isMobileSelector(state),
  isOrdersEnabled: siteConfigModel.isOrdersEnabled(state)
});

export default connect(mapStateToProps)(SearchStepProgressComponent);
