import React from 'react';
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import * as financialUtils from 'utils/financial.utils';
import { RadioGroup, Icon } from '@freightos/design-system';
import { SUPPORTED_PAYMENT_METHODS_TYPES } from 'financialSharedComponents/constants/app.constants';
import { t } from 'utils/translationProvider';
import CreditCardsMgmt from 'financialSharedComponents/components/Payment/CreditCardsMgmt';
import Badge from 'financialSharedComponents/components/common/Badge';
import { getPaymentMethodLabel, getPaymentMethodIcon } from './utils';
import PayPal from './PayPal';
import MoneyTransferOptions from './MoneyTransferOptions';

const PaymentMethods = ({
  paymentMethods,
  selectedPaymentMethod,
  defaultCard,
  availableCredit,
  paymentMethodChanged
}) => {
  const supportedMethodsKeys = Object.values(SUPPORTED_PAYMENT_METHODS_TYPES);
  const methodsKeys = supportedMethodsKeys.filter((m) => Object.keys(paymentMethods).includes(m));
  const options = methodsKeys.map((methodKey) => {
    const isDisabled =
      paymentMethods[methodKey].filter((m) => get(m, ['UIMetadata', 'disabled'])).length ===
      paymentMethods[methodKey].length;
    return {
      value: methodKey,
      label: (
        <div data-test-id={methodKey}>
          <Icon type={getPaymentMethodIcon(methodKey)} />	&nbsp;
          <span className='payment-method-label'>{getPaymentMethodLabel(methodKey, paymentMethods[methodKey])}</span>
          {methodKey === SUPPORTED_PAYMENT_METHODS_TYPES.BALANCE && (
            <span className="available-credit font-bolded">
              {isDisabled
                ? t('payment/shared_comp_freightos_credit_insufficient_text', 'Insufficient credit')
                : t('payment/shared_comp_freightos_credit_available_text', 'Available Credit')}:
              <span className={isDisabled ? 'red-value' : 'green-value'}>
                {financialUtils.appendCurrencyToAmount(
                  availableCredit.amount,
                  availableCredit.currency
                )}
              </span>
            </span>
          )}
        </div>
      ),
      disabled: isDisabled
    };
  });

  return (
    <div className={`financial-payment-methods`}>
      <h2>{t('actionRequired/payment_header1_text', 'Payment details')}</h2>
      <div className="font-bolded margin-bottom">
        {t('actionRequired/payment_select_method_header2_text', 'Select a payment method')}
      </div>
      <div className={'financial-secure-badge'}>
        <Badge
          iconType={'verified'}
          text={t('payment/shared_comp_secure_booking_badge_text', 'SECURE booking')}
        />
      </div>

      {!isEmpty(paymentMethods) > 0 && (
        <div data-test-id="financial-sc-payment-methods">
          <RadioGroup
            vertical
            options={options}
            onChange={(event) => {
              paymentMethodChanged({ method: event.target.value });
            }}
            defaultValue={selectedPaymentMethod}
          />
        </div>
      )}
      <div className="payment-methods-sub-method">
        {selectedPaymentMethod === SUPPORTED_PAYMENT_METHODS_TYPES.PAYPAL && (
          <div>
            <PayPal payPalClientId="AZCy9bs3v9TMjDTFOxNFDHm13206aEq8fPYC-ub8rjNJLLpSJ-mNYgo8UVN4J1TWnrZA3T5RExUPtAEv" />
          </div>
          
        )}
        {selectedPaymentMethod === SUPPORTED_PAYMENT_METHODS_TYPES.BANK_TRANSFER && (
          <React.Fragment>
            <hr />
            <MoneyTransferOptions />
          </React.Fragment>
        )}
        {selectedPaymentMethod === SUPPORTED_PAYMENT_METHODS_TYPES.CARD && (
          <React.Fragment>
            <hr />
            <CreditCardsMgmt defaultCard={defaultCard} />
          </React.Fragment>
        )}
      </div>
    </div>
  );
};

PaymentMethods.propTypes = {};

export default PaymentMethods;
