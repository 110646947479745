import React, { useState } from 'react';
import { connect } from 'microfronts-redux';
import siteConfig from 'propera/siteConfig';
import styled, { css } from 'styled-components/macro';
import { Alert } from '@freightos/design-system';

import { fdsComponentSpacingLg } from '@freightos/design-system/dist/tokens';
import { SECTIONS } from '../constants';
import { onGoingHolidays } from '../../utils';
import { isMobileSelector } from '../selectors';

export const HolidayAlert = ({ originLocation, holidays, shouldShow, isMobile }) => {
  const [holidaysAlert, hideHolidaysAlert] = useState(null);

  if (holidaysAlert === false && holidays.length === 0) {
    return null;
  }

  if (!shouldShow) {
    return null;
  }

  return onGoingHolidays(holidays, originLocation?.countryID?.value).map((item) => (
    <StyledHolidayAlert
      onClose={() => hideHolidaysAlert(true)}
      key={item.holiday?.holidayNotification}
      type="warning"
      isMobile={isMobile}
      message={item.holiday?.holidayNotification}
    />
  ));
};

const StyledHolidayAlert = styled(Alert)`
  ${({ isMobile }) =>
    isMobile
      ? css`
          margin: 0 20px ${fdsComponentSpacingLg};
        `
      : css`
          margin-bottom: ${fdsComponentSpacingLg};
        `}
`;

const mapStateToProps = (store) => ({
  originLocation: store.search.quote.origin.originLocation,
  holidays: siteConfig.getHolidays(store),
  shouldShow: store.search.touched[SECTIONS.ORIGIN] && !store.search.pristine[SECTIONS.ORIGIN],
  isMobile: isMobileSelector(store)
});

export default connect(mapStateToProps)(HolidayAlert);
