import React from 'react';
import { t } from 'utils/translationProvider';
import { Icon } from '@freightos/design-system';

const FreightosAutoPayBadge = () => (
    <div className="freightos-autopay-badge children-vertical-align-middle">
      <span className={'autopay-text-span'}>{t('FREIGHTOS AUTOPAY', 'FREIGHTOS AUTOPAY')}</span>{' '}
      <Icon type={'check-circled'} color={'#47A96E'} size={'small'} />
    </div>
  );

  export default FreightosAutoPayBadge;