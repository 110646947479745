import { doPutRequest, doGetRequest, doPostRequest } from '@propera/HTTP';
import { all, call, takeLatest } from 'redux-saga/effects';
import * as AT from './constants/actionTypes';
import { AUTH_TOKEN_ENDPOINT } from './constants/endpoints';
import { v4 as uuidv4 } from 'uuid';
import {
  getLhGatewayBaseUrl,
  LH_GATEWAY_VERIFY_END_POINT
} from 'financialSharedComponents/constants/lhEndpoints';
import {
  getFreightosServerBaseUrl,
  getFreightosServerEndpoint,
  getFreightosMpServerEndpoint
} from 'financialSharedComponents/constants/endpoints';

export function* fetchLHtoken() {
  const authenticationResponse = yield call(
    doGetRequest,
    getFreightosServerEndpoint(),
    {},
    {},
    true,
    true,
    getFreightosServerBaseUrl()
  );
  const verificationHeaders = { Authorization: `Application ${authenticationResponse.token}` };
  const verificationResponse = yield call(
    doGetRequest,
    LH_GATEWAY_VERIFY_END_POINT,
    verificationHeaders,
    {},
    true,
    true,
    getLhGatewayBaseUrl()
  );
  const { token } = verificationResponse; // token is a permanentJWTtoken
  return token;
}

export function* fetchMPtoken() {
  return yield call(
    doGetRequest,
    getFreightosMpServerEndpoint(),
    {},
    {},
    true,
    true,
    getFreightosServerBaseUrl()
  );
}

export function* doGet({ path, headers, requestId = true, customURL, options = undefined }) {
  const requestHeaders = {
    Accept: 'application/json',
    ...(requestId && { requestId: uuidv4() }),
    ...headers
  };
  return yield call(
    doGetRequest,
    path,
    requestHeaders,
    undefined,
    undefined,
    undefined,
    customURL || '',
    options,
    AUTH_TOKEN_ENDPOINT
  );
}

export function* doPost({ path, headers, customURL, data }) {
  return yield call(
    doPostRequest,
    path,
    data,
    {
      Accept: 'application/json',
      requestId: uuidv4(),
      ...headers
    },
    undefined,
    undefined,
    customURL || '',
    undefined,
    AUTH_TOKEN_ENDPOINT
  );
}

export function* doPut({ path, headers, data }) {
  return yield call(
    doPutRequest,
    path,
    data,
    {
      Accept: 'application/json',
      requestId: uuidv4(),
      ...headers
    },
    undefined,
    undefined,
    '',
    undefined,
    AUTH_TOKEN_ENDPOINT
  );
}

export default function* watcher() {
  return all([
    takeLatest(AT.DO_GET_REQUEST, doGet),
    takeLatest(AT.DO_POST_REQUEST, doPost),
    takeLatest(AT.DO_PUT_REQUEST, doPut)
  ]);
}
