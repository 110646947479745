/* eslint-disable */
import { get, pickBy } from 'lodash/fp';
import moment from 'moment';
import {
  LOAD_TYPES,
  OPEN_FREIGHT_LOCATION_TYPES,
  ADDRESS_TYPES,
  LCL_LOAD_DEFAULTS,
  FCL_LOAD_DEFAULTS,
  CONTAINER_TYPES,
  ACCESSORIALS_NAMES,
  GOODS_READY_RANGES,
  TOTAL_LOAD_DEFAULTS
} from 'slimSearch/constants';
import { getEventDates } from 'slimSearch/utils';

export const getLoadByTotal = (quote = {}, measurements = {}) => {
  return {
    netNetWeight: {
      value: Number(quote.load?.looseCargo?.totals?.weight?.value ?? 0),
      unitCode: measurements.weight
    },
    totalQuantity: quote.load?.looseCargo?.totals?.quantity,
    totalGrossVolumeMeasure: {
      value: Number(quote.load?.looseCargo?.totals?.volume?.value ?? 0),
      unitCode: measurements.volume
    }
  };
};

export const defineLocationTypeCode = (location = {}) => {
  if (location.locationTypeCode !== ADDRESS_TYPES.PORT.key) {
    return OPEN_FREIGHT_LOCATION_TYPES.ADDRESS;
  } else if (location.locationType === 'airport') {
    return OPEN_FREIGHT_LOCATION_TYPES.AIRPORT;
  } else {
    return OPEN_FREIGHT_LOCATION_TYPES.SEAPORT;
  }
};

export const addMeasurements = (packages, measurements) =>
  packages.map((item) => {
    const result = {
      ...item,
      weight: { ...item.weight, unitCode: measurements.weight },
      packageDimensions: {
        height: {
          value: Number(item.packageDimensions?.height?.value ?? 0),
          unitCode: switchUnitTypeFromInToInch(measurements.dimensions)
        }
      }
    };
    if (loadHasWidthLengthValue(item.packagingType)) {
      return {
        ...result,
        packageDimensions: {
          ...result.packageDimensions,
          width: {
            value: Number(item.packageDimensions?.width?.value ?? 0),
            unitCode: switchUnitTypeFromInToInch(measurements.dimensions)
          },
          length: {
            value: Number(item.packageDimensions?.length?.value ?? 0),
            unitCode: switchUnitTypeFromInToInch(measurements.dimensions)
          }
        }
      };
    }

    return result;
  });

export const codeOrAddress = (location = {}) => {
  if (location.locationType === 'address-book') {
    return {
      addressLines: [location.locationCode],
      cityName: location.locationCode,
      postalCode: location.locationZipCode ?? location.locationZipCode ?? '',
      stateOrProvince: location.stateOrProvince ?? location.stateOrProvince ?? ''
    };
  }

  if (location.locationTypeCode === ADDRESS_TYPES.PORT.key) {
    return {
      locationCode: location.locationCode,
      ID: location.locationCode
    };
  } else if (location.locationTypeCode === ADDRESS_TYPES.FULFILMENT_CENTER.key) {
    return {
      locationCode: location.ecommerceKey,
      addressLines: [location.locationCode]
    };
  } else {
    return { addressLines: [location.locationCode] };
  }
};

const additionalInformation = (quote) => {
  let info = [];

  if (quote.userSelectedAddress?.origin && quote.userSelectedAddress?.origin?.addressId) {
    info.push({
      key: 'originUserAddressId',
      value: String(quote.userSelectedAddress?.origin?.addressId)
    });
  }
  if (quote.userSelectedAddress?.destination && quote.userSelectedAddress?.destination?.addressId) {
    info.push({
      key: 'destinationUserAddressId',
      value: String(quote.userSelectedAddress?.destination?.addressId)
    });
  }

  return info;
};

export const mapQuoteToRfq = (quote = {}, measurements) => {
  let packages = undefined;
  let totals = undefined;

  const locationCodeOrAddressOrigin = codeOrAddress(quote.origin?.originLocation);

  const locationCodeOrAddressDestination = codeOrAddress(quote.destination?.destinationLocation);

  if (
    quote.load?.type === LOAD_TYPES.LOOSE_CARGO.value &&
    quote.load?.looseCargo?.calculateByTotals
  ) {
    totals = getLoadByTotal(quote, measurements);
  } else {
    packages =
      quote.load?.type === LOAD_TYPES.CONTAINER.value
        ? quote.load?.container?.packages
        : addMeasurements(quote.load?.looseCargo?.packages, measurements);
  }

  return {
    originLocation: {
      locationName: quote.origin?.originLocation?.locationTypeCode,
      locationTypeCode: defineLocationTypeCode(quote.origin?.originLocation),
      ID: quote.origin?.originLocation?.ID,
      countryID: {
        value: quote.origin?.originLocation?.countryID?.value
      },
      ...locationCodeOrAddressOrigin
    },
    destinationLocation: {
      locationName: quote.destination?.destinationLocation?.locationTypeCode,
      locationTypeCode: defineLocationTypeCode(quote.destination?.destinationLocation),
      ID: quote.destination?.destinationLocation?.ID,
      countryID: {
        value: quote.destination?.destinationLocation?.countryID?.value
      },
      ...locationCodeOrAddressDestination
    },
    ...(additionalInformation(quote)
      ? { additionalInformation: additionalInformation(quote) }
      : null),
    pickupEvent: {
      eventDate: getEventDates(quote.goods.pickupEvent.goodsReadyRange)
    },
    load: {
      ...totals,
      packages,
      declaredValue: {
        value: parseInt(quote.goods?.goodsValue?.value ?? 0, 10),
        currencyID: quote.goods?.goodsValue?.currency
      }
    },
    accessorials: quote.accessorials,
    insuranceValueAmount:
      quote.goods?.goodsValue?.value > 0 ? quote.insuranceValueAmount : undefined,
    pricePreference: quote.pricePreference,
    declaredCustoms: pickBy(Boolean, quote.declaredCustoms),
    fulfilmentInfo: quote.fulfilmentInfo
  };
};

export const getShipmentTypeByLoadData = (packages = []) => {
  const isFcl = packages.some((loadItem) => {
    const packagingType = (loadItem.packagingType ?? '').toLowerCase();
    return CONTAINER_TYPES.map((container) => container.value.toLowerCase()).includes(
      packagingType.toLowerCase()
    );
  });
  return isFcl ? LOAD_TYPES.CONTAINER.value : LOAD_TYPES.LOOSE_CARGO.value;
};

export const getRfqMeasurements = (load = {}) => {
  const measurements = {};
  if (isLoadCalculateByTotal(load)) {
    measurements.weight = load.netNetWeight?.unitCode;
    measurements.volume = load.totalGrossVolumeMeasure?.unitCode;
    measurements.dimensions = measurements.weight === 'kg' ? 'cm' : 'in';
  } else {
    measurements.weight = get('packages[0].weight.unitCode', load);
    const dimensions = get('packages[0].packageDimensions.height.unitCode', load);
    if (dimensions) {
      measurements.dimensions = dimensions === 'inch' ? 'in' : dimensions;
    } else {
      measurements.dimensions = measurements.weight === 'kg' ? 'cm' : 'in';
    }

    measurements.volume = measurements.dimensions === 'cm' ? 'cbm' : 'cft';
  }
  return measurements;
};

export const isLoadCalculateByTotal = (load = {}) => {
  return !load.packages || load.packages.length === 0;
};

export const isHazardousShipment = (accessorials = []) =>
  accessorials && accessorials.some((item) => item.name === ACCESSORIALS_NAMES.HAZARDOUS);

export const loadHasWidthLengthValue = (packagingType) =>
  [LCL_LOAD_DEFAULTS.boxes.packagingType, LCL_LOAD_DEFAULTS.pallets.packagingType].includes(
    packagingType
  );

const switchUnitTypeFromInToInch = (unitType) => (unitType === 'in' ? 'inch' : unitType);

export const mapLclPackages = (load = { packages: [] }) =>
  load.packages.map((item) => {
    const packageDimensions = {
      height: {
        value: item.packageDimensions?.height?.value
      }
    };
    if (loadHasWidthLengthValue(item.packagingType)) {
      packageDimensions.width = { value: item.packageDimensions?.width?.value };
      packageDimensions.length = { value: item.packageDimensions?.length?.value };
    }
    return {
      quantity: item.quantity,
      packagingType: item.packagingType,
      weight: { value: item.weight?.value },
      packageDimensions
    };
  });

export const mapFclPackages = (load = { packages: [] }) =>
  load.packages.map((item) => ({
    quantity: item.quantity,
    packagingType: item.packagingType,
    overWeightIndicator: item.overWeightIndicator
  }));

export const mapLclTotalLoad = (load = {}) => ({
  calculateByTotals: true,
  totals: {
    quantity: load.totalQuantity,
    weight: { value: load.netNetWeight?.value },
    volume: { value: load.totalGrossVolumeMeasure?.value }
  }
});

export const isPort = (locationType) =>
  [OPEN_FREIGHT_LOCATION_TYPES.AIRPORT, OPEN_FREIGHT_LOCATION_TYPES.SEAPORT].includes(
    locationType.toLowerCase()
  );

export const mapGoodsReady = (eventDate = {}) => {
  const today = moment().add(1, 'day');

  if (!eventDate.endDateTime) {
    return GOODS_READY_RANGES.READY_NOW;
  }
  if (today.isAfter(eventDate.endDateTime)) {
    return GOODS_READY_RANGES.READY_NOW;
  } else if (today.isBefore(eventDate.scheduledDateTime)) {
    return GOODS_READY_RANGES.READY_IN_MORE_THAN_TWO_WEEKS;
  } else if (today.isBetween(eventDate.scheduledDateTime, eventDate.endDateTime)) {
    return GOODS_READY_RANGES.READY_IN_TWO_WEEKS;
  }
  return GOODS_READY_RANGES.READY_NOW;
};

export const mapLocation = (location = {}, fulfilmentInfo = undefined) => {
  if (isPort(location.locationTypeCode)) {
    return {
      locationTypeCode: ADDRESS_TYPES.PORT.value,
      locationCode: location.locationCode,
      countryID: { value: location.countryID?.value }
    };
  } else if (location.locationName === ADDRESS_TYPES.FULFILMENT_CENTER.key) {
    const brand = fulfilmentInfo ? 'AMZ' : undefined;
    return {
      locationTypeCode: location.locationName,
      countryID: { value: location.countryID?.value },
      locationCode: location.addressLines[0],
      label: location.addressLines[0],
      ecommerceKey: location.locationCode,
      brand,
      ID: location?.ID
    };
  } else {
    return {
      locationTypeCode: location.locationName,
      countryID: { value: location.countryID?.value },
      locationCode: location.addressLines[0],
      ID: location?.ID
    };
  }
};

export const isKnownShipper = (businessInfo = { parties: [] }) =>
  businessInfo && businessInfo.parties?.some((part) => part.knownShipper);

/**
 * this is using the both rfq from the reducer or from the server
 * @param {} shipment
 * @returns
 */
export const isAmazonShipment = (shipment = {}) => {
  if (
    shipment.destinationLocation?.locationName === ADDRESS_TYPES.FULFILMENT_CENTER.key ||
    shipment.destination?.destinationLocation?.locationTypeCode ===
      ADDRESS_TYPES.FULFILMENT_CENTER.key
  ) {
    if (
      shipment.fulfilmentInfo?.fulfilmentCompanyShipment?.fulfilmentCompanyName?.toLowerCase() ===
      'amazon'
    ) {
      return true;
    }
  }
  return false;
};

export const mapRfqToQuote = ({ shipment, businessInfo }) => {
  const loadType = getShipmentTypeByLoadData(shipment.load?.packages ?? []);
  const load = {
    type: loadType,
    hazardous: isHazardousShipment(shipment.accessorials),
    measurements: getRfqMeasurements(shipment?.load),
    looseCargo: {
      ...mapLclTotalLoad(shipment.load),
      packages: [
        {
          ...LCL_LOAD_DEFAULTS.pallets
        }
      ]
    },
    container: {
      packages: [
        {
          ...FCL_LOAD_DEFAULTS
        }
      ]
    }
  };

  if (loadType === LOAD_TYPES.CONTAINER.value) {
    load.container = { packages: mapFclPackages(shipment.load) };
  } else if (isLoadCalculateByTotal(shipment.load)) {
    load.looseCargo = {
      ...mapLclTotalLoad(shipment.load),
      packages: [
        {
          ...LCL_LOAD_DEFAULTS.pallets
        }
      ]
    };
  } else {
    load.looseCargo = {
      calculateByTotals: false,
      packages: mapLclPackages(shipment.load),
      totals: TOTAL_LOAD_DEFAULTS
    };
  }

  return {
    additionalInformation: shipment.additionalInformation,
    knownShipper: isKnownShipper(businessInfo),
    pickupEvent: shipment.pickupEvent,
    declaredCustoms: shipment.declaredCustoms,
    insuranceValueAmount: shipment.insuranceValueAmount,
    pricePreference: shipment.pricePreference,
    accessorials: shipment.accessorials || [],
    fulfilmentInfo: shipment.fulfilmentInfo,
    origin: {
      originLocation: mapLocation(shipment.originLocation)
    },
    destination: {
      destinationLocation: mapLocation(shipment.destinationLocation, shipment.fulfilmentInfo)
    },
    load,
    goods: {
      goodsValue: {
        value: shipment.load?.declaredValue?.value,
        currency:
          shipment.load?.declaredValue?.currencyID ??
          shipment.pricePreference?.requestCurrency?.value
      },
      pickupEvent: { goodsReadyRange: mapGoodsReady(shipment.pickupEvent?.eventDate) }
    }
  };
};
