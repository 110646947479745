import React from 'react';
import { connect } from 'microfronts-redux';
import styled, { css } from 'styled-components/macro';
import { isMobileSelector } from 'slimSearch/selectors';

import Shipments from 'slimSearch/components/Tiles/components/Shipments';
import Payments from 'slimSearch/components/Tiles/components/Payments';
import Credit from 'slimSearch/components/Tiles/components/Credit';
import { PAGE_WIDTH } from '../../constants';
import { tokens } from '@freightos/design-system';

export const Tiles = ({ isMobile }) => {
  return (
    <TilesContainer isMobile={isMobile} w={window.innerWidth}>
      <Shipments />
      <Payments />
      <Credit />
    </TilesContainer>
  );
};

const TilesContainer = styled.div`
  max-width: ${PAGE_WIDTH}px;
  width: 100%;
  margin: 0 auto;
  display: grid;
  gap: ${tokens.fdsComponentSpacingXxl};
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  z-index: 1;
  ${({ isMobile }) =>
    isMobile
      ? css`
          padding: 0 16px;
        `
      : css``}
`;

const mapStateToProps = (store) => ({
  isMobile: isMobileSelector(store)
});

export default connect(mapStateToProps)(Tiles);
