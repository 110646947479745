import React, { useState, useEffect } from 'react';
import { model as LayoutModel } from 'layout';
import { connect } from 'react-redux';
import { Row, Col } from '@freightos/design-system';
import Classnames from 'classnames';
import { Braintree, HostedField } from 'react-braintree-fields';
import styles from './CreditCardForm.module.scss';
import CheckoutFormFooter from '../common/CheckoutFormFooter';
import { t } from 'utils/translationProvider';
import { SUPPORTED_TOKENIZE_FORMS_PSP_NAMES } from '../../constants';

const { isMobileSelector } = LayoutModel;

const BrainTreeTokenizeForm = ({
  isSavingToken,
  onSubmitToken,
  payButtonTitle,
  registerToAutoPay,
  tokenizeKey,
  isMobile
}) => {
  const [tokenize, setTokenizeFunc] = React.useState();
  const [isValuesValid, setIsValuesValid] = React.useState(false);
  const [saveCardChecked, setSaveCardChecked] = useState(false);
  const [error, setError] = React.useState(null);
  const [cardNumberError, setCardNumberError] = useState(false);
  const [expiryDateError, setExpiryDateError] = useState(false);
  const [submitting, setIsSubmitting] = React.useState(false);
  const numberField = React.useRef();
  const cvvField = React.useRef();
  const [cvvFieldLabel, setCvvFieldLabel] = useState("CVC");
  const cardholderNameField = React.useRef();

  const onValidityChange = (fieldState, formState) => {
    const { fields } = formState;
    if (fields) {
      const cardNumberField = fields.number || {};
      const expirationField = fields.expirationDate || {};
      setCardNumberError(!cardNumberField.isEmpty && !cardNumberField.isValid);
      setExpiryDateError(!expirationField.isEmpty && !expirationField.isValid);
      setIsValuesValid(!Object.values(fields).some((v) => !v.isValid));
    }
  };

  const onAuthorizationSuccess = () => {
    cardholderNameField.current.focus();
  };

  const onDataCollectorInstanceReady = (collector) => {
    // DO SOMETHING with Braintree collector as needed
  };

  const handleError = (newError) => {
    setError(newError.message || String(newError));
  };

  const onCardTypeChange = ({ cards }) => {
    if (1 === cards.length) {
      const [card] = cards;


      if (card.code && card.code.name) {
        cvvField.current.setPlaceholder(card.code.name);
        setCvvFieldLabel(card.code.name);

      } else {
        cvvField.current.setPlaceholder('CVV');
      }
    } else {
      cvvField.current.setPlaceholder('CVV');
    }
  };

  const getToken = () => {
    if (isValuesValid && (registerToAutoPay ? saveCardChecked : true)) {
      setIsSubmitting(true);
      tokenize()
        .then((token) => {
          onSubmitToken({
            nonce: token.nonce,
            pspName: SUPPORTED_TOKENIZE_FORMS_PSP_NAMES.BRAIN_TREE
          });
        })
        .catch(handleError)
        .finally(() => {
          setIsSubmitting(false);
        });
    }
  };

  return (
    <div className={styles.checkoutForm}>
      <Braintree
        className="demo"
        authorization={tokenizeKey}
        onAuthorizationSuccess={onAuthorizationSuccess}
        onDataCollectorInstanceReady={onDataCollectorInstanceReady}
        onError={handleError}
        onCardTypeChange={onCardTypeChange}
        getTokenRef={(ref) => setTokenizeFunc(() => ref)}
        styles={{
          input: {
            height: '40px',
            'font-size': 'inherit'
          }
        }}
      >
        <div>
          <Row gutter={16}>
            <Col span={24}>
              <span className={styles.ccInputLabel}>
                {t('payment/shared_comp_cardholder_name_label_text', 'Cardholder Name')}
              </span>
              <HostedField
                className={Classnames(styles.ccInput, 'form-control')}
                type="cardholderName"
                placeholder={t(
                  'payment/shared_comp_cardholder_name_prompt_text',
                  'Enter full name'
                )}
                onValidityChange={onValidityChange}
                ref={cardholderNameField}
              />
            </Col>
          </Row>
          <Row gutter={16}>
            <Col span={12} className={styles.ccInputContainer}>
              <Row>
                <span className={styles.ccInputLabel}>
                  {t('payment/shared_comp_card_number_label_text', 'Card Number')}
                </span>
              </Row>
              <Row>
                <HostedField
                  className={Classnames(styles.ccInput, 'form-control')}
                  type="number"
                  placeholder={t(
                    'payment/shared_comp_card_number_label_prompt',
                    'Enter credit card number'
                  )}
                  onValidityChange={onValidityChange}
                  ref={numberField}
                />
              </Row>
              {cardNumberError && (
                <Row>
                  <span className={styles.formElementError}>
                    {t('Invalid card number', 'Invalid card number')}
                  </span>
                </Row>
              )}
            </Col>
            <Col span={6} className={styles.ccInputContainer}>
              <Row>
                <span className={styles.ccInputLabel}>
                  {t('payment/shared_comp_expiration_label_text', 'Expiration')}
                </span>
              </Row>
              <Row>
                <HostedField
                  className={Classnames(styles.ccInput, 'form-control')}
                  placeholder={t('payment/shared_comp_expiration_prompt_text', 'MM/YY')}
                  type="expirationDate"
                  onValidityChange={onValidityChange}
                />
              </Row>
              {expiryDateError && (
                <Row>
                  <span className={styles.formElementError}>
                    {t('Invalid expiry date', 'Invalid expiry date')}
                  </span>
                </Row>
              )}
            </Col>
            <Col span={6} className={styles.ccInputContainer}>
              <Row>
                <span className={styles.ccInputLabel}>
                  {cvvFieldLabel}
                </span>
              </Row>
              <Row>
                <HostedField
                  className={Classnames(styles.ccInput, 'form-control')}
                  type="cvv"
                  onValidityChange={onValidityChange}
                  placeholder={t('payment/shared_comp_cvc_label_prompt', 'Enter CVC')}
                  ref={cvvField}
                />
              </Row>
            </Col>
          </Row>
        </div>
      </Braintree>
      <CheckoutFormFooter
        registerToAutoPay={registerToAutoPay}
        saveCardChecked={saveCardChecked}
        setSaveCardChecked={setSaveCardChecked}
        isTokenizing={submitting}
        isSavingToken={isSavingToken}
        payButtonTitle={payButtonTitle}
        onPayNowButtonClick={getToken}
        isSubmitDisabled={!isValuesValid || error || submitting}
      />
    </div>
  );
};

BrainTreeTokenizeForm.propTypes = {};

const mapStateToProps = (state) => {
  return {
    isMobile: isMobileSelector(state)
  };
};
export default connect(mapStateToProps)(BrainTreeTokenizeForm);
