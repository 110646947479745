import * as React from 'react';
import { ReactComponent as AmzImg } from 'slimSearch/img/amz.svg';
import { ReactComponent as ShipbobImg } from 'slimSearch/img/shipbob.svg';

export const Brand = ({ name }) => {
  if (name === 'AMZ') {
    return <AmzImg />;
  }
  if (name === 'SHIPBOB') {
    return <ShipbobImg />;
  }
  return null;
};
