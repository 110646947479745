import React, {useEffect, useState} from 'react';
import {Button, Col, Message, Row} from '@freightos/design-system';
import styles from 'application/BankDetails/bankDetails.module.scss';
import {getAccountDetails} from 'application/BankDetails/bankDetails.utils';
import PropTypes from 'prop-types';

const WireAccountDetails = ({ accountDetails }) => {
  const [accountDetailsToCopy, setAccountDetailsToCopy] = useState()

  useEffect(() => {
    let details  = '';
    Object.entries(accountDetails).filter(entry => !!entry[1]).forEach(entry => {
      details += `${entry[0]}\n${entry[1]}\n\n`;
    });
    setAccountDetailsToCopy(details)
  }, [accountDetails])

  return (
    <div className={styles.wireDetailsContent}>
      <Row>
        {getAccountDetails(accountDetails).map(
          ({label, value, dataTestId, fullWidth}) =>
            value && (
              <Col data-test-id={dataTestId} xs={24} md={fullWidth ? 24 : 12}>
                <Row className={styles.infoTitle}>{label}</Row>
                <Row className={styles.infoRow}>{value}</Row>
              </Col>
            )
        )}
      </Row>
      <Row>
        <Col>
          <Button
            className={styles.bankDetailsCopyIcon}
            icon="copy"
            size="medium"
            type="secondary"
            onClick={() => {
              navigator.clipboard.writeText(accountDetailsToCopy).then(() => {
                Message.success('Bank account details copied');
              });
            }}
          >
            Copy bank details
          </Button>
        </Col>
      </Row>
    </div>
  );
};

WireAccountDetails.propTypes = {
  accountDetails: PropTypes.object,
};

export default WireAccountDetails;
