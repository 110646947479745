import React, { useState } from 'react';
import styled, { css } from 'styled-components/macro';
import { connect } from 'microfronts-redux';
import { Divider, tokens, Skeleton, Button, Empty, Popconfirm } from '@freightos/design-system';
import userModel from 'propera/user';
import { t } from 'utils/translationProvider';
import { isMobileSelector } from 'slimSearch/selectors';
import { spacingContainerVertical } from 'slimSearch/sharedStyles';
import {
  deleteSearchHistory as deleteSearchHistoryAction,
  setSearchWithNewFields as setSearchWithNewFieldsAction
} from '../actions';
import { getPendingRequest, getRecentSearchItems } from '../reducer';
import LoggedOutView from './LoggedOutView';
import SearchItem from './SearchItem';
import DeleteConfirmation from './DeleteConfirmation';
import { updateFulFilamentDefaultValues } from '../../../utils/recommendedServices.utils';

const { isLoggedIn: userModelGetIsLoggedIn } = userModel;

const RecentSearches = ({
  isMobile,
  isLoggedIn,
  pendingRequest,
  searchesList,
  onItemSelected,
  onSignupClicked,
  setSearchWithNewFields,
  deleteSearchHistory,
  currency
}) => {
  const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);

  const onDeleteAllClicked = () => setShowDeleteConfirmation(true);
  const onCancelDelete = () => setShowDeleteConfirmation(false);
  const onDeleteConfirmed = () => {
    deleteSearchHistory();
    setShowDeleteConfirmation(false);
  };

  const setSearchFields = (searchFields) => {
    setSearchWithNewFields(updateFulFilamentDefaultValues(searchFields));
    onItemSelected && onItemSelected();
  };

  const searchItemsExist = searchesList && !!searchesList.length;
  const deleteConfirmationOnlyMode = showDeleteConfirmation && isMobile;
  const renderSearchItemList = !(deleteConfirmationOnlyMode || pendingRequest);

  return (
    <RecentSearchesWrapper spacing={tokens.fdsComponentSpacingLg} isMobile={isMobile}>
      {!isMobile && <StyledDivider />}
      <HeaderContainer isMobile={isMobile}>
        <StyledTitle>{t('myRecentSearches/title', 'My recent searches')}</StyledTitle>
        {searchItemsExist && (!showDeleteConfirmation || !isMobile) && (
          <Popconfirm
            placement="left"
            title={t('myRecentSearches/deletePopConfirm', 'Clear all recent searches?')}
            okText={t('myRecentSearches/deletePopConfirmYes', 'Yes')}
            cancelText={t('myRecentSearches/deletePopConfirmNo', 'No')}
            onConfirm={onDeleteConfirmed}
            onCancel={onCancelDelete}
            defaultVisible={showDeleteConfirmation && !isMobile}
          >
            <Button
              type="text"
              size="small"
              onClick={onDeleteAllClicked}
              data-test-id="search-history-clear-all"
            >
              {t('myRecentSearches/clearAll', 'Clear all')}
            </Button>
          </Popconfirm>
        )}
      </HeaderContainer>
      {isLoggedIn && (
        <>
          {deleteConfirmationOnlyMode && (
            <DeleteConfirmation onOk={onDeleteConfirmed} onCancel={onCancelDelete} />
          )}
          {pendingRequest && (
            <StyledSkeleton
              active={pendingRequest}
              paragraph={{ rows: isMobile ? 11 : 4 }}
              title={false}
              isMobile={isMobile}
            />
          )}
          {renderSearchItemList && (
            <SearchesList
              spacing={tokens.fdsComponentSpacingSm}
              isMobile={isMobile}
              data-test-id="search-history-list-container"
            >
              {!searchItemsExist && <Empty />}
              {searchesList.map((item, index) => (
                <>
                  <SearchItem
                    key={item.searchId}
                    index={index}
                    searchId={item.searchId}
                    rfqKey={item.rfqKey}
                    rfqFields={{
                      ...item.rfqFields,
                      load: {
                        ...item.rfqFields.load,
                        declaredValue: {
                          currencyID: currency ?? 'USD'
                        }
                      }
                    }}
                    isPinned={item.isPinned}
                    displayFields={item.displayFields}
                    onSelected={setSearchFields}
                  />
                  {isMobile && <StyledDivider />}
                </>
              ))}
            </SearchesList>
          )}
        </>
      )}
      {!isLoggedIn && <LoggedOutView onClick={onSignupClicked} />}
    </RecentSearchesWrapper>
  );
};

const RecentSearchesWrapper = styled.div`
  display: flex;
  flex-direction: column;
  overflow: hidden;

  ${spacingContainerVertical}

  ${(isMobile) =>
    isMobile &&
    css`
      height: 100%;
    `}
`;

const HeaderContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;

  ${({ isMobile }) =>
    isMobile &&
    css`
      padding: ${tokens.fdsComponentSpacingXxl} ${tokens.fdsComponentSpacingLg} 0
        ${tokens.fdsComponentSpacingXl};
    `}
`;

const StyledTitle = styled.span`
  font-family: Rajdhani, ${tokens.fdsBodyFontFamily};
  font-size: 14px;
  font-weight: 700;
  line-height: 23px;
`;

const StyledSkeleton = styled(Skeleton)`
  ${({ isMobile }) =>
    isMobile &&
    css`
      padding: 0 ${tokens.fdsComponentSpacingXl};
    `}
`;

const StyledDivider = styled(Divider)`
  /** Due to an uncle component that use Antd's Row, need to keep distance in order to see the divider */
  margin-top: 1px;
`;

const SearchesList = styled.div`
  overflow: auto;

  ${({ isMobile }) =>
    isMobile &&
    css`
      ${spacingContainerVertical}
    `}
`;

const mapStateToProps = (state) => ({
  isMobile: isMobileSelector(state),
  isLoggedIn: userModelGetIsLoggedIn(state),
  pendingRequest: getPendingRequest(state),
  searchesList: getRecentSearchItems(state),
  currency: state.search.currency
});

export default connect(mapStateToProps, {
  setSearchWithNewFields: setSearchWithNewFieldsAction,
  deleteSearchHistory: deleteSearchHistoryAction
})(RecentSearches);
