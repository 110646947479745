import React from 'react';
import {
  ShipmentsCounter,
  Number,
  StyledParagraph
} from 'slimSearch/components/Tiles/sharedStyles';

export const Counter = ({ value, label }) => (
  <ShipmentsCounter>
    <Number>{value}</Number>
    <StyledParagraph>{label}</StyledParagraph>
  </ShipmentsCounter>
);
