import { put, call, takeLatest, all, select } from 'redux-saga/effects';
import * as monitoring from '@propera/monitoring';
import { store } from 'microfronts-redux';
import { model as userModel } from 'propera/user';
import { flagStateOrDefaultValueSelector } from 'feature-flagging';
import { doPostRequest, doGetRequest } from '@propera/HTTP';
import * as AT from 'financialSharedComponents/constants/actionTypes';
import * as Actions from './actions';
import { fetchLHtoken } from 'financialSharedComponents/api.saga';
import { getLhGatewayBaseUrl } from '../../constants/lhEndpoints';
import * as selectors from 'financialSharedComponents/selectors';
import * as ldFlags from 'financialSharedComponents/ldFlags';
import { doPaymentLinkPaymentSuccess, doPaymentLinkPaymentFail } from './actions';

export const businessKeySelector = (state) => userModel.getBusinessKey(state);
const currentState = (state) => state;

export function* doPaymentLinkPayment({ payload }) {
  try {
    const token = yield call(fetchLHtoken);
    yield call(
      doPostRequest,
      `/billing-page/data/lh-billing/payments/direct-payment`,
      {
        paymentMethodNonce: payload.nonce,
        paymentAmount: payload.paymentAmount,
        currency: payload.currency,
        orderId: payload.orderId,
        description: payload.description
      },
      { Accept: 'application/json', Authorization: `Application ${token}` },
      undefined,
      undefined,
      getLhGatewayBaseUrl()
    );
    yield put(doPaymentLinkPaymentSuccess());
  } catch (err) {
    const error = err && err.response;
    yield put(Actions.doPaymentLinkPaymentFail(error));
    if (!JSON.stringify(error).toLowerCase().includes('declined') || !error) {
      yield call(monitoring.utils.exceptionReporter, err, store.getState);
    }
  }
}
export default function* watcher() {
  yield all([takeLatest(AT.DO_PAYMENT_LINK_PAYMENT, doPaymentLinkPayment)]);
}
