import { attachSharedComponent } from 'microfronts-redux';
import Payment from 'financialSharedComponents/components/Payment';

import BankDetails from 'financialSharedComponents/components/BankDetails';
import PaymentLink from 'financialSharedComponents/components/PaymentLink';
import CreditCardTokenizeFormFactory from 'financialSharedComponents/components/Payment/CreditCardsMgmt/CreditCardTokenizeForm/index';
import FreightosAutopayModal from 'financialSharedComponents/components/Payment/CreditCardsMgmt/CreditCardTokenizeForm/FreightosAutopayModal';
import * as FinancialActionTypes from 'financialSharedComponents/constants/actionTypes';
import * as FinancialConstants from 'financialSharedComponents/constants/app.constants';
import rootReducer from 'financialSharedComponents/rootReducer';
import rootSaga from 'financialSharedComponents/rootSaga';
import { PACKAGE_NAME } from 'constants/app.constants';

attachSharedComponent({ [PACKAGE_NAME]: rootReducer }, rootSaga);

export { Payment, PaymentLink, BankDetails, FinancialActionTypes, FinancialConstants, CreditCardTokenizeFormFactory, FreightosAutopayModal };
