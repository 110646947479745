import get from 'lodash/get';
import { createSelector } from 'reselect';
import { PACKAGE_NAME } from 'constants/app.constants';

const finState = (state) => get(state, [PACKAGE_NAME]);

export const bankDetailsSelector = createSelector(
  finState,
  state => state.details 
  );
  
export const bankDetailsFetchingSelector = createSelector(
  finState,
  state => state.fetching 
);
