import React from 'react';
import { Icon, Button, RadioButtonGroup } from '@freightos/design-system';
import get from 'lodash/get';
import Classnames from 'classnames';
import { PayPalLogo } from 'financialSharedComponents/images';
import styles from './paypalAccountsList.module.scss';
import { t } from 'utils/translationProvider';

const PayPalAccountLabel = ({ account, setIsChangingPaymentMethod }) => {
  return (
    <div className={Classnames(styles.paypalAccount)}>
      <PayPalLogo />
      <span className={styles.details}>
        {get(account, "metadata.email", null)}
      </span>
      <Button
        data-test-id="change-card-button"
        onClick={() => {
          setIsChangingPaymentMethod(true);
        }}
        className={styles.changeAccount}
        type={'text'}
        size={'large'}
      >
        {t('Replace', 'Replace')}
      </Button>
    </div>
  );
};

const PayPalAccountsList = ({ disabled, defaultAccount, setIsChangingPaymentMethod }) => {
  const options = [
    {
      value: get(defaultAccount, 'metadata.email', null),
      label: <PayPalAccountLabel setIsChangingPaymentMethod={setIsChangingPaymentMethod} account={defaultAccount} />
    }
  ];
  return (
    <RadioButtonGroup
      className="financial-separated-radio-button-group financial-user-paypal-accounts"
      defaultValue={get(defaultAccount, 'metadata.email', null)}
      options={options}
      size="large"
    />
  );
};

export default PayPalAccountsList;
