import { get, set, flow, groupBy, map, uniqBy } from 'lodash/fp';

import {
  GET_PORTS,
  GET_LOCATIONS,
  UPDATE_FIELD,
  GET_WAREHOUSES,
  GET_COUNTRIES,
  GET_RFQ,
  GET_RFQ_SUMMARY,
  SHOW_SERVICES_MODAL,
  HIDE_SERVICES_MODAL,
  SET_EDIT_MODE,
  SEARCH_SERVICES_COMPLETED,
  SET_SEARCH_SESSION_ID,
  CLEAR_SERVICE_PREDICTIONS,
  SET_SERVICE_PREDICTIONS,
  SAVE_QUOTE_FROM_SEARCH_URL,
  SET_SEARCH_MODE,
  SET_DROPDOWN_HEIGHT,
  RESET_SEARCH_REDUCER_VALUES,
  SET_IS_IN_SCOPE,
  UPDATE_RFQ,
  SET_OLD_RFQ_FLOW,
  GET_ADDRESS_BOOK_ADDRESSES,
  SET_USER_ADDRESS_FROM_ADDRESS_BOOK,
  GET_ADDRESS_BOOK_SINGLE_ADDRESS,
  GET_SHIPMENTS_METADATA,
  GET_SOA_INFO,
  GET_CREDIT_INFO,
} from 'slimSearch/actions';
import { getDefaultValue } from 'utils';
import { getCountryNameByBrand } from 'slimSearch/utils';
import {
  FCL_LOAD_DEFAULTS,
  LCL_LOAD_DEFAULTS,
  ADDRESS_TYPES,
  LOAD_TYPES,
  IS_SHIPBOB,
  ADDRESS_BASED_LOCATION_TYPES,
  FULFILMENT_CENTER_COMPANIES,
  DIMENSION_UNITS,
  WEIGHT_UNITS,
  VOLUME_UNITS
} from 'slimSearch/constants';
import { shouldDisableContainers } from 'slimSearch/selectors';
import {
  updateQuoteRecommendedServicesDefaults,
  changeRecommendedServicesAfterUpdate
} from 'slimSearch/utils/recommendedServices.utils';
import { getUnitsTotals } from './utils/calculateTotals.utils';

const initialState = {
  defaultsInitialized: false, // this is to solve a problem for low network connection , the slim search is loaded after bootstrap/success event - so we need to fire it again
  searchMode: 'search',
  dropdownHeight: undefined,
  summaryLoaded: false,
  userAddressesLoaded: true,
  search_session_id: window.crypto.randomUUID(),
  servicesModalVisible: false,
  editMode: false,
  readOnly: false,
  servicesPredictionsLocked: false,
  searchInProgress: false,
  oldRfqFlow: false,
  activeSection: getDefaultValue('activeSection') || null,
  currency: 'USD',
  shipmentInScope: false,
  alerts: {
    amazon45container: false
  },
  touched: {
    origin: false,
    destination: false,
    load: false,
    goods: false
  },
  pristine: {
    origin: true,
    destination: true,
    load: true,
    goods: true
  },
  locationsData: {
    origin: {
      ports: [],
      addresses: [],
      addressBook: []
    },
    destination: {
      ports: [],
      addresses: [],
      brandLocations: {},
      brandCountries: {},
      upsCountries: {},
      addressBook: []
    }
  },
  rfqFromQuote: null,
  quote: {
    userSelectedAddress: {
      origin: null,
      destination: null
    },
    knownShipper: false,
    origin: {
      originLocation: {
        locationTypeCode:
          getDefaultValue('originLocationType') || ADDRESS_TYPES.FACTORY_WAREHOUSE.value,
        locationCode: getDefaultValue('originLocation') || undefined,
        countryID: {
          value: getDefaultValue('originCountry') || 'CN'
        },
        ID: null
      }
    },
    destination: {
      destinationLocation: {
        locationTypeCode:
          getDefaultValue('destinationLocationType') ||
          (IS_SHIPBOB && ADDRESS_TYPES.FULFILMENT_CENTER.value) ||
          ADDRESS_TYPES.BUSINESS_ADDRESS.value,
        locationCode: getDefaultValue('destinationLocation') || undefined,
        countryID: {
          value: getDefaultValue('destinationCountry') || 'US'
        },
        ID: null
      }
    },
    load: {
      type: getDefaultValue('loadType') || LOAD_TYPES.LOOSE_CARGO.value,
      active: null,
      hazardous: false,
      measurements: {
        volume: 'cbm', // 'cft,
        weight: 'kg', // 'lb
        dimensions: 'cm' // inch
      },
      container: {
        packages: [
          {
            ...FCL_LOAD_DEFAULTS
          }
        ]
      },
      looseCargo: {
        calculateByTotals: false,
        totals: {
          quantity: 1,
          volume: {
            value: ''
          },
          weight: {
            value: ''
          }
        },
        packages: [
          {
            ...LCL_LOAD_DEFAULTS.pallets
          }
        ]
      }
    },
    goods: {
      goodsValue: {
        value: null,
        currency: 'USD'
      },
      pickupEvent: {
        goodsReadyRange: null
      }
    },
    pricePreference: {
      includeOriginPortCharges: undefined,
      includeDestinationPortCharges: undefined,
      requestCurrency: { value: undefined }
    },
    accessorials: [],
    fulfilmentInfo: undefined,
    declaredCustoms: {},
    insuranceValueAmount: undefined
  },
  tiles: {
    shipments: {
      loading: false,
      data: null,
      error: false
    },
    payments: {
      loading: false,
      data: null,
      error: false
    },
    credit: {
      loading: false,
      data: null,
      error: false
    }
  },
  deals: null
};

// only exported for tests
export const initialStateJestStub = initialState;

const handleFieldsChangeWithResets = (action) => (state) => {
  // @NOTE also saga has updateFieldInterceptor
  let tasks = [];
  const isLoadTypePath = action.payload.path === 'quote.load.type';
  const isOriginLocationTypePath =
    action.payload.path === 'quote.origin.originLocation.locationTypeCode';
  const isDestinationLocationTypePath =
    action.payload.path === 'quote.destination.destinationLocation.locationTypeCode';
  const isOriginCountryChangePath =
    action.payload.path === 'quote.origin.originLocation.countryID.value';
  const isDestinationCountryChangePath =
    action.payload.path === 'quote.destination.destinationLocation.countryID.value';
  const isAmazon =
    state.quote.destination.destinationLocation?.brand === FULFILMENT_CENTER_COMPANIES.AMZ.key &&
    state.quote.destination.destinationLocation?.locationTypeCode ===
      ADDRESS_TYPES.FULFILMENT_CENTER.value;

  if (action.payload.path === 'quote.goods.goodsValue.currency') {
    if (state.quote?.declaredCustoms?.singleEntryBond) {
      tasks = [
        ...tasks,
        set('quote.declaredCustoms.singleEntryBond.currencyID', action.payload.value)
      ];
    }
    tasks = [...tasks, set('quote.insuranceValueAmount.currencyID', action.payload.value)];
  }

  if (
    shouldDisableContainers({ search: { ...state } }) &&
    state.quote.load.type === LOAD_TYPES.CONTAINER.value
  ) {
    tasks = [...tasks, set('quote.load.type', LOAD_TYPES.LOOSE_CARGO.value)];
  }

  const handleDefaultLoad = (load) => {
    if (isAmazon) {
      return set(['looseCargo', 'packages'], [LCL_LOAD_DEFAULTS.pallet_48_40], load);
    }

    return load;
  };

  if (
    action.payload.path === 'quote.load.measurements.dimensions' ||
    action.payload.path === 'quote.load.measurements.volume'
  ) {
    if (action.payload.value === DIMENSION_UNITS.CM || action.payload.value === VOLUME_UNITS.CBM) {
      tasks = [
        ...tasks,
        set('quote.load.measurements.weight', WEIGHT_UNITS.KG),
        set('quote.load.measurements.dimensions', DIMENSION_UNITS.CM),
        set('quote.load.measurements.volume', VOLUME_UNITS.CBM)
      ];
    }
    if (
      action.payload.value === DIMENSION_UNITS.INCH ||
      action.payload.value === VOLUME_UNITS.CFT
    ) {
      tasks = [
        ...tasks,
        set('quote.load.measurements.weight', WEIGHT_UNITS.LB),
        set('quote.load.measurements.dimensions', DIMENSION_UNITS.INCH),
        set('quote.load.measurements.volume', VOLUME_UNITS.CFT)
      ];
    }
  }

  if (
    action.payload.path === 'quote.load.looseCargo.calculateByTotals' &&
    action.payload.value === true
  ) {
    const totals = getUnitsTotals(
      state.quote.load.looseCargo.packages,
      state.quote.load.measurements
    );

    tasks = [
      ...tasks,
      set('quote.load.looseCargo.totals', {
        quantity: Number(totals.totalQuantity),
        volume: {
          value: Number(totals.totalVolume)
        },
        weight: {
          value: Number(totals.totalWeight)
        }
      })
    ];
  }

  if (isLoadTypePath) {
    tasks = [...tasks, set('quote.load', handleDefaultLoad(initialState.quote.load))];
  }

  if (
    isOriginLocationTypePath ||
    isDestinationLocationTypePath ||
    isOriginCountryChangePath ||
    isDestinationCountryChangePath
  ) {
    const incomingIsAddress = ADDRESS_BASED_LOCATION_TYPES.includes(action.payload.value);
    const currentIsAddress = ADDRESS_BASED_LOCATION_TYPES.includes(get(action.payload.path, state));

    // reset origin
    if (
      isOriginCountryChangePath ||
      (isOriginLocationTypePath && (!incomingIsAddress || (!currentIsAddress && incomingIsAddress)))
    ) {
      tasks = [
        ...tasks,
        set(
          'quote.origin.originLocation.locationCode',
          initialState.quote.origin.originLocation.locationCode
        ),
        set('quote.origin.originLocation.ID', initialState.quote.origin.originLocation.ID),
        set('locationsData.origin.ports', initialState.locationsData.origin.ports),
        set('locationsData.origin.addresses', initialState.locationsData.origin.addresses)
      ];
    }

    // reset destination
    if (
      isDestinationCountryChangePath ||
      (isDestinationLocationTypePath &&
        (!incomingIsAddress || (!currentIsAddress && incomingIsAddress)))
    ) {
      tasks = [
        ...tasks,
        set(
          'quote.destination.destinationLocation.locationCode',
          initialState.quote.destination.destinationLocation.locationCode
        ),
        set(
          'quote.destination.destinationLocation.ID',
          initialState.quote.destination.destinationLocation.ID
        ),
        set('locationsData.destination.ports', initialState.locationsData.destination.ports),
        set('locationsData.destination.addresses', initialState.locationsData.destination.addresses)
      ];
    }
  }

  return flow([...tasks, set(action.payload.path, action.payload.value)])(state);
};

export const searchReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'AUTH_UI/LOG_OUT': {
      return initialState;
    }
    case UPDATE_FIELD:
      if (state.servicesPredictionsLocked) {
        // stuff to update on edit mode changes
        return flow([
          handleFieldsChangeWithResets(action),
          (state) => set('quote', changeRecommendedServicesAfterUpdate(state, action), state)
        ])(state);
      } else {
        return flow([
          handleFieldsChangeWithResets(action),
          (state) => set('quote', updateQuoteRecommendedServicesDefaults(state), state)
        ])(state);
      }
    case GET_PORTS.SUCCESS: {
      const airports = (action.payload.ports.airports || []).map((airports) => ({
        value: airports.code,
        label: `${airports.code} ${airports.englishName}`,
        type: 'airport'
      }));

      const seaports = (action.payload.ports.seaports || []).map((seaports) => ({
        value: seaports.code,
        label: `${seaports.code} ${seaports.englishName}`,
        type: 'port'
      }));
      const ports = [...seaports, ...airports];
      return set(['locationsData', action.meta.type, 'ports'], ports, state);
    }
    case GET_LOCATIONS.SUCCESS: {
      const addresses = (action.payload.addresses.predictions || []).map((address) => ({
        ...address,
        value: address.label,
        type: null
      }));

      return set(['locationsData', action.meta.type, 'addresses'], addresses, state);
    }
    case GET_WAREHOUSES.SUCCESS: {
      const brands = flow([
        map((item) => ({
          ...item,
          brand: item.brand.toUpperCase()
        })),
        groupBy('brand')
      ])(action.payload.data.locations);
      const upsCountries = action.payload.data.locations
        .filter((item) => item.brand === 'UPS')
        .map((item) => getCountryNameByBrand(item.country));

      return flow([
        set('locationsData.destination.upsCountries', uniqBy('code', upsCountries)),
        set('locationsData.destination.brandLocations', brands)
      ])(state);
    }

    case GET_COUNTRIES.SUCCESS: {
      const countries = flow([
        map((item) => ({
          code: item.code,
          name: item.name
        }))
      ])(action.payload.data.countries);

      return set('locationsData.destination.brandCountries', countries, state);
    }
    case UPDATE_RFQ.PENDING:
    case GET_RFQ.PENDING:
      return { ...state, searchInProgress: true, summaryLoaded: true };

    case UPDATE_RFQ.SUCCESS:
    case UPDATE_RFQ.FAILURE:
    case GET_RFQ.SUCCESS:
    case GET_RFQ.FAILURE: {
      return { ...state, searchInProgress: false };
    }
    case GET_ADDRESS_BOOK_SINGLE_ADDRESS.PENDING: {
      return { ...state, userAddressesLoaded: false };
    }
    case GET_RFQ_SUMMARY.SUCCESS:
      return {
        ...state,
        quote: action.payload.quote,
        rfqFromQuote: action.payload.rfq,
        touched: {
          origin: true,
          destination: true,
          load: true,
          goods: true
        },
        pristine: {
          origin: false,
          destination: false,
          load: false,
          goods: false
        },
        summaryLoaded: true,
        servicesPredictionsLocked: true
      };
    case SAVE_QUOTE_FROM_SEARCH_URL: {
      return {
        ...state,
        quote: action.payload,
        touched: {
          origin: true,
          destination: true,
          load: true,
          goods: true
        },
        pristine: {
          origin: false,
          destination: false,
          load: false,
          goods: false
        }
      };
    }
    case SET_OLD_RFQ_FLOW:
      return { ...state, oldRfqFlow: action.payload };

    case SET_IS_IN_SCOPE:
      return { ...state, shipmentInScope: action.payload };
    case SET_EDIT_MODE:
      return {
        ...state,
        editMode: {
          rfqKey: action.payload?.rfqKey,
          status: Boolean(action.payload?.rfqKey)
        }
      };
    case SHOW_SERVICES_MODAL:
      return { ...state, servicesModalVisible: true };
    case SEARCH_SERVICES_COMPLETED:
      return { ...state, servicesModalVisible: false };
    case HIDE_SERVICES_MODAL:
      return { ...state, servicesModalVisible: false };
    case SET_SEARCH_SESSION_ID.ON:
      if (!state.search_session_id) {
        return { ...state, search_session_id: action.payload };
      }
      return state;
    case SET_SEARCH_SESSION_ID.OFF:
      if (state.search_session_id) {
        return { ...state, search_session_id: undefined };
      }
      return state;
    case SET_SERVICE_PREDICTIONS:
      return { ...state, servicesPredictionsLocked: action.payload };
    case CLEAR_SERVICE_PREDICTIONS:
      return { ...state, servicesPredictionsLocked: false };
    case SET_SEARCH_MODE:
      return { ...state, searchMode: action.payload };
    case SET_DROPDOWN_HEIGHT:
      return { ...state, dropdownHeight: action.payload };
    case RESET_SEARCH_REDUCER_VALUES:
      return { ...initialState, locationsData: state.locationsData };
    case SET_USER_ADDRESS_FROM_ADDRESS_BOOK:
      return flow([
        set(['quote', 'userSelectedAddress', action.payload.type], action.payload.address),
        set('userAddressesLoaded', true)
      ])(state);
    case GET_ADDRESS_BOOK_ADDRESSES.SUCCESS: {
      const addressTypeFilter = action.payload.type === 'origin' ? 'PICKUP' : 'DELIVERY';
      const addresses = action.payload.addresses.filter((address) =>
        // we only need ['DELIVERY', 'PICKUP'] address types respectively
        [addressTypeFilter].some((items) => address.types.includes(items))
      );

      return set(['locationsData', action.payload.type, 'addressBook'], addresses, state);
    }
    case GET_SHIPMENTS_METADATA.SUCCESS: {
      const data = action.payload.reduce(
        (acc, current) => {
          switch (current.field) {
            case 'ORDER_PLACED': {
              acc.PLACED += current.count;
              acc.totals += current.count;
              break;
            }
            case 'ARRIVED_AT_PORT':
            case 'ORDER_SENT_TO_SELLER':
            case 'GOODS_RECEIVED':
            case 'DEPARTED_FROM_PORT':
            case 'TRANSPORT_BOOKED': {
              acc.IN_TRANSIT += current.count;
              acc.totals += current.count;
              break;
            }
            case 'DELIVERED': {
              acc.DELIVERED += current.count;
              acc.totals += current.count;
              break;
            }
            default:
              break;
          }

          return acc;
        },
        {
          IN_TRANSIT: 0,
          PLACED: 0,
          DELIVERED: 0,
          totals: 0
        }
      );
      return flow([
        set('tiles.shipments.data', data),
        set('tiles.shipments.loading', false),
        set('tiles.shipments.error', false)
      ])(state);
    }
    case GET_SHIPMENTS_METADATA.PENDING: {
      return flow([
        set('tiles.shipments.error', false),
        set('tiles.shipments.loading', action.payload)
      ])(state);
    }
    case GET_SHIPMENTS_METADATA.FAILURE: {
      return flow([set('tiles.shipments.error', true), set('tiles.shipments.loading', false)])(
        state
      );
    }
    case GET_SOA_INFO.SUCCESS: {
      const data = action.payload.reduce(
        (acc, current) => {
          switch (current.paymentStatus) {
            case 'Due Payment': {
              acc.DUE_PAYMENT += 1;
              acc.totals += 1;
              break;
            }
            case 'Open':
            case 'Pending Transfer':
            case 'Authorized': {
              acc.OPEN += 1;
              acc.totals += 1;
              break;
            }
            case 'Paid in Full': {
              acc.PAID += 1;
              acc.totals += 1;
              break;
            }
            default:
              break;
          }

          return acc;
        },
        {
          OPEN: 0,
          DUE_PAYMENT: 0,
          PAID: 0,
          totals: 0
        }
      );
      return flow([
        set('tiles.payments.data', data),
        set('tiles.payments.loading', false),
        set('tiles.payments.error', false)
      ])(state);
    }
    case GET_SOA_INFO.FAILURE: {
      return flow([set('tiles.payments.error', true), set('tiles.payments.loading', false)])(state);
    }
    case GET_SOA_INFO.PENDING: {
      return flow([
        set('tiles.payments.error', false),
        set('tiles.payments.loading', action.payload)
      ])(state);
    }
    case GET_CREDIT_INFO.SUCCESS: {
      return flow([
        set('tiles.credit.data', action.payload),
        set('tiles.credit.error', false),
        set('tiles.credit.loading', false)
      ])(state);
    }
    case GET_CREDIT_INFO.FAILURE: {
      return flow([set('tiles.credit.error', true), set('tiles.credit.loading', false)])(state);
    }
    case GET_CREDIT_INFO.PENDING: {
      return flow([set('tiles.credit.error', false), set('tiles.credit.loading', action.payload)])(
        state
      );
    }
    default:
      return state;
  }
};
