export const shipments = [
  {
    key: 'totals-of-undefined',
    label: 'totals undefined',
    data: {
      businessInfo: { parties: [{ partyTypeCode: 'BY', knownShipper: false }] },
      shipment: {
        originLocation: {
          locationTypeCode: 'Address',
          locationName: 'warehouse',
          countryID: { value: 'CN' },
          stateOrProvince: 'Shanghai',
          cityName: 'Shanghai',
          postalCode: '200000',
          addressLines: ['Shanghai, China'],
          ID: 'ChIJMzz1sUBwsjURoWTDI5QSlQI'
        },
        destinationLocation: {
          locationTypeCode: 'Address',
          locationName: 'business',
          countryID: { value: 'US' },
          stateOrProvince: 'CA',
          cityName: 'Los Angeles',
          postalCode: '90012',
          addressLines: ['Los Angeles, CA, USA'],
          ID: 'ChIJE9on3F3HwoAR9AhGJW_fL-I'
        },
        pickupEvent: { eventDate: { scheduledDateTime: '2021-05-24T00:00:00Z' } },
        load: {
          totalQuantity: 7,
          declaredValue: { value: 750.0, currencyID: 'USD' },
          packages: [
            {
              packagingType: 'container45HC',
              weight: { value: 0.0, unitCode: 'kg' },
              quantity: 7,
              overWeightIndicator: true
            }
          ]
        },
        pricePreference: {
          requestCurrency: { value: 'USD' },
          includeOriginPortCharges: true,
          includeDestinationPortCharges: true
        },
        insuranceValueAmount: { value: 750.0, currencyID: 'USD' },
        declaredCustoms: {
          singleEntryBond: { value: 750.0, currencyID: 'USD' },
          foreignImporterOfRecord: false,
          entry: true,
          commodityTypes: 0
        },
        accessorials: [
          { scope: 'Pickup', name: 'liftGate' },
          { scope: 'Delivery', name: 'liftGate' }
        ]
      }
    }
  },
  {
    key: 'not-us-destination',
    label: 'not us destination',
    data: {
      messageHeader: null,
      businessInfo: null,
      shipment: {
        shipmentNumber: null,
        shipmentID: null,
        shipmentURL: null,
        shipmentCreatedDateTime: null,
        shipmentDirection: null,
        transportMode: null,
        shipmentSecured: null,
        transportService: null,
        transportServiceInScope: null,
        shipmentPriorityCode: null,
        originLocation: {
          locationTypeCode: 'Address',
          locationCode: null,
          locationName: 'warehouse',
          terminalCode: null,
          terminalName: null,
          addressFormatCode: null,
          countryID: { value: 'CN', schemeID: null, schemeAgencyID: null, schemeVersionID: null },
          regionID: null,
          countryName: null,
          stateOrProvince: 'Shanghai',
          cityID: null,
          cityName: 'Shanghai',
          streetName: null,
          postalCode: null,
          addressLines: ['Shanghai, China'],
          locationCoordinate: null,
          timezoneOffset: null,
          countrySubDivisionId: null,
          countrySubDivisionName: null,
          ID: 'ChIJMzz1sUBwsjURoWTDI5QSlQI'
        },
        destinationLocation: {
          locationTypeCode: 'Address',
          locationCode: null,
          locationName: 'warehouse',
          terminalCode: null,
          terminalName: null,
          addressFormatCode: null,
          countryID: { value: 'GB', schemeID: null, schemeAgencyID: null, schemeVersionID: null },
          regionID: null,
          countryName: null,
          stateOrProvince: 'England',
          cityID: null,
          cityName: 'London',
          streetName: null,
          postalCode: null,
          addressLines: ['London, UK'],
          locationCoordinate: null,
          timezoneOffset: null,
          countrySubDivisionId: null,
          countrySubDivisionName: null,
          ID: 'ChIJdd4hrwug2EcRmSrV3Vo6llI'
        },
        pickupEvent: {
          eventDate: {
            actualDateTime: null,
            scheduledDateTime: '2021-04-21T00:00:00Z',
            endDateTime: null
          },
          party: null
        },
        deliveryEvent: null,
        timeOfAvailability: null,
        latestAcceptanceDate: null,
        numberOfDays: null,
        validFrom: null,
        validTo: null,
        load: {
          allotmentID: null,
          product: null,
          products: null,
          netNetWeight: { value: 13.0, unitCode: 'kg' },
          netWeight: null,
          netVolumeMeasure: null,
          totalChargeableWeight: null,
          totalQuantity: 3,
          totalGrossVolumeMeasure: { value: 12.0, unitCode: 'cbm' },
          hazardousInfo: null,
          declaredValue: { value: 300.0, currencyID: 'USD' },
          packages: null,
          transportEquipments: null,
          commodity: null,
          temperature: null,
          batteryType: null,
          activeTrackingDevices: null
        },
        pricePreference: {
          requestCurrency: {
            value: 'USD',
            listID: null,
            listAgencyID: null,
            listVersionID: null,
            listURI: null
          },
          rateCurrency: null,
          rateType: null,
          promotionCode: null,
          rateUnitOfMeasure: null,
          responseTime: null,
          discountAmount: null,
          proposedRate: null,
          discountPercent: null,
          allInIndicator: null,
          includeCustomsBrokerage: null,
          includeOriginPortCharges: false,
          includeDestinationPortCharges: false,
          referenceRateID: null
        },
        rateInformation: null,
        connection: null,
        fulfilmentInfo: null,
        insuranceValueAmount: { value: 300.0, currencyID: 'USD' },
        declaredCustoms: {
          continuousBond: null,
          continuousBondExpiryDate: null,
          singleEntryBond: null,
          bondNumber: null,
          bondHolderName: null,
          foreignImporterOfRecord: false,
          value: null,
          entry: true,
          exit: null,
          commodityTypes: 0,
          personalShipment: null,
          ISF: null,
          ISFBond: null
        },
        productCode: null,
        productCodes: null,
        specialHandlingCodes: null,
        terms: null,
        commodityClassification: null,
        accessorials: null,
        generalInformation: null,
        condition: null,
        attachments: null,
        additionalInformation: null,
        hash: null,
        paymentInstructionType: null
      }
    }
  },
  {
    key: 'us-destination-lcl',
    label: 'us destination lcl',
    data: {
      messageHeader: null,
      businessInfo: null,
      shipment: {
        shipmentNumber: null,
        shipmentID: null,
        shipmentURL: null,
        shipmentCreatedDateTime: null,
        shipmentDirection: null,
        transportMode: null,
        shipmentSecured: null,
        transportService: null,
        transportServiceInScope: null,
        shipmentPriorityCode: null,
        originLocation: {
          locationTypeCode: 'Address',
          locationCode: null,
          locationName: 'warehouse',
          terminalCode: null,
          terminalName: null,
          addressFormatCode: null,
          countryID: { value: 'CN', schemeID: null, schemeAgencyID: null, schemeVersionID: null },
          regionID: null,
          countryName: null,
          stateOrProvince: 'Shanghai',
          cityID: null,
          cityName: 'Shanghai',
          streetName: null,
          postalCode: null,
          addressLines: ['Shanghai, China'],
          locationCoordinate: null,
          timezoneOffset: null,
          countrySubDivisionId: null,
          countrySubDivisionName: null,
          ID: 'ChIJMzz1sUBwsjURoWTDI5QSlQI'
        },
        destinationLocation: {
          locationTypeCode: 'Address',
          locationCode: null,
          locationName: 'business',
          terminalCode: null,
          terminalName: null,
          addressFormatCode: null,
          countryID: { value: 'US', schemeID: null, schemeAgencyID: null, schemeVersionID: null },
          regionID: null,
          countryName: null,
          stateOrProvince: 'CA',
          cityID: null,
          cityName: 'Los Angeles',
          streetName: null,
          postalCode: null,
          addressLines: ['Los Angeles, CA, USA'],
          locationCoordinate: null,
          timezoneOffset: null,
          countrySubDivisionId: null,
          countrySubDivisionName: null,
          ID: 'ChIJE9on3F3HwoAR9AhGJW_fL-I'
        },
        pickupEvent: {
          eventDate: {
            actualDateTime: null,
            scheduledDateTime: '2021-04-21T00:00:00Z',
            endDateTime: null
          },
          party: null
        },
        deliveryEvent: null,
        timeOfAvailability: null,
        latestAcceptanceDate: null,
        numberOfDays: null,
        validFrom: null,
        validTo: null,
        load: {
          allotmentID: null,
          product: null,
          products: null,
          netNetWeight: { value: 147.0, unitCode: 'kg' },
          netWeight: null,
          netVolumeMeasure: null,
          totalChargeableWeight: null,
          totalQuantity: 7,
          totalGrossVolumeMeasure: { value: 1.1272235519999998, unitCode: 'cbm' },
          hazardousInfo: null,
          declaredValue: { value: 750.0, currencyID: 'USD' },
          packages: [
            {
              packagingType: 'pallet_48_40',
              equipmentID: null,
              weight: { value: 21.0, unitCode: 'kg' },
              chargeableWeightMeasure: null,
              volumetricRatio: null,
              quantity: 7,
              freightClass: null,
              dimensions: null,
              packageDimensions: {
                width: { value: 101.6, unitCode: 'cm' },
                length: { value: 121.92, unitCode: 'cm' },
                height: { value: 13.0, unitCode: 'cm' },
                description: null
              },
              density: null,
              stackable: null,
              dangerousGood: null,
              uld: null,
              turnable: null,
              hazardousInfo: null,
              handlingInstructions: null,
              declaredValue: null,
              transportTemperature: null,
              overDimensionIndicator: null,
              overWeightIndicator: false
            }
          ],
          transportEquipments: null,
          commodity: null,
          temperature: null,
          batteryType: null,
          activeTrackingDevices: null
        },
        pricePreference: {
          requestCurrency: {
            value: 'USD',
            listID: null,
            listAgencyID: null,
            listVersionID: null,
            listURI: null
          },
          rateCurrency: null,
          rateType: null,
          promotionCode: null,
          rateUnitOfMeasure: null,
          responseTime: null,
          discountAmount: null,
          proposedRate: null,
          discountPercent: null,
          allInIndicator: null,
          includeCustomsBrokerage: null,
          includeOriginPortCharges: false,
          includeDestinationPortCharges: false,
          referenceRateID: null
        },
        rateInformation: null,
        connection: null,
        fulfilmentInfo: null,
        insuranceValueAmount: { value: 750.0, currencyID: 'USD' },
        declaredCustoms: {
          continuousBond: true,
          continuousBondExpiryDate: null,
          singleEntryBond: null,
          bondNumber: null,
          bondHolderName: null,
          foreignImporterOfRecord: false,
          value: null,
          entry: true,
          exit: null,
          commodityTypes: 0,
          personalShipment: null,
          ISF: null,
          ISFBond: null
        },
        productCode: null,
        productCodes: null,
        specialHandlingCodes: null,
        terms: null,
        commodityClassification: null,
        accessorials: [{ scope: 'Delivery', name: 'liftGate', value: null }],
        generalInformation: null,
        condition: null,
        attachments: null,
        additionalInformation: null,
        hash: null,
        paymentInstructionType: null
      }
    }
  },
  {
    key: 'amazon-us-destination',
    label: 'amazon us destination',
    data: {
      shipment: {
        originLocation: {
          locationTypeCode: 'Address',
          locationName: 'warehouse',
          countryID: { value: 'CN' },
          stateOrProvince: 'Shanghai',
          cityName: 'Shanghai',
          addressLines: ['Shanghai, China'],
          ID: 'ChIJMzz1sUBwsjURoWTDI5QSlQI'
        },
        destinationLocation: {
          locationTypeCode: 'Address',
          locationCode: '#DPX1',
          locationName: 'fulfillment',
          countryID: { value: 'US' },
          stateOrProvince: 'AZ',
          cityName: 'Phoenix',
          streetName: '500 South 48th Street ',
          postalCode: '85034',
          addressLines: ['500 S 48th St, Phoenix, AZ 85034, USA'],
          ID: 'ChIJM12yVJQOK4cR2ZB_jmfDWWg'
        },
        pickupEvent: {
          eventDate: {
            scheduledDateTime: '2021-05-06T00:00:00Z',
            endDateTime: '2021-05-20T00:00:00Z'
          }
        },
        load: {
          netNetWeight: { value: 100.0, unitCode: 'kg' },
          totalQuantity: 5,
          totalGrossVolumeMeasure: { value: 0.8051596799999999, unitCode: 'cbm' },
          declaredValue: { value: 300.0, currencyID: 'USD' },
          packages: [
            {
              packagingType: 'pallet_48_40',
              weight: { value: 20.0, unitCode: 'kg' },
              quantity: 5,
              packageDimensions: {
                width: { value: 101.6, unitCode: 'cm' },
                length: { value: 121.92, unitCode: 'cm' },
                height: { value: 13.0, unitCode: 'cm' }
              },
              overWeightIndicator: false
            }
          ]
        },
        pricePreference: {
          requestCurrency: { value: 'USD' },
          includeOriginPortCharges: false,
          includeDestinationPortCharges: false
        },
        fulfilmentInfo: {
          fulfilmentCompanyShipment: {
            additionalServices: {
              floorLoaded: false,
              palletization: false,
              labeling: true,
              fulfilmentCompanyAppointment: true,
              inOut: true,
              delivery: false
            },
            fulfilmentCompanyName: 'AMAZON'
          }
        },
        insuranceValueAmount: { value: 300.0, currencyID: 'USD' },
        declaredCustoms: {
          singleEntryBond: { value: 300.0, currencyID: 'USD' },
          foreignImporterOfRecord: false,
          entry: true,
          commodityTypes: 0
        }
      }
    }
  },
  {
    key: 'known-shipper',
    label: 'known shipper',
    data: {
      businessInfo: { parties: [{ partyTypeCode: 'BY', knownShipper: true }] },
      shipment: {
        originLocation: {
          locationTypeCode: 'Address',
          locationName: 'warehouse',
          countryID: { value: 'US' },
          stateOrProvince: 'CA',
          cityName: 'Los Angeles',
          addressLines: ['Los Angeles, CA, USA'],
          ID: 'ChIJE9on3F3HwoAR9AhGJW_fL-I'
        },
        destinationLocation: {
          locationTypeCode: 'Address',
          locationName: 'warehouse',
          countryID: { value: 'GB' },
          stateOrProvince: 'England',
          cityName: 'London',
          addressLines: ['London, UK'],
          ID: 'ChIJdd4hrwug2EcRmSrV3Vo6llI'
        },
        pickupEvent: { eventDate: { scheduledDateTime: '2021-04-29T00:00:00Z' } },
        load: {
          netNetWeight: { value: 84.0, unitCode: 'kg' },
          totalQuantity: 6,
          totalGrossVolumeMeasure: { value: 0.21483, unitCode: 'cbm' },
          declaredValue: { value: 1150.0, currencyID: 'USD' },
          packages: [
            {
              packagingType: 'boxes',
              weight: { value: 14.0, unitCode: 'kg' },
              quantity: 6,
              packageDimensions: {
                width: { value: 31.0, unitCode: 'cm' },
                length: { value: 35.0, unitCode: 'cm' },
                height: { value: 33.0, unitCode: 'cm' }
              },
              overWeightIndicator: false
            }
          ]
        },
        pricePreference: {
          requestCurrency: { value: 'USD' },
          includeOriginPortCharges: false,
          includeDestinationPortCharges: false
        },
        insuranceValueAmount: { value: 1150.0, currencyID: 'USD' },
        declaredCustoms: { foreignImporterOfRecord: false, commodityTypes: 0 }
      }
    }
  },
  {
    key: '611-height',
    label: '611 height',
    data: {
      shipment: {
        originLocation: {
          locationName: 'warehouse',
          locationTypeCode: 'address',
          countryID: {
            value: 'CN'
          },
          addressLines: ['Shanghai, China']
        },
        destinationLocation: {
          locationName: 'business',
          locationTypeCode: 'address',
          countryID: {
            value: 'US'
          },
          addressLines: ['New York, NY, USA']
        },
        pickupEvent: {
          eventDate: {
            scheduledDateTime: '2021-05-11T00:00:00+00:00',
            endDateTime: '2021-05-25T00:00:00+00:00'
          }
        },
        load: {
          packages: [
            {
              quantity: 1,
              packagingType: 'container40',
              overWeightIndicator: false
            }
          ],
          declaredValue: {
            value: 3434,
            currencyID: 'USD'
          }
        },
        accessorials: [],
        insuranceValueAmount: {
          value: 3434,
          currencyID: 'USD'
        },
        pricePreference: {
          includeOriginPortCharges: true,
          includeDestinationPortCharges: true,
          requestCurrency: 'USD'
        },
        declaredCustoms: {
          entry: true,
          continuousBond: true
        }
      }
    }
  },
  {
    key: 'Air',
    value: 'Air',
    data: {
      shipment: {
        originLocation: {
          locationName: 'port',
          locationTypeCode: 'airport',
          ID: 'SHA',
          countryID: { value: 'CN' },
          locationCode: 'SHA'
        },
        destinationLocation: {
          locationName: 'port',
          locationTypeCode: 'airport',
          ID: 'LHR',
          countryID: { value: 'GB' },
          locationCode: 'LHR'
        },
        additionalInformation: [],
        pickupEvent: {
          eventDate: { scheduledDateTime: '2023-03-15T00:00:00+00:00', endDateTime: '' }
        },
        load: {
          netNetWeight: { value: 1.6, unitCode: 'kg' },
          totalQuantity: 1,
          totalGrossVolumeMeasure: { value: 1.1, unitCode: 'cbm' },
          declaredValue: { value: 750, currencyID: 'USD' }
        },
        accessorials: [],
        insuranceValueAmount: { value: 750, currencyID: 'USD' },
        pricePreference: {
          requestCurrency: { value: 'USD' },
          includeOriginPortCharges: false,
          includeDestinationPortCharges: true
        },
        declaredCustoms: { singleEntryBond: { value: 750, currencyID: 'USD' }, entry: true }
      }
    }
  }
];
