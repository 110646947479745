import React, { useEffect } from 'react';
import { t } from 'utils/translationProvider';
import get from 'lodash/get';
import Loading from 'financialSharedComponents/components/common/Loading';
import { Button } from '@freightos/design-system';
import FreightosAutoPayBadge from 'financialSharedComponents/components/common/FreightosAutoPayBadge';
import CreditCardTokenizeFormFactory from './CreditCardTokenizeForm';
import CreditCardList from './CreditCardList';

const CreditCardsMgmt = ({
  onSubmitToken,
  fetchPaymentMethods,
  isPaymentMethodsInitialized,
  defaultCard,
  setIsChangingPaymentMethod,
  isChangingPaymentMethod
}) => {
  useEffect(() => {
      fetchPaymentMethods();
  }, []);
  let isDefaultCardRegistered = get(defaultCard, 'registered', false);
  useEffect(() => {
    if (isPaymentMethodsInitialized) {
      if(!isDefaultCardRegistered)
      setIsChangingPaymentMethod(true);
    } else {
      setIsChangingPaymentMethod(false);
    }
  }, [isDefaultCardRegistered, isPaymentMethodsInitialized, defaultCard]);
  return (
    <React.Fragment>
      {((!isPaymentMethodsInitialized)) && <Loading />} 
      {(isPaymentMethodsInitialized || defaultCard) && (
        <div data-test-id="credit-card-management-header-div">
          {!isChangingPaymentMethod && isDefaultCardRegistered && (
            <React.Fragment>
              <div className="margin-bottom font-bolded children-vertical-align-middle">
                <span>
                  <b>{t('Use my saved credit card', 'Use my saved credit card')}</b>
                </span>
                <FreightosAutoPayBadge />
              </div>
              <CreditCardList defaultCard={defaultCard} />
            </React.Fragment>
          )}
          {(!isDefaultCardRegistered || isChangingPaymentMethod) && (
            <React.Fragment>
              <div className="margin-bottom font-bolded children-vertical-align-middle">
                <span>
                  {!isDefaultCardRegistered && (
                    <b>
                      {t(
                        'actionRequired/payment_add_cc_details_header2_text',
                        'Add your credit card details'
                      )}
                    </b>
                  )}
                  {isDefaultCardRegistered && isChangingPaymentMethod && (
                    <b>
                      Enter updated credit card details or{' '}
                      <Button
                        className={'use-my-saved-card-text'}
                        type={'text'}
                        onClick={() => {
                          setIsChangingPaymentMethod(false);
                        }}
                      >
                        use saved card
                      </Button>
                    </b>
                  )}
                </span>
              </div>
              <CreditCardTokenizeFormFactory registerToAutoPay={true} onSubmitToken={onSubmitToken} />
            </React.Fragment>
          )}
        </div>
      )}
    </React.Fragment>
  );
};

CreditCardsMgmt.propTypes = {};

export default CreditCardsMgmt;
